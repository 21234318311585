<template>
  <div id="appBar" ref="appBar">

    <v-app-bar app clipped-left color="primary" class="white--text">
      <v-btn icon dense class="white--text mb-0" @click="$emit('toggleDrawer')">
        <v-icon>
          mdi-menu
        </v-icon>
      </v-btn>
      <v-toolbar-title>Functional Intelligence Tool</v-toolbar-title>

      <v-spacer></v-spacer>
      
      <!--<v-btn
        dense
        :color="overallStatus"
        class="mb-0"
        width="130px"
        @click="statusDialog = !statusDialog"
      >
        <v-icon>
          {{ overallStatus == 'success' ? 'mdi-check-circle' : 'mdi-alert' }}
        </v-icon>
        <v-spacer></v-spacer>
        {{ overallStatus == 'success' || overallStatus == 'warning' ? 'ONLINE' : 'OFFLINE' }}
      </v-btn>-->

    </v-app-bar>

    <v-dialog
      v-model="statusDialog"
      width="300"
    >
      <v-card>
        <v-card-title class="primary white--text">Systeem status</v-card-title>
        <v-list>
          <v-list-item v-for="x in status" v-bind:key="x.name">
            <v-list-item-icon>
              <v-icon :color="x.online ? 'success' : (x.name == 'Incident detectie' ? 'warning' : 'error')">
                {{ x.online ? 'mdi-check-circle' : 'mdi-alert' }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content v-if="x.name == 'Incident detectie'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{x.name}} <v-icon small color="primary">mdi-help-circle</v-icon></span>
                </template>
                <span>Aantal verbonden systemen: {{ incidentDetectionSysCount }}</span>
              </v-tooltip>
            </v-list-item-content>
            <v-list-item-content v-else>
              {{ x.name }}
            </v-list-item-content>

          </v-list-item>
        </v-list>
        <v-card-text>
          <strong>API informatie</strong><br>
          <span>Versie</span> 
          <v-skeleton-loader 
            v-if="!status.api.online"
            type="text"
            class="mx-auto mt-1"
            width="100"
            tile
            style="display:inline; float:right;"
          >
          </v-skeleton-loader><span v-else style="float:right;">{{ apiInfo.version }}</span><br>
          <span>Auteur</span>
          <v-skeleton-loader 
            v-if="!status.api.online"
            type="text"
            class="mx-auto mt-1"
            width="100"
            tile
            style="display:inline; float:right;"
          >
          </v-skeleton-loader><span v-else style="float:right;">{{ apiInfo.author }}</span><br>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: [
    'status'
  ],
  data: function() {
    return {
      statusDialog: false
    }
  },
  computed: {
    overallStatus() {
      let result = 'success'
      for(let x in this.status) {
        // Temp model ignore
        if(!this.status[x].online && x != "model") {
          result = 'error'
          break
        } else if (!this.status[x].online && x == "model") {
          result = 'warning'
        }
      }
      return result
    },
    apiInfo() {
      return this.$store.getters.getApiInfo
    },
    incidentDetectionSysCount() {
      return this.$store.getters.getSystemCount
    }
  },
}
</script>

