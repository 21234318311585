import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import AsyncComputed from 'vue-async-computed'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import VueApexCharts from 'vue-apexcharts'
import titleMixin from './assets/dependencies/titleMixin'
export const bus = new Vue();
Vue.use(VueApexCharts)
Vue.use(AsyncComputed)

Vue.component('apexchart', VueApexCharts)

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);


Vue.config.productionTip = false


Vue.mixin({
  methods: {
    stringToDate(dateString) {
      return new Date(dateString + "+00:00")
    }
  }
})

Vue.mixin(titleMixin)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

