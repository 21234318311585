<template>
  <div id="alerts">
    <v-alert v-for="alert in alerts" v-bind:key="alert.id"
      dense
      :type="alert.type"
      :dismissible="alert.dismissable"
    >{{ alert.message }}</v-alert>
  </div>
</template>

<script>
export default {
  computed: {
    alerts() {
      return this.$store.getters.getAlerts
    }
  },
}
</script>

