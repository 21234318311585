<template>
  <div :style="{ height: dynamicHeight + 'px' }">
    <div id="mynetwork"></div>
  </div>
</template>


<script>
import vis from "vis-network/dist/vis-network.min.js";


export default {
  namapHeightme: 'Network',
  components: {
  },

  mounted() {
    this.reCalc(this.tweets);
  },

  watch: {
    tweets(newTweets) {
      this.reCalc(newTweets);
    },
  },

  methods: {
    reCalc: function (tweetList) {
      const maxNumNodes = 200;

      let networkNodes = {};
      let networkEdges = {};
      let id_index = 0;

      for (let i = 0; i < tweetList.length; i++) {
        let keyWords = Object.keys(tweetList[i].keyword_clusters);
        for (let j = 0; j < keyWords.length; j++) {
          let keyWord = keyWords[j];
          if ( Object.hasOwn(networkNodes, keyWord) ) {
            networkNodes[keyWord].value = networkNodes[keyWord].value + 1;
          } else {
            networkNodes[keyWord] = {
              id: id_index, label: keyWord, value: 1,
              group: tweetList[i].keyword_clusters[keyWord],
              title: keyWord,
            }
            id_index = id_index + 1;
          }
        }
      }

      let networkNodesList = Object.values(networkNodes).sort(function (a, b) {
        return parseFloat(b.value) - parseFloat(a.value);
      }).slice(0, maxNumNodes);

      let noteNames = new Set(networkNodesList.map(function(obj) {return obj.label;}));

       for (let i = 0; i < tweetList.length; i++) {
         let keyWords = Object.keys(tweetList[i].keyword_clusters);
         let keywordCombinations = keyWords.flatMap((v, i) => keyWords.slice(i+1).map( w => [v,w] ));
         for (let j = 0; j < keywordCombinations.length; j++) {
           let keyWordCombination = keywordCombinations[j];
           if (noteNames.has(keyWordCombination[0]) && noteNames.has(keyWordCombination[1])) {
             let edgeName = keyWordCombination.join("_");
             let edgeNameRev = keyWordCombination.reverse().join("_");

             if ( Object.hasOwn(networkEdges, edgeName) ) {
               networkEdges[edgeName].value = networkEdges[edgeName].value + 1;
             } else if ( Object.hasOwn(networkEdges, edgeNameRev) ) {
               networkEdges[edgeNameRev].value = networkEdges[edgeNameRev].value + 1;
             } else {
               networkEdges[edgeName] = { from: networkNodes[keyWordCombination[0]].id , to: networkNodes[keyWordCombination[1]].id, value: 1 };
             }
           }
         }
       }

       let networkEdgesList = Object.values(networkEdges).sort(function (a, b) {
         return parseFloat(b.value) - parseFloat(a.value);
       }).slice(0, maxNumNodes*2);


       console.log(networkNodesList);
       console.log(networkNodesList.length);
       console.log(networkEdgesList);
       console.log(networkEdgesList.length);


       this.nodes = new vis.DataSet(networkNodesList);
       this.edges = new vis.DataSet(networkEdgesList);

       this.container = document.getElementById("mynetwork");

       this.data = {
         nodes: this.nodes,
         edges: this.edges,
       };

       this.options = {
             nodes: {
               shape: "dot",
               scaling: {
                 min: 20,
                 max: 80,
               },
               font: {
                 size: 12,
                 face: "Tahoma",
               },
             },
             edges: {
               width: 0.15,
               color: { inherit: "both" },
               smooth: {
                 type: "continuous",
               },
             },
             physics: {
               stabilization: false,
               barnesHut: {
                 gravitationalConstant: -80000,
                 springConstant: 0.001,
                 springLength: 200,
               },
             },
             interaction: {
               tooltipDelay: 200,
               hideEdgesOnDrag: false,
             },
           };

       new vis.Network(this.container, this.data, this.options);
    },
  },

  data: function() {
    return {
      selectedIncident: null,
      windowHeight: window.innerHeight,
      data: null,
      nodes: null,
      edges: null,
      container: null,
      options: {},
    }
  },

  computed: {
    dynamicHeight() {
      const otherElement = this.$refs.appBar;
      const seachBar = this.$refs.searchBar;
      const topics = this.$refs.myTopics;
      const otherElementHeight = otherElement ? otherElement.clientHeight : 64;
      const seachBarHeight = seachBar ? seachBar.clientHeight : 76;
      const topicsHeight = topics ? topics.clientHeight : 240;
      const margin = 60;
      return window.innerHeight - otherElementHeight - seachBarHeight - topicsHeight - margin;
    },
    tweets() {
      return this.$store.state.tweets;
    },
  },
}


</script>

<style scoped>

html,
body {
}
#mynetwork {
  width: 100%;
  height: 100%;
  border: 1px solid lightgray;
}

</style>

