import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
// import { HTTP } from '@/services/axios.js'

export default new Vuex.Store({
  state: {
    incidents: [],
    alerts: [],
    api_info: {},
    dataLoading: true,
    bins: [],
    detectionSystemCount: 0,
    keywords: [],
    tweetsOrg: [
{sentiment: 0.9979422688484192, lat: null, lng: null, count: 1, topics: ['lente', 'keukenhof'], group: 'piet', emojis: [], keywords: ['Keukenhof', 'publiek', 'lente', 'Keukenhof'], full_text: "Eindelijk is het weer zo ver! De Keukenhof is open voor het publiek. #lente #Keukenhof", keyword_clusters: {'Keukenhof': 0, 'publiek': 7, 'lente': 4}},
{sentiment: 0.9982944130897522, lat: null, lng: null, count: 1, topics: ['bloemenpracht', 'keukenhof'], group: 'piet', emojis: [], keywords: ['Keukenhof', 'aanrader', 'bloemenpracht', 'Keukenhof'], full_text: "Ben jij al eens in de Keukenhof geweest? Het is een absolute aanrader! #bloemenpracht #Keukenhof", keyword_clusters: {'Keukenhof': 0, 'aanrader': 5, 'bloemenpracht': 0}},
{sentiment: 0.9982806444168091, lat: null, lng: null, count: 1, topics: ['tulpen', 'keukenhof'], group: 'piet', emojis: [], keywords: ['park', 'Keukenhof', 'bloemen', 'bezoekje', 'tulpen', 'Keukenhof'], full_text: "Het park van de Keukenhof staat vol met de mooiste bloemen. Wanneer ga jij een bezoekje brengen? #tulpen #Keukenhof", keyword_clusters: {'park': 5, 'Keukenhof': 0, 'bloemen': 0, 'bezoekje': 0, 'tulpen': 7}},
{sentiment: 0.9983695149421692, lat: null, lng: null, count: 1, topics: ['bloemenzee', 'keukenhof'], group: 'piet', emojis: [], keywords: ['Keukenhof', 'jaar', 'miljoen', 'bloembollen', 'plant', 'bloemenzee', 'Keukenhof'], full_text: "Wist je dat de Keukenhof elk jaar meer dan 7 miljoen bloembollen plant? #bloemenzee #Keukenhof", keyword_clusters: {'Keukenhof': 0, 'jaar': 4, 'miljoen': 4, 'bloembollen': 4, 'plant': 4, 'bloemenzee': 5}},
{sentiment: 0.9981655478477478, lat: null, lng: null, count: 1, topics: ['zomerbloemen', 'keukenhof'], group: 'piet', emojis: [], keywords: ['Keukenhof', 'voorjaar', 'zomer', 'zomerbloemen', 'Keukenhof'], full_text: "De Keukenhof is niet alleen prachtig in het voorjaar, maar ook in de zomer is er genoeg te zien. #zomerbloemen #Keukenhof", keyword_clusters: {'Keukenhof': 0, 'voorjaar': 5, 'zomer': 7, 'zomerbloemen': 0}},
{sentiment: 0.9980136156082153, lat: null, lng: null, count: 1, topics: ['weekendtip', 'keukenhof'], group: 'piet', emojis: [], keywords: ['plannen', 'weekend', 'Keukenhof', 'bloemenpracht', 'weekendtip', 'Keukenhof'], full_text: "Heb je nog geen plannen voor het weekend? Ga dan naar de Keukenhof en geniet van de kleurrijke bloemenpracht. #weekendtip #Keukenhof", keyword_clusters: {'plannen': 7, 'weekend': 5, 'Keukenhof': 0, 'bloemenpracht': 0, 'weekendtip': 5}},
{sentiment: 0.9976294636726379, lat: null, lng: null, count: 1, topics: ['familieuitje', 'keukenhof'], group: 'piet', emojis: [], keywords: ['Keukenhof', 'uitje', 'gezin', 'familieuitje', 'Keukenhof'], full_text: "De Keukenhof is het perfecte uitje voor het hele gezin. Er is genoeg te zien en te doen voor jong en oud. #familieuitje #Keukenhof", keyword_clusters: {'Keukenhof': 0, 'uitje': 7, 'gezin': 7, 'familieuitje': 4}},
{sentiment: 0.9979047775268555, lat: null, lng: null, count: 1, topics: ['geurigeoase', 'keukenhof'], group: 'piet', emojis: [], keywords: ['Keukenhof', 'lust', 'oog', 'neus', 'geur', 'bloemen', 'geurigeoase', 'Keukenhof'], full_text: "De Keukenhof is niet alleen een lust voor het oog, maar ook voor de neus. De geur van de bloemen is heerlijk! #geurigeoase #Keukenhof", keyword_clusters: {'Keukenhof': 0, 'lust': 4, 'oog': 5, 'neus': 4, 'geur': 4, 'bloemen': 0, 'geurigeoase': 0}},
{sentiment: 0.998295247554779, lat: 52.2434979, lng: 5.6343227, count: 1, topics: ['trotsopnl', 'keukenhof'], group: 'piet', emojis: [], keywords: ['Keukenhof', 'attracties', 'Nederland', 'Keukenhof'], full_text: "De Keukenhof is een van de bekendste attracties van Nederland en dat is niet voor niets. #trotsopNL #Keukenhof", keyword_clusters: {'Keukenhof': 0, 'attracties': 7, 'Nederland': 5}},
{sentiment: 0.9978654980659485, lat: null, lng: null, count: 1, topics: ['bloemenliefhebber', 'keukenhof'], group: 'piet', emojis: [], keywords: ['liefhebber', 'bloemen', 'planten', 'bezoekje', 'Keukenhof', 'spijt', 'bloemenliefhebber', 'Keukenhof'], full_text: "Ben jij ook zo'n liefhebber van bloemen en planten? Breng dan snel een bezoekje aan de Keukenhof. Je zult er geen spijt van krijgen! #bloemenliefhebber #Keukenhof", keyword_clusters: {'liefhebber': 7, 'bloemen': 0, 'planten': 5, 'bezoekje': 0, 'Keukenhof': 0, 'spijt': 5, 'bloemenliefhebber': 0}},
{sentiment: 0.9844051003456116, lat: null, lng: null, count: 1, topics: ['verdeeldheid', 'ongemak'], group: 'piet', emojis: [], keywords: ['partij', 'keer', 'keer', 'mensen', 'opzet', 'verdeeldheid', 'ongemak'], full_text: "Het is ongelofelijk hoe deze politieke partij keer op keer mensen tegen elkaar opzet. #verdeeldheid #ongemak", keyword_clusters: {'partij': 4, 'keer': 7, 'mensen': 0, 'opzet': 5, 'verdeeldheid': 2, 'ongemak': 2}},
{sentiment: 0.022108912467956543, lat: null, lng: null, count: 1, topics: ['zorgwekkend', 'ongemak'], group: 'piet', emojis: [], keywords: ['mensen', 'partij', 'standpunten', 'ongemak'], full_text: "Ik kan niet geloven dat er zoveel mensen op deze partij stemmen, gezien hun intolerante standpunten. #zorgwekkend #ongemak", keyword_clusters: {'mensen': 0, 'partij': 4, 'standpunten': 0, 'ongemak': 2}},
{sentiment: 0.9973764419555664, lat: 53.0006, lng: 6.68635, count: 1, topics: ['ongemak', 'verdeeldheid'], group: 'piet', emojis: [], keywords: ['partij', 'vrijheid', 'verdeeldheid', 'ongemak', 'verdeeldheid'], full_text: "Het is pijnlijk om te zien hoe deze partij de vrijheid van anderen beknot en zo verdeeldheid zaait. #ongemak #verdeeldheid", keyword_clusters: {'partij': 4, 'vrijheid': 5, 'verdeeldheid': 2, 'ongemak': 2}},
{sentiment: 0.9981741905212402, lat: null, lng: null, count: 1, topics: ['uitsluiting', 'ongemak'], group: 'piet', emojis: [], keywords: ['retoriek', 'partij', 'samenleving', 'uitsluiting', 'ongemak'], full_text: "De retoriek van deze partij is beangstigend en draagt niet bij aan een inclusieve samenleving. #uitsluiting #ongemak", keyword_clusters: {'retoriek': 0, 'partij': 4, 'samenleving': 7, 'uitsluiting': 2, 'ongemak': 2}},
{sentiment: 0.9808767437934875, lat: null, lng: null, count: 1, topics: ['ongemak', 'discriminatie'], group: 'piet', emojis: [], keywords: ['partij', 'achterban', 'ongemak', 'discriminatie'], full_text: "Het is verontrustend dat deze partij openlijk discrimineert en toch zo'n grote achterban heeft. #ongemak #discriminatie", keyword_clusters: {'partij': 4, 'achterban': 4, 'ongemak': 2, 'discriminatie': 2}},
{sentiment: 0.9951432943344116, lat: null, lng: null, count: 1, topics: ['kwetsbaarheid', 'ongemak'], group: 'piet', emojis: [], keywords: ['manier', 'partij', 'groepen', 'samenleving', 'kwetsbaarheid', 'ongemak'], full_text: "Ik voel me ongemakkelijk bij de manier waarop deze partij omgaat met kwetsbare groepen in de samenleving. #kwetsbaarheid #ongemak", keyword_clusters: {'manier': 5, 'partij': 4, 'groepen': 5, 'samenleving': 7, 'kwetsbaarheid': 2, 'ongemak': 2}},
{sentiment: 0.06812775135040283, lat: null, lng: null, count: 1, topics: ['angstzaaierij', 'ongemak'], group: 'piet', emojis: [], keywords: ['partij', 'verdeeldheid', 'angst', 'angstzaaierij', 'ongemak'], full_text: "Het lijkt wel alsof deze partij erop uit is om verdeeldheid te zaaien en angst te creëren. #angstzaaierij #ongemak", keyword_clusters: {'partij': 4, 'verdeeldheid': 2, 'angst': 2, 'angstzaaierij': 2, 'ongemak': 2}},
{sentiment: 0.05385720729827881, lat: null, lng: null, count: 1, topics: ['ongemak', 'radicalisme'], group: 'piet', emojis: [], keywords: ['mensen', 'verstand', 'partij', 'standpunten', 'ongemak', 'radicalisme'], full_text: "Ik kan me niet voorstellen dat mensen met gezond verstand op deze partij stemmen, gezien hun radicale standpunten. #ongemak #radicalisme", keyword_clusters: {'mensen': 0, 'verstand': 7, 'partij': 4, 'standpunten': 0, 'ongemak': 2, 'radicalisme': 2}},
{sentiment: 0.9969203472137451, lat: null, lng: null, count: 1, topics: ['polarisatie', 'ongemak'], group: 'piet', emojis: [], keywords: ['partij', 'ruimte', 'debat', 'polarisatie', 'polarisatie', 'ongemak'], full_text: "Het is teleurstellend dat deze partij zoveel ruimte krijgt in het publieke debat, terwijl ze alleen maar polarisatie veroorzaken. #polarisatie #ongemak", keyword_clusters: {'partij': 4, 'ruimte': 5, 'debat': 7, 'polarisatie': 2, 'ongemak': 2}},
{sentiment: 0.9942400455474854, lat: null, lng: null, count: 1, topics: ['zorgelijk', 'ongemak'], group: 'piet', emojis: [], keywords: ['opkomst', 'partij', 'gevolgen', 'democratie', 'samenleving', 'ongemak'], full_text: "Ik ben echt bezorgd over de opkomst van deze partij en de gevolgen die dat kan hebben voor onze democratie en samenleving. #zorgelijk #ongemak", keyword_clusters: {'opkomst': 7, 'partij': 4, 'gevolgen': 1, 'democratie': 5, 'samenleving': 7, 'ongemak': 2}},
{sentiment: 0.9982354640960693, lat: null, lng: null, count: 1, topics: ['indirectetijdsaanduiding'], group: 'piet', emojis: [], keywords: ['indirectetijdsaanduiding'], full_text: "Ik hoop dat alles goed met je gaat, nadat we elkaar voor het laatst hebben gesproken. #indirectetijdsaanduiding", keyword_clusters: {'indirectetijdsaanduiding': 4}},
{sentiment: 0.9976682066917419, lat: null, lng: null, count: 1, topics: ['indirectetijdsaanduiding'], group: 'piet', emojis: [], keywords: ['boek', 'tijd', 'indirectetijdsaanduiding'], full_text: "Het is zo lang geleden dat ik een boek heb gelezen. Misschien wordt het weer tijd om er eentje te pakken. #indirectetijdsaanduiding", keyword_clusters: {'boek': 4, 'tijd': 3, 'indirectetijdsaanduiding': 4}},
{sentiment: 0.9982408285140991, lat: null, lng: null, count: 1, topics: ['indirectetijdsaanduiding'], group: 'piet', emojis: [], keywords: ['kind', 'strand', 'indirectetijdsaanduiding'], full_text: "Ik herinner me nog dat ik als kind altijd naar het strand wilde, maar nu kom ik er bijna nooit meer. #indirectetijdsaanduiding", keyword_clusters: {'kind': 5, 'strand': 7, 'indirectetijdsaanduiding': 4}},
{sentiment: 0.9977832436561584, lat: null, lng: null, count: 1, topics: ['indirectetijdsaanduiding'], group: 'piet', emojis: [], keywords: ['prijzen', 'boodschappen', 'producten', 'indirectetijdsaanduiding'], full_text: "Het lijkt wel alsof de prijzen van boodschappen steeds hoger worden, terwijl het nog maar kort geleden is dat ik voor dezelfde producten veel minder betaalde. #indirectetijdsaanduiding", keyword_clusters: {'prijzen': 0, 'boodschappen': 4, 'producten': 0, 'indirectetijdsaanduiding': 4}},
{sentiment: 0.997918426990509, lat: null, lng: null, count: 1, topics: ['indirectetijdsaanduiding'], group: 'piet', emojis: [], keywords: ['tijd', 'tijd', 'momentje', 'indirectetijdsaanduiding'], full_text: "De afgelopen tijd heb ik steeds minder tijd voor mezelf gehad. Ik moet echt weer eens wat vaker een momentje voor mezelf nemen. #indirectetijdsaanduiding", keyword_clusters: {'tijd': 3, 'momentje': 5, 'indirectetijdsaanduiding': 4}},
{sentiment: 0.9982604384422302, lat: null, lng: null, count: 1, topics: ['indirectetijdsaanduiding'], group: 'piet', emojis: [], keywords: ['vriend', 'tijdje', 'berichtje', 'indirectetijdsaanduiding'], full_text: "Ik ben benieuwd hoe het met die ene vriend gaat die ik al een tijdje niet meer heb gesproken. Misschien moet ik hem weer eens een berichtje sturen. #indirectetijdsaanduiding", keyword_clusters: {'vriend': 7, 'tijdje': 7, 'berichtje': 4, 'indirectetijdsaanduiding': 4}},
{sentiment: 0.9978395700454712, lat: null, lng: null, count: 1, topics: ['indirectetijdsaanduiding'], group: 'piet', emojis: [], keywords: ['regen', 'tijd', 'indirectetijdsaanduiding'], full_text: "Vroeger vond ik het heerlijk om in de regen te spelen, maar de laatste tijd ben ik altijd bang om ziek te worden als ik nat word. #indirectetijdsaanduiding", keyword_clusters: {'regen': 5, 'tijd': 3, 'indirectetijdsaanduiding': 4}},
{sentiment: 0.9976606369018555, lat: null, lng: null, count: 1, topics: ['indirectetijdsaanduiding'], group: 'piet', emojis: [], keywords: ['dagen', 'avond', 'indirectetijdsaanduiding'], full_text: "Het lijkt wel alsof de dagen steeds sneller voorbijgaan. Voor ik het weet is het alweer avond. #indirectetijdsaanduiding", keyword_clusters: {'dagen': 4, 'avond': 0, 'indirectetijdsaanduiding': 4}},
{sentiment: 0.9977850914001465, lat: null, lng: null, count: 1, topics: ['indirectetijdsaanduiding'], group: 'piet', emojis: [], keywords: ['student', 'tijd', 'schema', 'indirectetijdsaanduiding'], full_text: "Ik herinner me nog dat ik als student altijd tijd had om te sporten, maar nu vind ik het lastig om het in mijn drukke schema in te passen. #indirectetijdsaanduiding", keyword_clusters: {'student': 7, 'tijd': 3, 'schema': 4, 'indirectetijdsaanduiding': 4}},
{sentiment: 0.9976154565811157, lat: null, lng: null, count: 1, topics: ['indirectetijdsaanduiding'], group: 'piet', emojis: [], keywords: ['baan', 'maanden', 'indirectetijdsaanduiding'], full_text: "Het voelt als gisteren dat ik aan mijn nieuwe baan begon, maar inmiddels zijn er alweer maanden voorbijgevlogen. #indirectetijdsaanduiding", keyword_clusters: {'baan': 0, 'maanden': 4, 'indirectetijdsaanduiding': 4}},
{sentiment: 0.998254120349884, lat: 52.370216, lng: 4.895168, count: 1, topics: ['protest', 'datum', 'tijd', 'locatie'], group: 'piet', emojis: [], keywords: ['zaterdag', '15:00', 'uur', 'protest', 'plaats', 'Dam', 'Amsterdam', 'wetgeving', 'protest', 'datum', 'tijd', 'locatie'], full_text: "Komende zaterdag om 15:00 uur vindt er een protest plaats op de Dam in Amsterdam tegen de nieuwe wetgeving. #protest #datum #tijd #locatie", keyword_clusters: {'zaterdag': 0, '15:00': 0, 'uur': 0, 'protest': 1, 'plaats': 0, 'Dam': 7, 'Amsterdam': 0, 'wetgeving': 0, 'datum': 3, 'tijd': 3, 'locatie': 3}},
{sentiment: 0.9981043338775635, lat: null, lng: null, count: 1, topics: ['voetbal', 'datum', 'tijd', 'locatie'], group: 'piet', emojis: [], keywords: ['juni', 'uur', 'elftal', 'wedstrijd', 'Portugal', 'Johan', 'Cruijff', 'ArenA', 'Amsterdam', 'voetbal', 'datum', 'tijd', 'locatie'], full_text: "Op 5 juni om 20:00 uur speelt het Nederlands elftal een belangrijke wedstrijd tegen Portugal in de Johan Cruijff ArenA in Amsterdam. #voetbal #datum #tijd #locatie", keyword_clusters: {'juni': 7, 'uur': 0, 'elftal': 7, 'wedstrijd': 5, 'Portugal': 4, 'Johan': 5, 'Cruijff': 7, 'ArenA': 7, 'Amsterdam': 0, 'voetbal': 6, 'datum': 3, 'tijd': 3, 'locatie': 3}},
{sentiment: 0.9982705116271973, lat: null, lng: null, count: 1, topics: ['lezing', 'datum', 'tijd', 'locatie'], group: 'piet', emojis: [], keywords: ['donderdag', 'uur', 'lezing', 'duurzaamheid', 'bibliotheek', 'Utrecht', 'lezing', 'datum', 'tijd', 'locatie'], full_text: "Aanstaande donderdag om 19:30 uur geef ik een lezing over duurzaamheid in de bibliotheek van Utrecht. #lezing #datum #tijd #locatie", keyword_clusters: {'donderdag': 0, 'uur': 0, 'lezing': 6, 'duurzaamheid': 1, 'bibliotheek': 5, 'Utrecht': 7, 'datum': 3, 'tijd': 3, 'locatie': 3}},
{sentiment: 0.9976430535316467, lat: null, lng: null, count: 1, topics: ['keukenhof', 'datum', 'tijd', 'locatie'], group: 'piet', emojis: [], keywords: ['september', 'uur', 'Keukenhof', 'deuren', 'publiek', 'Keukenhof', 'datum', 'tijd', 'locatie'], full_text: "Op 23 september om 12:00 uur opent de Keukenhof haar deuren weer voor het publiek. Mis het niet! #Keukenhof #datum #tijd #locatie", keyword_clusters: {'september': 5, 'uur': 0, 'Keukenhof': 0, 'deuren': 4, 'publiek': 7, 'datum': 3, 'tijd': 3, 'locatie': 3}},
{sentiment: 0.9981197714805603, lat: null, lng: null, count: 1, topics: ['concert', 'datum', 'tijd', 'locatie'], group: 'piet', emojis: [], keywords: ['mei', 'uur', 'concert', 'plaats', 'Rotterdams', 'Philharmonisch', 'Orkest', 'De', 'Doelen', 'Rotterdam', 'concert', 'datum', 'tijd', 'locatie'], full_text: "Op 14 mei om 19:00 uur vindt er een concert plaats van het Rotterdams Philharmonisch Orkest in De Doelen in Rotterdam. #concert #datum #tijd #locatie", keyword_clusters: {'mei': 4, 'uur': 0, 'concert': 6, 'plaats': 0, 'Rotterdams': 0, 'Philharmonisch': 0, 'Orkest': 0, 'De': 4, 'Doelen': 7, 'Rotterdam': 0, 'datum': 3, 'tijd': 3, 'locatie': 3}},
{sentiment: 0.9984696507453918, lat: null, lng: null, count: 1, topics: ['workshop', 'datum', 'tijd', 'locatie'], group: 'piet', emojis: [], keywords: ['uur', 'workshop', 'storytelling', 'Tropenmuseum', 'Amsterdam', 'workshop', 'datum', 'tijd', 'locatie'], full_text: "Morgenochtend om 9:00 uur geef ik een workshop over storytelling in het Tropenmuseum in Amsterdam. #workshop #datum #tijd #locatie", keyword_clusters: {'uur': 0, 'workshop': 6, 'storytelling': 7, 'Tropenmuseum': 4, 'Amsterdam': 0, 'datum': 3, 'tijd': 3, 'locatie': 3}},
{sentiment: 0.9982026815414429, lat: null, lng: null, count: 1, topics: ['buurtbarbecue', 'datum', 'tijd', 'locatie'], group: 'piet', emojis: [], keywords: ['juli', 'uur', 'buurtbarbecue', 'plaats', 'park', 'Kerkstraat', 'Den', 'Haag', 'buurtbarbecue', 'datum', 'tijd', 'locatie'], full_text: "Op 17 juli om 14:00 uur vindt er een buurtbarbecue plaats in het park bij de Kerkstraat in Den Haag. Neem allemaal iets lekkers mee! #buurtbarbecue #datum #tijd #locatie", keyword_clusters: {'juli': 5, 'uur': 0, 'buurtbarbecue': 6, 'plaats': 0, 'park': 5, 'Kerkstraat': 5, 'Den': 0, 'Haag': 5, 'datum': 3, 'tijd': 3, 'locatie': 3}},
{sentiment: 0.9981649518013, lat: 51.4392648, lng: 5.478633, count: 1, topics: ['marathon', 'datum', 'tijd', 'locatie'], group: 'piet', emojis: [], keywords: ['oktober', 'uur', 'marathon', 'Eindhoven', 'lopers', 'marathon', 'datum', 'tijd', 'locatie'], full_text: "Op 9 oktober om 10:00 uur wordt de jaarlijkse marathon van Eindhoven gelopen. Kom jij ook de lopers aanmoedigen? #marathon #datum #tijd #locatie", keyword_clusters: {'oktober': 5, 'uur': 0, 'marathon': 6, 'Eindhoven': 5, 'lopers': 0, 'datum': 3, 'tijd': 3, 'locatie': 3}},
{sentiment: 0.9981934428215027, lat: null, lng: null, count: 1, topics: ['rommelmarkt', 'datum', 'tijd', 'locatie'], group: 'piet', emojis: [], keywords: ['augustus', 'uur', 'rommelmarkt', 'plaats', 'Kinkerstraat', 'Amsterdam', 'spulletjes', 'rommelmarkt', 'datum', 'tijd', 'locatie'], full_text: "Op 21 augustus om 13:00 uur vindt er een rommelmarkt plaats in de Kinkerstraat in Amsterdam. Kom langs en scoor de leukste spulletjes! #rommelmarkt #datum #tijd #locatie", keyword_clusters: {'augustus': 5, 'uur': 0, 'rommelmarkt': 6, 'plaats': 0, 'Kinkerstraat': 4, 'Amsterdam': 0, 'spulletjes': 0, 'datum': 3, 'tijd': 3, 'locatie': 3}},
{sentiment: 0.9984134435653687, lat: null, lng: null, count: 1, topics: ['tentoonstelling', 'datum', 'tijd', 'locatie'], group: 'piet', emojis: [], keywords: ['week', 'dinsdag', 'uur', 'tentoonstelling', 'Rijksmuseum', 'Amsterdam', 'tentoonstelling', 'datum', 'tijd', 'locatie'], full_text: "Volgende week dinsdag om 18:00 uur opent de nieuwe tentoonstelling in het Rijksmuseum in Amsterdam. Mis het niet! #tentoonstelling #datum #tijd #locatie", keyword_clusters: {'week': 0, 'dinsdag': 7, 'uur': 0, 'tentoonstelling': 6, 'Rijksmuseum': 4, 'Amsterdam': 0, 'datum': 3, 'tijd': 3, 'locatie': 3}},
{sentiment: 0.9980883002281189, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'milieu', 'uitbreiding', 'ongerustheid'], group: 'piet', emojis: [], keywords: ['impact', 'uitbreiding', 'Schiphol', 'milieu', 'Schiphol', 'milieu', 'uitbreiding', 'ongerustheid'], full_text: "Wanneer gaan we eens nadenken over de impact van de uitbreiding van Schiphol op ons milieu? #Schiphol #milieu #uitbreiding #ongerustheid", keyword_clusters: {'impact': 5, 'uitbreiding': 1, 'Schiphol': 5, 'milieu': 1, 'ongerustheid': 1}},
{sentiment: 0.9975945353507996, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'milieu', 'leefomgeving'], group: 'piet', emojis: [], keywords: ['groei', 'Schiphol', 'koste', 'leefomgeving', 'Schiphol', 'uitbreiding', 'milieu', 'leefomgeving'], full_text: "Het is toch van de zotte dat we de groei van Schiphol blijven faciliteren, terwijl we weten dat het ten koste gaat van onze leefomgeving? #Schiphol #uitbreiding #milieu #leefomgeving", keyword_clusters: {'groei': 4, 'Schiphol': 5, 'koste': 5, 'leefomgeving': 1, 'uitbreiding': 1, 'milieu': 1}},
{sentiment: 0.9967895150184631, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'overlast', 'vervuiling'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'overlast', 'vervuiling', 'Schiphol', 'uitbreiding', 'overlast', 'vervuiling'], full_text: "De uitbreiding van Schiphol zorgt alleen maar voor meer overlast en vervuiling. Wanneer gaan we eens stoppen met groeien? #Schiphol #uitbreiding #overlast #vervuiling", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'overlast': 1, 'vervuiling': 1}},
{sentiment: 0.9977472424507141, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'duurzaamheid', 'alternatieven'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'prioriteit', 'alternatieven', 'Schiphol', 'uitbreiding', 'duurzaamheid', 'alternatieven'], full_text: "Het is onbegrijpelijk dat we de uitbreiding van Schiphol nog steeds als prioriteit zien, terwijl we moeten investeren in duurzame alternatieven. #Schiphol #uitbreiding #duurzaamheid #alternatieven", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'prioriteit': 4, 'alternatieven': 1, 'duurzaamheid': 1}},
{sentiment: 0.9977443218231201, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'economie', 'milieu', 'gezondheid'], group: 'piet', emojis: [], keywords: ['voordelen', 'uitbreiding', 'Schiphol', 'nadelen', 'milieu', 'gezondheid', 'Schiphol', 'uitbreiding', 'economie', 'milieu', 'gezondheid'], full_text: "We moeten ons afvragen of de economische voordelen van de uitbreiding van Schiphol opwegen tegen de nadelen voor ons milieu en onze gezondheid. #Schiphol #uitbreiding #economie #milieu #gezondheid", keyword_clusters: {'voordelen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'nadelen': 5, 'milieu': 1, 'gezondheid': 1, 'economie': 1}},
{sentiment: 0.03124547004699707, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'vliegbewegingen', 'geluidsoverlast'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'vliegbewegingen', 'geluidsoverlast', 'tijd', 'waanzin', 'Schiphol', 'uitbreiding', 'vliegbewegingen', 'geluidsoverlast'], full_text: "De uitbreiding van Schiphol betekent alleen maar meer vliegbewegingen en meer geluidsoverlast. Het is tijd om te stoppen met deze waanzin. #Schiphol #uitbreiding #vliegbewegingen #geluidsoverlast", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'vliegbewegingen': 1, 'geluidsoverlast': 1, 'tijd': 3, 'waanzin': 4}},
{sentiment: 0.9954130053520203, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'milieu', 'duurzaamheid', 'alternatieven'], group: 'piet', emojis: [], keywords: ['milieu', 'uitbreiding', 'Schiphol', 'alternatieven', 'Schiphol', 'uitbreiding', 'milieu', 'duurzaamheid', 'alternatieven'], full_text: "Als we echt om ons milieu geven, dan moeten we de uitbreiding van Schiphol stoppen en ons richten op duurzame alternatieven. #Schiphol #uitbreiding #milieu #duurzaamheid #alternatieven", keyword_clusters: {'milieu': 1, 'uitbreiding': 1, 'Schiphol': 5, 'alternatieven': 1, 'duurzaamheid': 1}},
{sentiment: 0.9978235960006714, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'klimaatverandering', 'gevolgen'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'gevolgen', 'klimaatverandering', 'luchtvaart', 'Schiphol', 'uitbreiding', 'klimaatverandering', 'gevolgen'], full_text: "Het is niet alleen de uitbreiding van Schiphol die ons zorgen baart, maar ook de gevolgen van klimaatverandering die door de luchtvaart worden versterkt. #Schiphol #uitbreiding #klimaatverandering #gevolgen", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'gevolgen': 1, 'klimaatverandering': 1, 'luchtvaart': 7}},
{sentiment: 0.9973099231719971, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding', 'overlast', 'belangen'], group: 'piet', emojis: [], keywords: ['mensen', 'last', 'uitbreiding', 'Schiphol', 'tijd', 'belangen', 'Schiphol', 'uitbreiding', 'overlast', 'belangen'], full_text: "Wanneer gaan we eens luisteren naar de mensen die dagelijks last hebben van de uitbreiding van Schiphol? Het wordt tijd om hun belangen serieus te nemen. #Schiphol #uitbreiding #overlast #belangen", keyword_clusters: {'mensen': 0, 'last': 5, 'uitbreiding': 1, 'Schiphol': 5, 'tijd': 3, 'belangen': 1, 'overlast': 1}},
{sentiment: 0.9974865913391113, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'gezondheid', 'milieu', 'leefomgeving'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'koste', 'gezondheid', 'milieu', 'leefomgeving', 'tijd', 'groei', 'Schiphol', 'uitbreiding', 'gezondheid', 'milieu', 'leefomgeving'], full_text: "De uitbreiding van Schiphol gaat ten koste van onze gezondheid, ons milieu en onze leefomgeving. Het is tijd om te stoppen met deze onverantwoorde groei. #Schiphol #uitbreiding #gezondheid #milieu #leefomgeving", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'koste': 5, 'gezondheid': 1, 'milieu': 1, 'leefomgeving': 1, 'tijd': 3, 'groei': 4}},
{sentiment: 0.9973983764648438, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'milieu', 'gezondheid', 'samensterk'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'stemmen', 'milieu', 'gezondheid', 'Schiphol', 'uitbreiding', 'milieu', 'gezondheid', 'samensterk'], full_text: "Samen kunnen we ervoor zorgen dat de uitbreiding van Schiphol wordt gestopt. Laten we onze stemmen horen en opkomen voor ons milieu en onze gezondheid! #Schiphol #uitbreiding #milieu #gezondheid #samensterk", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'stemmen': 5, 'milieu': 1, 'gezondheid': 1, 'samensterk': 1}},
{sentiment: 0.9965665340423584, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'gezamenlijk', 'zorgen'], group: 'piet', emojis: [], keywords: ['Schiphol', 'krachten', 'zorgen', 'Schiphol', 'uitbreiding', 'zorgen'], full_text: "Als we niets doen, blijft Schiphol groeien en gaan wij eronder lijden. Laten we daarom de krachten bundelen en onze zorgen uiten. #Schiphol #uitbreiding #gezamenlijk #zorgen", keyword_clusters: {'Schiphol': 5, 'krachten': 0, 'zorgen': 1, 'uitbreiding': 1}},
{sentiment: 0.9975689053535461, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding', 'actie', 'duurzaamheid'], group: 'piet', emojis: [], keywords: ['tijd', 'actie', 'uitbreiding', 'Schiphol', 'toekomst', 'groei', 'Schiphol', 'uitbreiding', 'actie', 'duurzaamheid'], full_text: "Het is tijd om in actie te komen tegen de uitbreiding van Schiphol. Laten we samen strijden voor een duurzame toekomst en ons verzetten tegen onverantwoorde groei. #Schiphol #uitbreiding #actie #duurzaamheid", keyword_clusters: {'tijd': 3, 'actie': 1, 'uitbreiding': 1, 'Schiphol': 5, 'toekomst': 1, 'groei': 4, 'duurzaamheid': 1}},
{sentiment: 0.9975088834762573, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding', 'opstand', 'leefomgeving', 'toekomst'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'opstand', 'leefomgeving', 'toekomst', 'Schiphol', 'uitbreiding', 'opstand', 'leefomgeving', 'toekomst'], full_text: "We moeten ons niet neerleggen bij de uitbreiding van Schiphol, maar juist in opstand komen. Laten we samen vechten voor een gezonde leefomgeving en een leefbare toekomst! #Schiphol #uitbreiding #opstand #leefomgeving #toekomst", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'opstand': 1, 'leefomgeving': 1, 'toekomst': 1}},
{sentiment: 0.9972165822982788, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'samenwerken', 'welzijn', 'toekomst'], group: 'piet', emojis: [], keywords: ['stem', 'uitbreiding', 'Schiphol', 'welzijn', 'toekomst', 'Schiphol', 'uitbreiding', 'welzijn', 'toekomst'], full_text: "Alleen samen kunnen we ervoor zorgen dat onze stem gehoord wordt en dat de uitbreiding van Schiphol gestopt wordt. Laten we ons verenigen en opkomen voor ons welzijn en onze toekomst! #Schiphol #uitbreiding #samenwerken #welzijn #toekomst", keyword_clusters: {'stem': 1, 'uitbreiding': 1, 'Schiphol': 5, 'welzijn': 1, 'toekomst': 1}},
{sentiment: 0.9975564479827881, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding', 'samenwerking', 'milieu', 'gezondheid'], group: 'piet', emojis: [], keywords: ['tijd', 'krachten', 'uitbreiding', 'Schiphol', 'milieu', 'gezondheid', 'leefomgeving', 'Schiphol', 'uitbreiding', 'samenwerking', 'milieu', 'gezondheid'], full_text: "Het is tijd om de krachten te bundelen en ons te verzetten tegen de uitbreiding van Schiphol. Laten we samenwerken en opkomen voor ons milieu, onze gezondheid en onze leefomgeving! #Schiphol #uitbreiding #samenwerking #milieu #gezondheid", keyword_clusters: {'tijd': 3, 'krachten': 0, 'uitbreiding': 1, 'Schiphol': 5, 'milieu': 1, 'gezondheid': 1, 'leefomgeving': 1, 'samenwerking': 1}},
{sentiment: 0.9967575669288635, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding', 'verzet', 'leefomgeving', 'gezondheid', 'duurzaamheid'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'leefomgeving', 'gezondheid', 'spel', 'actie', 'toekomst', 'Schiphol', 'uitbreiding', 'verzet', 'leefomgeving', 'gezondheid', 'duurzaamheid'], full_text: "We moeten ons verzetten tegen de uitbreiding van Schiphol, omdat onze leefomgeving en onze gezondheid op het spel staan. Laten we samen actie ondernemen voor een duurzame toekomst! #Schiphol #uitbreiding #verzet #leefomgeving #gezondheid #duurzaamheid", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'leefomgeving': 1, 'gezondheid': 1, 'spel': 4, 'actie': 1, 'toekomst': 1, 'verzet': 1, 'duurzaamheid': 1}},
{sentiment: 0.9974716901779175, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding', 'gezamenlijk', 'stem', 'toekomst'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'stem', 'toekomst', 'Schiphol', 'uitbreiding', 'stem', 'toekomst'], full_text: "Als we samen opstaan tegen de uitbreiding van Schiphol, dan kunnen we ervoor zorgen dat onze stem gehoord wordt. Laten we ons verenigen en opkomen voor een gezonde en leefbare toekomst! #Schiphol #uitbreiding #gezamenlijk #stem #toekomst", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'stem': 1, 'toekomst': 1}},
{sentiment: 0.9966080188751221, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding', 'samenwerking', 'welzijn', 'toekomst', 'kinderen'], group: 'piet', emojis: [], keywords: ['tijd', 'handen', 'uitbreiding', 'Schiphol', 'welzijn', 'toekomst', 'kinderen', 'Schiphol', 'uitbreiding', 'samenwerking', 'welzijn', 'toekomst', 'kinderen'], full_text: "Het is tijd om de handen ineen te slaan en ons te verzetten tegen de uitbreiding van Schiphol. Laten we samenwerken en opkomen voor ons welzijn en de toekomst van onze kinderen! #Schiphol #uitbreiding #samenwerking #welzijn #toekomst #kinderen", keyword_clusters: {'tijd': 3, 'handen': 5, 'uitbreiding': 1, 'Schiphol': 5, 'welzijn': 1, 'toekomst': 1, 'kinderen': 1, 'samenwerking': 1}},
{sentiment: 0.9972231388092041, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding', 'samen'], group: 'piet', emojis: [], keywords: ['actie', 'uitbreiding', 'Schiphol', 'leefomgeving', 'gezondheid', 'toekomst', 'Schiphol', 'uitbreiding'], full_text: "Als we samen actie ondernemen tegen de uitbreiding van Schiphol, dan kunnen we ervoor zorgen dat onze leefomgeving en onze gezondheid beschermd worden. Laten we ons verenigen en opkomen voor een duurzame toekomst! #Schiphol #uitbreiding #samen", keyword_clusters: {'actie': 1, 'uitbreiding': 1, 'Schiphol': 5, 'leefomgeving': 1, 'gezondheid': 1, 'toekomst': 1}},
{sentiment: 0.9972460269927979, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'kunstproject', 'milieubewustzijn', 'verandering'], group: 'piet', emojis: [], keywords: ['creativiteit', 'uitbreiding', 'Schiphol', 'gemeenschapskunstproject', 'bewustzijn', 'verandering', 'Schiphol', 'kunstproject', 'milieubewustzijn', 'verandering'], full_text: "Laten we samen onze creativiteit inzetten om de uitbreiding van Schiphol te stoppen! Doe mee aan onze gemeenschapskunstproject om bewustzijn te creëren en verandering te inspireren. #Schiphol #kunstproject #milieubewustzijn #verandering", keyword_clusters: {'creativiteit': 1, 'uitbreiding': 1, 'Schiphol': 5, 'gemeenschapskunstproject': 5, 'bewustzijn': 1, 'verandering': 1, 'kunstproject': 1, 'milieubewustzijn': 1}},
{sentiment: 0.997856080532074, lat: null, lng: null, count: 1, topics: ['schiphol', 'kunstproject', 'stem', 'welzijn'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'kunstproject', 'stem', 'uitbreiding', 'omgeving', 'welzijn', 'Schiphol', 'kunstproject', 'stem', 'welzijn'], full_text: "Ben je bezorgd over de uitbreiding van Schiphol? Doe dan mee aan ons kunstproject om jouw stem te laten horen! Laat ons zien wat deze uitbreiding betekent voor onze omgeving en ons welzijn. #Schiphol #kunstproject #stem #welzijn", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'kunstproject': 1, 'stem': 1, 'omgeving': 0, 'welzijn': 1}},
{sentiment: 0.9978073239326477, lat: null, lng: null, count: 1, topics: ['schiphol', 'kunstproject', 'samenwerking', 'bewustzijn'], group: 'piet', emojis: [], keywords: ['creativiteit', 'middel', 'verandering', 'gemeenschapskunstproject', 'bewustzijn', 'planeet', 'welzijn', 'Schiphol', 'kunstproject', 'samenwerking', 'bewustzijn'], full_text: "Onze creativiteit kan een krachtig middel zijn om verandering teweeg te brengen. Laten we samenwerken aan een gemeenschapskunstproject om bewustzijn te creëren en op te komen voor onze planeet en ons welzijn. #Schiphol #kunstproject #samenwerking #bewustzijn", keyword_clusters: {'creativiteit': 1, 'middel': 7, 'verandering': 1, 'gemeenschapskunstproject': 5, 'bewustzijn': 1, 'planeet': 1, 'welzijn': 1, 'Schiphol': 5, 'kunstproject': 1, 'samenwerking': 1}},
{sentiment: 0.9981301426887512, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'kunstproject', 'stem', 'leefomgeving', 'gezondheid'], group: 'piet', emojis: [], keywords: ['stem', 'kunstproject', 'gevolgen', 'uitbreiding', 'Schiphol', 'leefomgeving', 'gezondheid', 'Schiphol', 'kunstproject', 'stem', 'leefomgeving', 'gezondheid'], full_text: "Zorg ervoor dat jouw stem wordt gehoord! Doe mee aan ons kunstproject om de negatieve gevolgen van de uitbreiding van Schiphol voor onze leefomgeving en gezondheid te laten zien. #Schiphol #kunstproject #stem #leefomgeving #gezondheid", keyword_clusters: {'stem': 1, 'kunstproject': 1, 'gevolgen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'leefomgeving': 1, 'gezondheid': 1}},
{sentiment: 0.9978759288787842, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'kunstproject', 'inspiratie', 'verandering'], group: 'piet', emojis: [], keywords: ['Kunst', 'kracht', 'mensen', 'gemeenschapskunstproject', 'problemen', 'uitbreiding', 'Schiphol', 'kaarten', 'verandering', 'Schiphol', 'kunstproject', 'inspiratie', 'verandering'], full_text: "Kunst heeft de kracht om mensen te raken en te inspireren. Doe mee aan ons gemeenschapskunstproject om de problemen rondom de uitbreiding van Schiphol aan te kaarten en verandering te bewerkstelligen. #Schiphol #kunstproject #inspiratie #verandering", keyword_clusters: {'Kunst': 7, 'kracht': 5, 'mensen': 0, 'gemeenschapskunstproject': 5, 'problemen': 4, 'uitbreiding': 1, 'Schiphol': 5, 'kaarten': 7, 'verandering': 1, 'kunstproject': 1, 'inspiratie': 1}},
{sentiment: 0.997028648853302, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'kunstproject', 'samenwerking', 'leefbaretoekomst'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'kunstproject', 'bewustzijn', 'toekomst', 'Schiphol', 'kunstproject', 'samenwerking', 'leefbaretoekomst'], full_text: "We moeten ons verenigen en creatief zijn om de uitbreiding van Schiphol te stoppen. Laten we samenwerken aan een kunstproject om bewustzijn te creëren en op te komen voor een leefbare toekomst. #Schiphol #kunstproject #samenwerking #leefbaretoekomst", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'kunstproject': 1, 'bewustzijn': 1, 'toekomst': 1, 'samenwerking': 1, 'leefbaretoekomst': 1}},
{sentiment: 0.9982016086578369, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'kunstproject', 'beeldtaal', 'welzijn', 'leefomgeving'], group: 'piet', emojis: [], keywords: ['beeld', 'woorden', 'gemeenschapskunstproject', 'gevolgen', 'uitbreiding', 'Schiphol', 'welzijn', 'leefomgeving', 'Schiphol', 'kunstproject', 'beeldtaal', 'welzijn', 'leefomgeving'], full_text: "Een beeld zegt meer dan duizend woorden. Laten we samenwerken aan een gemeenschapskunstproject om de gevolgen van de uitbreiding van Schiphol voor ons welzijn en onze leefomgeving te visualiseren. #Schiphol #kunstproject #beeldtaal #welzijn #leefomgeving", keyword_clusters: {'beeld': 5, 'woorden': 7, 'gemeenschapskunstproject': 5, 'gevolgen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'welzijn': 1, 'leefomgeving': 1, 'kunstproject': 1, 'beeldtaal': 1}},
{sentiment: 0.9977414608001709, lat: null, lng: null, count: 1, topics: ['schiphol', 'kunstproject', 'verbeeldingskracht', 'verandering'], group: 'piet', emojis: [], keywords: ['Creativiteit', 'verbeeldingskracht', 'combinatie', 'strijd', 'uitbreiding', 'Schiphol', 'kunstproject', 'stem', 'verandering', 'Schiphol', 'kunstproject', 'verbeeldingskracht', 'verandering'], full_text: "Creativiteit en verbeeldingskracht kunnen een krachtige combinatie zijn in de strijd tegen de uitbreiding van Schiphol. Doe mee aan ons kunstproject om jouw stem te laten horen en verandering te bewerkstelligen! #Schiphol #kunstproject #verbeeldingskracht #verandering", keyword_clusters: {'Creativiteit': 0, 'verbeeldingskracht': 1, 'combinatie': 7, 'strijd': 7, 'uitbreiding': 1, 'Schiphol': 5, 'kunstproject': 1, 'stem': 1, 'verandering': 1}},
{sentiment: 0.9970616698265076, lat: null, lng: null, count: 1, topics: ['schiphol', 'kunstproject', 'opstaan', 'welzijn', 'planeet'], group: 'piet', emojis: [], keywords: ['tijd', 'stem', 'uitbreiding', 'Schiphol', 'creativiteit', 'kunstproject', 'uitbreiding', 'welzijn', 'planeet', 'Schiphol', 'kunstproject', 'welzijn', 'planeet'], full_text: "Het is tijd om op te staan en onze stem te laten horen tegen de uitbreiding van Schiphol. Laten we onze creativiteit inzetten voor een kunstproject om te laten zien hoe deze uitbreiding ons welzijn en onze planeet bedreigt. #Schiphol #kunstproject #opstaan #welzijn #planeet", keyword_clusters: {'tijd': 3, 'stem': 1, 'uitbreiding': 1, 'Schiphol': 5, 'creativiteit': 1, 'kunstproject': 1, 'welzijn': 1, 'planeet': 1}},
{sentiment: 0.998319685459137, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['gemeenschapskunstproject', 'gevolgen', 'uitbreiding', 'Schiphol', 'verandering'], full_text: "Doe mee aan ons gemeenschapskunstproject om de negatieve gevolgen van de uitbreiding van Schiphol te laten zien en verandering te", keyword_clusters: {'gemeenschapskunstproject': 5, 'gevolgen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'verandering': 1}},
{sentiment: 0.9981728792190552, lat: null, lng: null, count: 1, topics: ['schiphol', 'kunstproject', 'bewustzijn', 'haarlemmermeer'], group: 'piet', emojis: [], keywords: ['agenda', 'zaterdag', 'april', 'gemeenschapskunstproject', 'bewustzijn', 'uitbreiding', 'Schiphol', 'park', 'Raadhuis', 'Haarlemmermeer', 'stem', 'Schiphol', 'kunstproject', 'bewustzijn', 'Haarlemmermeer'], full_text: "Zet het in je agenda: op zaterdag 8 april organiseren we ons gemeenschapskunstproject om bewustzijn te creëren over de uitbreiding van Schiphol. Kom naar het park bij het Raadhuis van Haarlemmermeer en laat jouw stem horen! #Schiphol #kunstproject #bewustzijn #Haarlemmermeer", keyword_clusters: {'agenda': 7, 'zaterdag': 0, 'april': 7, 'gemeenschapskunstproject': 5, 'bewustzijn': 1, 'uitbreiding': 1, 'Schiphol': 5, 'park': 5, 'Raadhuis': 5, 'Haarlemmermeer': 0, 'stem': 1, 'kunstproject': 1}},
{sentiment: 0.9982038736343384, lat: null, lng: null, count: 1, topics: ['schiphol', 'kunstproject', 'gemeenschap', 'amsterdamsebos'], group: 'piet', emojis: [], keywords: ['kunstenaars', 'zondag', 'april', 'kunstproject', 'uitbreiding', 'Schiphol', 'uur', 'ingang', 'Bos', 'Schiphol', 'kunstproject', 'gemeenschap', 'AmsterdamseBos'], full_text: "We roepen alle kunstenaars en gemeenschapsleden op om zich bij ons aan te sluiten op zondag 16 april voor ons kunstproject tegen de uitbreiding van Schiphol. We verzamelen om 11.00 uur bij de ingang van het Amsterdamse Bos. #Schiphol #kunstproject #gemeenschap #AmsterdamseBos", keyword_clusters: {'kunstenaars': 4, 'zondag': 5, 'april': 7, 'kunstproject': 1, 'uitbreiding': 1, 'Schiphol': 5, 'uur': 0, 'ingang': 5, 'Bos': 7, 'gemeenschap': 1, 'AmsterdamseBos': 7}},
{sentiment: 0.9982407093048096, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'kunstproject', 'leefomgeving'], group: 'piet', emojis: [], keywords: ['zaterdag', 'april', 'kunstproject', 'gevolgen', 'uitbreiding', 'Schiphol', 'leefomgeving', 'uur', 'Kasteel', 'Keukenhof', 'Lisse', 'stem', 'Schiphol', 'kunstproject', 'leefomgeving'], full_text: "Op zaterdag 22 april organiseren we een kunstproject om de negatieve gevolgen van de uitbreiding van Schiphol op onze leefomgeving aan te kaarten. We ontmoeten elkaar om 13.00 uur bij het Kasteel Keukenhof in Lisse. Kom en laat je stem horen! #Schiphol #kunstproject #leefomgeving", keyword_clusters: {'zaterdag': 0, 'april': 7, 'kunstproject': 1, 'gevolgen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'leefomgeving': 1, 'uur': 0, 'Kasteel': 7, 'Keukenhof': 0, 'Lisse': 4, 'stem': 1}},
{sentiment: 0.9979793429374695, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'kunstproject', 'creativiteit', 'schipholplaza'], group: 'piet', emojis: [], keywords: ['kunstproject', 'uitbreiding', 'Schiphol', 'zondag', 'april', 'uur', 'rotonde', 'Schiphol', 'Plaza', 'creativiteit', 'Schiphol', 'kunstproject', 'creativiteit', 'SchipholPlaza'], full_text: "Maak je klaar voor ons kunstproject tegen de uitbreiding van Schiphol op zondag 30 april! We verzamelen om 10.00 uur bij de rotonde bij Schiphol Plaza. Kom en laat jouw creativiteit zien! #Schiphol #kunstproject #creativiteit #SchipholPlaza", keyword_clusters: {'kunstproject': 1, 'uitbreiding': 1, 'Schiphol': 5, 'zondag': 5, 'april': 7, 'uur': 0, 'rotonde': 5, 'Plaza': 7, 'creativiteit': 1, 'SchipholPlaza': 7}},
{sentiment: 0.9981683492660522, lat: null, lng: null, count: 1, topics: ['schiphol', 'kunstproject', 'bewustzijn', 'amstelveen'], group: 'piet', emojis: [], keywords: ['hulp', 'gemeenschapskunstproject', 'zaterdag', 'mei', 'bewustzijn', 'uitbreiding', 'Schiphol', 'uur', 'Stadshuis', 'Amstelveen', 'Schiphol', 'kunstproject', 'bewustzijn', 'Amstelveen'], full_text: "We hebben jouw hulp nodig! Doe mee aan ons gemeenschapskunstproject op zaterdag 6 mei om bewustzijn te creëren over de uitbreiding van Schiphol. We verzamelen om 12.00 uur bij het Stadshuis in Amstelveen. #Schiphol #kunstproject #bewustzijn #Amstelveen", keyword_clusters: {'hulp': 5, 'gemeenschapskunstproject': 5, 'zaterdag': 0, 'mei': 4, 'bewustzijn': 1, 'uitbreiding': 1, 'Schiphol': 5, 'uur': 0, 'Stadshuis': 7, 'Amstelveen': 4, 'kunstproject': 1}},
{sentiment: 0.9981517195701599, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'kunstproject', 'gezondheid', 'amsterdamsebos'], group: 'piet', emojis: [], keywords: ['zondag', 'mei', 'kunstproject', 'gevolgen', 'uitbreiding', 'Schiphol', 'gezondheid', 'uur', 'Bos', 'Schiphol', 'kunstproject', 'gezondheid', 'AmsterdamseBos'], full_text: "Op zondag 14 mei organiseren we een kunstproject om de negatieve gevolgen van de uitbreiding van Schiphol op onze gezondheid aan te kaarten. We ontmoeten elkaar om 15.00 uur bij het Amsterdamse Bos. Kom en doe mee! #Schiphol #kunstproject #gezondheid #AmsterdamseBos", keyword_clusters: {'zondag': 5, 'mei': 4, 'kunstproject': 1, 'gevolgen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'gezondheid': 1, 'uur': 0, 'Bos': 7, 'AmsterdamseBos': 7}},
{sentiment: 0.9982700347900391, lat: null, lng: null, count: 1, topics: ['schiphol', 'kunstproject', 'bewustzijn', 'vondelpark'], group: 'piet', emojis: [], keywords: ['stem', 'uitbreiding', 'Schiphol', 'kunstproject', 'zaterdag', 'mei', 'bewustzijn', 'kwestie', 'uur', 'ingang', 'Vondelpark', 'Schiphol', 'kunstproject', 'bewustzijn', 'Vondelpark'], full_text: "Laat jouw stem horen tegen de uitbreiding van Schiphol! Kom naar ons kunstproject op zaterdag 20 mei en help ons bewustzijn te creëren over deze kwestie. We verzamelen om 14.00 uur bij de ingang van het Vondelpark. #Schiphol #kunstproject #bewustzijn #Vondelpark", keyword_clusters: {'stem': 1, 'uitbreiding': 1, 'Schiphol': 5, 'kunstproject': 1, 'zaterdag': 0, 'mei': 4, 'bewustzijn': 1, 'kwestie': 0, 'uur': 0, 'ingang': 5, 'Vondelpark': 0}},
{sentiment: 0.9982511401176453, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'kunstproject', 'verandering', 'haarlemmermeer'], group: 'piet', emojis: [], keywords: ['gemeenschapskunstproject', 'zondag', 'mei', 'uitbreiding', 'Schiphol', 'kaarten', 'verandering', 'uur', 'Stadshuis', 'Haarlemmermeer', 'Schiphol', 'kunstproject', 'verandering', 'Haarlemmermeer'], full_text: "Doe mee aan ons gemeenschapskunstproject op zondag 28 mei om de uitbreiding van Schiphol aan te kaarten en verandering te bewerkstelligen. We verzamelen om 11.00 uur bij het Stadshuis in Haarlemmermeer. #Schiphol #kunstproject #verandering #Haarlemmermeer", keyword_clusters: {'gemeenschapskunstproject': 5, 'zondag': 5, 'mei': 4, 'uitbreiding': 1, 'Schiphol': 5, 'kaarten': 7, 'verandering': 1, 'uur': 0, 'Stadshuis': 7, 'Haarlemmermeer': 0, 'kunstproject': 1}},
{sentiment: 0.9984332919120789, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['creativiteit', 'kunstproject', 'uitbreiding'], full_text: "Kom en laat jouw creativiteit zien op ons kunstproject tegen de uitbreiding", keyword_clusters: {'creativiteit': 1, 'kunstproject': 1, 'uitbreiding': 1}},
{sentiment: 0.997960090637207, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'expansion', 'economy', 'jobs'], group: 'piet', emojis: [], keywords: ['Schiphol', 'protests', 'are', 'shortsighted', 'us', 'all', 'Schiphol', 'jobs'], full_text: "The expansion of Schiphol is crucial for our economy and job market. These protests are shortsighted and only serve to hurt us all. #Schiphol #expansion #economy #jobs", keyword_clusters: {'Schiphol': 5, 'protests': 3, 'are': 0, 'shortsighted': 4, 'us': 7, 'all': 4, 'jobs': 6}},
{sentiment: 0.9981411695480347, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'expansion', 'travel', 'convenience'], group: 'piet', emojis: [], keywords: ['traveler', 'Protests', 'Schiphol'], full_text: "As a frequent traveler, I depend on Schiphol for efficient and convenient travel. Protests against expansion only harm the experience for everyone. #Schiphol #expansion #travel #convenience", keyword_clusters: {'traveler': 5, 'Protests': 7, 'Schiphol': 5}},
{sentiment: 0.9978519678115845, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'expansion', 'competition', 'future'], group: 'piet', emojis: [], keywords: ['The', 'Schiphol', 'protests', 'hold', 'Schiphol'], full_text: "The growth of Schiphol is necessary to keep up with global competition and meet the demands of the future. Don't let these protests hold us back. #Schiphol #expansion #competition #future", keyword_clusters: {'The': 4, 'Schiphol': 5, 'protests': 3, 'hold': 4}},
{sentiment: 0.9978861212730408, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'expansion', 'benefits', 'progress'], group: 'piet', emojis: [], keywords: ['Schiphol', 'progress', 'Schiphol', 'progress'], full_text: "I support the expansion of Schiphol and the benefits it will bring to our city and country. Don't let a small group of protestors stop progress. #Schiphol #expansion #benefits #progress", keyword_clusters: {'Schiphol': 5, 'progress': 3}},
{sentiment: 0.9977706670761108, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'expansion', 'greatergood', 'protests'], group: 'piet', emojis: [], keywords: ['protestors', 'Schiphol', 'Schiphol', 'greatergood', 'protests'], full_text: "These protestors are only thinking about their own interests and not the greater good. The expansion of Schiphol will benefit everyone in the long run. #Schiphol #expansion #greatergood #protests", keyword_clusters: {'protestors': 0, 'Schiphol': 5, 'greatergood': 3, 'protests': 3}},
{sentiment: 0.9976724982261658, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'expansion', 'travelers', 'progress'], group: 'piet', emojis: [], keywords: ['Schiphol', 'travelers', 'operations', 'these', 'protests', 'hinder', 'Schiphol', 'travelers', 'progress'], full_text: "The expansion of Schiphol is necessary to accommodate the growing number of travelers and ensure efficient operations. Let's not let these protests hinder our progress. #Schiphol #expansion #travelers #progress", keyword_clusters: {'Schiphol': 5, 'travelers': 3, 'operations': 0, 'these': 4, 'protests': 3, 'hinder': 7, 'progress': 3}},
{sentiment: 0.9979196190834045, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'expansion', 'aviationindustry', 'protests'], group: 'piet', emojis: [], keywords: ['Schiphol', 'behind', 'because', 'protests', 'Schiphol', 'aviationindustry', 'protests'], full_text: "The expansion of Schiphol is a necessary step to keep up with the changing demands of the aviation industry. Let's not fall behind because of protests. #Schiphol #expansion #aviationindustry #protests", keyword_clusters: {'Schiphol': 5, 'behind': 4, 'because': 0, 'protests': 3, 'aviationindustry': 3}},
{sentiment: 0.9965640902519226, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'expansion', 'longtermbenefits', 'protests'], group: 'piet', emojis: [], keywords: ['The', 'protestors', 'Schiphol', 'longtermbenefits', 'protests'], full_text: "The protestors against Schiphol expansion are only thinking about their own immediate concerns and not the long-term benefits for our country. Let's not let them hold us back. #Schiphol #expansion #longtermbenefits #protests", keyword_clusters: {'The': 4, 'protestors': 0, 'Schiphol': 5, 'longtermbenefits': 3, 'protests': 3}},
{sentiment: 0.9982200264930725, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'expansion', 'internationaltrade', 'protests'], group: 'piet', emojis: [], keywords: ['Schiphol', 'for', 'international', 'trade', 'protests', 'Schiphol', 'protests'], full_text: "As a business owner, I rely on the convenience and accessibility of Schiphol for international trade. These protests only hurt our ability to compete globally. #Schiphol #expansion #internationaltrade #protests", keyword_clusters: {'Schiphol': 5, 'for': 7, 'international': 4, 'trade': 0, 'protests': 3}},
{sentiment: 0.997617781162262, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'expansion', 'jobsgrowth', 'progress'], group: 'piet', emojis: [], keywords: ['Schiphol', 'protestors', 'stand', 'progress', 'Schiphol', 'progress'], full_text: "The expansion of Schiphol will bring jobs and economic growth to our country. Don't let these protestors stand in the way of progress. #Schiphol #expansion #jobsgrowth #progress", keyword_clusters: {'Schiphol': 5, 'protestors': 0, 'stand': 0, 'progress': 3}},
{sentiment: 0.9974773526191711, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'economie', 'werkgelegenheid'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'economie', 'werkgelegenheid', 'protesten', 'Schiphol', 'uitbreiding', 'economie', 'werkgelegenheid'], full_text: "De uitbreiding van Schiphol is cruciaal voor onze economie en de werkgelegenheid. Deze protesten zijn kortzichtig en schaden ons allemaal. #Schiphol #uitbreiding #economie #werkgelegenheid", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'economie': 1, 'werkgelegenheid': 1, 'protesten': 1}},
{sentiment: 0.9979285001754761, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'reizen', 'gemak'], group: 'piet', emojis: [], keywords: ['reiziger', 'Schiphol', 'reizen', 'Protesten', 'uitbreiding', 'ervaring', 'Schiphol', 'uitbreiding', 'gemak'], full_text: "Als frequente reiziger ben ik afhankelijk van Schiphol voor efficiënte en handige reizen. Protesten tegen uitbreiding schaden alleen de ervaring voor iedereen. #Schiphol #uitbreiding #reizen #gemak", keyword_clusters: {'reiziger': 4, 'Schiphol': 5, 'reizen': 1, 'Protesten': 0, 'uitbreiding': 1, 'ervaring': 7, 'gemak': 1}},
{sentiment: 0.9971712231636047, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'concurrentie', 'toekomst'], group: 'piet', emojis: [], keywords: ['groei', 'Schiphol', 'concurrentie', 'eisen', 'toekomst', 'protesten', 'Schiphol', 'uitbreiding', 'concurrentie', 'toekomst'], full_text: "De groei van Schiphol is noodzakelijk om de wereldwijde concurrentie bij te houden en te voldoen aan de eisen van de toekomst. Laat deze protesten ons niet tegenhouden. #Schiphol #uitbreiding #concurrentie #toekomst", keyword_clusters: {'groei': 4, 'Schiphol': 5, 'concurrentie': 1, 'eisen': 5, 'toekomst': 1, 'protesten': 1, 'uitbreiding': 1}},
{sentiment: 0.9976332187652588, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'voordelen', 'vooruitgang'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'voordelen', 'stad', 'land', 'groep', 'protestanten', 'vooruitgang', 'Schiphol', 'uitbreiding', 'voordelen', 'vooruitgang'], full_text: "Ik steun de uitbreiding van Schiphol en de voordelen die het zal brengen voor onze stad en ons land. Laat een kleine groep protestanten de vooruitgang niet stoppen. #Schiphol #uitbreiding #voordelen #vooruitgang", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'voordelen': 1, 'stad': 4, 'land': 7, 'groep': 5, 'protestanten': 7, 'vooruitgang': 1}},
{sentiment: 0.9976223111152649, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'grotergoed', 'protesten'], group: 'piet', emojis: [], keywords: ['demonstranten', 'belangen', 'uitbreiding', 'Schiphol', 'termijn', 'Schiphol', 'uitbreiding', 'grotergoed', 'protesten'], full_text: "Deze demonstranten denken alleen aan hun eigen belangen en niet aan het grotere goed. De uitbreiding van Schiphol zal op de lange termijn iedereen ten goede komen. #Schiphol #uitbreiding #grotergoed #protesten", keyword_clusters: {'demonstranten': 5, 'belangen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'termijn': 5, 'grotergoed': 1, 'protesten': 1}},
{sentiment: 0.9974943399429321, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'reizigers', 'vooruitgang'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'aantal', 'reizigers', 'operaties', 'protesten', 'vooruitgang', 'Schiphol', 'uitbreiding', 'reizigers', 'vooruitgang'], full_text: "De uitbreiding van Schiphol is nodig om het groeiende aantal reizigers te accommoderen en efficiënte operaties te garanderen. Laten we deze protesten onze vooruitgang niet belemmeren. #Schiphol #uitbreiding #reizigers #vooruitgang", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'aantal': 5, 'reizigers': 1, 'operaties': 5, 'protesten': 1, 'vooruitgang': 1}},
{sentiment: 0.9974610805511475, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'luchtvaartindustrie', 'protesten'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'stap', 'eisen', 'luchtvaartindustrie', 'protesten', 'Schiphol', 'uitbreiding', 'luchtvaartindustrie', 'protesten'], full_text: "De uitbreiding van Schiphol is een noodzakelijke stap om aan de veranderende eisen van de luchtvaartindustrie te voldoen. Laten we niet achterblijven vanwege protesten. #Schiphol #uitbreiding #luchtvaartindustrie #protesten", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'stap': 0, 'eisen': 5, 'luchtvaartindustrie': 1, 'protesten': 1}},
{sentiment: 0.9964770674705505, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'langetermijnvoordelen', 'protesten'], group: 'piet', emojis: [], keywords: ['protestanten', 'uitbreiding', 'Schiphol', 'zorgen', 'langetermijnvoordelen', 'land', 'Schiphol', 'uitbreiding', 'langetermijnvoordelen', 'protesten'], full_text: "De protestanten tegen de uitbreiding van Schiphol denken alleen aan hun eigen directe zorgen en niet aan de langetermijnvoordelen voor ons land. Laten we ons niet laten tegenhouden. #Schiphol #uitbreiding #langetermijnvoordelen #protesten", keyword_clusters: {'protestanten': 7, 'uitbreiding': 1, 'Schiphol': 5, 'zorgen': 1, 'langetermijnvoordelen': 1, 'land': 7, 'protesten': 1}},
{sentiment: 0.9975799322128296, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'internationalehandel', 'protesten'], group: 'piet', emojis: [], keywords: ['bedrijfseigenaar', 'gemak', 'toegankelijkheid', 'Schiphol', 'handel', 'protesten', 'vermogen', 'Schiphol', 'uitbreiding', 'internationalehandel', 'protesten'], full_text: "Als bedrijfseigenaar ben ik afhankelijk van het gemak en de toegankelijkheid van Schiphol voor internationale handel. Deze protesten schaden alleen ons vermogen om wereldwijd te concurreren. #Schiphol #uitbreiding #internationalehandel #protesten", keyword_clusters: {'bedrijfseigenaar': 4, 'gemak': 1, 'toegankelijkheid': 7, 'Schiphol': 5, 'handel': 2, 'protesten': 1, 'vermogen': 5, 'uitbreiding': 1, 'internationalehandel': 1}},
{sentiment: 0.9971659779548645, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'banengroei', 'vooruitgang'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'banen', 'groei', 'land', 'protestanten', 'vooruitgang', 'weg', 'Schiphol', 'uitbreiding', 'banengroei', 'vooruitgang'], full_text: "De uitbreiding van Schiphol zal banen en economische groei naar ons land brengen. Laat deze protestanten de vooruitgang niet in de weg staan. #Schiphol #uitbreiding #banengroei #vooruitgang", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'banen': 0, 'groei': 4, 'land': 7, 'protestanten': 7, 'vooruitgang': 1, 'weg': 0, 'banengroei': 1}},
{sentiment: 0.9946433305740356, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol', 'expansie', 'angst'], group: 'piet', emojis: [], keywords: ['angst', 'Schiphol', 'reizen', 'stopSchiphol', 'expansie', 'angst'], full_text: "Oh nee, ik heb zo'n angst dat Schiphol te groot wordt en we allemaal betere en goedkopere reizen krijgen. #stopSchiphol #expansie #angst", keyword_clusters: {'angst': 2, 'Schiphol': 5, 'reizen': 1, 'stopSchiphol': 4, 'expansie': 2}},
{sentiment: 0.9940378665924072, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol', 'sluiting', 'paardenwagen'], group: 'piet', emojis: [], keywords: ['Schiphol', 'dagen', 'paard', 'wagen', 'stopSchiphol', 'sluiting', 'paardenwagen'], full_text: "Laten we Schiphol gewoon sluiten, dan kunnen we allemaal terugkeren naar de goede oude dagen van het paard en wagen. #stopSchiphol #sluiting #paardenwagen", keyword_clusters: {'Schiphol': 5, 'dagen': 4, 'paard': 0, 'wagen': 5, 'stopSchiphol': 4, 'sluiting': 2, 'paardenwagen': 2}},
{sentiment: 0.9955151677131653, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol', 'expansie', 'planeet'], group: 'piet', emojis: [], keywords: ['Schiphol', 'planeet', 'stopSchiphol', 'expansie', 'planeet'], full_text: "Ik ben zo bang dat Schiphol zo groot wordt dat het een eigen planeet wordt. #stopSchiphol #expansie #planeet", keyword_clusters: {'Schiphol': 5, 'planeet': 1, 'stopSchiphol': 4, 'expansie': 2}},
{sentiment: 0.9571096897125244, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol', 'expansie', 'handbagage'], group: 'piet', emojis: [], keywords: ['Schiphol', 'handbagage', 'stopSchiphol', 'expansie', 'handbagage'], full_text: "Als we Schiphol niet stoppen, worden we binnenkort allemaal verplicht om te reizen met alleen handbagage. Horror! #stopSchiphol #expansie #handbagage", keyword_clusters: {'Schiphol': 5, 'handbagage': 2, 'stopSchiphol': 4, 'expansie': 2}},
{sentiment: 0.9927594661712646, lat: 51.5879873, lng: 4.9194511, count: 1, topics: ['stopschiphol', 'expansie', 'souvenirwinkels'], group: 'piet', emojis: [], keywords: ['Schiphol', 'plaats', 'rijen', 'souvenirwinkels', 'stopSchiphol', 'expansie', 'souvenirwinkels'], full_text: "Ik ben zo bang dat als Schiphol blijft groeien, we geen plaats meer hebben voor de eindeloze rijen souvenirwinkels. #stopSchiphol #expansie #souvenirwinkels", keyword_clusters: {'Schiphol': 5, 'plaats': 0, 'rijen': 5, 'souvenirwinkels': 2, 'stopSchiphol': 4, 'expansie': 2}},
{sentiment: 0.9950318336486816, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol', 'expansie', 'boardingpas'], group: 'piet', emojis: [], keywords: ['Schiphol', 'boardingpas', 'stopSchiphol', 'expansie', 'boardingpas'], full_text: "Als we Schiphol niet stoppen, dan zijn we straks allemaal verplicht om een boardingpas te tatoeëren. #stopSchiphol #expansie #boardingpas", keyword_clusters: {'Schiphol': 5, 'boardingpas': 2, 'stopSchiphol': 4, 'expansie': 2}},
{sentiment: 0.018787682056427002, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol', 'expansie', 'brainwashen'], group: 'piet', emojis: ['🤬'], keywords: ['Schiphol', 'stopSchiphol', 'expansie', '🤬'], full_text: "Ik denk dat Schiphol ons allemaal probeert te brainwashen om te denken dat reizen leuk is. We moeten stoppen voordat het te laat is! #stopSchiphol #expansie #brainwashen 🤬", keyword_clusters: {'Schiphol': 5, 'stopSchiphol': 4, 'expansie': 2}},
{sentiment: 0.016252577304840088, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol', 'expansie', 'fietsvakantie'], group: 'piet', emojis: ['🤬'], keywords: ['fiets', 'vakantiebestemming', 'Schiphol', 'stopSchiphol', 'expansie', 'fietsvakantie', '🤬'], full_text: "Laten we allemaal op de fiets naar onze vakantiebestemming gaan, want als we Schiphol blijven laten groeien, wordt reizen straks belachelijk gemakkelijk en betaalbaar. #stopSchiphol #expansie #fietsvakantie 🤬", keyword_clusters: {'fiets': 0, 'vakantiebestemming': 0, 'Schiphol': 5, 'stopSchiphol': 4, 'expansie': 2, 'fietsvakantie': 2}},
{sentiment: 0.020576119422912598, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol', 'expansie', 'vechten'], group: 'piet', emojis: ['🤬'], keywords: ['Schiphol', 'zitplaats', 'vliegtuig', 'plekken', 'stopSchiphol', 'expansie', '🤬'], full_text: "Als Schiphol blijft groeien, dan zullen we binnenkort moeten vechten om een zitplaats in het vliegtuig. Laten we stoppen voordat we allemaal blauwe plekken hebben. #stopSchiphol #expansie #vechten 🤬", keyword_clusters: {'Schiphol': 5, 'zitplaats': 7, 'vliegtuig': 0, 'plekken': 7, 'stopSchiphol': 4, 'expansie': 2}},
{sentiment: 0.015767812728881836, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol', 'expansie', 'fouilleren'], group: 'piet', emojis: ['🤬'], keywords: ['Schiphol', 'aankomst', 'bestemming', 'bevolking', 'stopSchiphol', 'expansie', '🤬'], full_text: "Als we Schiphol niet stoppen, dan zijn we binnenkort allemaal verplicht om bij aankomst op onze bestemming te worden gefouilleerd door de lokale bevolking. #stopSchiphol #expansie #fouilleren 🤬", keyword_clusters: {'Schiphol': 5, 'aankomst': 0, 'bestemming': 0, 'bevolking': 0, 'stopSchiphol': 4, 'expansie': 2}},
{sentiment: 0.012651324272155762, lat: null, lng: null, count: 1, topics: ['politiek', 'droeftoeter'], group: 'piet', emojis: ['🤮'], keywords: ['droeftoeters', 'tegenpartij', 'idee', 'politiek', 'droeftoeter', '🤮'], full_text: "Die droeftoeters van de tegenpartij hebben geen idee wat ze doen. #politiek #droeftoeter 🤮", keyword_clusters: {'droeftoeters': 7, 'tegenpartij': 4, 'idee': 5, 'politiek': 1, 'droeftoeter': 1}},
{sentiment: 0.016921937465667725, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'economie'], group: 'piet', emojis: ['🤬'], keywords: ['mensen', 'voordelen', 'Schiphol', 'plaats', 'effecten', 'Schiphol', 'protesten', 'economie', '🤬'], full_text: "Het is schokkend om te zien hoeveel mensen bereid zijn om de economische voordelen van Schiphol te negeren en zich in plaats daarvan te concentreren op de vermeende negatieve effecten. #Schiphol #protesten #economie 🤬", keyword_clusters: {'mensen': 0, 'voordelen': 1, 'Schiphol': 5, 'plaats': 0, 'effecten': 4, 'protesten': 1, 'economie': 1}},
{sentiment: 0.04712212085723877, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'hypocrisie'], group: 'piet', emojis: ['🤮'], keywords: ['hypocrisie', 'protestanten', 'Schiphol', 'wereld', 'luchthaven', 'Schiphol', 'protesten', 'hypocrisie'], full_text: "De hypocrisie van sommige protestanten tegen Schiphol is verbazingwekkend. Ze vliegen zelf de hele wereld over, maar klagen over de luchthaven als het hen uitkomt. #Schiphol #protesten #hypocrisie 🤮", keyword_clusters: {'hypocrisie': 1, 'protestanten': 7, 'Schiphol': 5, 'wereld': 7, 'luchthaven': 3, 'protesten': 1}},
{sentiment: 0.02866685390472412, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'rellen'], group: 'piet', emojis: ['🤮'], keywords: ['mensen', 'protesten', 'Schiphol', 'excuus', 'rotzooi', 'Schiphol', 'protesten', 'rellen'], full_text: "Het is triest om te zien hoe sommige mensen de protesten tegen Schiphol gebruiken als een excuus om gewoon lekker te rellen en rotzooi te trappen. #Schiphol #protesten #rellen 🤮", keyword_clusters: {'mensen': 0, 'protesten': 1, 'Schiphol': 5, 'excuus': 5, 'rotzooi': 0, 'rellen': 1}},
{sentiment: 0.030030786991119385, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'overdrijven'], group: 'piet', emojis: ['🤮'], keywords: ['mensen', 'uitbreiding', 'Schiphol', 'protestanten', 'retoriek', "doemscenario's", 'Schiphol', 'protesten'], full_text: "Het is begrijpelijk dat mensen bezorgd zijn over de uitbreiding van Schiphol, maar sommige protestanten gaan veel te ver met hun overdreven retoriek en doemscenario's. #Schiphol #protesten #overdrijven 🤮", keyword_clusters: {'mensen': 0, 'uitbreiding': 1, 'Schiphol': 5, 'protestanten': 7, 'retoriek': 0, "doemscenario's": 4, 'protesten': 1}},
{sentiment: 0.9980827569961548, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'politiek'], group: 'piet', emojis: [], keywords: ['politici', 'protesten', 'Schiphol', 'agenda', 'Schiphol', 'protesten', 'politiek'], full_text: "Het is frustrerend om te zien dat sommige politici de protesten tegen Schiphol misbruiken om hun eigen agenda te promoten en zichzelf te profileren. #Schiphol #protesten #politiek", keyword_clusters: {'politici': 0, 'protesten': 1, 'Schiphol': 5, 'agenda': 7, 'politiek': 1}},
{sentiment: 0.02570521831512451, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'economie'], group: 'piet', emojis: ['🤬'], keywords: ['protestanten', 'idee', 'voordelen', 'Schiphol', 'land', 'termijnbelangen', 'Schiphol', 'protesten', 'economie', '🤬'], full_text: "Het is duidelijk dat sommige protestanten geen idee hebben van de economische voordelen van Schiphol voor ons land. Ze denken alleen aan hun eigen korte termijnbelangen. #Schiphol #protesten #economie 🤬", keyword_clusters: {'protestanten': 7, 'idee': 5, 'voordelen': 1, 'Schiphol': 5, 'land': 7, 'termijnbelangen': 4, 'protesten': 1, 'economie': 1}},
{sentiment: 0.9979729056358337, lat: 52.2434979, lng: 5.6343227, count: 1, topics: ['schiphol', 'protesten', 'reclame'], group: 'piet', emojis: [], keywords: ['protesten', 'Schiphol', 'anti-reclamecampagne', 'Nederland', 'poging', 'luchthaven', 'Schiphol', 'protesten', 'reclame'], full_text: "De protesten tegen Schiphol lijken meer op een anti-reclamecampagne voor Nederland dan op een serieuze poging om de luchthaven te veranderen. #Schiphol #protesten #reclame", keyword_clusters: {'protesten': 1, 'Schiphol': 5, 'anti-reclamecampagne': 5, 'Nederland': 5, 'poging': 5, 'luchthaven': 3, 'reclame': 1}},
{sentiment: 0.9979608058929443, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'media'], group: 'piet', emojis: [], keywords: ['media', 'protesten', 'Schiphol', 'sensatie', 'kijkcijfers', 'plaats', 'verslag', 'Schiphol', 'protesten', 'media'], full_text: "Het is teleurstellend dat sommige media de protesten tegen Schiphol alleen maar gebruiken om sensatie te maken en kijkcijfers te scoren, in plaats van een eerlijk en evenwichtig verslag te doen. #Schiphol #protesten #media", keyword_clusters: {'media': 1, 'protesten': 1, 'Schiphol': 5, 'sensatie': 7, 'kijkcijfers': 4, 'plaats': 0, 'verslag': 7}},
{sentiment: 0.9980092644691467, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'wetenschap'], group: 'piet', emojis: ['😤'], keywords: ['protestanten', 'wetenschappers', 'experts', 'gebied', 'luchtvaart', 'milieu', 'Schiphol', 'protesten', 'wetenschap', '😤'], full_text: "Het is onbegrijpelijk dat sommige protestanten denken dat ze het beter weten dan wetenschappers en experts op het gebied van luchtvaart en milieu. #Schiphol #protesten #wetenschap 😤", keyword_clusters: {'protestanten': 7, 'wetenschappers': 4, 'experts': 4, 'gebied': 4, 'luchtvaart': 7, 'milieu': 1, 'Schiphol': 5, 'protesten': 1, 'wetenschap': 1}},
{sentiment: 0.035902440547943115, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'oplossingen'], group: 'piet', emojis: ['😤'], keywords: ['tijd', 'Schiphol', 'plaats', 'oplossingen', 'problemen', 'Schiphol', 'protesten', 'oplossingen'], full_text: "Het is tijd dat we ophouden met het demoniseren van Schiphol en in plaats daarvan werken aan constructieve oplossingen voor de problemen die het met zich meebrengt. #Schiphol #protesten #oplossingen 😤", keyword_clusters: {'tijd': 3, 'Schiphol': 5, 'plaats': 0, 'oplossingen': 1, 'problemen': 4, 'protesten': 1}},
{sentiment: 0.9898415207862854, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'economie'], group: 'piet', emojis: ['😤'], keywords: ['Protesten', 'Schiphol', 'voordelen', 'Schiphol', 'protesten', 'economie', '😤'], full_text: "Protesten tegen Schiphol negeren economische voordelen. #Schiphol #protesten #economie 😤", keyword_clusters: {'Protesten': 0, 'Schiphol': 5, 'voordelen': 1, 'protesten': 1, 'economie': 1}},
{sentiment: 0.3340680003166199, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'hypocrisie'], group: 'piet', emojis: ['😤'], keywords: ['Hypocrisie', 'protestanten', 'Schiphol', 'Schiphol', 'protesten', 'hypocrisie'], full_text: "Hypocrisie van sommige protestanten tegen Schiphol is verbazingwekkend. #Schiphol #protesten #hypocrisie 😤", keyword_clusters: {'Hypocrisie': 7, 'protestanten': 7, 'Schiphol': 5, 'protesten': 1, 'hypocrisie': 1}},
{sentiment: 0.9927714467048645, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'rellen'], group: 'piet', emojis: ['😤'], keywords: ['protesten', 'Schiphol', 'rellen', 'Schiphol', 'protesten', 'rellen'], full_text: "Sommige protesten tegen Schiphol ontaarden in rellen. #Schiphol #protesten #rellen 😤", keyword_clusters: {'protesten': 1, 'Schiphol': 5, 'rellen': 1}},
{sentiment: 0.015464842319488525, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'overdrijven'], group: 'piet', emojis: ['🤬'], keywords: ['Protesten', 'Schiphol', "risico's", 'Schiphol', 'protesten', '🤬'], full_text: "Protesten tegen Schiphol overdrijven risico's. #Schiphol #protesten #overdrijven 🤬", keyword_clusters: {'Protesten': 0, 'Schiphol': 5, "risico's": 7, 'protesten': 1}},
{sentiment: 0.16762375831604004, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'politiek'], group: 'piet', emojis: ['😤'], keywords: ['Politici', 'protesten', 'Schiphol', 'Schiphol', 'protesten', 'politiek'], full_text: "Politici misbruiken protesten tegen Schiphol. #Schiphol #protesten #politiek 😤", keyword_clusters: {'Politici': 5, 'protesten': 1, 'Schiphol': 5, 'politiek': 1}},
{sentiment: 0.02780371904373169, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'economie'], group: 'piet', emojis: ['🤬'], keywords: ['Protesten', 'Schiphol', 'voordelen', 'Schiphol', 'protesten', 'economie', '🤬'], full_text: "Protesten tegen Schiphol negeren economische voordelen. #Schiphol #protesten #economie 🤬", keyword_clusters: {'Protesten': 0, 'Schiphol': 5, 'voordelen': 1, 'protesten': 1, 'economie': 1}},
{sentiment: 0.8980523943901062, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'reclame'], group: 'piet', emojis: ['🤬'], keywords: ['Protesten', 'Schiphol', 'anti-reclame', 'Nederland', 'Schiphol', 'protesten', 'reclame', '🤬'], full_text: "Protesten tegen Schiphol zijn anti-reclame voor Nederland. #Schiphol #protesten #reclame 🤬", keyword_clusters: {'Protesten': 0, 'Schiphol': 5, 'anti-reclame': 4, 'Nederland': 5, 'protesten': 1, 'reclame': 1}},
{sentiment: 0.019188404083251953, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'media'], group: 'piet', emojis: ['🤬'], keywords: ['Media', 'sensatie', 'protesten', 'Schiphol', 'Schiphol', 'protesten', 'media', '🤬'], full_text: "Media maken sensatie van protesten tegen Schiphol. #Schiphol #protesten #media 🤬", keyword_clusters: {'Media': 7, 'sensatie': 7, 'protesten': 1, 'Schiphol': 5, 'media': 1}},
{sentiment: 0.018695056438446045, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'wetenschap'], group: 'piet', emojis: ['🤬'], keywords: ['Protestanten', 'experts', 'Schiphol', 'protesten', 'wetenschap', '🤬'], full_text: "Protestanten denken het beter te weten dan experts. #Schiphol #protesten #wetenschap 🤬", keyword_clusters: {'Protestanten': 0, 'experts': 4, 'Schiphol': 5, 'protesten': 1, 'wetenschap': 1}},
{sentiment: 0.9978664517402649, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'oplossingen'], group: 'piet', emojis: [], keywords: ['demonisering', 'Schiphol', 'werk', 'oplossingen', 'Schiphol', 'protesten', 'oplossingen'], full_text: "Stop demonisering van Schiphol, werk aan oplossingen. #Schiphol #protesten #oplossingen", keyword_clusters: {'demonisering': 4, 'Schiphol': 5, 'werk': 4, 'oplossingen': 1, 'protesten': 1}},
{sentiment: 0.9980364441871643, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'economie', 'weer'], group: 'piet', emojis: [], keywords: ['protesten', 'Schiphol', 'voordelen', 'Schiphol', 'protesten', 'economie', 'weer'], full_text: "Weer protesten tegen Schiphol, terwijl economische voordelen overduidelijk zijn. #Schiphol #protesten #economie #weer", keyword_clusters: {'protesten': 1, 'Schiphol': 5, 'voordelen': 1, 'economie': 1, 'weer': 1}},
{sentiment: 0.9982659220695496, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'hypocrisie', 'ook'], group: 'piet', emojis: [], keywords: ['Hypocrisie', 'protestanten', 'Schiphol', 'Schiphol', 'protesten', 'hypocrisie'], full_text: "Hypocrisie van sommige protestanten tegen Schiphol is verbazingwekkend, ook nu weer. #Schiphol #protesten #hypocrisie #ook", keyword_clusters: {'Hypocrisie': 7, 'protestanten': 7, 'Schiphol': 5, 'protesten': 1, 'hypocrisie': 1}},
{sentiment: 0.01563405990600586, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'rellen', 'ook'], group: 'piet', emojis: ['🤬'], keywords: ['Protesten', 'Schiphol', 'keer', 'rellen', 'Schiphol', 'protesten', 'rellen', '🤬'], full_text: "Protesten tegen Schiphol ontaarden ook deze keer weer in rellen. #Schiphol #protesten #rellen #ook 🤬", keyword_clusters: {'Protesten': 0, 'Schiphol': 5, 'keer': 7, 'rellen': 1, 'protesten': 1}},
{sentiment: 0.9962130784988403, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'overdrijven', 'weer'], group: 'piet', emojis: [], keywords: ['Protesten', 'Schiphol', "risico's", 'Schiphol', 'protesten'], full_text: "Protesten tegen Schiphol overdrijven risico's, weer. #Schiphol #protesten #overdrijven #weer", keyword_clusters: {'Protesten': 0, 'Schiphol': 5, "risico's": 7, 'protesten': 1}},
{sentiment: 0.016129136085510254, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'politiek', 'ook'], group: 'piet', emojis: ['🤬'], keywords: ['Politici', 'keer', 'protesten', 'Schiphol', 'Schiphol', 'protesten', 'politiek', '🤬'], full_text: "Politici misbruiken ook dit keer weer protesten tegen Schiphol. #Schiphol #protesten #politiek #ook 🤬", keyword_clusters: {'Politici': 5, 'keer': 7, 'protesten': 1, 'Schiphol': 5, 'politiek': 1}},
{sentiment: 0.997676432132721, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'economie', 'weer'], group: 'piet', emojis: [], keywords: ['protesten', 'Schiphol', 'voordelen', 'Schiphol', 'protesten', 'economie', 'weer'], full_text: "Weer protesten tegen Schiphol die economische voordelen negeren. #Schiphol #protesten #economie #weer", keyword_clusters: {'protesten': 1, 'Schiphol': 5, 'voordelen': 1, 'economie': 1, 'weer': 1}},
{sentiment: 0.9977840781211853, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'reclame', 'ook'], group: 'piet', emojis: [], keywords: ['Protesten', 'Schiphol', 'anti-reclame', 'Nederland', 'Schiphol', 'protesten', 'reclame'], full_text: "Protesten tegen Schiphol zijn anti-reclame voor Nederland, ook nu weer. #Schiphol #protesten #reclame #ook", keyword_clusters: {'Protesten': 0, 'Schiphol': 5, 'anti-reclame': 4, 'Nederland': 5, 'protesten': 1, 'reclame': 1}},
{sentiment: 0.9981589913368225, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'media', 'weer'], group: 'piet', emojis: [], keywords: ['Media', 'sensatie', 'protesten', 'Schiphol', 'Schiphol', 'protesten', 'media'], full_text: "Media maken sensatie van protesten tegen Schiphol, ook weer. #Schiphol #protesten #media #weer", keyword_clusters: {'Media': 7, 'sensatie': 7, 'protesten': 1, 'Schiphol': 5, 'media': 1}},
{sentiment: 0.99815434217453, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'wetenschap', 'ook'], group: 'piet', emojis: [], keywords: ['Protestanten', 'experts', 'Schiphol', 'protesten', 'wetenschap'], full_text: "Protestanten denken het beter te weten dan experts, ook nu weer. #Schiphol #protesten #wetenschap #ook", keyword_clusters: {'Protestanten': 0, 'experts': 4, 'Schiphol': 5, 'protesten': 1, 'wetenschap': 1}},
{sentiment: 0.9971464276313782, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'oplossingen', 'ook'], group: 'piet', emojis: [], keywords: ['demonisering', 'Schiphol', 'werk', 'oplossingen', 'Schiphol', 'protesten', 'oplossingen'], full_text: "Stop demonisering van Schiphol, werk aan oplossingen, ook nu weer. #Schiphol #protesten #oplossingen #ook", keyword_clusters: {'demonisering': 4, 'Schiphol': 5, 'werk': 4, 'oplossingen': 1, 'protesten': 1}},
{sentiment: 0.9982669353485107, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['discussie', 'uitbreiding', 'Schiphol', 'onderwerp', 'tijd', 'gemoederen', 'Nederland', 'discussie', 'Twitter', 'media-site', 'mensen', 'achtergronden'], full_text: "De discussie over de uitbreiding van Schiphol is een onderwerp dat al geruime tijd de gemoederen bezighoudt in Nederland. Het is dan ook niet verwonderlijk dat deze discussie zich ook op Twitter afspeelt. Op deze social media-site komen mensen met verschillende achtergronden en m", keyword_clusters: {'discussie': 5, 'uitbreiding': 1, 'Schiphol': 5, 'onderwerp': 7, 'tijd': 3, 'gemoederen': 5, 'Nederland': 5, 'Twitter': 7, 'media-site': 5, 'mensen': 0, 'achtergronden': 0}},
{sentiment: 0.9977003931999207, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Twitter-discussie', 'tweedeling', 'mensen', 'uitbreiding', 'Schiphol', 'mensen', 'tweets', 'discussie'], full_text: "In deze Twitter-discussie zien we een duidelijke tweedeling tussen mensen die tegen de uitbreiding van Schiphol zijn en mensen die hiervoor zijn. Dit wordt onderstreept door de verschillende tweets die in deze discussie zijn geplaatst.", keyword_clusters: {'Twitter-discussie': 0, 'tweedeling': 7, 'mensen': 0, 'uitbreiding': 1, 'Schiphol': 5, 'tweets': 4, 'discussie': 5}},
{sentiment: 0.9976925849914551, lat: 53.0006, lng: 6.68635, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['tweets', 'mensen', 'milieueffecten', 'uitbreiding', 'Schiphol', 'feit', 'luchtverkeer', 'bijdrage', 'uitstoot', 'broeikasgassen', 'klimaatverandering', 'overlast'], full_text: "Zo zijn er tweets van mensen die zich zorgen maken over de milieueffecten van de uitbreiding van Schiphol. Zij wijzen op het feit dat het luchtverkeer een grote bijdrage levert aan de uitstoot van broeikasgassen en daarmee aan klimaatverandering. Anderen benadrukken de overlast d", keyword_clusters: {'tweets': 4, 'mensen': 0, 'milieueffecten': 0, 'uitbreiding': 1, 'Schiphol': 5, 'feit': 7, 'luchtverkeer': 0, 'bijdrage': 0, 'uitstoot': 7, 'broeikasgassen': 5, 'klimaatverandering': 1, 'overlast': 1}},
{sentiment: 0.9974164962768555, lat: 53.0006, lng: 6.68635, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['kant', 'tweets', 'mensen', 'voordelen', 'uitbreiding', 'Schiphol', 'Schiphol', 'motor', 'Nederland', 'positie', 'vin'], full_text: "Aan de andere kant zijn er tweets van mensen die wijzen op de economische voordelen van de uitbreiding van Schiphol. Zij wijzen erop dat Schiphol een belangrijke economische motor is voor Nederland en dat het belangrijk is om deze positie te behouden en te versterken. Anderen vin", keyword_clusters: {'kant': 0, 'tweets': 4, 'mensen': 0, 'voordelen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'motor': 0, 'Nederland': 5, 'positie': 7, 'vin': 0}},
{sentiment: 0.9949796795845032, lat: 53.0006, lng: 6.68635, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['standpunten', 'tweets', 'mensen', 'protesten', 'uitbreiding', 'Schiphol', 'protesten', 'protesten', 'rellen'], full_text: "Naast deze standpunten zijn er ook tweets van mensen die zich afzetten tegen de protesten tegen de uitbreiding van Schiphol. Zij noemen deze protesten overdreven of zelfs hypocriet. Anderen wijzen erop dat de protesten soms uitmonden in rellen en dat dit niet bijdraagt aan een zi", keyword_clusters: {'standpunten': 0, 'tweets': 4, 'mensen': 0, 'protesten': 1, 'uitbreiding': 1, 'Schiphol': 5, 'rellen': 1}},
{sentiment: 0.9984112977981567, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Twitter-discussie', 'uitbreiding', 'Schiphol', 'onderwerp', 'mensen', 'discussie', 'discussie', 'manier', 'o'], full_text: "De Twitter-discussie over de uitbreiding van Schiphol laat zien dat dit een onderwerp is dat zeer gevoelig ligt bij veel mensen. De discussie is soms gepassioneerd en soms fel, maar het is belangrijk dat deze discussie blijft plaatsvinden. Alleen op die manier kunnen we tot een o", keyword_clusters: {'Twitter-discussie': 0, 'uitbreiding': 1, 'Schiphol': 5, 'onderwerp': 7, 'mensen': 0, 'discussie': 5, 'manier': 5, 'o': 0}},
{sentiment: 0.9978002905845642, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['discussie', 'Twitter', 'ruimte', 'nuance', 'dialoog', 'samenleving', 'grond', 'oplossingen', 'ied'], full_text: "Het is opvallend dat de discussie op Twitter vaak gepolariseerd is en dat er weinig ruimte lijkt te zijn voor nuance en dialoog. Het zou goed zijn als we ons als samenleving meer zouden richten op het vinden van gemeenschappelijke grond en het zoeken naar oplossingen die voor ied", keyword_clusters: {'discussie': 5, 'Twitter': 7, 'ruimte': 5, 'nuance': 5, 'dialoog': 0, 'samenleving': 7, 'grond': 4, 'oplossingen': 1, 'ied': 4}},
{sentiment: 0.9982870221138, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Twitter-discussie', 'uitbreiding', 'Schiphol', 'onderwerp', 'onderwerp', 'economie', 'milieu', 'manier', 'oplossing'], full_text: "Kortom, de Twitter-discussie over de uitbreiding van Schiphol is een belangrijk onderwerp dat ons allemaal aangaat. Het is een onderwerp dat niet alleen gaat over economie en milieu, maar ook over onze manier van samenleven. Laten we daarom blijven praten en zoeken naar oplossing", keyword_clusters: {'Twitter-discussie': 0, 'uitbreiding': 1, 'Schiphol': 5, 'onderwerp': 7, 'economie': 1, 'milieu': 1, 'manier': 5, 'oplossing': 0}},
{sentiment: 0.9974160194396973, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopgroei', 'luchthaven'], group: 'piet', emojis: [], keywords: ['Schiphol', 'stopgroei', 'luchthaven'], full_text: "Eindelijk iemand die het snapt! Schiphol moet niet verder uitbreiden. #stopgroei #luchthaven", keyword_clusters: {'Schiphol': 5, 'stopgroei': 3, 'luchthaven': 3}},
{sentiment: 0.9977384805679321, lat: 52.1594747, lng: 4.4908843, count: 1, topics: ['gezondeleefomgeving', 'duurzaamheid'], group: 'piet', emojis: [], keywords: ['artikel', 'belangen', 'gezondheid', 'mens', 'milieu', 'gezondeleefomgeving', 'duurzaamheid'], full_text: "Ik ben het helemaal eens met dit artikel. We moeten ons niet laten leiden door economische belangen, maar door de gezondheid van mens en milieu. #gezondeleefomgeving #duurzaamheid", keyword_clusters: {'artikel': 5, 'belangen': 1, 'gezondheid': 1, 'mens': 4, 'milieu': 1, 'gezondeleefomgeving': 1, 'duurzaamheid': 1}},
{sentiment: 0.9970145225524902, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['milieubewustzijn', 'duurzametoekomst'], group: 'piet', emojis: [], keywords: ['tijd', 'uitbreiding', 'Schiphol', 'koste', 'welzijn', 'alternatieven', 'oplossing', 'milieubewustzijn', 'duurzametoekomst'], full_text: "Het wordt tijd dat we ons realiseren dat een uitbreiding van Schiphol ten koste gaat van ons welzijn. Minder vliegen en meer investeren in duurzame alternatieven is de enige oplossing. #milieubewustzijn #duurzametoekomst", keyword_clusters: {'tijd': 3, 'uitbreiding': 1, 'Schiphol': 5, 'koste': 5, 'welzijn': 1, 'alternatieven': 1, 'oplossing': 0, 'milieubewustzijn': 1, 'duurzametoekomst': 1}},
{sentiment: 0.9964518547058105, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['gezondeleefomgeving', 'rechtvaardigheid'], group: 'piet', emojis: [], keywords: ['overlast', 'Schiphol', 'belangen', 'groep', 'gezondeleefomgeving', 'rechtvaardigheid'], full_text: "Eens! De overlast van Schiphol is al groot genoeg. We moeten ons niet laten gijzelen door de belangen van een selecte groep. #gezondeleefomgeving #rechtvaardigheid", keyword_clusters: {'overlast': 1, 'Schiphol': 5, 'belangen': 1, 'groep': 5, 'gezondeleefomgeving': 1, 'rechtvaardigheid': 1}},
{sentiment: 0.9942373037338257, lat: null, lng: null, count: 1, topics: ['bewustzijn', 'natuurbehoud'], group: 'piet', emojis: [], keywords: ['mensen', 'voorstander', "Schiphol's", 'uitbreiding', 'tijd', 'mens', 'natuur', 'groei', 'bewustzijn', 'natuurbehoud'], full_text: "Ik kan niet begrijpen waarom er nog steeds mensen zijn die voorstander zijn van Schiphol's uitbreiding. Het is tijd om te kiezen voor de mens en de natuur, niet voor economische groei. #bewustzijn #natuurbehoud", keyword_clusters: {'mensen': 0, 'voorstander': 0, "Schiphol's": 5, 'uitbreiding': 1, 'tijd': 3, 'mens': 4, 'natuur': 1, 'groei': 4, 'bewustzijn': 1, 'natuurbehoud': 1}},
{sentiment: 0.9966247081756592, lat: null, lng: null, count: 1, topics: ['gezondleven', 'duurzametoekomst'], group: 'piet', emojis: [], keywords: ['gehoor', 'roep', 'leefomgeving', 'trend', 'toekomst', 'duurzametoekomst'], full_text: "Eindelijk wordt er gehoor gegeven aan de roep om een gezonde leefomgeving. Laten we deze trend voortzetten en ons inzetten voor een duurzame toekomst. #gezondleven #duurzametoekomst", keyword_clusters: {'gehoor': 4, 'roep': 5, 'leefomgeving': 1, 'trend': 5, 'toekomst': 1, 'duurzametoekomst': 1}},
{sentiment: 0.9977110624313354, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['milieubewustzijn', 'duurzaamheid'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'milieu', 'leefbaarheid', 'omgeving', 'tijd', 'toekomst', 'milieubewustzijn', 'duurzaamheid'], full_text: "De uitbreiding van Schiphol is niet alleen slecht voor het milieu, maar ook voor de leefbaarheid van de omgeving. Het wordt tijd dat we ons richten op een groenere en duurzamere toekomst. #milieubewustzijn #duurzaamheid", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'milieu': 1, 'leefbaarheid': 1, 'omgeving': 0, 'tijd': 3, 'toekomst': 1, 'milieubewustzijn': 1, 'duurzaamheid': 1}},
{sentiment: 0.9979023933410645, lat: null, lng: null, count: 1, topics: ['bewustzijn', 'natuurbehoud'], group: 'piet', emojis: [], keywords: ['kinderen', 'kleinkinderen', 'omgeving', 'actie', 'uitbreiding', 'Schiphol', 'bewustzijn', 'natuurbehoud'], full_text: "Als we willen dat onze kinderen en kleinkinderen ook kunnen genieten van een gezonde en groene omgeving, dan moeten we nu in actie komen tegen de uitbreiding van Schiphol. #bewustzijn #natuurbehoud", keyword_clusters: {'kinderen': 1, 'kleinkinderen': 5, 'omgeving': 0, 'actie': 1, 'uitbreiding': 1, 'Schiphol': 5, 'bewustzijn': 1, 'natuurbehoud': 1}},
{sentiment: 0.9975214600563049, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['milieubewustzijn', 'duurzaamtoekomstperspectief'], group: 'piet', emojis: [], keywords: ['groei', 'Schiphol', 'koste', 'leefomgeving', 'uitbreiding', 'toekomst', 'milieubewustzijn'], full_text: "De groei van Schiphol gaat ten koste van onze leefomgeving. Laten we ons daarom verzetten tegen de uitbreiding en ons inzetten voor een groenere en duurzamere toekomst. #milieubewustzijn #duurzaamtoekomstperspectief", keyword_clusters: {'groei': 4, 'Schiphol': 5, 'koste': 5, 'leefomgeving': 1, 'uitbreiding': 1, 'toekomst': 1, 'milieubewustzijn': 1}},
{sentiment: 0.9974914789199829, lat: null, lng: null, count: 1, topics: ['gezondleven', 'rechtvaardigheid'], group: 'piet', emojis: [], keywords: ['tijd', 'groei', 'gezondheid', 'welzijn', 'rechtvaardigheid'], full_text: "Het is tijd dat we ons afvragen wat we werkelijk belangrijk vinden. Is economische groei belangrijker dan onze gezondheid en ons welzijn? Ik denk van niet. #gezondleven #rechtvaardigheid", keyword_clusters: {'tijd': 3, 'groei': 4, 'gezondheid': 1, 'welzijn': 1, 'rechtvaardigheid': 1}},
{sentiment: 0.9981562495231628, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'milieu', 'leefbaarheid', 'veiligheid'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'gevolgen', 'milieu', 'leefbaarheid', 'veiligheid', 'omgeving', 'Schiphol', 'milieu', 'leefbaarheid', 'veiligheid'], full_text: "De uitbreiding van Schiphol heeft gevolgen voor het milieu, de leefbaarheid en de veiligheid in de omgeving. #Schiphol #milieu #leefbaarheid #veiligheid", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'gevolgen': 1, 'milieu': 1, 'leefbaarheid': 1, 'veiligheid': 1, 'omgeving': 0}},
{sentiment: 0.99739009141922, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'overlast', 'uitbreiding', 'omwonenden'], group: 'piet', emojis: [], keywords: ['Schiphol', 'overlast', 'omwonenden', 'Schiphol', 'overlast', 'uitbreiding', 'omwonenden'], full_text: "Waarom moet Schiphol uitbreiden als er al zoveel overlast is voor omwonenden? #Schiphol #overlast #uitbreiding #omwonenden", keyword_clusters: {'Schiphol': 5, 'overlast': 1, 'omwonenden': 1, 'uitbreiding': 1}},
{sentiment: 0.9982325434684753, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding', 'burgers', 'zorgen'], group: 'piet', emojis: [], keywords: ['tijd', 'regering', 'zorgen', 'burgers', 'uitbreiding', 'Schiphol', 'Schiphol', 'uitbreiding', 'burgers', 'zorgen'], full_text: "Het is tijd dat de regering meer luistert naar de zorgen van burgers over de uitbreiding van Schiphol. #Schiphol #uitbreiding #burgers #zorgen", keyword_clusters: {'tijd': 3, 'regering': 1, 'zorgen': 1, 'burgers': 1, 'uitbreiding': 1, 'Schiphol': 5}},
{sentiment: 0.996890127658844, lat: 52.1594747, lng: 4.4908843, count: 1, topics: ['schiphol', 'uitbreiding', 'geluidsoverlast', 'luchtvervuiling'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'geluidsoverlast', 'luchtvervuiling', 'Schiphol', 'uitbreiding', 'geluidsoverlast', 'luchtvervuiling'], full_text: "De uitbreiding van Schiphol zal leiden tot meer geluidsoverlast en luchtvervuiling, wat onacceptabel is. #Schiphol #uitbreiding #geluidsoverlast #luchtvervuiling", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'geluidsoverlast': 1, 'luchtvervuiling': 1}},
{sentiment: 0.9978184700012207, lat: null, lng: null, count: 1, topics: ['schiphol', 'gezondheid', 'milieu', 'economischegroei'], group: 'piet', emojis: [], keywords: ['gezondheid', 'milieu', 'groei', 'Schiphol', 'gezondheid', 'milieu', 'economischegroei'], full_text: "Wanneer zullen onze gezondheid en het milieu belangrijker zijn dan economische groei? #Schiphol #gezondheid #milieu #economischegroei", keyword_clusters: {'gezondheid': 1, 'milieu': 1, 'groei': 4, 'Schiphol': 5, 'economischegroei': 1}},
{sentiment: 0.9976577758789062, lat: null, lng: null, count: 1, topics: ['schiphol', 'alternatieven', 'treinverbindingen', 'regionaleluchthavens'], group: 'piet', emojis: [], keywords: ['tijd', 'alternatieven', 'uitbreiding', 'Schiphol', 'treinverbindingen', 'investeringen', 'luchthavens', 'Schiphol', 'alternatieven', 'treinverbindingen', 'regionaleluchthavens'], full_text: "Het is tijd dat we alternatieven vinden voor de uitbreiding van Schiphol, zoals betere treinverbindingen en investeringen in regionale luchthavens. #Schiphol #alternatieven #treinverbindingen #regionaleluchthavens", keyword_clusters: {'tijd': 3, 'alternatieven': 1, 'uitbreiding': 1, 'Schiphol': 5, 'treinverbindingen': 1, 'investeringen': 5, 'luchthavens': 5, 'regionaleluchthavens': 1}},
{sentiment: 0.9979974627494812, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'belangen', 'burgers', 'regering'], group: 'piet', emojis: [], keywords: ['regering', 'belangen', 'Schiphol', 'burgers', 'Schiphol', 'belangen', 'burgers', 'regering'], full_text: "Het lijkt erop dat de regering de belangen van Schiphol boven die van de burgers stelt. Dit moet veranderen. #Schiphol #belangen #burgers #regering", keyword_clusters: {'regering': 1, 'belangen': 1, 'Schiphol': 5, 'burgers': 1}},
{sentiment: 0.9979731440544128, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding', 'langetermijngevolgen', 'nadentijd'], group: 'piet', emojis: [], keywords: ['tijd', 'termijn', 'gevolgen', 'uitbreiding', 'Schiphol', 'Schiphol', 'uitbreiding', 'langetermijngevolgen', 'nadentijd'], full_text: "Het is tijd dat we nadenken over de lange termijn gevolgen van de uitbreiding van Schiphol, voordat het te laat is. #Schiphol #uitbreiding #langetermijngevolgen #nadentijd", keyword_clusters: {'tijd': 3, 'termijn': 5, 'gevolgen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'langetermijngevolgen': 1, 'nadentijd': 1}},
{sentiment: 0.9967449903488159, lat: null, lng: null, count: 1, topics: ['schiphol', 'overlast', 'omwonenden', 'uitbreiding'], group: 'piet', emojis: [], keywords: ['Schiphol', 'overlast', 'omwonenden', 'plaats', 'Schiphol', 'overlast', 'omwonenden', 'uitbreiding'], full_text: "Schiphol zou meer moeten doen om de overlast voor omwonenden te verminderen, in plaats van uit te breiden. #Schiphol #overlast #omwonenden #uitbreiding", keyword_clusters: {'Schiphol': 5, 'overlast': 1, 'omwonenden': 1, 'plaats': 0, 'uitbreiding': 1}},
{sentiment: 0.9980865716934204, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'gevolgen', 'duurzameoplossingen'], group: 'piet', emojis: [], keywords: ['gevolgen', 'uitbreiding', 'Schiphol', 'oplossingen', 'Schiphol', 'uitbreiding', 'gevolgen', 'duurzameoplossingen'], full_text: "Het is belangrijk dat we blijven praten over de gevolgen van de uitbreiding van Schiphol en op zoek gaan naar duurzame oplossingen. #Schiphol #uitbreiding #gevolgen #duurzameoplossingen", keyword_clusters: {'gevolgen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'oplossingen': 1, 'duurzameoplossingen': 1}},
{sentiment: 0.998178243637085, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['communities', 'looking'], full_text: "Hey guys! I wanted to take a moment to talk about the recent discussion surrounding Schiphol's expansion plans. As much as I love to travel, we need to consider the impact that this will have on the environment and the surrounding communities. We need to start looking at sustaina", keyword_clusters: {'communities': 0, 'looking': 4}},
{sentiment: 0.9979304075241089, lat: 52.2434979, lng: 5.6343227, count: 1, topics: ['schiphol', 'vvd', 'economie'], group: 'piet', emojis: [], keywords: ['Schiphol', 'motor', 'Nederland', 'VVD', 'uitbreiding', 'luchthaven', 'oog', 'duurzaamheid', 'leefbaarheid', 'regio', 'Schiphol', 'VVD', 'economie'], full_text: "Schiphol is een belangrijke economische motor voor Nederland en de VVD ondersteunt de verdere uitbreiding van de luchthaven, met oog voor duurzaamheid en leefbaarheid in de regio. #Schiphol #VVD #economie", keyword_clusters: {'Schiphol': 5, 'motor': 0, 'Nederland': 5, 'VVD': 5, 'uitbreiding': 1, 'luchthaven': 3, 'oog': 5, 'duurzaamheid': 1, 'leefbaarheid': 1, 'regio': 7, 'economie': 1}},
{sentiment: 0.9980742931365967, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd', 'economie', 'connectiviteit'], group: 'piet', emojis: [], keywords: ['zorgen', 'omwonenden', 'uitbreiding', 'Schiphol', 'economie', 'connectiviteit', 'VVD', 'ontwikkeling', 'luchthaven', 'Schiphol', 'VVD', 'economie', 'connectiviteit'], full_text: "Wij begrijpen de zorgen van omwonenden, maar de uitbreiding van Schiphol is essentieel voor onze economie en internationale connectiviteit. De VVD steunt dan ook de verdere ontwikkeling van de luchthaven. #Schiphol #VVD #economie #connectiviteit", keyword_clusters: {'zorgen': 1, 'omwonenden': 1, 'uitbreiding': 1, 'Schiphol': 5, 'economie': 1, 'connectiviteit': 1, 'VVD': 5, 'ontwikkeling': 0, 'luchthaven': 3}},
{sentiment: 0.9978869557380676, lat: 52.2434979, lng: 5.6343227, count: 1, topics: ['schiphol', 'vvd', 'luchtvaart'], group: 'piet', emojis: [], keywords: ['luchtvaartsector', 'belang', 'Nederland', 'positie', 'wereld', 'Schiphol', 'VVD', 'ontwikkeling', 'Schiphol', 'VVD', 'luchtvaart'], full_text: "De luchtvaartsector is van groot belang voor Nederland en onze positie in de wereld. Een groeiende Schiphol is daarom noodzakelijk en de VVD staat achter deze ontwikkeling. #Schiphol #VVD #luchtvaart", keyword_clusters: {'luchtvaartsector': 0, 'belang': 7, 'Nederland': 5, 'positie': 7, 'wereld': 7, 'Schiphol': 5, 'VVD': 5, 'ontwikkeling': 0, 'luchtvaart': 7}},
{sentiment: 0.9978328347206116, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd', 'werkgelegenheid', 'economie'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'economie', 'werkgelegenheid', 'VVD', 'ontwikkeling', 'luchthaven', 'Schiphol', 'VVD', 'werkgelegenheid', 'economie'], full_text: "De uitbreiding van Schiphol is niet alleen goed voor onze economie, maar biedt ook veel werkgelegenheid. De VVD staat daarom achter de verdere ontwikkeling van de luchthaven. #Schiphol #VVD #werkgelegenheid #economie", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'economie': 1, 'werkgelegenheid': 1, 'VVD': 5, 'ontwikkeling': 0, 'luchthaven': 3}},
{sentiment: 0.9972975850105286, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd', 'duurzaamheid', 'leefbaarheid'], group: 'piet', emojis: [], keywords: ['VVD', 'groei', 'Schiphol', 'investeringen', 'duurzaamheid', 'overlast', 'omwonenden', 'economie', 'omgeving', 'Schiphol', 'VVD', 'duurzaamheid', 'leefbaarheid'], full_text: "De VVD vindt dat de groei van Schiphol moet samengaan met investeringen in duurzaamheid en het verminderen van de overlast voor omwonenden. Zo kunnen we een sterke economie combineren met een leefbare omgeving. #Schiphol #VVD #duurzaamheid #leefbaarheid", keyword_clusters: {'VVD': 5, 'groei': 4, 'Schiphol': 5, 'investeringen': 5, 'duurzaamheid': 1, 'overlast': 1, 'omwonenden': 1, 'economie': 1, 'omgeving': 0, 'leefbaarheid': 1}},
{sentiment: 0.9980875849723816, lat: 51.5794077, lng: 5.7135574, count: 1, topics: ['schiphol', 'vvd', 'handel', 'transport'], group: 'piet', emojis: [], keywords: ['Schiphol', 'knooppunt', 'handel', 'transport', 'VVD', 'luchthaven', 'bijdrage', 'economie', 'Schiphol', 'VVD', 'handel', 'transport'], full_text: "Schiphol is een belangrijk knooppunt voor internationale handel en transport. De VVD wil daarom dat de luchthaven zich verder kan ontwikkelen en zo een positieve bijdrage kan leveren aan onze economie. #Schiphol #VVD #handel #transport", keyword_clusters: {'Schiphol': 5, 'knooppunt': 4, 'handel': 2, 'transport': 2, 'VVD': 5, 'luchthaven': 3, 'bijdrage': 0, 'economie': 1}},
{sentiment: 0.9979727864265442, lat: null, lng: null, count: 1, topics: ['schiphol', 'vvd', 'veiligheid', 'gezondheid'], group: 'piet', emojis: [], keywords: ['VVD', 'uitbreiding', 'Schiphol', 'oog', 'veiligheid', 'gezondheid', 'omwonenden', 'reizigers', 'aandacht', 'impact', 'luchthaven', 'omgeving', 'Schiphol', 'VVD', 'veiligheid', 'gezondheid'], full_text: "De VVD steunt de uitbreiding van Schiphol, maar wel met oog voor de veiligheid en gezondheid van omwonenden en reizigers. Er moet blijvend aandacht zijn voor de impact van de luchthaven op de omgeving. #Schiphol #VVD #veiligheid #gezondheid", keyword_clusters: {'VVD': 5, 'uitbreiding': 1, 'Schiphol': 5, 'oog': 5, 'veiligheid': 1, 'gezondheid': 1, 'omwonenden': 1, 'reizigers': 1, 'aandacht': 5, 'impact': 5, 'luchthaven': 3, 'omgeving': 0}},
{sentiment: 0.9979459643363953, lat: null, lng: null, count: 1, topics: ['schiphol', 'vvd', 'overlast', 'innovatie'], group: 'piet', emojis: [], keywords: ['Schiphol', 'economie', 'connectiviteit', 'VVD', 'omwonenden', 'overlast', 'oplossingen', 'overlast', 'Schiphol', 'VVD', 'overlast', 'innovatie'], full_text: "Schiphol is belangrijk voor onze economie en internationale connectiviteit, maar de VVD begrijpt ook dat omwonenden overlast ervaren. We moeten daarom blijven investeren in innovatieve oplossingen om deze overlast te verminderen. #Schiphol #VVD #overlast #innovatie", keyword_clusters: {'Schiphol': 5, 'economie': 1, 'connectiviteit': 1, 'VVD': 5, 'omwonenden': 1, 'overlast': 1, 'oplossingen': 1, 'innovatie': 1}},
{sentiment: 0.9977728724479675, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd', 'duurzaamheid', 'efficiëntie'], group: 'piet', emojis: [], keywords: ['VVD', 'ontwikkeling', 'Schiphol', 'luchthaven', 'technologieën', 'innovaties', 'doelstelling', 'Schiphol', 'VVD', 'duurzaamheid', 'efficiëntie'], full_text: "De VVD steunt de ontwikkeling van Schiphol als duurzame en efficiënte luchthaven. We moeten blijven investeren in moderne technologieën en innovaties om deze doelstelling te bereiken. #Schiphol #VVD #duurzaamheid #efficiëntie", keyword_clusters: {'VVD': 5, 'ontwikkeling': 0, 'Schiphol': 5, 'luchthaven': 3, 'technologieën': 5, 'innovaties': 5, 'doelstelling': 7, 'duurzaamheid': 1, 'efficiëntie': 1}},
{sentiment: 0.9979515671730042, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd', 'economie', 'concurrentiepositie'], group: 'piet', emojis: [], keywords: ['VVD', 'kracht', 'economie', 'rol', 'Schiphol', 'ontwikkeling', 'luchthaven', 'concurrentiepositie', 'welvaart', 'Schiphol', 'VVD', 'economie', 'concurrentiepositie'], full_text: "De VVD gelooft in de kracht van de Nederlandse economie en de rol van Schiphol daarin. De verdere ontwikkeling van de luchthaven is daarom belangrijk voor onze internationale concurrentiepositie en welvaart. #Schiphol #VVD #economie #concurrentiepositie", keyword_clusters: {'VVD': 5, 'kracht': 5, 'economie': 1, 'rol': 5, 'Schiphol': 5, 'ontwikkeling': 0, 'luchthaven': 3, 'concurrentiepositie': 1, 'welvaart': 5}},
{sentiment: 0.9968979358673096, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'duurzaamheid', 'groenlinks'], group: 'piet', emojis: [], keywords: ['groei', 'Schiphol', 'tijd', 'natuur', 'leefomgeving', 'GroenLinks', 'alternatieven', 'Schiphol', 'duurzaamheid', 'GroenLinks'], full_text: "De groei van Schiphol moet worden afgeremd, het is tijd om de natuur en de leefomgeving te beschermen. GroenLinks kiest voor duurzame alternatieven! #Schiphol #duurzaamheid #GroenLinks", keyword_clusters: {'groei': 4, 'Schiphol': 5, 'tijd': 3, 'natuur': 1, 'leefomgeving': 1, 'GroenLinks': 0, 'alternatieven': 1, 'duurzaamheid': 1}},
{sentiment: 0.9975517392158508, lat: null, lng: null, count: 1, topics: ['duurzaamheid', 'schiphol', 'groenlinks'], group: 'piet', emojis: [], keywords: ['GroenLinks', 'uitbreiding', 'Schiphol', 'tijd', 'toekomst', 'luchtvaartsector', 'banden', 'duurzaamheid', 'Schiphol', 'GroenLinks'], full_text: "GroenLinks maakt zich zorgen over de uitbreiding van Schiphol. Het is hoog tijd dat we kiezen voor een duurzame toekomst en de luchtvaartsector aan banden leggen. #duurzaamheid #Schiphol #GroenLinks", keyword_clusters: {'GroenLinks': 0, 'uitbreiding': 1, 'Schiphol': 5, 'tijd': 3, 'toekomst': 1, 'luchtvaartsector': 0, 'banden': 4, 'duurzaamheid': 1}},
{sentiment: 0.9969410300254822, lat: null, lng: null, count: 1, topics: ['schiphol', 'duurzaamheid', 'groenlinks'], group: 'piet', emojis: [], keywords: ['alternatieven', 'Schiphol', 'uitstoot', 'CO2', 'stoffen', 'leefomgeving', 'toekomst', 'Schiphol', 'duurzaamheid', 'GroenLinks'], full_text: "Wij vinden het belangrijk om te investeren in duurzame alternatieven voor Schiphol. De uitstoot van CO2 en andere schadelijke stoffen moet worden teruggedrongen, voor een betere leefomgeving en gezonde toekomst. #Schiphol #duurzaamheid #GroenLinks", keyword_clusters: {'alternatieven': 1, 'Schiphol': 5, 'uitstoot': 7, 'CO2': 7, 'stoffen': 4, 'leefomgeving': 1, 'toekomst': 1, 'duurzaamheid': 1, 'GroenLinks': 0}},
{sentiment: 0.9975900650024414, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'duurzaamheid', 'groenlinks'], group: 'piet', emojis: [], keywords: ['Schiphol', 'luchtvervuiler', 'Nederland', 'GroenLinks', 'uitbreiding', 'Schiphol', 'alternatieven', 'Schiphol', 'duurzaamheid', 'GroenLinks'], full_text: "Schiphol is de grootste luchtvervuiler van Nederland. GroenLinks wil de uitbreiding van Schiphol stoppen en investeren in duurzame alternatieven. #Schiphol #duurzaamheid #GroenLinks", keyword_clusters: {'Schiphol': 5, 'luchtvervuiler': 7, 'Nederland': 5, 'GroenLinks': 0, 'uitbreiding': 1, 'alternatieven': 1, 'duurzaamheid': 1}},
{sentiment: 0.9980593323707581, lat: null, lng: null, count: 1, topics: ['schiphol', 'duurzaamheid', 'groenlinks'], group: 'piet', emojis: [], keywords: ['GroenLinks', 'belangen', 'luchtvaartsector', 'gezondheid', 'welzijn', 'omwonenden', 'milieu', 'Schiphol', 'duurzaamheid', 'GroenLinks'], full_text: "GroenLinks vindt dat de belangen van de luchtvaartsector niet boven die van de gezondheid en het welzijn van de omwonenden en het milieu mogen staan. #Schiphol #duurzaamheid #GroenLinks", keyword_clusters: {'GroenLinks': 0, 'belangen': 1, 'luchtvaartsector': 0, 'gezondheid': 1, 'welzijn': 1, 'omwonenden': 1, 'milieu': 1, 'Schiphol': 5, 'duurzaamheid': 1}},
{sentiment: 0.9946672916412354, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'duurzaamheid', 'groenlinks'], group: 'piet', emojis: [], keywords: ['Schiphol', 'GroenLinks', 'halt', 'groei', 'oplossingen', 'Schiphol', 'duurzaamheid', 'GroenLinks'], full_text: "Het is onverantwoord om Schiphol verder uit te breiden. GroenLinks wil een halt toeroepen aan de groei en kiezen voor duurzame oplossingen. #Schiphol #duurzaamheid #GroenLinks", keyword_clusters: {'Schiphol': 5, 'GroenLinks': 0, 'halt': 7, 'groei': 4, 'oplossingen': 1, 'duurzaamheid': 1}},
{sentiment: 0.9977307915687561, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'duurzaamheid', 'groenlinks'], group: 'piet', emojis: [], keywords: ['Schiphol', 'geluidsoverlast', 'luchtvervuiling', 'GroenLinks', 'einde', 'groei', 'Schiphol', 'alternatieven', 'Schiphol', 'duurzaamheid', 'GroenLinks'], full_text: "Schiphol leidt tot geluidsoverlast en luchtvervuiling. GroenLinks wil daarom een einde maken aan de groei van Schiphol en inzetten op duurzame alternatieven. #Schiphol #duurzaamheid #GroenLinks", keyword_clusters: {'Schiphol': 5, 'geluidsoverlast': 1, 'luchtvervuiling': 1, 'GroenLinks': 0, 'einde': 0, 'groei': 4, 'alternatieven': 1, 'duurzaamheid': 1}},
{sentiment: 0.9978602528572083, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'duurzaamheid', 'groenlinks'], group: 'piet', emojis: [], keywords: ['GroenLinks', 'Schiphol', 'leefomgeving', 'toekomst', 'stop', 'groei', 'alternatieven', 'Schiphol', 'duurzaamheid', 'GroenLinks'], full_text: "GroenLinks wil dat Schiphol bijdraagt aan een gezonde leefomgeving en een duurzame toekomst. Daarom kiezen wij voor een stop op de groei en duurzame alternatieven. #Schiphol #duurzaamheid #GroenLinks", keyword_clusters: {'GroenLinks': 0, 'Schiphol': 5, 'leefomgeving': 1, 'toekomst': 1, 'stop': 7, 'groei': 4, 'alternatieven': 1, 'duurzaamheid': 1}},
{sentiment: 0.997775137424469, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'duurzaamheid', 'groenlinks'], group: 'piet', emojis: [], keywords: ['tijd', 'verandering', 'GroenLinks', 'groei', 'Schiphol', 'alternatieven', 'Schiphol', 'duurzaamheid', 'GroenLinks'], full_text: "Het is tijd voor verandering. GroenLinks wil af van de groei van Schiphol en investeren in duurzame alternatieven. #Schiphol #duurzaamheid #GroenLinks", keyword_clusters: {'tijd': 3, 'verandering': 1, 'GroenLinks': 0, 'groei': 4, 'Schiphol': 5, 'alternatieven': 1, 'duurzaamheid': 1}},
{sentiment: 0.997346043586731, lat: null, lng: null, count: 1, topics: ['schiphol', 'duurzaamheid', 'groenlinks'], group: 'piet', emojis: [], keywords: ['Schiphol', 'overlast', 'vervuiling', 'uitbreiding', 'GroenLinks', 'toekomst', 'planeet', 'lucht', 'Schiphol', 'duurzaamheid', 'GroenLinks'], full_text: "Schiphol veroorzaakt veel overlast en vervuiling, daarom moet de uitbreiding worden gestopt. GroenLinks kiest voor een duurzame toekomst, waarin we onze planeet beschermen en gezonde lucht inademen. #Schiphol #duurzaamheid #GroenLinks", keyword_clusters: {'Schiphol': 5, 'overlast': 1, 'vervuiling': 1, 'uitbreiding': 1, 'GroenLinks': 0, 'toekomst': 1, 'planeet': 1, 'lucht': 0, 'duurzaamheid': 1}},
{sentiment: 0.998011589050293, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'duurzaamheid', 'leefomgeving'], group: 'piet', emojis: [], keywords: ["CU'er", 'belang', 'duurzaamheid', 'behoud', 'leefomgeving', 'groei', 'Schiphol', 'koste', 'milieu', 'Schiphol', 'duurzaamheid', 'leefomgeving'], full_text: "Als CU'er geloof ik in het belang van duurzaamheid en behoud van onze leefomgeving. Een verantwoorde groei van Schiphol is mogelijk, maar niet ten koste van het milieu. #Schiphol #duurzaamheid #leefomgeving", keyword_clusters: {"CU'er": 4, 'belang': 7, 'duurzaamheid': 1, 'behoud': 7, 'leefomgeving': 1, 'groei': 4, 'Schiphol': 5, 'koste': 5, 'milieu': 1}},
{sentiment: 0.9981096982955933, lat: null, lng: null, count: 1, topics: ['schiphol', 'economie', 'leefbaarheid'], group: 'piet', emojis: [], keywords: ['CU', 'voorstander', 'groei', 'Schiphol', 'belangen', 'leefbaarheid', 'omwonenden', 'Schiphol', 'economie', 'leefbaarheid'], full_text: "De CU is voorstander van een evenwichtige groei van Schiphol, waarbij zowel de economische belangen als de leefbaarheid van omwonenden worden meegewogen. #Schiphol #economie #leefbaarheid", keyword_clusters: {'CU': 7, 'voorstander': 0, 'groei': 4, 'Schiphol': 5, 'belangen': 1, 'leefbaarheid': 1, 'omwonenden': 1, 'economie': 1}},
{sentiment: 0.9981603026390076, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'economie', 'milieu'], group: 'piet', emojis: [], keywords: ['Schiphol', 'economie', 'koste', 'gezondheid', 'omwonenden', 'milieu', 'CU', 'groei', 'afspraken', 'luchtvaartsector', 'Schiphol', 'economie', 'milieu'], full_text: "Het is belangrijk dat Schiphol bijdraagt aan een gezonde economie, maar niet ten koste van de gezondheid van omwonenden en het milieu. Daarom pleit de CU voor een verantwoorde groei en goede afspraken met de luchtvaartsector. #Schiphol #economie #milieu", keyword_clusters: {'Schiphol': 5, 'economie': 1, 'koste': 5, 'gezondheid': 1, 'omwonenden': 1, 'milieu': 1, 'CU': 7, 'groei': 4, 'afspraken': 7, 'luchtvaartsector': 0}},
{sentiment: 0.9978653788566589, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'duurzaamheid', 'toekomst'], group: 'piet', emojis: [], keywords: ["CU'er", 'toekomst', 'kinderen', 'groei', 'Schiphol', 'luchtvaartsector', 'technologieën', 'Schiphol', 'duurzaamheid', 'toekomst'], full_text: "Als CU'er sta ik voor een duurzame toekomst voor onze kinderen. Een verantwoorde groei van Schiphol is daarbij essentieel, waarbij we de luchtvaartsector stimuleren om te investeren in duurzame technologieën. #Schiphol #duurzaamheid #toekomst", keyword_clusters: {"CU'er": 4, 'toekomst': 1, 'kinderen': 1, 'groei': 4, 'Schiphol': 5, 'luchtvaartsector': 0, 'technologieën': 5, 'duurzaamheid': 1}},
{sentiment: 0.9980396628379822, lat: null, lng: null, count: 1, topics: ['schiphol', 'omwonenden', 'leefbaarheid'], group: 'piet', emojis: [], keywords: ['CU', 'belangen', 'omwonenden', 'leefklimaat', 'Schiphol', 'groei', 'luchthaven', 'leefbaarheid', 'omwonenden', 'Schiphol', 'omwonenden', 'leefbaarheid'], full_text: "De CU maakt zich hard voor de belangen van omwonenden en pleit voor een goed leefklimaat rondom Schiphol. Een verantwoorde groei van de luchthaven is alleen mogelijk als de leefbaarheid van omwonenden wordt gewaarborgd. #Schiphol #omwonenden #leefbaarheid", keyword_clusters: {'CU': 7, 'belangen': 1, 'omwonenden': 1, 'leefklimaat': 7, 'Schiphol': 5, 'groei': 4, 'luchthaven': 3, 'leefbaarheid': 1}},
{sentiment: 0.9979639053344727, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'veiligheid', 'gezondheid'], group: 'piet', emojis: ['😜'], keywords: ['CU', 'groei', 'Schiphol', 'koste', 'veiligheid', 'gezondheid', 'omwonenden', 'groei', 'afspraken', 'beleid', 'overlast', 'Schiphol', 'veiligheid', 'gezondheid', '😜'], full_text: "De CU vindt dat de groei van Schiphol niet ten koste mag gaan van de veiligheid en gezondheid van omwonenden. We staan daarom voor verantwoorde groei met goede afspraken en een actief beleid om de overlast te verminderen. #Schiphol #veiligheid #gezondheid 😜", keyword_clusters: {'CU': 7, 'groei': 4, 'Schiphol': 5, 'koste': 5, 'veiligheid': 1, 'gezondheid': 1, 'omwonenden': 1, 'afspraken': 7, 'beleid': 7, 'overlast': 1}},
{sentiment: 0.9981252551078796, lat: null, lng: null, count: 1, topics: ['schiphol', 'economie', 'duurzaamhe'], group: 'piet', emojis: [], keywords: ['CU', 'balans', 'economie', 'leefbaarheid', 'pleit', 'groei', 'Schiphol', 'luchtvaartsector', 'verantwoordelijkheid', 'gebied', 'duurzaamheid', 'impact', 'Schiphol', 'economie', 'duurzaamhe'], full_text: "De CU wil een balans tussen economie en leefbaarheid en pleit voor een verantwoorde groei van Schiphol. Daarbij is het belangrijk dat de luchtvaartsector ook haar verantwoordelijkheid neemt op het gebied van duurzaamheid en maatschappelijke impact. #Schiphol #economie #duurzaamhe", keyword_clusters: {'CU': 7, 'balans': 0, 'economie': 1, 'leefbaarheid': 1, 'pleit': 7, 'groei': 4, 'Schiphol': 5, 'luchtvaartsector': 0, 'verantwoordelijkheid': 4, 'gebied': 4, 'duurzaamheid': 1, 'impact': 5, 'duurzaamhe': 1}},
{sentiment: 0.9980220794677734, lat: null, lng: null, count: 1, topics: ['schiphol', 'leefomgeving', 'rechtvaardigheid'], group: 'piet', emojis: [], keywords: ['CU', 'samenleving', 'recht', 'leefomgeving', 'groei', 'Schiphol', 'belangen', 'omwonenden', 'milieu', 'Schiphol', 'leefomgeving', 'rechtvaardigheid'], full_text: "De CU staat voor een rechtvaardige samenleving en vindt dat iedereen recht heeft op een gezonde leefomgeving. Daarom pleiten wij voor een verantwoorde groei van Schiphol, waarbij de belangen van omwonenden en het milieu worden meegewogen. #Schiphol #leefomgeving #rechtvaardigheid", keyword_clusters: {'CU': 7, 'samenleving': 7, 'recht': 0, 'leefomgeving': 1, 'groei': 4, 'Schiphol': 5, 'belangen': 1, 'omwonenden': 1, 'milieu': 1, 'rechtvaardigheid': 1}},
{sentiment: 0.9979493021965027, lat: 52.1594747, lng: 4.4908843, count: 1, topics: ['schiphol', 'klimaatverandering', 'duurzaamheid'], group: 'piet', emojis: [], keywords: ['CU', 'groei', 'Schiphol', 'CO2-uitstoot', 'klimaatverandering', 'investeringen', 'technologieën', 'groei', 'luchthaven', 'Schiphol', 'klimaatverandering', 'duurzaamheid'], full_text: "De CU vindt dat de groei van Schiphol niet mag leiden tot extra CO2-uitstoot en klimaatverandering. Daarom pleiten wij voor investeringen in duurzame technologieën en een verantwoorde groei van de luchthaven. #Schiphol #klimaatverandering #duurzaamheid", keyword_clusters: {'CU': 7, 'groei': 4, 'Schiphol': 5, 'CO2-uitstoot': 0, 'klimaatverandering': 1, 'investeringen': 5, 'technologieën': 5, 'luchthaven': 3, 'duurzaamheid': 1}},
{sentiment: 0.9983127117156982, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ["CU'er", 'oog', 'belangen', 'omwonenden', 'belangen', 'Schip'], full_text: "Als CU'er vind ik het belangrijk dat we oog hebben voor de belangen van omwonenden én de economische belangen van Schip", keyword_clusters: {"CU'er": 4, 'oog': 5, 'belangen': 1, 'omwonenden': 1, 'Schip': 0}},
{sentiment: 0.9968181848526001, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'd66'], group: 'piet', emojis: [], keywords: ['groei', 'Schiphol', 'ontwikkeling', 'voordelen', 'Schiphol', 'vermindering', 'geluidsoverlast', 'CO2-uitstoot', 'Schiphol'], full_text: "Als D66 willen we de groei van Schiphol niet stoppen, maar wel zorgen voor duurzame ontwikkeling. Laten we de economische voordelen van Schiphol behouden, terwijl we ons richten op vermindering van geluidsoverlast en CO2-uitstoot. #Schiphol #D66", keyword_clusters: {'groei': 4, 'Schiphol': 5, 'ontwikkeling': 0, 'voordelen': 1, 'vermindering': 5, 'geluidsoverlast': 1, 'CO2-uitstoot': 0}},
{sentiment: 0.9974908828735352, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'd66'], group: 'piet', emojis: [], keywords: ['D66', 'aanpak', 'Schiphol', 'leefbaarheid', 'omgeving', 'toekomst', 'Schiphol'], full_text: "Bij D66 geloven we in een gebalanceerde aanpak. Schiphol kan groeien, maar niet zonder dat we ons richten op het verbeteren van de leefbaarheid in de omgeving. We moeten samenwerken voor een duurzame toekomst! #Schiphol #D66", keyword_clusters: {'D66': 0, 'aanpak': 7, 'Schiphol': 5, 'leefbaarheid': 1, 'omgeving': 0, 'toekomst': 1}},
{sentiment: 0.9978678226470947, lat: null, lng: null, count: 1, topics: ['schiphol', 'd66'], group: 'piet', emojis: ['😜'], keywords: ['D66', 'aanpak', 'Schiphol-groei', 'oplossingen', 'geluidsoverlast', 'milieuschade', 'voordelen', 'ontwikkeling', 'Schiphol', 'D66', '😜'], full_text: "D66 staat voor een verantwoordelijke aanpak van de Schiphol-groei. Laten we kijken naar innovatieve oplossingen voor geluidsoverlast en milieuschade, zodat we zowel economische voordelen als duurzame ontwikkeling kunnen realiseren. #Schiphol #D66 😜", keyword_clusters: {'D66': 0, 'aanpak': 7, 'Schiphol-groei': 7, 'oplossingen': 1, 'geluidsoverlast': 1, 'milieuschade': 5, 'voordelen': 1, 'ontwikkeling': 0, 'Schiphol': 5}},
{sentiment: 0.9971568584442139, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'd66'], group: 'piet', emojis: [], keywords: ['tijd', 'groei', 'Schiphol', 'D66', 'oplossingen', 'geluidsoverlast', 'effecten', 'milieu', 'voordelen', 'Schiphol', 'Schiphol'], full_text: "Het is tijd om de groei van Schiphol duurzaam aan te pakken. D66 wil investeren in slimme oplossingen voor geluidsoverlast en de negatieve effecten op het milieu, terwijl we de economische voordelen van Schiphol behouden. #Schiphol #D66", keyword_clusters: {'tijd': 3, 'groei': 4, 'Schiphol': 5, 'D66': 0, 'oplossingen': 1, 'geluidsoverlast': 1, 'effecten': 4, 'milieu': 1, 'voordelen': 1}},
{sentiment: 0.9978247880935669, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'd66'], group: 'piet', emojis: [], keywords: ['D66', 'potentie', 'Schiphol', 'motor', 'effecten', 'innovatie', 'duurzaamheid', 'Schiphol'], full_text: "D66 ziet de potentie van Schiphol als een belangrijke economische motor, maar we moeten ook de negatieve effecten erkennen. Laten we ons richten op innovatie en duurzaamheid, zodat we zowel economisch als milieutechnisch vooruit kunnen. #Schiphol #D66", keyword_clusters: {'D66': 0, 'potentie': 0, 'Schiphol': 5, 'motor': 0, 'effecten': 4, 'innovatie': 1, 'duurzaamheid': 1}},
{sentiment: 0.9978376030921936, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'd66'], group: 'piet', emojis: [], keywords: ['D66', 'toekomst', 'Schiphol', 'omgeving', 'groei', 'leefbaarheid', 'milieu', 'Schiphol'], full_text: "Bij D66 geloven we in een gezonde toekomst voor Schiphol en de omgeving. Dat betekent dat we duurzame groei mogelijk moeten maken, terwijl we tegelijkertijd de leefbaarheid en het milieu beschermen. #Schiphol #D66", keyword_clusters: {'D66': 0, 'toekomst': 1, 'Schiphol': 5, 'omgeving': 0, 'groei': 4, 'leefbaarheid': 1, 'milieu': 1}},
{sentiment: 0.997334897518158, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'd66'], group: 'piet', emojis: [], keywords: ['Schiphol', 'aanpak', 'D66', 'technologie', 'innovatie', 'geluidsoverlast', 'milieuschade', 'voordelen', 'Schiphol', 'Schiphol'], full_text: "Als het gaat om Schiphol, moeten we ons richten op een gebalanceerde aanpak. D66 wil investeren in technologie en innovatie om de geluidsoverlast en milieuschade te verminderen, terwijl we de economische voordelen van Schiphol behouden. #Schiphol #D66", keyword_clusters: {'Schiphol': 5, 'aanpak': 7, 'D66': 0, 'technologie': 7, 'innovatie': 1, 'geluidsoverlast': 1, 'milieuschade': 5, 'voordelen': 1}},
{sentiment: 0.9978240728378296, lat: null, lng: null, count: 1, topics: ['schiphol', 'd66'], group: 'piet', emojis: [], keywords: ['D66', 'Schiphol-groei', 'ontwikkeling', 'toekomst', 'voorspoed', 'bescherming', 'milieu', 'Schiphol'], full_text: "D66 wil de Schiphol-groei niet stoppen, maar we willen wel zorgen voor een duurzame ontwikkeling. Laten we samenwerken voor een betere toekomst, waarin we economische voorspoed combineren met bescherming van het milieu. #Schiphol #D66", keyword_clusters: {'D66': 0, 'Schiphol-groei': 7, 'ontwikkeling': 0, 'toekomst': 1, 'voorspoed': 0, 'bescherming': 7, 'milieu': 1, 'Schiphol': 5}},
{sentiment: 0.9977415800094604, lat: null, lng: null, count: 1, topics: ['schiphol', 'd66'], group: 'piet', emojis: ['😜'], keywords: ['Schiphol-groei', 'kans', 'toekomst', 'D66', 'kans', 'ontwikkeling', 'innovatie', 'vooruitgang', 'Schiphol', 'D66', '😜'], full_text: "Het is belangrijk om de Schiphol-groei te zien als een kans om te investeren in de toekomst. Bij D66 willen we deze kans grijpen door te investeren in duurzame ontwikkeling en innovatie, zodat we zowel economisch als milieutechnisch vooruitgang boeken. #Schiphol #D66 😜", keyword_clusters: {'Schiphol-groei': 7, 'kans': 0, 'toekomst': 1, 'D66': 0, 'ontwikkeling': 0, 'innovatie': 1, 'vooruitgang': 1, 'Schiphol': 5}},
{sentiment: 0.997843861579895, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['D66', 'toekomst', 'Schiphol', 'omgeving', 'aanpak', 'voordelen', 'Schip'], full_text: "D66 staat voor een gezonde en duurzame toekomst voor Schiphol en de omgeving. Laten we samenwerken aan een gebalanceerde aanpak, waarin we de economische voordelen van Schip", keyword_clusters: {'D66': 0, 'toekomst': 1, 'Schiphol': 5, 'omgeving': 0, 'aanpak': 7, 'voordelen': 1, 'Schip': 0}},
{sentiment: 0.9978724718093872, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['cda', 'schiphol'], group: 'piet', emojis: [], keywords: ['CDA', 'toekomst', 'Nederland', 'Schiphol', 'oog', 'mens', 'milieu', 'CDA', 'Schiphol'], full_text: "Als CDA geloven we in een duurzame toekomst voor Nederland. Schiphol moet daarom verantwoord blijven groeien, met oog voor mens en milieu. #CDA #Schiphol", keyword_clusters: {'CDA': 4, 'toekomst': 1, 'Nederland': 5, 'Schiphol': 5, 'oog': 5, 'mens': 4, 'milieu': 1}},
{sentiment: 0.9971428513526917, lat: null, lng: null, count: 1, topics: ['cda', 'schiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'motor', 'land', 'koste', 'gezondheid', 'welzijn', 'omwonenden', 'CDA', 'oplossing', 'CDA', 'Schiphol'], full_text: "Schiphol is een belangrijke economische motor voor ons land, maar dit mag niet ten koste gaan van de gezondheid en het welzijn van omwonenden. CDA zet zich in voor een gebalanceerde oplossing. #CDA #Schiphol", keyword_clusters: {'Schiphol': 5, 'motor': 0, 'land': 7, 'koste': 5, 'gezondheid': 1, 'welzijn': 1, 'omwonenden': 1, 'CDA': 4, 'oplossing': 0}},
{sentiment: 0.9979530572891235, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['cda', 'schiphol'], group: 'piet', emojis: ['😜'], keywords: ['Schiphol', 'geluidsoverlast', 'omwonenden', 'CDA', 'maatregelen', 'aantal', 'nachtvluchten', 'CDA', 'Schiphol', '😜'], full_text: "Het is belangrijk dat Schiphol de geluidsoverlast voor omwonenden beperkt. CDA steunt dan ook de maatregelen om het aantal nachtvluchten terug te dringen. #CDA #Schiphol 😜", keyword_clusters: {'Schiphol': 5, 'geluidsoverlast': 1, 'omwonenden': 1, 'CDA': 4, 'maatregelen': 0, 'aantal': 5, 'nachtvluchten': 5}},
{sentiment: 0.9980577826499939, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['cda', 'schiphol'], group: 'piet', emojis: [], keywords: ['CDA', 'Schiphol', 'luchthaven', 'investeringen', 'bussen', 'brandstoffen', 'CDA', 'Schiphol'], full_text: "CDA vindt dat Schiphol zich moet blijven ontwikkelen als duurzame luchthaven, met investeringen in bijvoorbeeld elektrische bussen en duurzame brandstoffen. #CDA #Schiphol", keyword_clusters: {'CDA': 4, 'Schiphol': 5, 'luchthaven': 3, 'investeringen': 5, 'bussen': 5, 'brandstoffen': 0}},
{sentiment: 0.9973963499069214, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['cda', 'schiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'leefbaarheid', 'gemeenten', 'CDA', 'CDA', 'Schiphol'], full_text: "Het is belangrijk dat Schiphol verantwoord blijft groeien, zonder de leefbaarheid van omliggende gemeenten aan te tasten. CDA blijft zich hiervoor inzetten. #CDA #Schiphol", keyword_clusters: {'Schiphol': 5, 'leefbaarheid': 1, 'gemeenten': 0, 'CDA': 4}},
{sentiment: 0.9975560903549194, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['cda', 'schiphol'], group: 'piet', emojis: [], keywords: ['CDA', 'voorstander', 'verdeling', 'vluchten', 'luchthavens', 'druk', 'Schiphol', 'overlast', 'omwonenden', 'CDA', 'Schiphol'], full_text: "CDA is voorstander van een evenwichtige verdeling van vluchten over verschillende luchthavens, om de druk op Schiphol te verminderen en de overlast voor omwonenden te beperken. #CDA #Schiphol", keyword_clusters: {'CDA': 4, 'voorstander': 0, 'verdeling': 5, 'vluchten': 0, 'luchthavens': 5, 'druk': 4, 'Schiphol': 5, 'overlast': 1, 'omwonenden': 1}},
{sentiment: 0.9977618455886841, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['cda', 'schiphol'], group: 'piet', emojis: [], keywords: ['overlast', 'Schiphol', 'omwonenden', 'jaren', 'probleem', 'CDA', 'leefbaarheid', 'geluidsisolatie', 'woningen', 'CDA', 'Schiphol'], full_text: "De overlast van Schiphol voor omwonenden is al jaren een groot probleem. CDA vindt dat er meer gedaan moet worden om de leefbaarheid te verbeteren, bijvoorbeeld door geluidsisolatie van woningen. #CDA #Schiphol", keyword_clusters: {'overlast': 1, 'Schiphol': 5, 'omwonenden': 1, 'jaren': 7, 'probleem': 4, 'CDA': 4, 'leefbaarheid': 1, 'geluidsisolatie': 0, 'woningen': 5}},
{sentiment: 0.998058021068573, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['cda', 'schiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'innovatie', 'duurzaamheid', 'luchtvaartsector', 'CDA', 'ontwikkelingen', 'CDA', 'Schiphol'], full_text: "Het is belangrijk dat Schiphol blijft investeren in innovatie en duurzaamheid, om de luchtvaartsector toekomstbestendig te maken. CDA steunt deze ontwikkelingen. #CDA #Schiphol", keyword_clusters: {'Schiphol': 5, 'innovatie': 1, 'duurzaamheid': 1, 'luchtvaartsector': 0, 'CDA': 4, 'ontwikkelingen': 4}},
{sentiment: 0.9973748922348022, lat: null, lng: null, count: 1, topics: ['cda', 'schiphol'], group: 'piet', emojis: [], keywords: ['CDA', 'leefomgeving', 'omwonenden', 'Schiphol', 'waarde', 'luchthaven', 'oog', 'CDA', 'Schiphol'], full_text: "CDA blijft zich inzetten voor een veilige en gezonde leefomgeving voor omwonenden van Schiphol, zonder de economische waarde van de luchthaven uit het oog te verliezen. #CDA #Schiphol", keyword_clusters: {'CDA': 4, 'leefomgeving': 1, 'omwonenden': 1, 'Schiphol': 5, 'waarde': 5, 'luchthaven': 3, 'oog': 5}},
{sentiment: 0.9979044198989868, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['cda', 'schiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'omwonenden', 'overheden', 'overlast', 'oplossing', 'CDA', 'samenwerking', 'CDA', 'Schiphol'], full_text: "Het is belangrijk dat Schiphol samenwerkt met omwonenden en lokale overheden om de overlast te verminderen en tot een gebalanceerde oplossing te komen. CDA ondersteunt deze samenwerking. #CDA #Schiphol", keyword_clusters: {'Schiphol': 5, 'omwonenden': 1, 'overheden': 4, 'overlast': 1, 'oplossing': 0, 'CDA': 4, 'samenwerking': 1}},
{sentiment: 0.9973498582839966, lat: null, lng: null, count: 1, topics: ['schiphol', 'sp'], group: 'piet', emojis: [], keywords: ['Schiphol', 'koste', 'gezondheid', 'milieu', 'SP', 'vliegverkeer', 'Schiphol', 'SP'], full_text: "Schiphol mag niet ten koste gaan van onze gezondheid en het milieu! SP zet zich in voor duurzaam vliegverkeer. #Schiphol #SP", keyword_clusters: {'Schiphol': 5, 'koste': 5, 'gezondheid': 1, 'milieu': 1, 'SP': 0, 'vliegverkeer': 5}},
{sentiment: 0.9979543685913086, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'sp'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'overlast', 'vervuiling', 'SP', 'Nederland', 'Schiphol', 'SP'], full_text: "De uitbreiding van Schiphol leidt tot meer overlast en vervuiling. SP kiest voor een groen en gezond Nederland. #Schiphol #SP", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'overlast': 1, 'vervuiling': 1, 'SP': 0, 'Nederland': 5}},
{sentiment: 0.9968910813331604, lat: null, lng: null, count: 1, topics: ['schiphol', 'sp'], group: 'piet', emojis: [], keywords: ['Schiphol', 'regels', 'verantwoordelijkheid', 'gevolgen', 'uitbreiding', 'SP', 'eerlijkheid', 'rechtvaardigheid', 'Schiphol', 'SP'], full_text: "Schiphol moet zich aan regels houden en verantwoordelijkheid nemen voor de gevolgen van haar uitbreiding. SP staat voor eerlijkheid en rechtvaardigheid. #Schiphol #SP", keyword_clusters: {'Schiphol': 5, 'regels': 4, 'verantwoordelijkheid': 4, 'gevolgen': 1, 'uitbreiding': 1, 'SP': 0, 'eerlijkheid': 7, 'rechtvaardigheid': 1}},
{sentiment: 0.9967498779296875, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'sp'], group: 'piet', emojis: [], keywords: ['winsten', 'Schiphol', 'koste', 'gezondheid', 'milieu', 'SP', 'verdeling', 'lasten', 'Schiphol', 'SP'], full_text: "Het is onacceptabel dat de winsten van Schiphol ten koste gaan van onze gezondheid en het milieu. SP pleit voor een rechtvaardigere verdeling van de lasten en lusten. #Schiphol #SP", keyword_clusters: {'winsten': 5, 'Schiphol': 5, 'koste': 5, 'gezondheid': 1, 'milieu': 1, 'SP': 0, 'verdeling': 5, 'lasten': 5}},
{sentiment: 0.9976613521575928, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'sp'], group: 'piet', emojis: [], keywords: ['SP', 'Schiphol', 'verantwoordelijkheid', 'gezondheid', 'milieu', 'Schiphol', 'SP'], full_text: "SP vindt dat Schiphol moet worden afgerekend op haar maatschappelijke verantwoordelijkheid. Het gaat immers om onze gezondheid en het milieu. #Schiphol #SP", keyword_clusters: {'SP': 0, 'Schiphol': 5, 'verantwoordelijkheid': 4, 'gezondheid': 1, 'milieu': 1}},
{sentiment: 0.9981417655944824, lat: null, lng: null, count: 1, topics: ['schiphol', 'sp'], group: 'piet', emojis: [], keywords: ['Schiphol', 'afspraken', 'overlast', 'vervuiling', 'SP', 'Nederland', 'buurt', 'Schiphol', 'Schiphol', 'SP'], full_text: "Schiphol moet zich houden aan afspraken en zorgen voor minder overlast en vervuiling. SP gaat voor een leefbaar Nederland, ook in de buurt van Schiphol. #Schiphol #SP", keyword_clusters: {'Schiphol': 5, 'afspraken': 7, 'overlast': 1, 'vervuiling': 1, 'SP': 0, 'Nederland': 5, 'buurt': 5}},
{sentiment: 0.9978960752487183, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'sp'], group: 'piet', emojis: ['😜'], keywords: ['uitbreiding', 'Schiphol', 'milieu', 'economie', 'SP', 'groei', 'werkgelegenheid', 'Schiphol', 'SP', '😜'], full_text: "De uitbreiding van Schiphol is niet alleen schadelijk voor ons milieu, maar ook voor de economie. SP kiest voor duurzame groei en werkgelegenheid. #Schiphol #SP 😜", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'milieu': 1, 'economie': 1, 'SP': 0, 'groei': 4, 'werkgelegenheid': 1}},
{sentiment: 0.9979490637779236, lat: null, lng: null, count: 1, topics: ['schiphol', 'sp'], group: 'piet', emojis: [], keywords: ['SP', 'Schiphol', 'belangen', 'mens', 'natuur', 'Schiphol', 'SP'], full_text: "SP strijdt voor een rechtvaardig en duurzaam Schiphol, waarbij de belangen van mens en natuur voorop staan. #Schiphol #SP", keyword_clusters: {'SP': 0, 'Schiphol': 5, 'belangen': 1, 'mens': 4, 'natuur': 1}},
{sentiment: 0.9977753758430481, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'sp'], group: 'piet', emojis: [], keywords: ['Schiphol', 'wensen', 'samenleving', 'SP', 'Schiphol', 'toekomst', 'Schiphol', 'SP'], full_text: "Schiphol moet zich aanpassen aan de wensen van de samenleving, niet andersom. SP gaat voor een Schiphol dat bijdraagt aan een groene en gezonde toekomst. #Schiphol #SP", keyword_clusters: {'Schiphol': 5, 'wensen': 4, 'samenleving': 7, 'SP': 0, 'toekomst': 1}},
{sentiment: 0.997525155544281, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'sp'], group: 'piet', emojis: [], keywords: ['SP', 'uitbreiding', 'Schiphol', 'koste', 'gezondheid', 'milieu', 'leefomgeving', 'luchtvaart', 'Schiphol', 'SP'], full_text: "SP is tegen de uitbreiding van Schiphol als dat ten koste gaat van onze gezondheid, het milieu en onze leefomgeving. Wij kiezen voor een verantwoorde en duurzame luchtvaart. #Schiphol #SP", keyword_clusters: {'SP': 0, 'uitbreiding': 1, 'Schiphol': 5, 'koste': 5, 'gezondheid': 1, 'milieu': 1, 'leefomgeving': 1, 'luchtvaart': 7}},
{sentiment: 0.9971351623535156, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: ['🤔'], keywords: ['uitbreiding', 'Schiphol', 'voorbeeld', 'politici', 'oren', 'bedrijven', 'FvD', 'groei', '🤔'], full_text: "De uitbreiding van Schiphol is een typisch voorbeeld van politici die hun oren laten hangen naar de grote bedrijven. FvD zegt nee tegen deze ongebreidelde groei! 🤔", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'voorbeeld': 4, 'politici': 0, 'oren': 4, 'bedrijven': 7, 'FvD': 0, 'groei': 4}},
{sentiment: 0.9980250597000122, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: ['🤔'], keywords: ['Schiphol', 'overlast', 'FvD', 'uitbreiding', 'belangen', 'omwonenden'], full_text: "Schiphol heeft al genoeg overlast veroorzaakt. FvD zal er alles aan doen om de uitbreiding tegen te houden en de belangen van omwonenden te beschermen. 🤔", keyword_clusters: {'Schiphol': 5, 'overlast': 1, 'FvD': 0, 'uitbreiding': 1, 'belangen': 1, 'omwonenden': 1}},
{sentiment: 0.9959421753883362, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: ['🤔'], keywords: ['Schiphollen', 'Nederland', 'FvD', 'leefomgeving', 'uitbreiding'], full_text: "Wij willen geen Schiphollen in Nederland! FvD gaat voor een gezonde leefomgeving en zal zich inzetten om de uitbreiding te stoppen. 🤔", keyword_clusters: {'Schiphollen': 7, 'Nederland': 5, 'FvD': 0, 'leefomgeving': 1, 'uitbreiding': 1}},
{sentiment: 0.9972570538520813, lat: 52.1594747, lng: 4.4908843, count: 1, topics: [], group: 'piet', emojis: ['🤔'], keywords: ['FvD', 'grenzen', 'groei', 'Schiphol', 'uitbreiding', 'overlast', 'vervuiling'], full_text: "FvD vindt dat de grenzen van groei van Schiphol zijn bereikt. Verdere uitbreiding is onwenselijk en zal leiden tot nog meer overlast en vervuiling. 🤔", keyword_clusters: {'FvD': 0, 'grenzen': 5, 'groei': 4, 'Schiphol': 5, 'uitbreiding': 1, 'overlast': 1, 'vervuiling': 1}},
{sentiment: 0.9972621202468872, lat: 52.2434979, lng: 5.6343227, count: 1, topics: [], group: 'piet', emojis: ['🤔'], keywords: ['tijd', 'luchtvaartindustrie', 'Nederland', 'FvD', 'alternatieven', 'uitbreiding', 'Schiphol'], full_text: "Het is tijd om de luchtvaartindustrie in Nederland te hervormen. FvD pleit voor duurzame alternatieven en zal zich verzetten tegen de uitbreiding van Schiphol. 🤔", keyword_clusters: {'tijd': 3, 'luchtvaartindustrie': 1, 'Nederland': 5, 'FvD': 0, 'alternatieven': 1, 'uitbreiding': 1, 'Schiphol': 5}},
{sentiment: 0.9978579878807068, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: ['🤔'], keywords: ['groei', 'Schiphol', 'koste', 'leefomgeving', 'FvD', 'ontwikkeling', 'luchtvaartsector'], full_text: "Wij willen geen ongebreidelde groei van Schiphol ten koste van onze leefomgeving. FvD staat voor een evenwichtige en duurzame ontwikkeling van de luchtvaartsector. 🤔", keyword_clusters: {'groei': 4, 'Schiphol': 5, 'koste': 5, 'leefomgeving': 1, 'FvD': 0, 'ontwikkeling': 0, 'luchtvaartsector': 0}},
{sentiment: 0.9971438050270081, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'oplossing', 'luchtvaartproblematiek', 'FvD', 'innovatie', 'duurzaamheid', 'groei'], full_text: "De uitbreiding van Schiphol is geen oplossing voor de luchtvaartproblematiek. FvD wil inzetten op innovatie en duurzaamheid, niet op groei.", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'oplossing': 0, 'luchtvaartproblematiek': 7, 'FvD': 0, 'innovatie': 1, 'duurzaamheid': 1, 'groei': 4}},
{sentiment: 0.998022198677063, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: ['😜'], keywords: ['FvD', 'uitbreiding', 'Schiphol', 'leefomgeving', 'toekomst', '😜'], full_text: "FvD zegt nee tegen de uitbreiding van Schiphol en ja tegen een gezonde leefomgeving voor ons allemaal. Laten we samenwerken aan een duurzame toekomst. 😜", keyword_clusters: {'FvD': 0, 'uitbreiding': 1, 'Schiphol': 5, 'leefomgeving': 1, 'toekomst': 1}},
{sentiment: 0.9976837635040283, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: ['🤔'], keywords: ['Schiphol', 'koste', 'gezondheid', 'leefomgeving', 'FvD', 'ontwikkeling', 'luchtvaartindustrie'], full_text: "Schiphol moet niet ten koste gaan van onze gezondheid en leefomgeving. FvD zal blijven strijden voor een evenwichtige ontwikkeling van de luchtvaartindustrie. 🤔", keyword_clusters: {'Schiphol': 5, 'koste': 5, 'gezondheid': 1, 'leefomgeving': 1, 'FvD': 0, 'ontwikkeling': 0, 'luchtvaartindustrie': 1}},
{sentiment: 0.9978418350219727, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: ['🤔', '🤔', '🤔'], keywords: ['FvD', 'uitbreiding', 'Schiphol', 'zorgen', 'effecten', 'klimaat', 'gezondheid', 'omwonenden', 'aanpak'], full_text: "FvD is tegen de uitbreiding van Schiphol omdat we ons zorgen maken over de effecten op het klimaat en de gezondheid van omwonenden. Wij pleiten voor een verstandige en duurzame aanpak. 🤔🤔🤔", keyword_clusters: {'FvD': 0, 'uitbreiding': 1, 'Schiphol': 5, 'zorgen': 1, 'effecten': 4, 'klimaat': 1, 'gezondheid': 1, 'omwonenden': 1, 'aanpak': 7}},
{sentiment: 0.9952853322029114, lat: 52.2434979, lng: 5.6343227, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['grootheidswaanzin', 'Schiphol', 'Nederland', 'PVV', 'Schiphol'], full_text: "Stop de grootheidswaanzin van Schiphol! Nederland is vol genoeg! #PVV #Schiphol", keyword_clusters: {'grootheidswaanzin': 0, 'Schiphol': 5, 'Nederland': 5, 'PVV': 5}},
{sentiment: 0.9965358972549438, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['pvv'], group: 'piet', emojis: [], keywords: ['overlast', 'Schiphol', 'PVV', 'belangen', 'omwonenden', 'milieu'], full_text: "De overlast van Schiphol moet stoppen! De #PVV staat voor de belangen van omwonenden en het milieu!", keyword_clusters: {'overlast': 1, 'Schiphol': 5, 'PVV': 5, 'belangen': 1, 'omwonenden': 1, 'milieu': 1}},
{sentiment: 0.9979994893074036, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'tijd', 'alternatieven', 'PVV', 'Schiphol'], full_text: "Waarom moet Schiphol zo nodig groeien? Het is de hoogste tijd om te investeren in alternatieven! #PVV #Schiphol", keyword_clusters: {'Schiphol': 5, 'tijd': 3, 'alternatieven': 1, 'PVV': 5}},
{sentiment: 0.9974071383476257, lat: null, lng: null, count: 1, topics: ['pvv'], group: 'piet', emojis: [], keywords: ['PVV', 'uitbreiding', 'Schiphol'], full_text: "De #PVV zegt nee tegen verdere uitbreiding van Schiphol! Genoeg is genoeg!", keyword_clusters: {'PVV': 5, 'uitbreiding': 1, 'Schiphol': 5}},
{sentiment: 0.9975098371505737, lat: null, lng: null, count: 1, topics: ['pvv'], group: 'piet', emojis: [], keywords: ['Schiphol', 'koste', 'leefbaarheid', 'omgeving', 'klimaat', 'PVV', 'toekomst'], full_text: "Schiphol gaat ten koste van de leefbaarheid in de omgeving en het klimaat! De #PVV kiest voor een duurzame toekomst!", keyword_clusters: {'Schiphol': 5, 'koste': 5, 'leefbaarheid': 1, 'omgeving': 0, 'klimaat': 1, 'PVV': 5, 'toekomst': 1}},
{sentiment: 0.9915122389793396, lat: null, lng: null, count: 1, topics: ['schiphol', 'pvv'], group: 'piet', emojis: [], keywords: ['Schiphol', 'lak', 'omwonenden', 'milieu', 'PVV', 'lobby', 'luchtvaartindustrie'], full_text: "#Schiphol heeft lak aan omwonenden en het milieu! De #PVV laat zich niet intimideren door de lobby van de luchtvaartindustrie!", keyword_clusters: {'Schiphol': 5, 'lak': 0, 'omwonenden': 1, 'milieu': 1, 'PVV': 5, 'lobby': 0, 'luchtvaartindustrie': 1}},
{sentiment: 0.995776891708374, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['pvv'], group: 'piet', emojis: [], keywords: ['PVV', 'macht', 'Schiphol', 'toekomst', 'land', 'inwoners'], full_text: "De #PVV wil de macht van Schiphol breken en kiezen voor een betere toekomst voor ons land en haar inwoners!", keyword_clusters: {'PVV': 5, 'macht': 5, 'Schiphol': 5, 'toekomst': 1, 'land': 7, 'inwoners': 5}},
{sentiment: 0.9949967861175537, lat: null, lng: null, count: 1, topics: ['pvv'], group: 'piet', emojis: [], keywords: ['Schiphol', 'bedreiging', 'gezondheid', 'welzijn', 'PVV', 'belangen', 'burgers'], full_text: "Schiphol is een bedreiging voor onze gezondheid en ons welzijn! De #PVV staat voor de belangen van de burgers!", keyword_clusters: {'Schiphol': 5, 'bedreiging': 7, 'gezondheid': 1, 'welzijn': 1, 'PVV': 5, 'belangen': 1, 'burgers': 1}},
{sentiment: 0.9972483515739441, lat: null, lng: null, count: 1, topics: ['pvv'], group: 'piet', emojis: [], keywords: ['tijd', 'regulering', 'Schiphol', 'PVV', 'rechten', 'omwonenden'], full_text: "Het is tijd voor een strengere regulering van Schiphol! De #PVV komt op voor de rechten van omwonenden!", keyword_clusters: {'tijd': 3, 'regulering': 7, 'Schiphol': 5, 'PVV': 5, 'rechten': 1, 'omwonenden': 1}},
{sentiment: 0.9973537921905518, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['pvv'], group: 'piet', emojis: [], keywords: ['PVV', 'overlast', 'Schiphol', 'alternatieven', 'spreiding', 'vluchten'], full_text: "De #PVV wil de overlast van Schiphol verminderen door te investeren in duurzame alternatieven en betere spreiding van vluchten!", keyword_clusters: {'PVV': 5, 'overlast': 1, 'Schiphol': 5, 'alternatieven': 1, 'spreiding': 4, 'vluchten': 0}},
{sentiment: 0.08231359720230103, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['Nederland', 'waanzin', 'Schiphol', 'uitbreiding', 'plaats', 'luchthavens', 'PVV', 'Schiphol'], full_text: "Nederland moet stoppen met de waanzin van Schiphol uitbreiding en in plaats daarvan investeren in onze eigen luchthavens! #PVV #Schiphol", keyword_clusters: {'Nederland': 5, 'waanzin': 4, 'Schiphol': 5, 'uitbreiding': 1, 'plaats': 0, 'luchthavens': 5, 'PVV': 5}},
{sentiment: 0.9958004355430603, lat: null, lng: null, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'lawaaieriger', 'Tijd', 'belang', 'burgers', 'uitbreiding', 'PVV', 'Schiphol'], full_text: "Schiphol wordt steeds groter en lawaaieriger. Tijd om het belang van burgers voorop te stellen en de uitbreiding te stoppen! #PVV #Schiphol", keyword_clusters: {'Schiphol': 5, 'lawaaieriger': 4, 'Tijd': 4, 'belang': 7, 'burgers': 1, 'uitbreiding': 1, 'PVV': 5}},
{sentiment: 0.9959254264831543, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['lawaai', 'vervuiling', 'handvol', 'banen', 'uitbreiding', 'Schiphol', 'PVV', 'Schiphol'], full_text: "Waarom moeten we nog meer lawaai en vervuiling tolereren voor een handvol banen? Stop de uitbreiding van Schiphol nu! #PVV #Schiphol", keyword_clusters: {'lawaai': 5, 'vervuiling': 1, 'handvol': 0, 'banen': 0, 'uitbreiding': 1, 'Schiphol': 5, 'PVV': 5}},
{sentiment: 0.09149640798568726, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'aanval', 'gezondheid', 'welzijn', 'omwonenden', 'PVV', 'Schiphol'], full_text: "De uitbreiding van Schiphol is een directe aanval op de gezondheid en het welzijn van de omwonenden. Dit moet stoppen! #PVV #Schiphol", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'aanval': 7, 'gezondheid': 1, 'welzijn': 1, 'omwonenden': 1, 'PVV': 5}},
{sentiment: 0.9968543648719788, lat: null, lng: null, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['lobby', 'luchtvaartmaatschappijen', 'belangen', 'burgers', 'PVV', 'Schiphol'], full_text: "We moeten stoppen met het blindelings volgen van de lobby van de luchtvaartmaatschappijen en opkomen voor de belangen van onze burgers! #PVV #Schiphol", keyword_clusters: {'lobby': 0, 'luchtvaartmaatschappijen': 5, 'belangen': 1, 'burgers': 1, 'PVV': 5, 'Schiphol': 5}},
{sentiment: 0.9970958232879639, lat: null, lng: null, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'vrijbrief', 'lucht', 'water', 'grond', 'Tijd', 'uitbreiding', 'PVV', 'Schiphol'], full_text: "Schiphol mag niet langer een vrijbrief krijgen om onze lucht, water en grond te vervuilen. Tijd om de uitbreiding te stoppen! #PVV #Schiphol", keyword_clusters: {'Schiphol': 5, 'vrijbrief': 0, 'lucht': 0, 'water': 7, 'grond': 4, 'Tijd': 4, 'uitbreiding': 1, 'PVV': 5}},
{sentiment: 0.9943475127220154, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'voorbeeld', 'arrogantie', 'politiek', 'stem', 'burgers', 'uitbreiding', 'PVV', 'Schiphol'], full_text: "De uitbreiding van Schiphol is een typisch voorbeeld van de arrogantie van de politiek. We moeten luisteren naar de stem van de burgers en de uitbreiding stoppen! #PVV #Schiphol", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'voorbeeld': 4, 'arrogantie': 0, 'politiek': 1, 'stem': 1, 'burgers': 1, 'PVV': 5}},
{sentiment: 0.9970591068267822, lat: null, lng: null, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['tijd', 'grenzen', 'uitbreiding', 'Schiphol', 'land', 'overlast', 'vervuiling', 'PVV', 'Schiphol'], full_text: "Het is tijd om de grenzen te sluiten voor de uitbreiding van Schiphol. We moeten ons land beschermen tegen de overlast en vervuiling! #PVV #Schiphol", keyword_clusters: {'tijd': 3, 'grenzen': 5, 'uitbreiding': 1, 'Schiphol': 5, 'land': 7, 'overlast': 1, 'vervuiling': 1, 'PVV': 5}},
{sentiment: 0.017795145511627197, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'druk', 'infrastructuur', 'Tijd', 'halt', 'waanzin', 'PVV', 'Schiphol'], full_text: "De uitbreiding van Schiphol zal alleen maar meer druk leggen op onze toch al overvolle infrastructuur. Tijd om een halt toe te roepen aan deze waanzin! #PVV #Schiphol", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'druk': 4, 'infrastructuur': 5, 'Tijd': 4, 'halt': 7, 'waanzin': 4, 'PVV': 5}},
{sentiment: 0.9968907237052917, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['Nederland', 'behoefte', 'luchthavenbeleid', 'rekening', 'leefbaarheid', 'steden', 'dorpen', 'uitbreiding', 'Schiphol', 'PVV', 'Schiphol'], full_text: "Nederland heeft behoefte aan een luchthavenbeleid dat rekening houdt met de leefbaarheid van onze steden en dorpen. Stop de uitbreiding van Schiphol nu! #PVV #Schiphol", keyword_clusters: {'Nederland': 5, 'behoefte': 7, 'luchthavenbeleid': 5, 'rekening': 5, 'leefbaarheid': 1, 'steden': 7, 'dorpen': 5, 'uitbreiding': 1, 'Schiphol': 5, 'PVV': 5}},
{sentiment: 0.9981873631477356, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['been', 'more', 'Stay', 'developments'], full_text: "Schiphol's expansion has been a hot topic for years now, but it seems the debate is more intense than ever before. Stay tuned for updates on the latest developments.", keyword_clusters: {'been': 5, 'more': 7, 'Stay': 5, 'developments': 7}},
{sentiment: 0.9983869791030884, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['As', 'concerns', 'expanding', 'Schiphol', 'Airport', 'listen'], full_text: "As concerns over climate change grow, many are questioning the wisdom of expanding Schiphol Airport. Will the government listen?", keyword_clusters: {'As': 4, 'concerns': 5, 'expanding': 0, 'Schiphol': 5, 'Airport': 4, 'listen': 7}},
{sentiment: 0.9983534812927246, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['The', 'Schiphol', 'sustainability'], full_text: "The Schiphol expansion debate is not just about noise pollution and air quality. It's also a question of economic growth vs. sustainability.", keyword_clusters: {'The': 4, 'Schiphol': 5, 'sustainability': 7}},
{sentiment: 0.9971712231636047, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Experts'], full_text: "Experts say that the government's plans for Schiphol's expansion are not in line with the Netherlands' climate targets. Will the politicians take note?", keyword_clusters: {}},
{sentiment: 0.997805655002594, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol', 'rages', 'plan'], full_text: "As the Schiphol expansion debate rages on, residents and environmental groups are speaking out against what they see as an unsustainable and dangerous plan.", keyword_clusters: {'Schiphol': 5, 'rages': 7, 'plan': 0}},
{sentiment: 0.9981367588043213, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['the', 'deadline', 'tensions', 'listen', 'concerns', 'the', 'people'], full_text: "With the deadline for public comment on the Schiphol expansion plans fast approaching, tensions are rising. Will the government listen to the concerns of the people?", keyword_clusters: {'the': 5, 'deadline': 0, 'tensions': 7, 'listen': 7, 'concerns': 5, 'people': 4}},
{sentiment: 0.9981105327606201, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol', 'heats'], full_text: "As the Schiphol expansion debate heats up, it's clear that this is a critical moment for the future of aviation in the Netherlands.", keyword_clusters: {'Schiphol': 5, 'heats': 7}},
{sentiment: 0.9980637431144714, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: [], full_text: "Many are questioning whether the economic benefits of Schiphol's expansion outweigh the potential environmental and health risks.", keyword_clusters: {}},
{sentiment: 0.998157799243927, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['The', 'Schiphol', 'sustainability'], full_text: "The Schiphol expansion debate highlights the delicate balance between economic growth and sustainability in the Netherlands.", keyword_clusters: {'The': 4, 'Schiphol': 5, 'sustainability': 7}},
{sentiment: 0.9982448816299438, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['deadline', 'interests'], full_text: "As the deadline for public comment on the Schiphol expansion approaches, it's clear that the government will have to navigate a complex web of economic and environmental interests.", keyword_clusters: {'deadline': 0, 'interests': 5}},
{sentiment: 0.9983538389205933, lat: null, lng: null, count: 1, topics: ['schiphol', 'milieu', 'vliegtuigspotter'], group: 'piet', emojis: [], keywords: ['vliegtuigspotter', 'gevolgen', 'Schiphol-uitbreiding', 'milieu', 'omgeving', 'Schiphol', 'milieu', 'vliegtuigspotter'], full_text: "Als vliegtuigspotter maak ik me zorgen over de gevolgen van de Schiphol-uitbreiding voor het milieu en de omgeving. #Schiphol #milieu #vliegtuigspotter", keyword_clusters: {'vliegtuigspotter': 1, 'gevolgen': 1, 'Schiphol-uitbreiding': 0, 'milieu': 1, 'omgeving': 0, 'Schiphol': 5}},
{sentiment: 0.997066080570221, lat: 52.1594747, lng: 4.4908843, count: 1, topics: ['schiphol', 'geluidsoverlast', 'vliegtuigspotter'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'vluchten', 'geluidsoverlast', 'nieuws', 'vliegtuigspotters', 'Schiphol', 'geluidsoverlast', 'vliegtuigspotter'], full_text: "De uitbreiding van Schiphol zal leiden tot meer vluchten en meer geluidsoverlast. Geen goed nieuws voor vliegtuigspotters. #Schiphol #geluidsoverlast #vliegtuigspotter", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'vluchten': 0, 'geluidsoverlast': 1, 'nieuws': 5, 'vliegtuigspotters': 4, 'vliegtuigspotter': 1}},
{sentiment: 0.32450586557388306, lat: null, lng: null, count: 1, topics: ['schiphol', 'natuur', 'vliegtuigspotter'], group: 'piet', emojis: [], keywords: ['Schiphol-uitbreiding', 'koste', 'natuur', 'leefomgeving', 'vliegtuigspotter', 'Schiphol', 'natuur', 'vliegtuigspotter'], full_text: "Het is triest om te zien hoe de Schiphol-uitbreiding ten koste gaat van de natuur en de leefomgeving. Als vliegtuigspotter kan ik dit niet accepteren. #Schiphol #natuur #vliegtuigspotter", keyword_clusters: {'Schiphol-uitbreiding': 0, 'koste': 5, 'natuur': 1, 'leefomgeving': 1, 'vliegtuigspotter': 1, 'Schiphol': 5}},
{sentiment: 0.9976640939712524, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'milieu', 'vliegtuigspotter'], group: 'piet', emojis: [], keywords: ['balans', 'groei', 'impact', 'milieu', 'omgeving', 'uitbreiding', 'Schiphol', 'balans', 'Schiphol', 'milieu', 'vliegtuigspotter'], full_text: "Het is belangrijk om een balans te vinden tussen economische groei en de impact op het milieu en de omgeving. Helaas lijkt de uitbreiding van Schiphol deze balans te verstoren. #Schiphol #milieu #vliegtuigspotter", keyword_clusters: {'balans': 0, 'groei': 4, 'impact': 5, 'milieu': 1, 'omgeving': 0, 'uitbreiding': 1, 'Schiphol': 5, 'vliegtuigspotter': 1}},
{sentiment: 0.9972368478775024, lat: 52.1594747, lng: 4.4908843, count: 1, topics: ['schiphol', 'geluidsoverlast', 'vliegtuigspotter'], group: 'piet', emojis: ['😂'], keywords: ['Schiphol-uitbreiding', 'vliegtuigen', 'hoofd', 'rust', 'Schiphol', 'geluidsoverlast', 'vliegtuigspotter'], full_text: "Ik ben bang dat de Schiphol-uitbreiding zal leiden tot meer vliegtuigen die over mijn hoofd vliegen en de rust verstoren. #Schiphol #geluidsoverlast #vliegtuigspotter 😂", keyword_clusters: {'Schiphol-uitbreiding': 0, 'vliegtuigen': 0, 'hoofd': 7, 'rust': 5, 'Schiphol': 5, 'geluidsoverlast': 1, 'vliegtuigspotter': 1}},
{sentiment: 0.9981527924537659, lat: null, lng: null, count: 1, topics: ['schiphol', 'milieu', 'vliegtuigspotter'], group: 'piet', emojis: ['😂'], keywords: ['vliegtuigspotter', 'schoonheid', 'vliegtuigen', 'luchtvaart', 'gevolgen', 'industrie', 'milieu', 'Schiphol', 'milieu', 'vliegtuigspotter'], full_text: "Als vliegtuigspotter geniet ik van de schoonheid van vliegtuigen en de luchtvaart, maar ik ben me ook bewust van de gevolgen van deze industrie voor het milieu. #Schiphol #milieu #vliegtuigspotter 😂", keyword_clusters: {'vliegtuigspotter': 1, 'schoonheid': 7, 'vliegtuigen': 0, 'luchtvaart': 7, 'gevolgen': 1, 'industrie': 7, 'milieu': 1, 'Schiphol': 5}},
{sentiment: 0.997992753982544, lat: 52.1594747, lng: 4.4908843, count: 1, topics: ['schiphol', 'milieu', 'vliegtuigspotter'], group: 'piet', emojis: ['😂'], keywords: ['uitbreiding', 'Schiphol', 'luchtvervuiling', 'klimaatverandering', 'vliegtuigspotter', 'toekomst', 'planeet', 'Schiphol', 'milieu', 'vliegtuigspotter'], full_text: "De uitbreiding van Schiphol zal leiden tot meer luchtvervuiling en klimaatverandering. Als vliegtuigspotter ben ik bezorgd over de toekomst van onze planeet. #Schiphol #milieu #vliegtuigspotter 😂", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'luchtvervuiling': 1, 'klimaatverandering': 1, 'vliegtuigspotter': 1, 'toekomst': 1, 'planeet': 1, 'milieu': 1}},
{sentiment: 0.9981881976127625, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'milieu', 'vliegtuigspotter'], group: 'piet', emojis: ['😂'], keywords: ['uitbreiding', 'Schiphol', 'gevolgen', 'omgeving', 'rest', 'Nederland', 'vliegtuigspotter', 'boodschap', 'Schiphol', 'milieu', 'vliegtuigspotter'], full_text: "Het is belangrijk om te beseffen dat de uitbreiding van Schiphol niet alleen gevolgen heeft voor de directe omgeving, maar ook voor de rest van Nederland. Als vliegtuigspotter voel ik me verantwoordelijk om deze boodschap te delen. #Schiphol #milieu #vliegtuigspotter 😂", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'gevolgen': 1, 'omgeving': 0, 'rest': 0, 'Nederland': 5, 'vliegtuigspotter': 1, 'boodschap': 5, 'milieu': 1}},
{sentiment: 0.9976537823677063, lat: 52.1594747, lng: 4.4908843, count: 1, topics: ['schiphol', 'vertraging', 'vliegtuigspotter'], group: 'piet', emojis: ['😂'], keywords: ['uitbreiding', 'Schiphol', 'drukte', 'luchthaven', 'vertragingen', 'passagiers', 'vliegtuigspotter', 'probleem', 'Schiphol', 'vertraging', 'vliegtuigspotter'], full_text: "De uitbreiding van Schiphol zal leiden tot meer drukte op de luchthaven en meer vertragingen voor passagiers. Als vliegtuigspotter zie ik dit als een probleem dat moet worden aangepakt. #Schiphol #vertraging #vliegtuigspotter 😂", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'drukte': 7, 'luchthaven': 3, 'vertragingen': 5, 'passagiers': 4, 'vliegtuigspotter': 1, 'probleem': 4, 'vertraging': 1}},
{sentiment: 0.9977107048034668, lat: null, lng: null, count: 1, topics: ['schiphol', 'milieu', 'vliegtuigspotter'], group: 'piet', emojis: ['😂'], keywords: ['rekening', 'gevolgen', 'Schiphol-uitbreiding', 'milieu', 'omgeving', 'luchtvaartindustrie', 'geheel', 'vliegtuigspotter', 'oplossing', 'Schiphol', 'milieu', 'vliegtuigspotter'], full_text: "Ik hoop dat er rekening wordt gehouden met de gevolgen van de Schiphol-uitbreiding voor het milieu, de omgeving en de luchtvaartindustrie als geheel. Als vliegtuigspotter ben ik bereid om bij te dragen aan een oplossing. #Schiphol #milieu #vliegtuigspotter 😂", keyword_clusters: {'rekening': 5, 'gevolgen': 1, 'Schiphol-uitbreiding': 0, 'milieu': 1, 'omgeving': 0, 'luchtvaartindustrie': 1, 'geheel': 5, 'vliegtuigspotter': 1, 'oplossing': 0, 'Schiphol': 5}},
{sentiment: 0.9980869293212891, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding'], group: 'piet', emojis: ['😂'], keywords: ['vliegtuigspotter', 'voorstander', 'vliegbewegingen', 'Schiphol', 'koste', 'Schiphol', 'uitbreiding'], full_text: "Als vliegtuigspotter ben ik natuurlijk voorstander van meer vliegbewegingen op Schiphol, maar niet ten koste van alles! #Schiphol #uitbreiding 😂", keyword_clusters: {'vliegtuigspotter': 1, 'voorstander': 0, 'vliegbewegingen': 1, 'Schiphol': 5, 'koste': 5, 'uitbreiding': 1}},
{sentiment: 0.9980123043060303, lat: null, lng: null, count: 1, topics: ['vliegtuigspotter', 'economie'], group: 'piet', emojis: ['😂'], keywords: ['zorgen', 'uitbreiding', 'Schiphol', 'luchtvaart', 'sector', 'Nederland', 'vliegtuigspotter', 'economie'], full_text: "Ik begrijp de zorgen over de uitbreiding van Schiphol, maar laten we niet vergeten dat de luchtvaart een belangrijke economische sector is voor Nederland. #vliegtuigspotter #economie 😂", keyword_clusters: {'zorgen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'luchtvaart': 7, 'sector': 0, 'Nederland': 5, 'vliegtuigspotter': 1, 'economie': 1}},
{sentiment: 0.9978231191635132, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'werkgelegenheid'], group: 'piet', emojis: ['😂', '😂', '😂'], keywords: ['mensen', 'overlast', 'vliegtuigen', 'Schiphol', 'werkgelegenheid', 'Schiphol', 'werkgelegenheid'], full_text: "Ik snap dat mensen overlast ervaren van de vliegtuigen, maar laten we niet vergeten dat Schiphol ook voor veel werkgelegenheid zorgt. #Schiphol #werkgelegenheid 😂😂😂", keyword_clusters: {'mensen': 0, 'overlast': 1, 'vliegtuigen': 0, 'Schiphol': 5, 'werkgelegenheid': 1}},
{sentiment: 0.9966711401939392, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['vliegtuigspotter', 'schiphol'], group: 'piet', emojis: ['😂'], keywords: ['oplossing', 'Schiphol', 'overlast', 'omwonenden', 'vliegtuigspotter', 'Schiphol'], full_text: "Ik hoop dat er een oplossing wordt gevonden waarbij Schiphol kan uitbreiden én de overlast voor omwonenden wordt verminderd. #vliegtuigspotter #Schiphol 😂", keyword_clusters: {'oplossing': 0, 'Schiphol': 5, 'overlast': 1, 'omwonenden': 1, 'vliegtuigspotter': 1}},
{sentiment: 0.9979368448257446, lat: null, lng: null, count: 1, topics: ['duurzaamheid', 'schiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'hub', 'luchtvaartmaatschappijen', 'vliegtuigspotters', 'positie', 'manier', 'duurzaamheid', 'Schiphol'], full_text: "Schiphol is een belangrijke hub voor veel luchtvaartmaatschappijen en vliegtuigspotters. Laten we ervoor zorgen dat deze positie behouden blijft, maar wel op een duurzame manier. #duurzaamheid #Schiphol", keyword_clusters: {'Schiphol': 5, 'hub': 4, 'luchtvaartmaatschappijen': 5, 'vliegtuigspotters': 4, 'positie': 7, 'manier': 5, 'duurzaamheid': 1}},
{sentiment: 0.9975134134292603, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['vliegtuigspotter', 'duurzaamheid'], group: 'piet', emojis: [], keywords: ['vliegtuigspotter', 'vliegtuigtype', 'Schiphol', 'toekomst', 'planeet', 'vliegtuigspotter', 'duurzaamheid'], full_text: "Als vliegtuigspotter ben ik altijd blij als er weer een nieuw vliegtuigtype op Schiphol landt of opstijgt. Maar laten we niet vergeten dat we ook moeten denken aan de toekomst van onze planeet. #vliegtuigspotter #duurzaamheid", keyword_clusters: {'vliegtuigspotter': 1, 'vliegtuigtype': 5, 'Schiphol': 5, 'toekomst': 1, 'planeet': 1, 'duurzaamheid': 1}},
{sentiment: 0.9981305003166199, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vliegtuigspotter'], group: 'piet', emojis: [], keywords: ['balans', 'belangen', 'Schiphol', 'overlast', 'omwonenden', 'vliegtuigspotter', 'steun', 'zoektocht', 'oplossing', 'Schiphol', 'vliegtuigspotter'], full_text: "Het is belangrijk dat er een goede balans wordt gevonden tussen de economische belangen van Schiphol en de overlast voor omwonenden. Als vliegtuigspotter steun ik deze zoektocht naar een oplossing. #Schiphol #vliegtuigspotter", keyword_clusters: {'balans': 0, 'belangen': 1, 'Schiphol': 5, 'overlast': 1, 'omwonenden': 1, 'vliegtuigspotter': 1, 'steun': 7, 'zoektocht': 7, 'oplossing': 0}},
{sentiment: 0.9978095889091492, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'bereikbaarheid'], group: 'piet', emojis: [], keywords: ['Schiphol', 'rol', 'luchtverkeer', 'bereikbaarheid', 'Nederland', 'manieren', 'overlast', 'Schiphol', 'bereikbaarheid'], full_text: "Laten we niet vergeten dat Schiphol een belangrijke rol speelt in het internationale luchtverkeer en daarmee ook voor de bereikbaarheid van Nederland. Maar laten we ook zoeken naar manieren om de overlast te verminderen. #Schiphol #bereikbaarheid", keyword_clusters: {'Schiphol': 5, 'rol': 5, 'luchtverkeer': 0, 'bereikbaarheid': 7, 'Nederland': 5, 'manieren': 7, 'overlast': 1}},
{sentiment: 0.9981279969215393, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['vliegtuigspotter', 'duurzaamheid'], group: 'piet', emojis: [], keywords: ['vliegtuigspotter', 'balans', 'voordelen', 'Schiphol', 'impact', 'milieu', 'toekomst', 'luchtvaart', 'vliegtuigspotter', 'duurzaamheid'], full_text: "Als vliegtuigspotter vind ik het belangrijk dat er een goede balans is tussen de economische voordelen van Schiphol en de impact op het milieu. Laten we samenwerken aan een duurzame toekomst voor de luchtvaart. #vliegtuigspotter #duurzaamheid", keyword_clusters: {'vliegtuigspotter': 1, 'balans': 0, 'voordelen': 1, 'Schiphol': 5, 'impact': 5, 'milieu': 1, 'toekomst': 1, 'luchtvaart': 7, 'duurzaamheid': 1}},
{sentiment: 0.9981818199157715, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vliegtuigspotter'], group: 'piet', emojis: [], keywords: ['mensen', 'overlast', 'vliegtuigen', 'Schiphol', 'vliegtuigspotter', 'positie', 'Schiphol', 'luchthavens', 'wereld', 'oplossing', 'toekomst', 'Schiphol', 'vliegtuigspotter'], full_text: "Ik begrijp dat mensen overlast ervaren van de vliegtuigen op Schiphol, maar als vliegtuigspotter ben ik ook trots op de positie van Schiphol als één van de belangrijkste luchthavens ter wereld. Laten we samenwerken aan een oplossing voor de toekomst. #Schiphol #vliegtuigspotter", keyword_clusters: {'mensen': 0, 'overlast': 1, 'vliegtuigen': 0, 'Schiphol': 5, 'vliegtuigspotter': 1, 'positie': 7, 'luchthavens': 5, 'wereld': 7, 'oplossing': 0, 'toekomst': 1}},
{sentiment: 0.9966506361961365, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'achtertuin', 'Schiphol', 'uitbreiding'], full_text: "Geen uitbreiding van Schiphol in mijn achtertuin! #Schiphol #uitbreiding", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'achtertuin': 7}},
{sentiment: 0.9971696734428406, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'overlast'], group: 'piet', emojis: [], keywords: ['omwonenden', 'overlast', 'Schiphol', 'uitbreiding', 'Schiphol', 'overlast'], full_text: "Waarom moeten wij als omwonenden de overlast van Schiphol accepteren? Geen uitbreiding! #Schiphol #overlast", keyword_clusters: {'omwonenden': 1, 'overlast': 1, 'Schiphol': 5, 'uitbreiding': 1}},
{sentiment: 0.9931930899620056, lat: null, lng: null, count: 1, topics: ['schiphol', 'woonplezier'], group: 'piet', emojis: [], keywords: ['groei', 'Schiphol', 'woongenot', 'spel', 'Schiphol', 'woonplezier'], full_text: "Stop de groei van Schiphol, ons woongenot staat op het spel #Schiphol #woonplezier", keyword_clusters: {'groei': 4, 'Schiphol': 5, 'woongenot': 4, 'spel': 4, 'woonplezier': 5}},
{sentiment: 0.9978544116020203, lat: null, lng: null, count: 1, topics: ['schiphol', 'gezondheid'], group: 'piet', emojis: [], keywords: ['Schiphol', 'koste', 'gezondheid', 'leefomgeving', 'Schiphol', 'gezondheid'], full_text: "Schiphol mag niet ten koste gaan van onze gezondheid en leefomgeving #Schiphol #gezondheid", keyword_clusters: {'Schiphol': 5, 'koste': 5, 'gezondheid': 1, 'leefomgeving': 1}},
{sentiment: 0.9982860684394836, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding'], group: 'piet', emojis: [], keywords: ['Schiphol', 'uitbreiding', 'luchthaven', 'Schiphol', 'uitbreiding'], full_text: "Ik ben niet tegen Schiphol, maar wel tegen de uitbreiding van de luchthaven #Schiphol #uitbreiding", keyword_clusters: {'Schiphol': 5, 'uitbreiding': 1, 'luchthaven': 3}},
{sentiment: 0.9963951706886292, lat: null, lng: null, count: 1, topics: ['schiphol', 'vervuiling'], group: 'piet', emojis: [], keywords: ['herrie', 'vervuiling', 'Schiphol', 'uitbreiding', 'Schiphol', 'vervuiling'], full_text: "Het is genoeg geweest met de herrie en vervuiling van Schiphol, geen uitbreiding! #Schiphol #vervuiling", keyword_clusters: {'herrie': 4, 'vervuiling': 1, 'Schiphol': 5, 'uitbreiding': 1}},
{sentiment: 0.9674670696258545, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'drukte'], group: 'piet', emojis: [], keywords: ['drukte', 'overlast', 'Schiphol', 'uitbreiding', 'optie', 'Schiphol'], full_text: "De drukte en overlast van Schiphol zijn nu al niet te doen, uitbreiding is geen optie! #Schiphol #drukte", keyword_clusters: {'drukte': 7, 'overlast': 1, 'Schiphol': 5, 'uitbreiding': 1, 'optie': 4}},
{sentiment: 0.9978522062301636, lat: null, lng: null, count: 1, topics: ['schiphol', 'afspraak'], group: 'piet', emojis: [], keywords: ['Schiphol', 'afspraken', 'sprake', 'uitbreiding', 'Schiphol', 'afspraak'], full_text: "Schiphol moet zich eerst aan de gemaakte afspraken houden, voordat er sprake kan zijn van uitbreiding #Schiphol #afspraak", keyword_clusters: {'Schiphol': 5, 'afspraken': 7, 'sprake': 4, 'uitbreiding': 1, 'afspraak': 4}},
{sentiment: 0.9979478716850281, lat: null, lng: null, count: 1, topics: ['schiphol', 'woonrust'], group: 'piet', emojis: [], keywords: ['rust', 'ruimte', 'luchthaven', 'buurt', 'Schiphol', 'woonrust'], full_text: "Wij willen rust en ruimte om te wonen, geen groeiende luchthaven in onze buurt #Schiphol #woonrust", keyword_clusters: {'rust': 5, 'ruimte': 5, 'luchthaven': 3, 'buurt': 5, 'Schiphol': 5, 'woonrust': 5}},
{sentiment: 0.9981032609939575, lat: null, lng: null, count: 1, topics: ['schiphol', 'economie'], group: 'piet', emojis: [], keywords: ['voordelen', 'Schiphol', 'schade', 'leefomgeving', 'Schiphol', 'economie'], full_text: "Wij zijn niet tegen de economische voordelen van Schiphol, maar wel tegen de schade aan onze leefomgeving #Schiphol #economie.", keyword_clusters: {'voordelen': 1, 'Schiphol': 5, 'schade': 4, 'leefomgeving': 1, 'economie': 1}},
{sentiment: 0.9977891445159912, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol'], full_text: "Schiphol expansion means more carbon emissions. We need to reduce our footprint, not increase it.", keyword_clusters: {'Schiphol': 5}},
{sentiment: 0.9851502776145935, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['worsens', 'expanding', 'Schiphol'], full_text: "As climate change worsens, expanding Schiphol is a reckless move. It's time to prioritize our planet over profits.", keyword_clusters: {'worsens': 5, 'expanding': 0, 'Schiphol': 5}},
{sentiment: 0.9968873858451843, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol', 'expansion', 'the'], full_text: "We can't afford to ignore the impacts of aviation on our environment. Schiphol expansion is a step in the wrong direction.", keyword_clusters: {'Schiphol': 5, 'expansion': 3, 'the': 5}},
{sentiment: 0.99752277135849, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol'], full_text: "The expansion of Schiphol puts the health and safety of our planet at risk. We need to demand better solutions.", keyword_clusters: {'Schiphol': 5}},
{sentiment: 0.9978523254394531, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Expanding', 'Schiphol'], full_text: "Expanding Schiphol only benefits the aviation industry, not our planet. We need to find sustainable alternatives.", keyword_clusters: {'Expanding': 0, 'Schiphol': 5}},
{sentiment: 0.9974259734153748, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['already', 'suffering', 'Schiphol'], full_text: "Our air quality is already suffering. We can't afford to let Schiphol expand and make things worse.", keyword_clusters: {'already': 0, 'suffering': 4, 'Schiphol': 5}},
{sentiment: 0.99801105260849, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol'], full_text: "Climate change is real, and Schiphol expansion will only exacerbate the problem. We need to take action now.", keyword_clusters: {'Schiphol': 5}},
{sentiment: 0.9970541000366211, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['from', 'fossil', 'fuels', 'expansion'], full_text: "We need to transition away from fossil fuels, not encourage their use with Schiphol expansion.", keyword_clusters: {'from': 4, 'fossil': 7, 'fuels': 5, 'expansion': 3}},
{sentiment: 0.9982727766036987, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['prioritizing', 'the', 'Schiphol', 'expansion', 'this'], full_text: "We can't keep prioritizing profits over the health of our planet. Schiphol expansion is a prime example of this.", keyword_clusters: {'prioritizing': 5, 'the': 5, 'Schiphol': 5, 'expansion': 3, 'this': 0}},
{sentiment: 0.9979666471481323, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol', 'expansion'], full_text: "Schiphol expansion is a short-sighted decision that will have long-term impacts on our climate. We need to find better solutions.", keyword_clusters: {'Schiphol': 5, 'expansion': 3}},
{sentiment: 0.9640083909034729, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol', 'hoofd', 'uitbreiding'], full_text: "Schiphol groeit ons boven het hoofd. Stop de uitbreiding nu!", keyword_clusters: {'Schiphol': 5, 'hoofd': 7, 'uitbreiding': 1}},
{sentiment: 0.9963032007217407, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['vliegtuiglawaai', 'Schiphol', 'rekening', 'gezondheid', 'omwonenden'], full_text: "Ik slaap niet meer door al dat vliegtuiglawaai. Schiphol moet rekening houden met de gezondheid van de omwonenden.", keyword_clusters: {'vliegtuiglawaai': 5, 'Schiphol': 5, 'rekening': 5, 'gezondheid': 1, 'omwonenden': 1}},
{sentiment: 0.9977424144744873, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol', 'winst', 'gevolgen', 'milieu', 'groei'], full_text: "Schiphol denkt alleen aan de winst, niet aan de gevolgen voor het milieu. Stop de groei!", keyword_clusters: {'Schiphol': 5, 'winst': 4, 'gevolgen': 1, 'milieu': 1, 'groei': 4}},
{sentiment: 0.555110514163971, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['tuin', 'vliegverkeer'], full_text: "Ik kan niet eens meer rustig in mijn eigen tuin zitten door al dat vliegverkeer. Het moet anders.", keyword_clusters: {'tuin': 7, 'vliegverkeer': 5}},
{sentiment: 0.9854261875152588, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol', 'maling', 'tijd', 'actie'], full_text: "Schiphol neemt ons in de maling met hun zogenaamde 'duurzaamheidsplannen'. Het is tijd voor actie!", keyword_clusters: {'Schiphol': 5, 'maling': 5, 'tijd': 3, 'actie': 1}},
{sentiment: 0.9925454258918762, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['vluchten', 'overlast', 'groei', 'Schiphol'], full_text: "Meer vluchten betekent meer overlast. Stop de groei van Schiphol!", keyword_clusters: {'vluchten': 0, 'overlast': 1, 'groei': 4, 'Schiphol': 5}},
{sentiment: 0.9979165196418762, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['leefbaarheid', 'buurt', 'spel', 'tijd', 'verandering'], full_text: "De leefbaarheid van onze buurt staat op het spel. Het is tijd voor verandering.", keyword_clusters: {'leefbaarheid': 1, 'buurt': 5, 'spel': 4, 'tijd': 3, 'verandering': 1}},
{sentiment: 0.9978952407836914, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol', 'zorgen', 'omwonenden'], full_text: "Schiphol moet luisteren naar de zorgen van de omwonenden en stoppen met uitbreiden.", keyword_clusters: {'Schiphol': 5, 'zorgen': 1, 'omwonenden': 1}},
{sentiment: 0.9948427081108093, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['kinderen', 'lawaai'], full_text: "Mijn kinderen kunnen niet eens meer rustig buiten spelen door al dat lawaai. Genoeg is genoeg.", keyword_clusters: {'kinderen': 1, 'lawaai': 5}},
{sentiment: 0.9980948567390442, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol', 'ramp', 'klimaat', 'uitbreiding'], full_text: "Schiphol is een ramp voor het klimaat. We moeten ons samen uitspreken tegen de uitbreiding.", keyword_clusters: {'Schiphol': 5, 'ramp': 0, 'klimaat': 1, 'uitbreiding': 1}},
{sentiment: 0.9945829510688782, lat: null, lng: null, count: 1, topics: ['schiphol', 'klimaat', 'protest'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'lucht', 'toekomst', 'Schiphol', 'klimaat', 'protest'], full_text: "Stop de uitbreiding van Schiphol! We willen gezonde lucht en een leefbare toekomst voor ons allemaal. #Schiphol #klimaat #protest", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'lucht': 0, 'toekomst': 1, 'klimaat': 1, 'protest': 1}},
{sentiment: 0.9975239634513855, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'milieu', 'protest'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'koste', 'milieu', 'gezondheid', 'stemmen', 'Schiphol', 'milieu', 'protest'], full_text: "Geen uitbreiding van Schiphol ten koste van ons milieu en onze gezondheid! Laten we opstaan ​​en onze stemmen laten horen! #Schiphol #milieu #protest", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'koste': 5, 'milieu': 1, 'gezondheid': 1, 'stemmen': 5, 'protest': 1}},
{sentiment: 0.9975981116294861, lat: null, lng: null, count: 1, topics: ['schiphol', 'luchtvervuiling', 'protest'], group: 'piet', emojis: [], keywords: ['Schiphol', 'gezondheidsschade', 'vervuiling', 'uitbreiding', 'overlast', 'Schiphol', 'luchtvervuiling', 'protest'], full_text: "Schiphol brengt ons gezondheidsschade en vervuiling. We willen geen uitbreiding van deze overlast! #Schiphol #luchtvervuiling #protest", keyword_clusters: {'Schiphol': 5, 'gezondheidsschade': 0, 'vervuiling': 1, 'uitbreiding': 1, 'overlast': 1, 'luchtvervuiling': 1, 'protest': 1}},
{sentiment: 0.9977350234985352, lat: null, lng: null, count: 1, topics: ['schiphol', 'toekomst', 'protest'], group: 'piet', emojis: [], keywords: ['kinderen', 'toekomst', 'uitbreiding', 'Schiphol', 'Schiphol', 'toekomst', 'protest'], full_text: "Onze kinderen verdienen een gezonde en leefbare toekomst. Daarom zeggen we nee tegen de uitbreiding van Schiphol! #Schiphol #toekomst #protest", keyword_clusters: {'kinderen': 1, 'toekomst': 1, 'uitbreiding': 1, 'Schiphol': 5, 'protest': 1}},
{sentiment: 0.9968722462654114, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'gezondheid', 'protest'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'prioriteit', 'gezondheid', 'milieu', 'Schiphol', 'gezondheid', 'protest'], full_text: "Stop de uitbreiding van Schiphol en geef prioriteit aan onze gezondheid en ons milieu! #Schiphol #gezondheid #protest", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'prioriteit': 4, 'gezondheid': 1, 'milieu': 1, 'protest': 1}},
{sentiment: 0.9964712858200073, lat: null, lng: null, count: 1, topics: ['schiphol', 'actie', 'protest'], group: 'piet', emojis: [], keywords: ['tijd', 'actie', 'uitbreiding', 'Schiphol', 'gezondheid', 'milieu', '\u200b\u200bop', 'spel', 'Schiphol', 'actie', 'protest'], full_text: "Het is tijd om actie te ondernemen tegen de uitbreiding van Schiphol. Onze gezondheid en ons milieu staan ​​op het spel! #Schiphol #actie #protest", keyword_clusters: {'tijd': 3, 'actie': 1, 'uitbreiding': 1, 'Schiphol': 5, 'gezondheid': 1, 'milieu': 1, '\u200b\u200bop': 4, 'spel': 4, 'protest': 1}},
{sentiment: 0.9966909885406494, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'rechten', 'protest'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'rechten', 'leefomgeving', 'Schiphol', 'rechten', 'protest'], full_text: "We moeten ons verzetten tegen de uitbreiding van Schiphol en opkomen voor onze rechten op een gezonde leefomgeving! #Schiphol #rechten #protest", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'rechten': 1, 'leefomgeving': 1, 'protest': 1}},
{sentiment: 0.9978330731391907, lat: null, lng: null, count: 1, topics: ['schiphol', 'luchtvervuiling', 'protest'], group: 'piet', emojis: [], keywords: ['luchtvervuiling', 'geluidsoverlast', 'gevolg', 'uitbreiding', 'Schiphol', 'Schiphol', 'luchtvervuiling', 'protest'], full_text: "Het is onaanvaardbaar dat we worden blootgesteld aan luchtvervuiling en geluidsoverlast als gevolg van de uitbreiding van Schiphol. #Schiphol #luchtvervuiling #protest", keyword_clusters: {'luchtvervuiling': 1, 'geluidsoverlast': 1, 'gevolg': 7, 'uitbreiding': 1, 'Schiphol': 5, 'protest': 1}},
{sentiment: 0.9963401556015015, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'duurzaamheid', 'protest'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'oplossing', 'alternatieven', 'Schiphol', 'duurzaamheid', 'protest'], full_text: "De uitbreiding van Schiphol is geen oplossing. Laten we samenwerken aan duurzame alternatieven! #Schiphol #duurzaamheid #protest", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'oplossing': 0, 'alternatieven': 1, 'duurzaamheid': 1, 'protest': 1}},
{sentiment: 0.9958193302154541, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'gezondheid', 'protest'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'gezondheid', 'milieu', 'gevaar', 'rechten', 'Schiphol', 'gezondheid', 'protest'], full_text: "We kunnen niet langer toestaan ​​dat de uitbreiding van Schiphol onze gezondheid en ons milieu in gevaar brengt. We moeten opkomen voor onze rechten! #Schiphol #gezondheid #protest", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'gezondheid': 1, 'milieu': 1, 'gevaar': 7, 'rechten': 1, 'protest': 1}},
{sentiment: 0.9975340366363525, lat: null, lng: null, count: 1, topics: ['schiphol'], group: 'piet', emojis: [], keywords: ['sfeer', 'Schiphol', 'protest', 'Demonstranten', 'dag', 'actie', 'verzet', 'uitbreiding', 'luchthaven'], full_text: "De sfeer begint te stijgen op de #Schiphol protest. Demonstranten maken zich op voor een lange dag van actie en verzet tegen de uitbreiding van de luchthaven.", keyword_clusters: {'sfeer': 7, 'Schiphol': 5, 'protest': 1, 'Demonstranten': 5, 'dag': 5, 'actie': 1, 'verzet': 1, 'uitbreiding': 1, 'luchthaven': 3}},
{sentiment: 0.9976801872253418, lat: null, lng: null, count: 1, topics: ['schiphol'], group: 'piet', emojis: [], keywords: ['The', 'atmosphere', 'Schiphol', 'protest', 'Demonstrators', 'the', 'airport'], full_text: "The atmosphere is starting to heat up at the #Schiphol protest. Demonstrators are getting ready for a long day of action and resistance against the expansion of the airport.", keyword_clusters: {'The': 4, 'atmosphere': 4, 'Schiphol': 5, 'protest': 1, 'Demonstrators': 5, 'the': 5, 'airport': 4}},
{sentiment: 0.9973390698432922, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['demonstranten', 'ingang', 'Schiphol', 'spandoeken', 'borden', 'uitbreiding', 'luchthaven', 'Schipholprotest'], full_text: "Honderden demonstranten verzamelen zich bij de ingang van Schiphol, met spandoeken en borden die de uitbreiding van de luchthaven afkeuren. #Schipholprotest", keyword_clusters: {'demonstranten': 5, 'ingang': 5, 'Schiphol': 5, 'spandoeken': 0, 'borden': 5, 'uitbreiding': 1, 'luchthaven': 3, 'Schipholprotest': 0}},
{sentiment: 0.9979106783866882, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['Hundreds', 'demonstrators', 'gathering', 'Schiphol', 'banners', 'the', 'airport', 'Schipholprotest'], full_text: "Hundreds of demonstrators are gathering at the entrance of Schiphol, with banners and signs denouncing the expansion of the airport. #Schipholprotest", keyword_clusters: {'Hundreds': 5, 'demonstrators': 0, 'gathering': 5, 'Schiphol': 5, 'banners': 0, 'the': 5, 'airport': 4, 'Schipholprotest': 0}},
{sentiment: 0.9972250461578369, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['demonstranten', 'toegang', 'luchthaven', 'politie', 'menigte', 'controle', 'Schipholprotest'], full_text: "De demonstranten blokkeren nu de toegang tot de luchthaven. De politie probeert de menigte onder controle te houden. #Schipholprotest", keyword_clusters: {'demonstranten': 5, 'toegang': 7, 'luchthaven': 3, 'politie': 5, 'menigte': 7, 'controle': 0, 'Schipholprotest': 0}},
{sentiment: 0.9968249797821045, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['The', 'demonstrators', 'airport', 'Schipholprotest'], full_text: "The demonstrators are now blocking access to the airport. The police are trying to keep the crowd under control. #Schipholprotest", keyword_clusters: {'The': 4, 'demonstrators': 0, 'airport': 4, 'Schipholprotest': 0}},
{sentiment: 0.9976164102554321, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['aantal', 'demonstranten', 'vliegveld', 'luchtverkeer', 'luchthavenmedewerkers', 'paniek', 'Schipholprotest'], full_text: "Een aantal demonstranten is erin geslaagd om het vliegveld op te komen en verstoort nu het luchtverkeer. De luchthavenmedewerkers zijn in paniek. #Schipholprotest", keyword_clusters: {'aantal': 5, 'demonstranten': 5, 'vliegveld': 0, 'luchtverkeer': 0, 'luchthavenmedewerkers': 4, 'paniek': 4, 'Schipholprotest': 0}},
{sentiment: 0.9970012307167053, lat: 52.2952203, lng: 6.5772427, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['air', 'traffic', 'Airport', 'Schipholprotest'], full_text: "Some demonstrators have managed to enter the airport and are now disrupting air traffic. Airport staff are in a panic. #Schipholprotest", keyword_clusters: {'air': 7, 'traffic': 4, 'Airport': 4, 'Schipholprotest': 0}},
{sentiment: 0.9979994893074036, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['politie', 'demonstranten', 'waterkanonnen', 'traangas', 'situatie', 'Schipholprotest'], full_text: "De politie probeert de demonstranten te verdrijven met waterkanonnen en traangas. De situatie is gespannen. #Schipholprotest", keyword_clusters: {'politie': 5, 'demonstranten': 5, 'waterkanonnen': 7, 'traangas': 4, 'situatie': 7, 'Schipholprotest': 0}},
{sentiment: 0.9972265362739563, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['the', 'water', 'gas', 'The', 'Schipholprotest'], full_text: "The police are trying to disperse the demonstrators with water cannons and tear gas. The situation is tense. #Schipholprotest", keyword_clusters: {'the': 5, 'water': 7, 'gas': 0, 'The': 4, 'Schipholprotest': 0}},
{sentiment: 0.9974613189697266, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['verzet', 'uitbreiding', 'Schiphol', 'demonstranten', 'land', 'luchthaven', 'stem', 'Schipholprotest'], full_text: "Het verzet tegen de uitbreiding van Schiphol blijft groeien, met demonstranten uit het hele land die naar de luchthaven zijn gekomen om hun stem te laten horen. #Schipholprotest", keyword_clusters: {'verzet': 1, 'uitbreiding': 1, 'Schiphol': 5, 'demonstranten': 5, 'land': 7, 'luchthaven': 3, 'stem': 1, 'Schipholprotest': 0}},
{sentiment: 0.9977298378944397, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['Schiphol', 'Schipholprotest'], full_text: "The resistance against the expansion of Schiphol continues to grow, with demonstrators from across the country coming to the airport to make their voices heard. #Schipholprotest", keyword_clusters: {'Schiphol': 5, 'Schipholprotest': 0}},
{sentiment: 0.9951972365379333, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['demonstranten', 'ingang', 'Tweede', 'Kamer', 'regering', 'uitbreiding', 'Schiphol', 'Schipholprotest'], full_text: "De demonstranten hebben zich nu verplaatst naar de ingang van de Tweede Kamer. Ze eisen dat de regering de uitbreiding van Schiphol stopzet. #Schipholprotest", keyword_clusters: {'demonstranten': 5, 'ingang': 5, 'Tweede': 7, 'Kamer': 5, 'regering': 1, 'uitbreiding': 1, 'Schiphol': 5, 'Schipholprotest': 0}},
{sentiment: 0.9975316524505615, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['The', 'demonstrators', 'Dutch', 'Parliament', 'They', 'Schiphol', 'Schipholprotest'], full_text: "The demonstrators have now moved to the entrance of the Dutch Parliament. They demand that the government stop the expansion of Schiphol. #Schipholprotest", keyword_clusters: {'The': 4, 'demonstrators': 0, 'Dutch': 7, 'Parliament': 5, 'They': 4, 'Schiphol': 5, 'Schipholprotest': 0}},
{sentiment: 0.9979702830314636, lat: null, lng: null, count: 1, topics: ['schiphol'], group: 'piet', emojis: [], keywords: ['groep', 'klimaatactivisten', 'Schiphol', 'protest', 'bezorgdheid', 'klimaatimpact', 'luchtvaartindustrie'], full_text: "Een groep klimaatactivisten heeft zich aangesloten bij de #Schiphol protest om hun bezorgdheid te uiten over de klimaatimpact van de luchtvaartindustrie.", keyword_clusters: {'groep': 5, 'klimaatactivisten': 0, 'Schiphol': 5, 'protest': 1, 'bezorgdheid': 4, 'klimaatimpact': 5, 'luchtvaartindustrie': 1}},
{sentiment: 0.9979780316352844, lat: null, lng: null, count: 1, topics: ['schiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'protest', 'concern'], full_text: "A group of climate activists has joined the #Schiphol protest to express their concern about the climate impact of the aviation industry.", keyword_clusters: {'Schiphol': 5, 'protest': 1, 'concern': 0}},
{sentiment: 0.9971411228179932, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['politie', 'luchthaven', 'demonstranten', 'protest', 'Schipholprotest'], full_text: "De politie heeft nu de luchthaven volledig afgesloten en arresteert demonstranten die weigeren te vertrekken. Het protest escaleert snel. #Schipholprotest", keyword_clusters: {'politie': 5, 'luchthaven': 3, 'demonstranten': 5, 'protest': 1, 'Schipholprotest': 0}},
{sentiment: 0.9940586090087891, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['arresting', 'demonstrators', 'The', 'protest', 'quickly', 'Schipholprotest'], full_text: "The police have now completely closed off the airport and are arresting demonstrators who refuse to leave. The protest is escalating quickly. #Schipholprotest", keyword_clusters: {'arresting': 0, 'demonstrators': 0, 'The': 4, 'protest': 1, 'quickly': 0, 'Schipholprotest': 0}},
{sentiment: 0.9977896213531494, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['Demonstranten', 'eieren', 'tomaten', 'politie', 'schipholprotest'], full_text: "Demonstranten gooien met eieren en tomaten naar de politie #schipholprotest", keyword_clusters: {'Demonstranten': 5, 'eieren': 0, 'tomaten': 0, 'politie': 5, 'schipholprotest': 0}},
{sentiment: 0.9976237416267395, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['vechtpartijen', 'tegenstanders', 'Schiphol', 'schipholprotest'], full_text: "Er zijn vechtpartijen uitgebroken tussen voor- en tegenstanders van Schiphol #schipholprotest", keyword_clusters: {'vechtpartijen': 4, 'tegenstanders': 7, 'Schiphol': 5, 'schipholprotest': 0}},
{sentiment: 0.9959902167320251, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['politie', 'waterkanonnen', 'menigte', 'schipholprotest'], full_text: "De politie zet waterkanonnen in om de menigte uit elkaar te drijven #schipholprotest", keyword_clusters: {'politie': 5, 'waterkanonnen': 7, 'menigte': 7, 'schipholprotest': 0}},
{sentiment: 0.9981166124343872, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['sfeer', 'schipholprotest', 'mensen'], full_text: "De sfeer bij het #schipholprotest is grimmig, veel mensen zijn boos en gefrustreerd", keyword_clusters: {'sfeer': 7, 'schipholprotest': 0, 'mensen': 0}},
{sentiment: 0.9974353909492493, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['barricades', 'demonstranten', 'politie', 'schipholprotest'], full_text: "Er zijn barricades opgeworpen door de demonstranten, de politie probeert deze te verwijderen #schipholprotest", keyword_clusters: {'barricades': 0, 'demonstranten': 5, 'politie': 5, 'schipholprotest': 0}},
{sentiment: 0.9979487061500549, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['politie', 'arrestaties', 'schipholprotest'], full_text: "De politie heeft meerdere arrestaties verricht bij het #schipholprotest", keyword_clusters: {'politie': 5, 'arrestaties': 5, 'schipholprotest': 0}},
{sentiment: 0.997797966003418, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['Demonstranten', 'vuurwerk', 'politie', 'situatie', 'controle', 'schipholprotest'], full_text: "Demonstranten hebben vuurwerk afgestoken, de politie probeert de situatie onder controle te krijgen #schipholprotest", keyword_clusters: {'Demonstranten': 5, 'vuurwerk': 5, 'politie': 5, 'situatie': 7, 'controle': 0, 'schipholprotest': 0}},
{sentiment: 0.9978589415550232, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['groep', 'demonstranten', 'Schiphol', 'terrein', 'politie', 'schipholprotest'], full_text: "Een groep demonstranten heeft geprobeerd het Schiphol terrein op te komen, dit is door de politie voorkomen #schipholprotest", keyword_clusters: {'groep': 5, 'demonstranten': 5, 'Schiphol': 5, 'terrein': 7, 'politie': 5, 'schipholprotest': 0}},
{sentiment: 0.9983024597167969, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['verkeerschaos', 'Schiphol', 'protesten', 'schipholprotest'], full_text: "De verkeerschaos rondom Schiphol neemt toe door de protesten #schipholprotest", keyword_clusters: {'verkeerschaos': 7, 'Schiphol': 5, 'protesten': 1, 'schipholprotest': 0}},
{sentiment: 0.9970857501029968, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['luchtvaartmaatschappijen', 'last', 'protesten', 'vluchten', 'schipholprotest'], full_text: "De luchtvaartmaatschappijen hebben veel last van de protesten, veel vluchten zijn vertraagd of geannuleerd #schipholprotest", keyword_clusters: {'luchtvaartmaatschappijen': 5, 'last': 5, 'protesten': 1, 'vluchten': 0, 'schipholprotest': 0}},
{sentiment: 0.9984729886054993, lat: null, lng: null, count: 1, topics: ['schiphol'], group: 'piet', emojis: [], keywords: ['opkomst', 'Schiphol', 'stem', 'duurzamer', 'Nederland'], full_text: "Wat een geweldige opkomst vandaag bij de #Schiphol protestmars! Bedankt aan iedereen die zijn stem heeft laten horen voor een duurzamer Nederland.", keyword_clusters: {'opkomst': 7, 'Schiphol': 5, 'stem': 1, 'duurzamer': 4, 'Nederland': 5}},
{sentiment: 0.9979183077812195, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol'], group: 'piet', emojis: [], keywords: ['dag', 'uitbreiding', 'Schiphol', 'gemeenschap', 'toekomst', 'stopSchiphol'], full_text: "Na een lange dag van protesteren tegen de uitbreiding van Schiphol ben ik trots op onze gemeenschap en blijf ik hoopvol voor een betere toekomst. #stopSchiphol", keyword_clusters: {'dag': 5, 'uitbreiding': 1, 'Schiphol': 5, 'gemeenschap': 1, 'toekomst': 1, 'stopSchiphol': 4}},
{sentiment: 0.9983782768249512, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['herinnering', 'burgers', 'staat', 'verandering', 'Schipholprotest'], full_text: "Vandaag was een krachtige herinnering dat burgers nog steeds in staat zijn om samen te komen en verandering te eisen. #Schipholprotest", keyword_clusters: {'herinnering': 0, 'burgers': 1, 'staat': 5, 'verandering': 1, 'Schipholprotest': 0}},
{sentiment: 0.9975220561027527, lat: null, lng: null, count: 1, topics: ['schipholprotestmars'], group: 'piet', emojis: [], keywords: ['Schipholprotestmars', 'vastberadenheid', 'energie', 'planeet', 'toekomst'], full_text: "Ik hoop dat degenen die vandaag deelnamen aan de #Schipholprotestmars de vastberadenheid en energie voelen om op te komen voor onze planeet en onze toekomst.", keyword_clusters: {'Schipholprotestmars': 5, 'vastberadenheid': 7, 'energie': 4, 'planeet': 1, 'toekomst': 1}},
{sentiment: 0.9958447813987732, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol'], group: 'piet', emojis: [], keywords: ['strijd', 'uitbreiding', 'Schiphol', 'duurzamer', 'Nederland', 'stopSchiphol'], full_text: "De strijd tegen de uitbreiding van Schiphol is nog lang niet voorbij, maar vandaag hebben we laten zien dat we vastbesloten zijn om te vechten voor een duurzamer Nederland. #stopSchiphol", keyword_clusters: {'strijd': 7, 'uitbreiding': 1, 'Schiphol': 5, 'duurzamer': 4, 'Nederland': 5, 'stopSchiphol': 4}},
{sentiment: 0.9982292056083679, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['mensen', 'doel', 'uitbreiding', 'Schiphol', 'Schipholprotest'], full_text: "Het was inspirerend om zoveel verschillende mensen samen te zien komen voor één doel: het stoppen van de uitbreiding van Schiphol. #Schipholprotest", keyword_clusters: {'mensen': 0, 'doel': 5, 'uitbreiding': 1, 'Schiphol': 5, 'Schipholprotest': 0}},
{sentiment: 0.9956099390983582, lat: null, lng: null, count: 1, topics: ['stopschiphol'], group: 'piet', emojis: [], keywords: ['moed', 'vastberadenheid', 'gevechten', 'wereld', 'stopSchiphol'], full_text: "We moeten de moed en vastberadenheid die we vandaag zagen, meenemen in de toekomstige gevechten voor een duurzamere wereld. #stopSchiphol", keyword_clusters: {'moed': 7, 'vastberadenheid': 7, 'gevechten': 5, 'wereld': 7, 'stopSchiphol': 4}},
{sentiment: 0.9981186985969543, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['signaal', 'leiders', 'vernietiging', 'planeet', 'Schipholprotest'], full_text: "Vandaag was een krachtig signaal aan onze leiders dat we niet langer stilzwijgend de vernietiging van onze planeet accepteren. #Schipholprotest", keyword_clusters: {'signaal': 4, 'leiders': 0, 'vernietiging': 4, 'planeet': 1, 'Schipholprotest': 0}},
{sentiment: 0.9984291195869446, lat: null, lng: null, count: 1, topics: ['schipholprotestmars', 'stopschiphol'], group: 'piet', emojis: [], keywords: ['gemeenschap', 'Schipholprotestmars', 'voorbeeld', 'verandering', 'wereld', 'stopSchiphol'], full_text: "De gemeenschap van vandaag bij de #Schipholprotestmars was een voorbeeld van de verandering die we willen zien in de wereld. #stopSchiphol", keyword_clusters: {'gemeenschap': 1, 'Schipholprotestmars': 5, 'voorbeeld': 4, 'verandering': 1, 'wereld': 7, 'stopSchiphol': 4}},
{sentiment: 0.9976892471313477, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['stem', 'toekomst', 'einde', 'uitbreiding', 'Schiphol', 'Schipholprotest'], full_text: "Vandaag hebben we onze stem laten horen, maar we moeten blijven strijden voor een duurzamere toekomst en een einde aan de uitbreiding van Schiphol. #Schipholprotest", keyword_clusters: {'stem': 1, 'toekomst': 1, 'einde': 0, 'uitbreiding': 1, 'Schiphol': 5, 'Schipholprotest': 0}},
{sentiment: 0.9982441663742065, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['mensen', 'uitbreiding', 'Schiphol', 'mensen', 'zorgen', 'milieu'], full_text: "Het was indrukwekkend om te zien hoeveel mensen bij elkaar kwamen om te protesteren tegen de uitbreiding van Schiphol. Het geeft aan hoeveel mensen zich zorgen maken over het milieu.", keyword_clusters: {'mensen': 0, 'uitbreiding': 1, 'Schiphol': 5, 'zorgen': 1, 'milieu': 1}},
{sentiment: 0.99797123670578, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['protesten', 'emotie', 'Mensen', 'impact', 'luchthaven', 'leefomgeving'], full_text: "De protesten waren vreedzaam, maar er was wel veel emotie te zien. Mensen zijn duidelijk bezorgd over de impact van de luchthaven op hun leefomgeving.", keyword_clusters: {'protesten': 1, 'emotie': 0, 'Mensen': 0, 'impact': 5, 'luchthaven': 3, 'leefomgeving': 1}},
{sentiment: 0.9980666041374207, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['groepen', 'protesten', 'jong', 'achtergronden', 'draagvlak', 'kwestie'], full_text: "Het is opvallend dat er zoveel verschillende groepen aanwezig waren bij de protesten. Van jong tot oud en van allerlei achtergronden. Het geeft aan hoe breed het draagvlak is voor deze kwestie.", keyword_clusters: {'groepen': 5, 'protesten': 1, 'jong': 0, 'achtergronden': 0, 'draagvlak': 7, 'kwestie': 0}},
{sentiment: 0.9976469874382019, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['protesten', 'spandoeken', 'borden', 'boodschappen', 'uitbreiding', 'Schiphol', 'gezondheid', 'winst', 'mensen'], full_text: "Tijdens de protesten waren er veel spandoeken en borden te zien met boodschappen als \"Stop de uitbreiding van Schiphol\" en \"Onze gezondheid gaat voor winst\". Het laat zien dat mensen zich gehoord willen voelen.", keyword_clusters: {'protesten': 1, 'spandoeken': 0, 'borden': 5, 'boodschappen': 4, 'uitbreiding': 1, 'Schiphol': 5, 'gezondheid': 1, 'winst': 4, 'mensen': 0}},
{sentiment: 0.9983678460121155, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['aanwezigheid', 'politie', 'beveiliging', 'incidenten', 'protesten'], full_text: "De aanwezigheid van politie en beveiliging was duidelijk merkbaar, maar er waren geen incidenten tijdens de protesten. Dat is goed om te zien.", keyword_clusters: {'aanwezigheid': 7, 'politie': 5, 'beveiliging': 7, 'incidenten': 7, 'protesten': 1}},
{sentiment: 0.9976142644882202, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['protesten', 'signaal', 'vraag', 'politiek'], full_text: "Het is duidelijk dat de protesten een politiek signaal afgeven. De vraag is nu hoe de politiek hierop gaat reageren.", keyword_clusters: {'protesten': 1, 'signaal': 4, 'vraag': 0, 'politiek': 1}},
{sentiment: 0.9980995059013367, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['mensen', 'stem', 'protesten', 'kwestie', 'mensen'], full_text: "Het was indrukwekkend om te zien hoeveel mensen er bereid waren om hun stem te laten horen tijdens de protesten. Het toont aan hoe belangrijk deze kwestie is voor veel mensen.", keyword_clusters: {'mensen': 0, 'stem': 1, 'protesten': 1, 'kwestie': 0}},
{sentiment: 0.9982947707176208, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['protesten', 'sprekers', 'publiek', 'protesten', 'dimensie', 'saamhorigheid'], full_text: "Tijdens de protesten waren er ook veel sprekers aanwezig die het publiek toespraken. Het gaf de protesten een extra dimensie en zorgde voor meer saamhorigheid.", keyword_clusters: {'protesten': 1, 'sprekers': 5, 'publiek': 7, 'dimensie': 0, 'saamhorigheid': 0}},
{sentiment: 0.9981796741485596, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['protesten', 'impact', 'omgeving', 'politiek', 'opinie'], full_text: "Het is duidelijk dat de protesten veel impact hebben gehad. Niet alleen op de directe omgeving, maar ook op de politiek en de publieke opinie.", keyword_clusters: {'protesten': 1, 'impact': 5, 'omgeving': 0, 'politiek': 1, 'opinie': 7}},
{sentiment: 0.99769127368927, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['protesten', 'draagvlak', 'strijd', 'klimaatverandering', 'mensen', 'straat', 'toekomst'], full_text: "De protesten hebben laten zien dat er veel draagvlak is voor de strijd tegen klimaatverandering en dat mensen bereid zijn om daarvoor de straat op te gaan. Dat is hoopgevend voor de toekomst.", keyword_clusters: {'protesten': 1, 'draagvlak': 7, 'strijd': 7, 'klimaatverandering': 1, 'mensen': 0, 'straat': 5, 'toekomst': 1}},
{sentiment: 0.9969612956047058, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd'], group: 'piet', emojis: [], keywords: ['acties', 'uitbreiding', 'Schiphol', 'economie', 'VVD', 'groei', 'luchthaven', 'toekomst', 'land', 'Schiphol', 'VVD'], full_text: "De acties tegen de uitbreiding van Schiphol zijn ongegrond en schaden onze economie. De VVD steunt de groei van onze luchthaven en zal blijven werken aan een duurzame toekomst voor ons land. #Schiphol #VVD", keyword_clusters: {'acties': 4, 'uitbreiding': 1, 'Schiphol': 5, 'economie': 1, 'VVD': 5, 'groei': 4, 'luchthaven': 3, 'toekomst': 1, 'land': 7}},
{sentiment: 0.9972809553146362, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd'], group: 'piet', emojis: [], keywords: ['balans', 'groei', 'duurzaamheid', 'uitbreiding', 'Schiphol', 'welvaart', 'banen', 'VVD', 'Nederland', 'Schiphol', 'VVD'], full_text: "Het is belangrijk dat we een balans vinden tussen economische groei en duurzaamheid. De uitbreiding van Schiphol draagt bij aan onze welvaart en creëert banen. De VVD zal blijven werken aan een leefbaar en duurzaam Nederland. #Schiphol #VVD", keyword_clusters: {'balans': 0, 'groei': 4, 'duurzaamheid': 1, 'uitbreiding': 1, 'Schiphol': 5, 'welvaart': 5, 'banen': 0, 'VVD': 5, 'Nederland': 5}},
{sentiment: 0.9979623556137085, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd'], group: 'piet', emojis: [], keywords: ['protesten', 'Schiphol', 'luchthaven', 'motor', 'land', 'VVD', 'groei', 'luchthaven', 'Schiphol', 'VVD'], full_text: "De protesten tegen Schiphol zijn begrijpelijk, maar we moeten niet vergeten dat de luchthaven een belangrijke economische motor is voor ons land. De VVD zal zich blijven inzetten voor duurzame groei van onze luchthaven. #Schiphol #VVD", keyword_clusters: {'protesten': 1, 'Schiphol': 5, 'luchthaven': 3, 'motor': 0, 'land': 7, 'VVD': 5, 'groei': 4}},
{sentiment: 0.9976917505264282, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'stap', 'economie', 'VVD', 'toekomst', 'land', 'Schiphol', 'VVD'], full_text: "De uitbreiding van Schiphol is een belangrijke stap voorwaarts voor onze economie. De VVD begrijpt dat niet iedereen het hiermee eens is, maar we moeten ons ook richten op de toekomst van ons land. #Schiphol #VVD", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'stap': 0, 'economie': 1, 'VVD': 5, 'toekomst': 1, 'land': 7}},
{sentiment: 0.997980535030365, lat: null, lng: null, count: 1, topics: ['schiphol', 'vvd'], group: 'piet', emojis: [], keywords: ['VVD', 'voorstander', 'groei', 'Schiphol', 'protesten', 'luchthaven', 'voordelen', 'land', 'Schiphol', 'VVD'], full_text: "De VVD is voorstander van duurzame groei van Schiphol. De protesten van vandaag zijn begrijpelijk, maar we moeten niet vergeten dat de luchthaven ook veel voordelen heeft voor ons land. #Schiphol #VVD", keyword_clusters: {'VVD': 5, 'voorstander': 0, 'groei': 4, 'Schiphol': 5, 'protesten': 1, 'luchthaven': 3, 'voordelen': 1, 'land': 7}},
{sentiment: 0.9972620010375977, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'economie', 'land', 'banen', 'VVD', 'proces', 'toekomst', 'Schiphol', 'VVD'], full_text: "De uitbreiding van Schiphol is nodig om de economie van ons land te laten groeien en banen te creëren. De VVD steunt dit proces en zal blijven werken aan een duurzame toekomst voor ons allemaal. #Schiphol #VVD", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'economie': 1, 'land': 7, 'banen': 0, 'VVD': 5, 'proces': 4, 'toekomst': 1}},
{sentiment: 0.9980893731117249, lat: 51.5794077, lng: 5.7135574, count: 1, topics: ['schiphol', 'vvd'], group: 'piet', emojis: [], keywords: ['protesten', 'Schiphol', 'luchthaven', 'rol', 'handel', 'toerisme', 'VVD', 'groei', 'Schiphol', 'Schiphol', 'VVD'], full_text: "De protesten tegen Schiphol zijn begrijpelijk, maar we moeten niet vergeten dat de luchthaven ook een belangrijke rol speelt in onze internationale handel en het toerisme. De VVD zal blijven werken aan duurzame groei van Schiphol. #Schiphol #VVD", keyword_clusters: {'protesten': 1, 'Schiphol': 5, 'luchthaven': 3, 'rol': 5, 'handel': 2, 'toerisme': 7, 'VVD': 5, 'groei': 4}},
{sentiment: 0.9978694915771484, lat: null, lng: null, count: 1, topics: ['schiphol', 'vvd'], group: 'piet', emojis: [], keywords: ['VVD', 'uitbreiding', 'Schiphol', 'oog', 'voordelen', 'economie', 'toekomst', 'land', 'Schiphol', 'VVD'], full_text: "De VVD begrijpt dat er zorgen zijn over de uitbreiding van Schiphol, maar we moeten ook oog hebben voor de voordelen voor onze economie. We zullen blijven werken aan een duurzame toekomst voor ons land. #Schiphol #VVD", keyword_clusters: {'VVD': 5, 'uitbreiding': 1, 'Schiphol': 5, 'oog': 5, 'voordelen': 1, 'economie': 1, 'toekomst': 1, 'land': 7}},
{sentiment: 0.9978193044662476, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'economie', 'banen', 'VVD', 'proces', 'toekomst', 'Schiphol', 'VVD'], full_text: "De uitbreiding van Schiphol is goed voor onze economie en zorgt voor banen. De VVD steunt dit proces en zal blijven werken aan een duurzame toekomst voor ons allemaal. #Schiphol #VVD", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'economie': 1, 'banen': 0, 'VVD': 5, 'proces': 4, 'toekomst': 1}},
{sentiment: 0.9978792667388916, lat: null, lng: null, count: 1, topics: ['schiphol', 'vvd'], group: 'piet', emojis: [], keywords: ['VVD', 'zorgen', 'burgers', 'uitbreiding', 'Schiphol', 'voordelen', 'luchthaven', 'groei', 'Schiphol', 'Schiphol', 'VVD'], full_text: "De VVD begrijpt de zorgen van burgers over de uitbreiding van Schiphol, maar we moeten ons ook richten op de economische voordelen van de luchthaven. We zullen blijven werken aan duurzame groei van Schiphol. #Schiphol #VVD", keyword_clusters: {'VVD': 5, 'zorgen': 1, 'burgers': 1, 'uitbreiding': 1, 'Schiphol': 5, 'voordelen': 1, 'luchthaven': 3, 'groei': 4}},
{sentiment: 0.9969509840011597, lat: null, lng: null, count: 1, topics: ['klimaatprotest', 'schiphol', 'groenlinks'], group: 'piet', emojis: [], keywords: ['actievoerders', 'signaal', 'klimaatcrisis', 'Schiphol', 'GroenLinks', 'klimaatprotest', 'Schiphol'], full_text: "De actievoerders laten een duidelijk signaal horen: we moeten de klimaatcrisis serieus nemen en stoppen met het eindeloos uitbreiden van Schiphol. GroenLinks staat achter jullie! #klimaatprotest #Schiphol #groenlinks", keyword_clusters: {'actievoerders': 7, 'signaal': 4, 'klimaatcrisis': 4, 'Schiphol': 5, 'GroenLinks': 0, 'klimaatprotest': 5}},
{sentiment: 0.9981502294540405, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['groenlinks', 'schiphol', 'klimaatprotest'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'klimaatprobleem', 'probleem', 'leefbaarheid', 'omgeving', 'tijd', 'bewoners', 'natuur', 'Schiphol', 'klimaatprotest'], full_text: "De uitbreiding van Schiphol is niet alleen een klimaatprobleem, maar ook een probleem voor de leefbaarheid van de omgeving. Het wordt tijd dat we luisteren naar de lokale bewoners en de natuur. #groenlinks #Schiphol #klimaatprotest", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'klimaatprobleem': 5, 'probleem': 4, 'leefbaarheid': 1, 'omgeving': 0, 'tijd': 3, 'bewoners': 4, 'natuur': 1, 'klimaatprotest': 5}},
{sentiment: 0.9973727464675903, lat: null, lng: null, count: 1, topics: ['schiphol', 'klimaatprotest', 'groenlinks'], group: 'piet', emojis: [], keywords: ['tijd', 'groeidenken', 'nadruk', 'duurzaamheid', 'leefbaarheid', 'Schiphol', 'klimaatprotest'], full_text: "Het is tijd dat we afstappen van het groeidenken en de nadruk leggen op duurzaamheid en leefbaarheid. #Schiphol #klimaatprotest #groenlinks", keyword_clusters: {'tijd': 3, 'groeidenken': 5, 'nadruk': 5, 'duurzaamheid': 1, 'leefbaarheid': 1, 'Schiphol': 5, 'klimaatprotest': 5}},
{sentiment: 0.9981581568717957, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['groenlinks', 'klimaatprotest', 'schiphol'], group: 'piet', emojis: [], keywords: ['protestactie', 'Schiphol', 'mensen', 'zorgen', 'toekomst', 'planeet', 'tijd', 'toekomst', 'klimaatprotest', 'Schiphol'], full_text: "De protestactie bij Schiphol laat zien dat mensen zich zorgen maken over de toekomst van onze planeet. Het is tijd voor een groene en duurzame toekomst! #groenlinks #klimaatprotest #Schiphol", keyword_clusters: {'protestactie': 5, 'Schiphol': 5, 'mensen': 0, 'zorgen': 1, 'toekomst': 1, 'planeet': 1, 'tijd': 3, 'klimaatprotest': 5}},
{sentiment: 0.9973982572555542, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['groenlinks', 'klimaatprotest', 'schiphol'], group: 'piet', emojis: [], keywords: ['luchtvaartsector', 'bijdrage', 'aanpak', 'klimaatcrisis', 'Schiphol', 'klimaatprotest', 'Schiphol'], full_text: "De luchtvaartsector moet een bijdrage leveren aan de aanpak van de klimaatcrisis. Het eindeloos uitbreiden van Schiphol past daar niet bij. #groenlinks #klimaatprotest #Schiphol", keyword_clusters: {'luchtvaartsector': 0, 'bijdrage': 0, 'aanpak': 7, 'klimaatcrisis': 4, 'Schiphol': 5, 'klimaatprotest': 5}},
{sentiment: 0.9974109530448914, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['groenlinks', 'klimaatprotest', 'schiphol'], group: 'piet', emojis: [], keywords: ['actievoerders', 'tijd', 'koerswijziging', 'uitbreiding', 'Schiphol', 'planeet', 'klimaatprotest', 'Schiphol'], full_text: "De actievoerders laten zien dat het tijd is voor een koerswijziging. De uitbreiding van Schiphol is niet langer acceptabel als we onze planeet willen redden. #groenlinks #klimaatprotest #Schiphol", keyword_clusters: {'actievoerders': 7, 'tijd': 3, 'koerswijziging': 4, 'uitbreiding': 1, 'Schiphol': 5, 'planeet': 1, 'klimaatprotest': 5}},
{sentiment: 0.9977184534072876, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['groenlinks', 'klimaatprotest', 'schiphol'], group: 'piet', emojis: [], keywords: ['protestactie', 'Schiphol', 'teken', 'wand', 'weg', 'duurzaamheid', 'leefbaarheid', 'klimaatprotest', 'Schiphol'], full_text: "De protestactie bij Schiphol is een teken aan de wand: we kunnen niet doorgaan op de huidige weg. We moeten inzetten op duurzaamheid en leefbaarheid. #groenlinks #klimaatprotest #Schiphol", keyword_clusters: {'protestactie': 5, 'Schiphol': 5, 'teken': 4, 'wand': 7, 'weg': 0, 'duurzaamheid': 1, 'leefbaarheid': 1, 'klimaatprotest': 5}},
{sentiment: 0.9954577684402466, lat: null, lng: null, count: 1, topics: ['groenlinks', 'klimaatprotest', 'schiphol'], group: 'piet', emojis: [], keywords: ['tijd', 'planeet', 'natuur', 'leefomgeving', 'Schiphol', 'klimaatprotest', 'Schiphol'], full_text: "Het is tijd dat we ons realiseren dat we maar één planeet hebben. Laten we zuinig zijn op de natuur en de leefomgeving, en stoppen met het uitbreiden van Schiphol. #groenlinks #klimaatprotest #Schiphol", keyword_clusters: {'tijd': 3, 'planeet': 1, 'natuur': 1, 'leefomgeving': 1, 'Schiphol': 5, 'klimaatprotest': 5}},
{sentiment: 0.9976978898048401, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['groenlinks', 'klimaatprotest', 'schiphol'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'klimaatprobleem', 'geluidsprobleem', 'luchtvervuilingsprobleem', 'leefbaarheidsprobleem', 'klimaatprotest', 'Schiphol'], full_text: "De uitbreiding van Schiphol is een klimaatprobleem, een geluidsprobleem, een luchtvervuilingsprobleem, en een leefbaarheidsprobleem. We moeten ons afvragen of we dit willen. #groenlinks #klimaatprotest #Schiphol", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'klimaatprobleem': 5, 'geluidsprobleem': 4, 'luchtvervuilingsprobleem': 5, 'leefbaarheidsprobleem': 7, 'klimaatprotest': 5}},
{sentiment: 0.9963420033454895, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['groenlinks', 'klimaatprotest', 'schiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'planeet', 'tijd', 'nadruk', 'duurzaamheid', 'leefbaarheid', 'klimaatprotest', 'Schiphol'], full_text: "We kunnen niet doorgaan met het eindeloos uitbreiden van Schiphol als we onze planeet willen redden. Het is tijd dat we de nadruk leggen op duurzaamheid en leefbaarheid. #groenlinks #klimaatprotest #Schiphol", keyword_clusters: {'Schiphol': 5, 'planeet': 1, 'tijd': 3, 'nadruk': 5, 'duurzaamheid': 1, 'leefbaarheid': 1, 'klimaatprotest': 5}},
{sentiment: 0.9974519610404968, lat: null, lng: null, count: 1, topics: ['climatejustice', 'extinctionrebellion'], group: 'piet', emojis: [], keywords: ['recht', 'democratie', 'boodschap', 'Extinction', 'Rebellion', 'toekomst'], full_text: "Geweldloos protesteren is een recht in een democratie, laten we de boodschap van Extinction Rebellion serieus nemen en werken aan een duurzame toekomst voor iedereen! #ClimateJustice #ExtinctionRebellion", keyword_clusters: {'recht': 0, 'democratie': 5, 'boodschap': 5, 'Extinction': 5, 'Rebellion': 5, 'toekomst': 1}},
{sentiment: 0.9978047013282776, lat: null, lng: null, count: 1, topics: ['actnow', 'xrnl'], group: 'piet', emojis: [], keywords: ['acties', 'Extinction', 'Rebellion', 'verandering', 'klimaat', 'planeet', 'ActNow', 'XRNL'], full_text: "De acties van Extinction Rebellion laten zien dat we niet langer kunnen wachten op verandering, we moeten nu handelen voor het klimaat en onze planeet! #ActNow #XRNL", keyword_clusters: {'acties': 4, 'Extinction': 5, 'Rebellion': 5, 'verandering': 1, 'klimaat': 1, 'planeet': 1, 'ActNow': 0, 'XRNL': 0}},
{sentiment: 0.9978644251823425, lat: null, lng: null, count: 1, topics: ['climateemergency', 'xrnl'], group: 'piet', emojis: [], keywords: ['methoden', 'Extinction', 'Rebellion', 'actie', 'klimaatcrisis', 'ClimateEmergency', 'XRNL'], full_text: "Hoewel we het misschien niet eens zijn met de methoden van Extinction Rebellion, kunnen we niet ontkennen dat er dringende actie nodig is om de klimaatcrisis aan te pakken. #ClimateEmergency #XRNL", keyword_clusters: {'methoden': 4, 'Extinction': 5, 'Rebellion': 5, 'actie': 1, 'klimaatcrisis': 4, 'ClimateEmergency': 7, 'XRNL': 0}},
{sentiment: 0.9980927109718323, lat: null, lng: null, count: 1, topics: ['togetherfortheplanet', 'xrnl'], group: 'piet', emojis: [], keywords: ['tijd', 'klimaatcrisis', 'oproepen', 'Extinction', 'Rebellion', 'toekomst', 'generaties', 'XRNL'], full_text: "Het is tijd om de klimaatcrisis serieus te nemen en te luisteren naar de oproepen van Extinction Rebellion. We moeten samenwerken om een duurzame toekomst te creëren voor onszelf en toekomstige generaties! #TogetherForThePlanet #XRNL", keyword_clusters: {'tijd': 3, 'klimaatcrisis': 4, 'oproepen': 0, 'Extinction': 5, 'Rebellion': 5, 'toekomst': 1, 'generaties': 7, 'XRNL': 0}},
{sentiment: 0.9977206587791443, lat: null, lng: null, count: 1, topics: ['climateaction', 'xrnl'], group: 'piet', emojis: [], keywords: ['acties', 'Extinction', 'Rebellion', 'business', 'as', 'klimaat', 'ClimateAction', 'XRNL'], full_text: "De acties van Extinction Rebellion herinneren ons eraan dat we niet kunnen doorgaan met business as usual, we moeten nu handelen voor het klimaat! #ClimateAction #XRNL", keyword_clusters: {'acties': 4, 'Extinction': 5, 'Rebellion': 5, 'business': 0, 'as': 0, 'klimaat': 1, 'ClimateAction': 5, 'XRNL': 0}},
{sentiment: 0.9980164766311646, lat: null, lng: null, count: 1, topics: ['nonviolentprotest', 'xrnl'], group: 'piet', emojis: [], keywords: ['recht', 'acties', 'communicatie', 'verandering', 'XRNL'], full_text: "Hoewel we het recht hebben om te protesteren, moeten we altijd streven naar geweldloze acties en open communicatie om verandering teweeg te brengen. #NonViolentProtest #XRNL", keyword_clusters: {'recht': 0, 'acties': 4, 'communicatie': 5, 'verandering': 1, 'XRNL': 0}},
{sentiment: 0.9980971217155457, lat: null, lng: null, count: 1, topics: ['sustainabilityforall', 'xrnl'], group: 'piet', emojis: [], keywords: ['protesten', 'Extinction', 'Rebellion', 'signaal', 'klimaat', 'oplossingen', 'SustainabilityForAll', 'XRNL'], full_text: "De protesten van Extinction Rebellion zijn een belangrijk signaal dat we moeten handelen voor het klimaat, maar we moeten altijd blijven zoeken naar oplossingen die voor iedereen werken. #SustainabilityForAll #XRNL", keyword_clusters: {'protesten': 1, 'Extinction': 5, 'Rebellion': 5, 'signaal': 4, 'klimaat': 1, 'oplossingen': 1, 'SustainabilityForAll': 7, 'XRNL': 0}},
{sentiment: 0.9979738593101501, lat: null, lng: null, count: 1, topics: ['globalclimateaction', 'xrnl'], group: 'piet', emojis: [], keywords: ['klimaatcrisis', 'uitdaging', 'reactie', 'verandering', 'toekomst', 'GlobalClimateAction', 'XRNL'], full_text: "De klimaatcrisis is een mondiale uitdaging en vereist een gecoördineerde internationale reactie. We moeten samenwerken om verandering teweeg te brengen en een duurzame toekomst te creëren. #GlobalClimateAction #XRNL", keyword_clusters: {'klimaatcrisis': 4, 'uitdaging': 7, 'reactie': 0, 'verandering': 1, 'toekomst': 1, 'GlobalClimateAction': 0, 'XRNL': 0}},
{sentiment: 0.9979454874992371, lat: null, lng: null, count: 1, topics: ['timeforaction', 'xrnl'], group: 'piet', emojis: [], keywords: ['boodschap', 'Extinction', 'Rebellion', 'actie', 'klimaatcrisis', 'tijd', 'verandering', 'XRNL'], full_text: "\"We moeten de boodschap van Extinction Rebellion serieus nemen en actie ondernemen om de klimaatcrisis aan te pakken. Het is tijd voor verandering! #TimeForAction #XRNL", keyword_clusters: {'boodschap': 5, 'Extinction': 5, 'Rebellion': 5, 'actie': 1, 'klimaatcrisis': 4, 'tijd': 3, 'verandering': 1, 'XRNL': 0}},
{sentiment: 0.9978287816047668, lat: null, lng: null, count: 1, topics: ['takeresponsibility', 'xrnl'], group: 'piet', emojis: [], keywords: ['protesten', 'Extinction', 'Rebellion', 'oproep', 'actie', 'verantwoordelijkheid', 'toekomst', 'XRNL'], full_text: "De protesten van Extinction Rebellion zijn een oproep tot actie voor ons allemaal, laten we onze verantwoordelijkheid nemen en werken aan een duurzame toekomst voor iedereen. #TakeResponsibility #XRNL", keyword_clusters: {'protesten': 1, 'Extinction': 5, 'Rebellion': 5, 'oproep': 0, 'actie': 1, 'verantwoordelijkheid': 4, 'toekomst': 1, 'XRNL': 0}},
{sentiment: 0.9970280528068542, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['rebelforlife', 'schipholschiphol'], group: 'piet', emojis: [], keywords: ['tijd', 'tijd', 'actie', 'uitbreiding', 'Schiphol', 'projecten', 'rebelforlife', 'schipholschiphol'], full_text: "De tijd van praten is voorbij. Het is tijd voor actie. We blijven ons verzetten tegen de uitbreiding van Schiphol en andere klimaatverwoestende projecten. #rebelforlife #schipholschiphol", keyword_clusters: {'tijd': 3, 'actie': 1, 'uitbreiding': 1, 'Schiphol': 5, 'projecten': 5, 'rebelforlife': 3, 'schipholschiphol': 3}},
{sentiment: 0.9979355335235596, lat: null, lng: null, count: 1, topics: ['rebelforlife', 'schipholschiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'planeet', 'toekomst', 'kinderen', 'rebelforlife', 'schipholschiphol'], full_text: "We hebben vreedzaam geprotesteerd tegen Schiphol, maar het lijkt erop dat we niet worden gehoord. We blijven vechten voor onze planeet en de toekomst van onze kinderen. #rebelforlife #schipholschiphol", keyword_clusters: {'Schiphol': 5, 'planeet': 1, 'toekomst': 1, 'kinderen': 1, 'rebelforlife': 3, 'schipholschiphol': 3}},
{sentiment: 0.9940358400344849, lat: null, lng: null, count: 1, topics: ['rebelforlife', 'schipholschiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'jaren', 'keuze', 'straat', 'toekomst', 'rebelforlife', 'schipholschiphol'], full_text: "Schiphol, jullie hebben ons de afgelopen jaren genegeerd en nu hebben we geen andere keuze dan de straat op te gaan en te strijden voor onze toekomst. #rebelforlife #schipholschiphol", keyword_clusters: {'Schiphol': 5, 'jaren': 7, 'keuze': 4, 'straat': 5, 'toekomst': 1, 'rebelforlife': 3, 'schipholschiphol': 3}},
{sentiment: 0.996229350566864, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['rebelforlife', 'schipholschiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'eisen', 'volk', 'planeet', 'rebelforlife', 'schipholschiphol'], full_text: "Wij laten ons niet het zwijgen opleggen. Schiphol moet luisteren naar de eisen van het volk en stoppen met het vernietigen van onze planeet. #rebelforlife #schipholschiphol", keyword_clusters: {'Schiphol': 5, 'eisen': 5, 'volk': 0, 'planeet': 1, 'rebelforlife': 3, 'schipholschiphol': 3}},
{sentiment: 0.9964494705200195, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['rebelforlife', 'schipholschiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'activiteiten', 'vernietiging', 'planeet', 'rebelforlife', 'schipholschiphol'], full_text: "Wij zijn hier niet om te spelen. Schiphol moet stoppen met het uitbreiden van haar activiteiten en de vernietiging van onze planeet. #rebelforlife #schipholschiphol", keyword_clusters: {'Schiphol': 5, 'activiteiten': 7, 'vernietiging': 4, 'planeet': 1, 'rebelforlife': 3, 'schipholschiphol': 3}},
{sentiment: 0.9962018132209778, lat: null, lng: null, count: 1, topics: ['rebelforlife', 'schipholschiphol'], group: 'piet', emojis: [], keywords: ['tijd', 'rand', 'afgrond', 'planeet', 'rebelforlife', 'schipholschiphol'], full_text: "Het is tijd om wakker te worden en te beseffen dat we op de rand van de afgrond staan. We moeten nu handelen om onze planeet te redden. #rebelforlife #schipholschiphol", keyword_clusters: {'tijd': 3, 'rand': 4, 'afgrond': 7, 'planeet': 1, 'rebelforlife': 3, 'schipholschiphol': 3}},
{sentiment: 0.9944381713867188, lat: null, lng: null, count: 1, topics: ['rebelforlife', 'schipholschiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'vervuilers', 'Nederland', 'tijd', 'verantwoordelijkheid', 'uitbreiding', 'industrie', 'rebelforlife', 'schipholschiphol'], full_text: "Schiphol is een van de grootste vervuilers van Nederland. Het is tijd om verantwoordelijkheid te nemen en te stoppen met de uitbreiding van deze destructieve industrie. #rebelforlife #schipholschiphol", keyword_clusters: {'Schiphol': 5, 'vervuilers': 4, 'Nederland': 5, 'tijd': 3, 'verantwoordelijkheid': 4, 'uitbreiding': 1, 'industrie': 7, 'rebelforlife': 3, 'schipholschiphol': 3}},
{sentiment: 0.9967851638793945, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['rebelforlife', 'schipholschiphol'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'projecten', 'planeet', 'rebelforlife', 'schipholschiphol'], full_text: "We moeten ons verzetten tegen de uitbreiding van Schiphol en andere destructieve projecten, anders zal onze planeet verloren gaan. #rebelforlife #schipholschiphol", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'projecten': 5, 'planeet': 1, 'rebelforlife': 3, 'schipholschiphol': 3}},
{sentiment: 0.9975270628929138, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['rebelforlife', 'schipholschiphol'], group: 'piet', emojis: [], keywords: ['tijd', 'waarheid', 'ogen', 'Schiphol', 'klimaatramp', 'actie', 'planeet', 'rebelforlife', 'schipholschiphol'], full_text: "Het is tijd om de waarheid onder ogen te zien: Schiphol is een klimaatramp. We moeten nu actie ondernemen om onze planeet te beschermen. #rebelforlife #schipholschiphol", keyword_clusters: {'tijd': 3, 'waarheid': 0, 'ogen': 0, 'Schiphol': 5, 'klimaatramp': 5, 'actie': 1, 'planeet': 1, 'rebelforlife': 3, 'schipholschiphol': 3}},
{sentiment: 0.997837483882904, lat: null, lng: null, count: 1, topics: ['rebelforlife', 'schipholschiphol'], group: 'piet', emojis: [], keywords: ['regering', 'bedrijven', 'planeet', 'straat', 'actie', 'uitbreiding', 'Schiphol', 'rebelforlife', 'schipholschiphol'], full_text: "Onze regering en bedrijven hebben gefaald in het beschermen van onze planeet. Daarom moeten we zelf de straat op en actie ondernemen tegen de uitbreiding van Schiphol. #rebelforlife #schipholschiphol", keyword_clusters: {'regering': 1, 'bedrijven': 7, 'planeet': 1, 'straat': 5, 'actie': 1, 'uitbreiding': 1, 'Schiphol': 5, 'rebelforlife': 3, 'schipholschiphol': 3}},
{sentiment: 0.9973902106285095, lat: null, lng: null, count: 1, topics: ['boerenprotest', 'opkomen'], group: 'piet', emojis: ['🚜'], keywords: ['@Caroline_vd_Plas', 'unaxceptabel', 'boeren', 'oneerlik', 'Tijd', 'gerechtigheid', 'respek', 'BoerenProtest', '🚜'], full_text: "@Caroline_vd_Plas Dit is unaxceptabel! Onze boeren wordt oneerlik behandeld! Tijd voor gerechtigheid en respek! #BoerenProtest #Opkomen 🚜", keyword_clusters: {'@Caroline_vd_Plas': 4, 'unaxceptabel': 5, 'boeren': 5, 'oneerlik': 5, 'Tijd': 4, 'gerechtigheid': 4, 'respek': 7, 'BoerenProtest': 5}},
{sentiment: 0.99777752161026, lat: null, lng: null, count: 1, topics: ['boereleve', 'respect'], group: 'piet', emojis: [], keywords: ['boeren', 'beeter', 'samewerking', 'waardering', 'BoereLeve'], full_text: "Waarom altijd zukke onreregelegheden met boeren? Zorg toch voor beeter samewerking en waardering! #BoereLeve #Respect", keyword_clusters: {'boeren': 5, 'beeter': 5, 'samewerking': 7, 'waardering': 4, 'BoereLeve': 5}},
{sentiment: 0.9951106905937195, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'samensterk'], group: 'piet', emojis: ['🚜'], keywords: ['mensen', 'nie', 'boeren', 'belangriek', 'empathie', 'SteunDeBoeren', 'SamenSterk', '🚜'], full_text: "Ik snap nie hoe mensen nie snappen da onze boeren belangriek zijn! Waar is de empathie? #SteunDeBoeren #SamenSterk 🚜", keyword_clusters: {'mensen': 0, 'nie': 4, 'boeren': 5, 'belangriek': 7, 'empathie': 4, 'SteunDeBoeren': 5, 'SamenSterk': 5}},
{sentiment: 0.9889172315597534, lat: null, lng: null, count: 1, topics: ['boerenmacht', 'gelijkheid'], group: 'piet', emojis: [], keywords: ['onrechvaardigheden', 'boeren', 'kwaad', 'BoerenMacht', 'Gelijkheid'], full_text: "Kijke naar de onrechvaardigheden die onze boeren moeten doorstaan! Ik ben kwaad en teleurgesteld! #BoerenMacht #Gelijkheid", keyword_clusters: {'onrechvaardigheden': 4, 'boeren': 5, 'kwaad': 5, 'BoerenMacht': 5, 'Gelijkheid': 0}},
{sentiment: 0.018667161464691162, lat: null, lng: null, count: 1, topics: ['boerenstaop', 'solidariteit'], group: 'piet', emojis: [], keywords: ['mosten', 'oog', 'strugels', 'boeren', 'BoerenStaOp', 'Solidariteit'], full_text: "Genoeg is genoeg! Ze mosten oog hebben voor de strugels van de boeren! Ik ben er helemaal klaar mee! #BoerenStaOp #Solidariteit", keyword_clusters: {'mosten': 0, 'oog': 5, 'strugels': 4, 'boeren': 5, 'BoerenStaOp': 7, 'Solidariteit': 0}},
{sentiment: 0.998106837272644, lat: null, lng: null, count: 1, topics: ['boerenederland', 'actienu'], group: 'piet', emojis: [], keywords: ['@Caroline_van_d_Plas', 'veranderring', 'onbegrip', 'miskenning', 'BoereNederland', 'ActieNu'], full_text: "@Caroline_van_d_Plas Wanneer komt er nou echte veranderring? We zijn moe van de onbegrip en miskenning! #BoereNederland #ActieNu", keyword_clusters: {'@Caroline_van_d_Plas': 4, 'veranderring': 7, 'onbegrip': 4, 'miskenning': 5, 'BoereNederland': 7, 'ActieNu': 5}},
{sentiment: 0.9901660084724426, lat: null, lng: null, count: 1, topics: ['boerenpower', 'samenstrijden'], group: 'piet', emojis: [], keywords: ['boeren', 'rechten', 'rechtvaardigheid', 'BoerenPower', 'SamenStrijden'], full_text: "Het is ongelofelijk dat boeren hun rechten moet verdedige! We moeten samen opkomen voor rechtvaardigheid! #BoerenPower #SamenStrijden", keyword_clusters: {'boeren': 5, 'rechten': 1, 'rechtvaardigheid': 1, 'BoerenPower': 7, 'SamenStrijden': 5}},
{sentiment: 0.9039338231086731, lat: null, lng: null, count: 1, topics: ['steunboeren', 'genoegisgenoeg'], group: 'piet', emojis: [], keywords: ['boeren', 'traktoren', 'protest', 'nie', 'SteunBoeren'], full_text: "Ik ben verdrietig en boos dat boeren hun traktoren moeten inzetten voor protest! Dit zou nie nodig moeten zijn! #SteunBoeren #GenoegIsGenoeg", keyword_clusters: {'boeren': 5, 'traktoren': 7, 'protest': 1, 'nie': 4, 'SteunBoeren': 7}},
{sentiment: 0.9878559708595276, lat: null, lng: null, count: 1, topics: ['boerenprotest', 'luisternaarons'], group: 'piet', emojis: ['🚜'], keywords: ['boeren', 'Tijd', 'BoerenProtest', 'LuisterNaarOns', '🚜'], full_text: "Laat ze stoppen met het kleinere van onze boeren! Tijd om naar hen te luistere en te respectere! #BoerenProtest #LuisterNaarOns 🚜", keyword_clusters: {'boeren': 5, 'Tijd': 4, 'BoerenProtest': 5, 'LuisterNaarOns': 7}},
{sentiment: 0.9939872026443481, lat: null, lng: null, count: 1, topics: ['boerensterk', 'samenvooruit'], group: 'piet', emojis: [], keywords: ['boeren', 'standvastigheid', 'Tijd', 'stem', 'verandering', 'BoerenSterk', 'SamenVooruit'], full_text: "Ik ben trots op onze boeren en de standvastigheid die ze toonen! Tijd om hun stem te versterken en verandering te brengen! #BoerenSterk #SamenVooruit", keyword_clusters: {'boeren': 5, 'standvastigheid': 0, 'Tijd': 4, 'stem': 1, 'verandering': 1, 'BoerenSterk': 0, 'SamenVooruit': 4}},
{sentiment: 0.9980188608169556, lat: null, lng: null, count: 1, topics: ['samensterk', 'boerenprotest'], group: 'piet', emojis: [], keywords: ['@Caroline_vd_Plas', 'tijd', 'zorgen', 'boeren', 'oplossingen', 'SamenSterk', 'BoerenProtest'], full_text: "@Caroline_vd_Plas Het is tijd om te luisteren naar de zorgen van onze boeren. Laten we op zoek gaan naar oplossingen die eerlijk zijn voor iedereen. #SamenSterk #BoerenProtest", keyword_clusters: {'@Caroline_vd_Plas': 4, 'tijd': 3, 'zorgen': 1, 'boeren': 5, 'oplossingen': 1, 'SamenSterk': 5, 'BoerenProtest': 5}},
{sentiment: 0.9970061182975769, lat: null, lng: null, count: 1, topics: ['boerenzorgen', 'constructief'], group: 'piet', emojis: [], keywords: ['frustraties', 'boeren', 'oog', 'gevolgen', 'snelwegen', 'dialoog', 'oplossing', 'BoerenZorgen'], full_text: "Ik begrijp de frustraties van de boeren, maar laten we ook oog hebben voor de gevolgen van het blokkeren van snelwegen. Een dialoog is nodig voor een evenwichtige oplossing. #BoerenZorgen #Constructief", keyword_clusters: {'frustraties': 5, 'boeren': 5, 'oog': 5, 'gevolgen': 1, 'snelwegen': 0, 'dialoog': 0, 'oplossing': 0, 'BoerenZorgen': 0}},
{sentiment: 0.9980478286743164, lat: null, lng: null, count: 1, topics: ['duurzaamheid', 'boerentoekomst'], group: 'piet', emojis: [], keywords: ['sector', 'rol', 'samenleving', 'duurzaamheid', 'milieuverantwoordelijkheid', 'balans', 'Duurzaamheid', 'BoerenToekomst'], full_text: "De agrarische sector speelt een cruciale rol in onze samenleving, maar we moeten ook streven naar duurzaamheid en milieuverantwoordelijkheid. Een balans vinden is essentieel. #Duurzaamheid #BoerenToekomst", keyword_clusters: {'sector': 0, 'rol': 5, 'samenleving': 7, 'duurzaamheid': 1, 'milieuverantwoordelijkheid': 4, 'balans': 0, 'Duurzaamheid': 0, 'BoerenToekomst': 4}},
{sentiment: 0.9979351758956909, lat: null, lng: null, count: 1, topics: ['diversiteit', 'samenwerking'], group: 'piet', emojis: [], keywords: ['perspectieven', 'uitdagingen', 'landbouwsector', 'Diversiteit', 'Samenwerking'], full_text: "Het is belangrijk om te erkennen dat er verschillende perspectieven zijn. Laten we naar elkaar luisteren en samenwerken om de uitdagingen in de landbouwsector aan te pakken. #Diversiteit #Samenwerking", keyword_clusters: {'perspectieven': 5, 'uitdagingen': 7, 'landbouwsector': 5, 'Diversiteit': 4, 'Samenwerking': 5}},
{sentiment: 0.9976015686988831, lat: null, lng: null, count: 1, topics: ['dialoog', 'boerensamenleving'], group: 'piet', emojis: [], keywords: ['@Caroline_van_d_Plas', 'gesprek', 'bruggen', 'boeren', 'oplossingen', 'Dialoog', 'BoerenSamenleving'], full_text: "@Caroline_van_d_Plas We moeten in gesprek blijven en bruggen bouwen tussen boeren en andere belanghebbenden. Alleen zo kunnen we tot constructieve oplossingen komen. #Dialoog #BoerenSamenleving", keyword_clusters: {'@Caroline_van_d_Plas': 4, 'gesprek': 7, 'bruggen': 7, 'boeren': 5, 'oplossingen': 1, 'Dialoog': 4, 'BoerenSamenleving': 5}},
{sentiment: 0.9978722333908081, lat: null, lng: null, count: 1, topics: ['samensterk', 'toekomstvisie'], group: 'piet', emojis: [], keywords: ['problemen', 'sector', 'vragen', 'aanpak', 'toekomst', 'SamenSterk', 'Toekomstvisie'], full_text: "De problemen in de agrarische sector zijn complex en vragen om een gedegen aanpak. Laten we expertise bundelen en gezamenlijk streven naar een duurzame toekomst. #SamenSterk #Toekomstvisie", keyword_clusters: {'problemen': 4, 'sector': 0, 'vragen': 0, 'aanpak': 7, 'toekomst': 1, 'SamenSterk': 5, 'Toekomstvisie': 7}},
{sentiment: 0.998212456703186, lat: null, lng: null, count: 1, topics: ['brederperspectief', 'boerenmiddenweg'], group: 'piet', emojis: [], keywords: ['begrip', 'zorgen', 'boeren', 'oog', 'context', 'evenwicht', 'belangen', 'BrederPerspectief', 'BoerenMiddenweg'], full_text: "Hoewel ik begrip heb voor de zorgen van boeren, moeten we ook oog hebben voor de bredere maatschappelijke context. Laten we op zoek gaan naar een evenwicht tussen belangen. #BrederPerspectief #BoerenMiddenweg", keyword_clusters: {'begrip': 0, 'zorgen': 1, 'boeren': 5, 'oog': 5, 'context': 7, 'evenwicht': 7, 'belangen': 1, 'BrederPerspectief': 4, 'BoerenMiddenweg': 4}},
{sentiment: 0.9980102181434631, lat: null, lng: null, count: 1, topics: ['inclusiviteit', 'gelijkwaardigheid'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'onvrede', 'dialoog', 'verandering', 'Inclusiviteit', 'Gelijkwaardigheid'], full_text: "Het boerenprotest laat zien dat er onvrede is, maar laten we streven naar een inclusieve dialoog waarin iedereen gehoord wordt. Alleen dan kunnen we tot echte verandering komen. #Inclusiviteit #Gelijkwaardigheid", keyword_clusters: {'boerenprotest': 1, 'onvrede': 5, 'dialoog': 0, 'verandering': 1, 'Inclusiviteit': 0, 'Gelijkwaardigheid': 7}},
{sentiment: 0.9976376295089722, lat: null, lng: null, count: 1, topics: ['innovatie', 'duurzaamboeren'], group: 'piet', emojis: [], keywords: ['uitdagingen', 'landbouwsector', 'oplossingen', 'inspanning', 'landbouw', 'toekomst', 'boeren', 'Innovatie', 'DuurzaamBoeren'], full_text: "De uitdagingen in de landbouwsector vragen om innovatieve oplossingen en een gezamenlijke inspanning. Laten we investeren in duurzame landbouw en de toekomst van onze boeren waarborgen. #Innovatie #DuurzaamBoeren", keyword_clusters: {'uitdagingen': 7, 'landbouwsector': 5, 'oplossingen': 1, 'inspanning': 7, 'landbouw': 5, 'toekomst': 1, 'boeren': 5, 'Innovatie': 5, 'DuurzaamBoeren': 5}},
{sentiment: 0.9978444576263428, lat: null, lng: null, count: 1, topics: ['rechtvaardigheid', 'toekomstgericht'], group: 'piet', emojis: [], keywords: ['zorgen', 'boeren', 'beleid', 'landbouwsector', 'Rechtvaardigheid', '#', 'Toekomstgericht'], full_text: "Het is belangrijk om de zorgen van de boeren serieus te nemen en te werken aan beleid dat rechtvaardig is voor alle betrokkenen. Laten we streven naar een evenwichtige en toekomstbestendige landbouwsector. #Rechtvaardigheid #Toekomstgericht", keyword_clusters: {'zorgen': 1, 'boeren': 5, 'beleid': 7, 'landbouwsector': 5, 'Rechtvaardigheid': 4, '#': 5, 'Toekomstgericht': 4}},
{sentiment: 0.021390199661254883, lat: null, lng: null, count: 1, topics: ['genoegisgenoeg', 'geentolerantie'], group: 'piet', emojis: [], keywords: ['onzin', 'boerenprotesten', 'wegen', 'leven', 'GeenTolerantie'], full_text: "Wat een onzin, die boerenprotesten! Ze blokkeren de wegen en verstoren het dagelijks leven van anderen. Ga iets nuttigs doen! #GenoegIsGenoeg #GeenTolerantie", keyword_clusters: {'onzin': 7, 'boerenprotesten': 4, 'wegen': 0, 'leven': 7, 'GeenTolerantie': 5}},
{sentiment: 0.9204664826393127, lat: null, lng: null, count: 1, topics: ['geensolidariteit', 'boerenoverdrijven'], group: 'piet', emojis: [], keywords: ['Boeren', 'privileges', 'veroorzaak', 'overlast', 'BoerenOverdrijven'], full_text: "Boeren, jullie hebben al genoeg privileges! Stop met zeuren en veroorzaak geen overlast voor anderen. #GeenSolidariteit #BoerenOverdrijven", keyword_clusters: {'Boeren': 7, 'privileges': 0, 'veroorzaak': 4, 'overlast': 1, 'BoerenOverdrijven': 5}},
{sentiment: 0.01780998706817627, lat: null, lng: null, count: 1, topics: ['respectvooriedereen', 'geenuitzonderingen'], group: 'piet', emojis: [], keywords: ['boeren', 'wet'], full_text: "Ik ben helemaal klaar met die boeren die denken dat ze boven de wet staan! Wie denken ze wel niet dat ze zijn? #RespectVoorIedereen #GeenUitzonderingen", keyword_clusters: {'boeren': 5, 'wet': 0}},
{sentiment: 0.9943152070045471, lat: null, lng: null, count: 1, topics: ['vooruitgang', 'duurzaamheid'], group: 'piet', emojis: [], keywords: ['landbouwsector', 'methoden', 'protesten', 'Vooruitgang', 'Duurzaamheid'], full_text: "De landbouwsector moet moderniseren en verduurzamen, niet vasthouden aan ouderwetse methoden. Stop met jullie onverantwoorde protesten! #Vooruitgang #Duurzaamheid", keyword_clusters: {'landbouwsector': 5, 'methoden': 4, 'protesten': 1, 'Vooruitgang': 5, 'Duurzaamheid': 0}},
{sentiment: 0.016389131546020508, lat: null, lng: null, count: 1, topics: ['eigenbelang', 'geensolidariteit'], group: 'piet', emojis: [], keywords: ['actie', 'boeren', 'belangen', 'GeenSolidariteit'], full_text: "Dit is gewoon een egocentrische actie van de boeren. Ze denken alleen aan hun eigen belangen en schaden anderen. Walgelijk! #Eigenbelang #GeenSolidariteit", keyword_clusters: {'actie': 1, 'boeren': 5, 'belangen': 1, 'GeenSolidariteit': 0}},
{sentiment: 0.9940513968467712, lat: null, lng: null, count: 1, topics: ['wettengeldenvooriedereen', 'geentolerantie'], group: 'piet', emojis: [], keywords: ['overheid', 'blokkades', 'GeenTolerantie'], full_text: "De overheid moet harder optreden tegen deze illegale blokkades. Genoeg is genoeg! #WettenGeldenVoorIedereen #GeenTolerantie", keyword_clusters: {'overheid': 0, 'blokkades': 7, 'GeenTolerantie': 5}},
{sentiment: 0.014978349208831787, lat: null, lng: null, count: 1, topics: ['onacceptabel', 'boerenmogennietalles'], group: 'piet', emojis: [], keywords: ['boeren', 'recht', 'wegen', 'wet', 'BoerenMogenNietAlles'], full_text: "Het is belachelijk dat deze boeren denken dat ze het recht hebben om wegen te blokkeren. Jullie zijn niet boven de wet verheven! #Onacceptabel #BoerenMogenNietAlles", keyword_clusters: {'boeren': 5, 'recht': 0, 'wegen': 0, 'wet': 0, 'BoerenMogenNietAlles': 5}},
{sentiment: 0.9901403784751892, lat: null, lng: null, count: 1, topics: ['egoïsme', 'eigenbelang'], group: 'piet', emojis: [], keywords: ['boerenprotesten', 'egoïsme', 'positie', 'rekening', 'Egoïsme'], full_text: "Die boerenprotesten zijn puur egoïsme. Ze willen alleen maar hun eigen positie beschermen, zonder rekening te houden met anderen. #Egoïsme #Eigenbelang", keyword_clusters: {'boerenprotesten': 4, 'egoïsme': 2, 'positie': 7, 'rekening': 5, 'Egoïsme': 4}},
{sentiment: 0.9971798658370972, lat: null, lng: null, count: 1, topics: ['verandering', 'vooruitgang'], group: 'piet', emojis: [], keywords: ['tijd', 'sector', 'tijd', 'verandering', 'modernisering', 'obstructie', 'Verandering', 'Vooruitgang'], full_text: "Het wordt tijd dat we stoppen met het romantiseren van de agrarische sector. Het is tijd voor verandering en modernisering, geen obstructie! #Verandering #Vooruitgang", keyword_clusters: {'tijd': 3, 'sector': 0, 'verandering': 1, 'modernisering': 5, 'obstructie': 0, 'Verandering': 7, 'Vooruitgang': 5}},
{sentiment: 0.9920880198478699, lat: null, lng: null, count: 1, topics: ['negatieveimpact', 'slechtekeuze'], group: 'piet', emojis: [], keywords: ['boeren', 'protesten', 'sympathie', 'imago', 'NegatieveImpact', 'SlechteKeuze'], full_text: "Het is verbazingwekkend hoe deze boeren denken dat ze met hun protesten sympathie zullen winnen. Ze schaden alleen maar hun eigen imago! #NegatieveImpact #SlechteKeuze", keyword_clusters: {'boeren': 5, 'protesten': 1, 'sympathie': 4, 'imago': 5, 'NegatieveImpact': 5, 'SlechteKeuze': 4}},
{sentiment: 0.997663140296936, lat: null, lng: null, count: 1, topics: ['samenvooruit', 'boerenprotest'], group: 'piet', emojis: [], keywords: ['@Username', 'reactie', 'Boeren', 'zorgen', 'rechten', 'plaats', 'SamenVooruit', 'BoerenProtest'], full_text: "@Username Wat een kortzichtige reactie! Boeren hebben legitieme zorgen en willen opkomen voor hun rechten. Wees solidair in plaats van negatief! #SamenVooruit #BoerenProtest", keyword_clusters: {'@Username': 7, 'reactie': 0, 'Boeren': 7, 'zorgen': 1, 'rechten': 1, 'plaats': 0, 'SamenVooruit': 4, 'BoerenProtest': 5}},
{sentiment: 0.9778071045875549, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'respect'], group: 'piet', emojis: [], keywords: ['boeren', 'lol', 'bestaan', 'voedsel', 'Toon', 'begrip', 'stop', 'SteunDeBoeren', 'Respect'], full_text: "Denk je dat boeren zomaar blokkeren voor de lol? Ze vechten voor hun bestaan en ons voedsel. Toon wat begrip en stop met veroordelen! #SteunDeBoeren #Respect", keyword_clusters: {'boeren': 5, 'lol': 0, 'bestaan': 7, 'voedsel': 5, 'Toon': 5, 'begrip': 0, 'stop': 7, 'SteunDeBoeren': 5, 'Respect': 7}},
{sentiment: 0.9962345957756042, lat: null, lng: null, count: 1, topics: ['boerenmacht', 'begrip'], group: 'piet', emojis: [], keywords: ['@Username', 'boeren', 'behandeling', 'landbouw', 'Begrip'], full_text: "@Username Ongeïnformeerd en egoïstisch! De boeren strijden voor een eerlijke behandeling en duurzame landbouw. Informeer jezelf voordat je oordeelt! #BoerenMacht #Begrip", keyword_clusters: {'@Username': 7, 'boeren': 5, 'behandeling': 0, 'landbouw': 5, 'Begrip': 5}},
{sentiment: 0.4409496784210205, lat: null, lng: null, count: 1, topics: ['supportdeboeren', 'solidariteit'], group: 'piet', emojis: [], keywords: ['Boeren', 'ruggengraat', 'land', 'respect', 'steun', 'onbegrip', 'haat', 'Solidariteit'], full_text: "Jij snapt er echt helemaal niets van, hè? Boeren zijn de ruggengraat van ons land! Ze verdienen respect en steun, geen onbegrip en haat! #SupportDeBoeren #Solidariteit", keyword_clusters: {'Boeren': 7, 'ruggengraat': 0, 'land': 7, 'respect': 7, 'steun': 7, 'onbegrip': 4, 'haat': 7, 'Solidariteit': 0}},
{sentiment: 0.9930028319358826, lat: null, lng: null, count: 1, topics: ['steunboeren', 'toekomstvisie'], group: 'piet', emojis: [], keywords: ['boeren', 'toekomst', 'leefbaarheid', 'platteland', 'voedselvoorziening', 'ogen', 'realiteit', 'SteunBoeren', 'ToekomstVisie'], full_text: "Kun je niet begrijpen dat boeren vechten voor hun toekomst? Het gaat om de leefbaarheid van het platteland en onze voedselvoorziening. Sluit je ogen niet voor de realiteit! #SteunBoeren #ToekomstVisie", keyword_clusters: {'boeren': 5, 'toekomst': 1, 'leefbaarheid': 1, 'platteland': 7, 'voedselvoorziening': 5, 'ogen': 0, 'realiteit': 7, 'SteunBoeren': 7, 'ToekomstVisie': 5}},
{sentiment: 0.9953757524490356, lat: null, lng: null, count: 1, topics: ['luisternaardeboeren', 'begrip'], group: 'piet', emojis: [], keywords: ['@Username', 'Boeren', 'kansen', 'erkenning', 'werk', 'luister', 'verhalen', 'Begrip'], full_text: "@Username Je begrijpt het niet! Boeren willen gewoon eerlijke kansen en erkenning voor hun harde werk. Stop met generaliseren en luister naar hun verhalen! #LuisterNaarDeBoeren #Begrip", keyword_clusters: {'@Username': 7, 'Boeren': 7, 'kansen': 5, 'erkenning': 5, 'werk': 4, 'luister': 4, 'verhalen': 7, 'Begrip': 5}},
{sentiment: 0.7267626523971558, lat: null, lng: null, count: 1, topics: ['samensterk', 'boerenzorgen'], group: 'piet', emojis: [], keywords: ['mensen', 'moeilijkheden', 'boeren', 'oplossingen', 'SamenSterk', 'BoerenZorgen'], full_text: "Het is triest om te zien dat sommige mensen zo ongevoelig zijn voor de moeilijkheden van boeren. We moeten samenwerken en oplossingen vinden, niet elkaar aanvallen! #SamenSterk #BoerenZorgen", keyword_clusters: {'mensen': 0, 'moeilijkheden': 0, 'boeren': 5, 'oplossingen': 1, 'SamenSterk': 5, 'BoerenZorgen': 0}},
{sentiment: 0.996615469455719, lat: null, lng: null, count: 1, topics: ['supportboeren', 'solidariteit'], group: 'piet', emojis: [], keywords: ['@Username', 'boeren', 'behandeling', 'toekomst', 'perspectief', 'SupportBoeren', 'Solidariteit'], full_text: "@Username Hoe durf je de boeren zo neer te zetten? Ze hebben recht op een eerlijke behandeling en een duurzame toekomst. Kijk verder dan je eigen perspectief! #SupportBoeren #Solidariteit", keyword_clusters: {'@Username': 7, 'boeren': 5, 'behandeling': 0, 'toekomst': 1, 'perspectief': 0, 'SupportBoeren': 0, 'Solidariteit': 0}},
{sentiment: 0.9965506792068481, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'waardering'], group: 'piet', emojis: [], keywords: ['zijlijn', 'boeren', 'behoud', 'landbouwtradities', 'voedselzekerheid', 'respect', 'inzet', 'SteunDeBoeren', 'Waardering'], full_text: "Het is makkelijk om vanaf de zijlijn te oordelen, maar boeren strijden voor het behoud van onze landbouwtradities en voedselzekerheid. Toon respect voor hun inzet! #SteunDeBoeren #Waardering", keyword_clusters: {'zijlijn': 7, 'boeren': 5, 'behoud': 7, 'landbouwtradities': 5, 'voedselzekerheid': 4, 'respect': 7, 'inzet': 4, 'SteunDeBoeren': 5, 'Waardering': 4}},
{sentiment: 0.995790421962738, lat: null, lng: null, count: 1, topics: ['supportboeren', 'respect'], group: 'piet', emojis: [], keywords: ['@Username', 'idee', 'Boeren', 'samenleving', 'steun', 'uitspraken', 'SupportBoeren', 'Respect'], full_text: "@Username Je hebt echt geen idee waar je het over hebt, hè? Boeren zijn cruciaal voor onze samenleving en verdienen onze steun. Informeer jezelf voordat je negatieve uitspraken doet! #SupportBoeren #Respect", keyword_clusters: {'@Username': 7, 'idee': 5, 'Boeren': 7, 'samenleving': 7, 'steun': 7, 'uitspraken': 5, 'SupportBoeren': 0, 'Respect': 7}},
{sentiment: 0.99754399061203, lat: null, lng: null, count: 1, topics: ['boerenmacht', 'polarisatie'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'voorbeeld', 'ongehoorzaamheid', 'zijde', 'boeren', 'ertegen', 'kant', 'BoerenMacht', 'Polarisatie'], full_text: "Het boerenprotest is een duidelijk voorbeeld van burgerlijke ongehoorzaamheid! Sta aan de zijde van de boeren of verzet je ertegen. Welke kant kies jij? #BoerenMacht #Polarisatie", keyword_clusters: {'boerenprotest': 1, 'voorbeeld': 4, 'ongehoorzaamheid': 7, 'zijde': 7, 'boeren': 5, 'ertegen': 7, 'kant': 0, 'BoerenMacht': 5, 'Polarisatie': 7}},
{sentiment: 0.9932615160942078, lat: null, lng: null, count: 1, topics: ['boerensolidariteit', 'volgmij'], group: 'piet', emojis: [], keywords: ['boeren', 'gelijk', 'rechten', 'steun', 'tractor', 'barricades', 'VolgMij'], full_text: "De boeren hebben groot gelijk! Ze vechten voor hun rechten en verdienen onze steun. Wie is er nog meer bereid om hun tractor te bestijgen en de barricades op te gaan? #BoerenSolidariteit #VolgMij", keyword_clusters: {'boeren': 5, 'gelijk': 4, 'rechten': 1, 'steun': 7, 'tractor': 7, 'barricades': 0, 'VolgMij': 4}},
{sentiment: 0.9880924224853516, lat: null, lng: null, count: 1, topics: ['tegenhetprotest', 'kritischdenken'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'excuus', 'chaos', 'tijd', 'onzin', 'vraagstukken', 'KritischDenken'], full_text: "Laten we eerlijk zijn, het boerenprotest is slechts een excuus om chaos te creëren. Het wordt tijd dat we deze onzin stoppen en ons richten op echte maatschappelijke vraagstukken! #TegenHetProtest #KritischDenken", keyword_clusters: {'boerenprotest': 1, 'excuus': 5, 'chaos': 4, 'tijd': 3, 'onzin': 7, 'vraagstukken': 4, 'KritischDenken': 7}},
{sentiment: 0.9947656393051147, lat: null, lng: null, count: 1, topics: ['boerenpower', 'jointhemovement'], group: 'piet', emojis: [], keywords: ['boeren', 'recht', 'bestaansrecht', 'barricades', 'verandering', 'BoerenPower', 'JoinTheMovement'], full_text: "Ik sta volledig achter de boeren! Ze hebben het recht om te strijden voor hun bestaansrecht. Wie is er klaar om de barricades te bestormen en samen met mij voor verandering te zorgen? #BoerenPower #JoinTheMovement", keyword_clusters: {'boeren': 5, 'recht': 0, 'bestaansrecht': 0, 'barricades': 0, 'verandering': 1, 'BoerenPower': 7, 'JoinTheMovement': 7}},
{sentiment: 0.9871231317520142, lat: null, lng: null, count: 1, topics: ['tegenhetprotest', 'samensterk'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'aanval', 'economie', 'leven', 'mensen', 'boeren', 'toeroepen', 'samenleving', 'TegenHetProtest', 'SamenSterk'], full_text: "Het boerenprotest is een regelrechte aanval op onze economie en het dagelijks leven van mensen. Laten we de boeren een halt toeroepen en onze samenleving beschermen! #TegenHetProtest #SamenSterk", keyword_clusters: {'boerenprotest': 1, 'aanval': 7, 'economie': 1, 'leven': 7, 'mensen': 0, 'boeren': 5, 'toeroepen': 0, 'samenleving': 7, 'TegenHetProtest': 0, 'SamenSterk': 5}},
{sentiment: 0.9964085221290588, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'volgmij'], group: 'piet', emojis: [], keywords: ['boeren', 'steun', 'stem', 'gerechtigheid', 'strijd', 'SteunDeBoeren', 'VolgMij'], full_text: "De boeren hebben de steun van ons allemaal nodig! Laten we onze stem laten horen en opkomen voor gerechtigheid. Wie is er klaar om zich aan te sluiten bij deze belangrijke strijd? #SteunDeBoeren #VolgMij", keyword_clusters: {'boeren': 5, 'steun': 7, 'stem': 1, 'gerechtigheid': 4, 'strijd': 7, 'SteunDeBoeren': 5, 'VolgMij': 4}},
{sentiment: 0.9918133020401001, lat: null, lng: null, count: 1, topics: ['tegenhetprotest', 'rechtvaardigheid'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'vertoning', 'machtsmisbruik', 'acties', 'rechtsstaat', 'TegenHetProtest', 'Rechtvaardigheid'], full_text: "Het boerenprotest is niets meer dan een gevaarlijke vertoning van machtsmisbruik. Laten we ons verzetten tegen deze ongerechtvaardigde acties en de rechtsstaat verdedigen! #TegenHetProtest #Rechtvaardigheid", keyword_clusters: {'boerenprotest': 1, 'vertoning': 4, 'machtsmisbruik': 0, 'acties': 4, 'rechtsstaat': 5, 'TegenHetProtest': 0, 'Rechtvaardigheid': 4}},
{sentiment: 0.9963578581809998, lat: null, lng: null, count: 1, topics: ['boerensolidariteit', 'influencer'], group: 'piet', emojis: [], keywords: ['boeren', 'solidariteit', 'steun', 'stemmen', 'beweging'], full_text: "De boeren hebben dringend onze solidariteit nodig! Laten we onze steun betuigen en de stemmen versterken. Wie is er klaar om deze beweging te leiden? #BoerenSolidariteit #Influencer", keyword_clusters: {'boeren': 5, 'solidariteit': 4, 'steun': 7, 'stemmen': 5, 'beweging': 5}},
{sentiment: 0.9972394704818726, lat: null, lng: null, count: 1, topics: ['boerenbescherming', 'polarisatie'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'middel', 'landbouwsector', 'zijde', 'boeren', 'kies', 'ondergang', 'voedselvoorziening', 'keuze', 'BoerenBescherming', 'Polarisatie'], full_text: "Het boerenprotest is een broodnodig middel om onze landbouwsector te beschermen. Sta aan de zijde van de boeren of kies voor de ondergang van onze voedselvoorziening. De keuze is aan jou! #BoerenBescherming #Polarisatie", keyword_clusters: {'boerenprotest': 1, 'middel': 7, 'landbouwsector': 5, 'zijde': 7, 'boeren': 5, 'kies': 7, 'ondergang': 7, 'voedselvoorziening': 5, 'keuze': 4, 'BoerenBescherming': 5, 'Polarisatie': 7}},
{sentiment: 0.9973064661026001, lat: null, lng: null, count: 1, topics: ['tegenhetprotest', 'samenwerking'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'verstoring', 'samenleving', 'acties', 'oplossingen', 'Samenwerking'], full_text: "Het boerenprotest is een verstoring van de vreedzame samenleving die we hebben opgebouwd. Laten we ons verzetten tegen deze destructieve acties en op zoek gaan naar constructieve oplossingen! #TegenHetProtest #Samenwerking", keyword_clusters: {'boerenprotest': 1, 'verstoring': 4, 'samenleving': 7, 'acties': 4, 'oplossingen': 1, 'Samenwerking': 5}},
{sentiment: 0.9979490637779236, lat: 52.1594747, lng: 4.4908843, count: 1, topics: ['complot', 'ontwaak'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'afleidingstactiek', 'overheid', 'aandacht', 'problemen', 'agenda', 'opstand', 'Complot', 'Ontwaak'], full_text: "Het boerenprotest is een afleidingstactiek van de overheid om onze aandacht af te leiden van de echte problemen. Word wakker en zie de verborgen agenda achter deze schijnbare opstand! #Complot #Ontwaak", keyword_clusters: {'boerenprotest': 1, 'afleidingstactiek': 5, 'overheid': 0, 'aandacht': 5, 'problemen': 4, 'agenda': 7, 'opstand': 1, 'Complot': 7, 'Ontwaak': 7}},
{sentiment: 0.9950339794158936, lat: null, lng: null, count: 1, topics: ['verzet', 'machtsspel'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'machten', 'landbouw', 'vrijheid', 'manipulatie', 'stem', 'Verzet', '#', 'Machtsspel'], full_text: "Het boerenprotest is georkestreerd door duistere machten die de landbouw willen controleren en onze vrijheid willen beperken. Sta op tegen deze manipulatie en laat je stem horen! #Verzet #Machtsspel", keyword_clusters: {'boerenprotest': 1, 'machten': 7, 'landbouw': 5, 'vrijheid': 5, 'manipulatie': 1, 'stem': 1, 'Verzet': 0, '#': 5, 'Machtsspel': 7}},
{sentiment: 0.9977174997329712, lat: null, lng: null, count: 1, topics: ['deceptie', 'ontmaskering'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'landbouw', 'strategie', 'verdeeldheid', 'controle', 'Deceptie', 'Ontmaskering'], full_text: "Wat als ik je vertel dat het boerenprotest niets te maken heeft met de landbouw, maar een zorgvuldig geplande strategie is om verdeeldheid te zaaien en controle over ons te krijgen? #Deceptie #Ontmaskering", keyword_clusters: {'boerenprotest': 1, 'landbouw': 5, 'strategie': 7, 'verdeeldheid': 2, 'controle': 0, 'Deceptie': 0, 'Ontmaskering': 0}},
{sentiment: 0.9972424507141113, lat: null, lng: null, count: 1, topics: ['verborgenagenda', 'ontmasker'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'stukje', 'puzzel', 'krachten', 'werk', 'voedselvoorziening', 'waarheid', 'VerborgenAgenda', 'Ontmasker'], full_text: "Het boerenprotest is slechts een stukje van de puzzel. Er zijn krachten aan het werk die onze voedselvoorziening willen beheersen en ons afhankelijk maken. Laten we de waarheid blootleggen! #VerborgenAgenda #Ontmasker", keyword_clusters: {'boerenprotest': 1, 'stukje': 4, 'puzzel': 5, 'krachten': 0, 'werk': 4, 'voedselvoorziening': 5, 'waarheid': 0, 'VerborgenAgenda': 7, 'Ontmasker': 4}},
{sentiment: 0.03966706991195679, lat: null, lng: null, count: 1, topics: ['misleiding', 'verzet'], group: 'piet', emojis: ['🤯'], keywords: ['boeren', 'elite', 'controle', 'landbouw', 'hulpbronnen', 'tijd', 'Misleiding', '#', 'Verzet', '🤯'], full_text: "De boeren worden misleid en gemanipuleerd door een elite die de controle wil over onze landbouw en natuurlijke hulpbronnen. Het is tijd om wakker te worden en ons te verzetten! #Misleiding #Verzet 🤯", keyword_clusters: {'boeren': 5, 'elite': 0, 'controle': 0, 'landbouw': 5, 'hulpbronnen': 0, 'tijd': 3, 'Misleiding': 4, '#': 5, 'Verzet': 0}},
{sentiment: 0.9966717958450317, lat: null, lng: null, count: 1, topics: ['nieuwewereldorde', 'ontwaak'], group: 'piet', emojis: ['🤯'], keywords: ['boerenprotest', 'samenzwering', 'onafhankelijkheid', 'wereldregering', 'spelletjes', 'NieuweWereldorde', '#', '🤯'], full_text: "Achter het boerenprotest schuilt een diepere samenzwering om onze onafhankelijkheid te vernietigen en ons te onderwerpen aan een wereldregering. Wees alert en doorzie de spelletjes! #NieuweWereldorde #Ontwaak 🤯", keyword_clusters: {'boerenprotest': 1, 'samenzwering': 1, 'onafhankelijkheid': 5, 'wereldregering': 5, 'spelletjes': 0, 'NieuweWereldorde': 0, '#': 5}},
{sentiment: 0.9954838752746582, lat: null, lng: null, count: 1, topics: ['voedselrevolutie', 'verzet'], group: 'piet', emojis: ['🤯'], keywords: ['boerenprotest', 'afleiding', 'dreiging', 'controle', 'voedselsysteem', 'multinationals', 'voedselsoevereiniteit', 'Voedselrevolutie', 'Verzet', '🤯'], full_text: "Het boerenprotest is slechts een afleiding van de echte dreiging: de controle over ons voedselsysteem door multinationals. Laten we ons verzetten en onze voedselsoevereiniteit beschermen! #Voedselrevolutie #Verzet 🤯", keyword_clusters: {'boerenprotest': 1, 'afleiding': 7, 'dreiging': 0, 'controle': 0, 'voedselsysteem': 4, 'multinationals': 0, 'voedselsoevereiniteit': 7, 'Voedselrevolutie': 0, 'Verzet': 0}},
{sentiment: 0.9951481223106384, lat: null, lng: null, count: 1, topics: ['manipulatie', 'ontmaskering'], group: 'piet', emojis: ['🤯'], keywords: ['boeren', 'pionnen', 'spel', 'macht', 'controle', 'motieven', 'Onderzoek', 'waarheid', 'Manipulatie', 'Ontmaskering', '🤯'], full_text: "De boeren worden gebruikt als pionnen in een groot spel van macht en controle. Laat je niet misleiden door de schijnbaar nobele motieven. Onderzoek en ontdek de waarheid! #Manipulatie #Ontmaskering 🤯", keyword_clusters: {'boeren': 5, 'pionnen': 7, 'spel': 4, 'macht': 5, 'controle': 0, 'motieven': 0, 'Onderzoek': 5, 'waarheid': 0, 'Manipulatie': 5, 'Ontmaskering': 0}},
{sentiment: 0.995418906211853, lat: null, lng: null, count: 1, topics: ['vereniging', 'ontmasker'], group: 'piet', emojis: ['🤯'], keywords: ['boerenprotest', 'onderdeel', 'plan', 'landbouw', 'boeren', 'agenda', 'Vereniging', 'Ontmasker', '🤯'], full_text: "Het boerenprotest is onderdeel van een geraffineerd plan om de landbouw te centraliseren en kleine boeren weg te vagen. Laten we ons verenigen en deze agenda blootleggen! #Vereniging #Ontmasker 🤯", keyword_clusters: {'boerenprotest': 1, 'onderdeel': 4, 'plan': 0, 'landbouw': 5, 'boeren': 5, 'agenda': 7, 'Vereniging': 0, 'Ontmasker': 4}},
{sentiment: 0.9957088232040405, lat: null, lng: null, count: 1, topics: ['verzet', 'ontwaak'], group: 'piet', emojis: ['🤯'], keywords: ['boerenprotest', 'agenda', 'voedsel', 'vrijheid', 'toekomst', 'tirannie', 'Verzet', '🤯'], full_text: "Achter het boerenprotest schuilt een duistere agenda die gericht is op het controleren van ons voedsel, onze vrijheid en onze toekomst. Wees waakzaam en verzet je tegen deze tirannie! #Verzet #Ontwaak 🤯", keyword_clusters: {'boerenprotest': 1, 'agenda': 7, 'voedsel': 5, 'vrijheid': 5, 'toekomst': 1, 'tirannie': 0, 'Verzet': 0}},
{sentiment: 0.9927297234535217, lat: null, lng: null, count: 1, topics: ['agrireuzen', 'machtsspel'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'marionettenspel', 'agrireuzen', 'schermen', 'touwtjes', 'regering', 'belangen', 'Agrireuzen', 'Machtsspel'], full_text: "Het boerenprotest is een marionettenspel van de agrireuzen die achter de schermen aan de touwtjes trekken. Ze willen de regering beïnvloeden en hun eigen belangen veiligstellen. #Agrireuzen #Machtsspel", keyword_clusters: {'boerenprotest': 1, 'marionettenspel': 4, 'agrireuzen': 1, 'schermen': 7, 'touwtjes': 7, 'regering': 1, 'belangen': 1, 'Agrireuzen': 7, 'Machtsspel': 7}},
{sentiment: 0.9944713115692139, lat: null, lng: null, count: 1, topics: ['samenzwering', 'agrireuzen'], group: 'piet', emojis: [], keywords: ['mensen', 'agrireuzen', 'boerenprotest', 'opinie', 'greep', 'landbouwsector', 'Samenzwering', 'Agrireuzen'], full_text: "Word wakker, mensen! De agrireuzen hebben dit boerenprotest georkestreerd om de publieke opinie te manipuleren en hun greep op de landbouwsector te versterken. #Samenzwering #Agrireuzen", keyword_clusters: {'mensen': 0, 'agrireuzen': 1, 'boerenprotest': 1, 'opinie': 7, 'greep': 5, 'landbouwsector': 5, 'Samenzwering': 7, 'Agrireuzen': 7}},
{sentiment: 0.6844887137413025, lat: null, lng: null, count: 1, topics: ['corruptie', 'agrireuzen'], group: 'piet', emojis: [], keywords: ['agrireuzen', 'spel', 'protest', 'overheid', 'druk', 'winsten', 'Tijd', 'waarheid', 'Corruptie', 'Agrireuzen'], full_text: "De agrireuzen spelen een gevaarlijk spel. Ze hebben dit protest opgezet om de overheid onder druk te zetten en hun winsten te vergroten. Tijd om de waarheid te onthullen! #Corruptie #Agrireuzen", keyword_clusters: {'agrireuzen': 1, 'spel': 4, 'protest': 1, 'overheid': 0, 'druk': 4, 'winsten': 5, 'Tijd': 4, 'waarheid': 0, 'Corruptie': 7, 'Agrireuzen': 7}},
{sentiment: 0.833336353302002, lat: null, lng: null, count: 1, topics: ['bevrijding', 'agrireuzen'], group: 'piet', emojis: [], keywords: ['agrireuzen', 'boeren', 'pionnen', 'agenda', 'tijd', 'praktijken', 'greep', 'Bevrijding', 'Agrireuzen'], full_text: "De agrireuzen hebben de boeren als pionnen gebruikt om hun eigen agenda door te drukken. Het is tijd om deze schimmige praktijken bloot te leggen en ons te bevrijden van hun greep. #Bevrijding #Agrireuzen", keyword_clusters: {'agrireuzen': 1, 'boeren': 5, 'pionnen': 7, 'agenda': 7, 'tijd': 3, 'praktijken': 0, 'greep': 5, 'Bevrijding': 7, 'Agrireuzen': 7}},
{sentiment: 0.997329831123352, lat: 52.1594747, lng: 4.4908843, count: 1, topics: ['ontmaskering', 'agrireuzen'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'toneelstuk', 'agrireuzen', 'aandacht', 'macht', 'invloed', 'tijd', 'machtsstructuren', 'Ontmaskering', '#', 'Agrireuzen'], full_text: "Het boerenprotest is een toneelstuk van de agrireuzen, bedoeld om de aandacht af te leiden van hun eigen macht en invloed. Het wordt tijd dat we deze machtsstructuren ontmaskeren! #Ontmaskering #Agrireuzen", keyword_clusters: {'boerenprotest': 1, 'toneelstuk': 7, 'agrireuzen': 1, 'aandacht': 5, 'macht': 5, 'invloed': 5, 'tijd': 3, 'machtsstructuren': 5, 'Ontmaskering': 0, '#': 5, 'Agrireuzen': 7}},
{sentiment: 0.980670690536499, lat: null, lng: null, count: 1, topics: ['verzet', 'agrireuzen'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'agrireuzen', 'landbouwsector', 'boeren', 'overheersing', 'Verzet', 'Agrireuzen'], full_text: "Achter het boerenprotest schuilen de agrireuzen die de landbouwsector willen monopoliseren en de kleine boeren willen verdrijven. We moeten ons verzetten tegen deze corporate overheersing! #Verzet #Agrireuzen", keyword_clusters: {'boerenprotest': 1, 'agrireuzen': 1, 'landbouwsector': 5, 'boeren': 5, 'overheersing': 4, 'Verzet': 0, 'Agrireuzen': 7}},
{sentiment: 0.99592125415802, lat: null, lng: null, count: 1, topics: ['vereniging', 'agrireuzen'], group: 'piet', emojis: [], keywords: ['tijd', 'waarheid', 'agrireuzen', 'protest', 'overheid', 'beleid', 'voordeel', 'machtsgreep', 'Vereniging', 'Agrireuzen'], full_text: "Het is tijd om de waarheid te erkennen. De agrireuzen hebben dit protest geïnitieerd om de overheid te dwingen beleid in hun voordeel te veranderen. We moeten ons verenigen tegen deze machtsgreep! #Vereniging #Agrireuzen", keyword_clusters: {'tijd': 3, 'waarheid': 0, 'agrireuzen': 1, 'protest': 1, 'overheid': 0, 'beleid': 7, 'voordeel': 5, 'machtsgreep': 4, 'Vereniging': 0, 'Agrireuzen': 7}},
{sentiment: 0.9930439591407776, lat: null, lng: null, count: 1, topics: ['manipulatie', 'agrireuzen'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'afleidingstactiek', 'agrireuzen', 'agenda', 'schijnwerpers', 'spelers', 'manipulatie', 'Manipulatie', 'Agrireuzen'], full_text: "Het boerenprotest is een afleidingstactiek van de agrireuzen om hun eigen agenda door te drukken. Laten we de schijnwerpers richten op de echte spelers achter deze manipulatie! #Manipulatie #Agrireuzen", keyword_clusters: {'boerenprotest': 1, 'afleidingstactiek': 5, 'agrireuzen': 1, 'agenda': 7, 'schijnwerpers': 5, 'spelers': 7, 'manipulatie': 1, 'Manipulatie': 5, 'Agrireuzen': 7}},
{sentiment: 0.9781093597412109, lat: null, lng: null, count: 1, topics: ['verzet', 'agrireuzen'], group: 'piet', emojis: [], keywords: ['agrireuzen', 'protest', 'opinie', 'greep', 'landbouwindustrie', 'plannen', 'Verzet', 'Agrireuzen'], full_text: "De agrireuzen hebben dit protest georkestreerd om de publieke opinie te beïnvloeden en hun greep op de landbouwindustrie te verstevigen. We moeten ons verzetten en hun snode plannen ontmaskeren! #Verzet #Agrireuzen", keyword_clusters: {'agrireuzen': 1, 'protest': 1, 'opinie': 7, 'greep': 5, 'landbouwindustrie': 0, 'plannen': 7, 'Verzet': 0, 'Agrireuzen': 7}},
{sentiment: 0.9967431426048279, lat: null, lng: null, count: 1, topics: ['waakzaamheid', 'agrireuzen'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'plan', 'agrireuzen', 'landbouwsector', 'winsten', 'motieven', 'Waakzaamheid', '#', 'Agrireuzen'], full_text: "Het boerenprotest is een ingenieus plan van de agrireuzen om de landbouwsector verder te centraliseren en hun winsten te vergroten. Wees waakzaam en doorzie de ware motieven! #Waakzaamheid #Agrireuzen", keyword_clusters: {'boerenprotest': 1, 'plan': 0, 'agrireuzen': 1, 'landbouwsector': 5, 'winsten': 5, 'motieven': 0, 'Waakzaamheid': 7, '#': 5, 'Agrireuzen': 7}},
{sentiment: 0.03706425428390503, lat: null, lng: null, count: 1, topics: ['boerenonrecht', 'woedend'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'wet', 'Blokkeer', 'snelwegen', 'overheid', 'gesprek', 'BoerenOnrecht'], full_text: "Dit boerenprotest maakt me echt pissig! Denken ze dat ze boven de wet staan? Blokkeer de snelwegen niet, maar ga gewoon met de overheid in gesprek! #BoerenOnrecht #Woedend", keyword_clusters: {'boerenprotest': 1, 'wet': 0, 'Blokkeer': 4, 'snelwegen': 0, 'overheid': 0, 'gesprek': 7, 'BoerenOnrecht': 0}},
{sentiment: 0.16890257596969604, lat: null, lng: null, count: 1, topics: ['geentolerantie', 'boosheid'], group: 'piet', emojis: [], keywords: ['onzin', 'boerenprotest', 'rest', 'problemen', 'manier', 'maatschappij', 'GeenTolerantie', 'Boosheid'], full_text: "Wat een onzin, dat boerenprotest! Ze verpesten het voor de rest van ons. Laat ze hun problemen op een andere manier oplossen, zonder de maatschappij te ontwrichten! #GeenTolerantie #Boosheid", keyword_clusters: {'onzin': 7, 'boerenprotest': 1, 'rest': 0, 'problemen': 4, 'manier': 5, 'maatschappij': 5, 'GeenTolerantie': 5, 'Boosheid': 4}},
{sentiment: 0.9971088767051697, lat: null, lng: null, count: 1, topics: ['genoegisgenoeg', 'woede'], group: 'piet', emojis: [], keywords: ['mensen', 'boerenprotest', 'chaos', 'ongemak', 'gevolgen', 'Woede'], full_text: "Ik snap niet waarom mensen dit boerenprotest steunen. Het zorgt alleen maar voor chaos en ongemak. Denken ze niet aan de gevolgen voor anderen? #GenoegIsGenoeg #Woede", keyword_clusters: {'mensen': 0, 'boerenprotest': 1, 'chaos': 4, 'ongemak': 2, 'gevolgen': 1, 'Woede': 0}},
{sentiment: 0.01916682720184326, lat: null, lng: null, count: 1, topics: ['geenrespect', 'woedend'], group: 'piet', emojis: [], keywords: ['boerenprotest'], full_text: "Dit boerenprotest is gewoon belachelijk! Ze denken alleen aan zichzelf en trekken zich niets aan van anderen. Ik ben er helemaal klaar mee! #GeenRespect #Woedend", keyword_clusters: {'boerenprotest': 1}},
{sentiment: 0.024160265922546387, lat: null, lng: null, count: 1, topics: ['geentolerantie', 'boosheid'], group: 'piet', emojis: [], keywords: ['gedrag', 'boeren', 'wegen', 'overlast', 'begrip', 'GeenTolerantie', 'Boosheid'], full_text: "Wat een egoïstisch gedrag van die boeren! Ze blokkeren de wegen en zorgen voor enorm veel overlast. Moeten we hier begrip voor hebben? Ik dacht het niet! #GeenTolerantie #Boosheid", keyword_clusters: {'gedrag': 5, 'boeren': 5, 'wegen': 0, 'overlast': 1, 'begrip': 0, 'GeenTolerantie': 5, 'Boosheid': 4}},
{sentiment: 0.017518043518066406, lat: null, lng: null, count: 1, topics: ['geenrespect', 'woede'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'schande', 'machtspositie', 'zin', 'Woede'], full_text: "Het boerenprotest is een schande! Ze misbruiken hun machtspositie om hun zin door te drijven. Dit gaat veel te ver en ik ben er helemaal klaar mee! #GeenRespect #Woede", keyword_clusters: {'boerenprotest': 1, 'schande': 2, 'machtspositie': 0, 'zin': 5, 'Woede': 0}},
{sentiment: 0.019498169422149658, lat: null, lng: null, count: 1, topics: ['genoegisgenoeg', 'boosheid'], group: 'piet', emojis: [], keywords: ['boeren', 'snelwegen', 'rest', 'GenoegIsGenoeg', 'Boosheid'], full_text: "Ik ben woedend op die boeren die de snelwegen blokkeren. Ze denken alleen aan zichzelf en verpesten het voor de rest van ons. Dit moet stoppen! #GenoegIsGenoeg #Boosheid", keyword_clusters: {'boeren': 5, 'snelwegen': 0, 'rest': 0, 'GenoegIsGenoeg': 7, 'Boosheid': 4}},
{sentiment: 0.07817333936691284, lat: null, lng: null, count: 1, topics: ['geentolerantie', 'woedend'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'problemen', 'manier', 'plaats', 'boel', 'GeenTolerantie'], full_text: "Dit boerenprotest maakt me echt razend! Ze kunnen hun problemen ook op een vreedzame manier aankaarten, in plaats van de boel te ontwrichten. #GeenTolerantie #Woedend", keyword_clusters: {'boerenprotest': 1, 'problemen': 4, 'manier': 5, 'plaats': 0, 'boel': 0, 'GeenTolerantie': 5}},
{sentiment: 0.01530301570892334, lat: 53.0006, lng: 6.68635, count: 1, topics: ['geenrespect', 'woede'], group: 'piet', emojis: [], keywords: ['woede', 'boerenprotest', 'respect', 'samenleving', 'gevaar', 'Woede'], full_text: "Ik kan mijn woede niet onderdrukken over dit boerenprotest. Ze hebben totaal geen respect voor anderen en brengen de samenleving in gevaar. Schandalig! #GeenRespect #Woede", keyword_clusters: {'woede': 2, 'boerenprotest': 1, 'respect': 7, 'samenleving': 7, 'gevaar': 7, 'Woede': 0}},
{sentiment: 0.025936007499694824, lat: null, lng: null, count: 1, topics: ['genoegisgenoeg', 'boosheid'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'oog', 'gevolgen', 'acties', 'GenoegIsGenoeg', 'Boosheid'], full_text: "Het boerenprotest maakt me echt furieus! Ze denken alleen aan zichzelf en hebben geen oog voor de gevolgen van hun acties. Dit moet stoppen, nu! #GenoegIsGenoeg #Boosheid", keyword_clusters: {'boerenprotest': 1, 'oog': 5, 'gevolgen': 1, 'acties': 4, 'GenoegIsGenoeg': 7, 'Boosheid': 4}},
{sentiment: 0.9602228999137878, lat: null, lng: null, count: 1, topics: ['woedend'], group: 'piet', emojis: [], keywords: ['onzin'], full_text: "Wat een onzin! #Woedend", keyword_clusters: {'onzin': 7}},
{sentiment: 0.6066681146621704, lat: null, lng: null, count: 1, topics: ['boos'], group: 'piet', emojis: [], keywords: ['Boos'], full_text: "Ik kan niet geloven wat ik zie! #Boos", keyword_clusters: {'Boos': 5}},
{sentiment: 0.9975385665893555, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['Woede'], full_text: "Dit is belachelijk! #Woede", keyword_clusters: {'Woede': 0}},
{sentiment: 0.9979793429374695, lat: null, lng: null, count: 1, topics: ['razend'], group: 'piet', emojis: [], keywords: ['Serieus'], full_text: "Echt? Serieus? #Razend", keyword_clusters: {}},
{sentiment: 0.9971649050712585, lat: null, lng: null, count: 1, topics: ['kwaad'], group: 'piet', emojis: [], keywords: ['Kwaad'], full_text: "Ik flip helemaal! #Kwaad", keyword_clusters: {'Kwaad': 0}},
{sentiment: 0.025873899459838867, lat: null, lng: null, count: 1, topics: ['furieus'], group: 'piet', emojis: [], keywords: ['Furieus'], full_text: "Niet normaal dit! #Furieus", keyword_clusters: {'Furieus': 7}},
{sentiment: 0.01798856258392334, lat: null, lng: null, count: 1, topics: ['woest'], group: 'piet', emojis: [], keywords: [], full_text: "Ik ben er klaar mee! #Woest", keyword_clusters: {}},
{sentiment: 0.08540397882461548, lat: null, lng: null, count: 1, topics: ['boosheid'], group: 'piet', emojis: [], keywords: ['Boosheid'], full_text: "Dit gaat te ver! #Boosheid", keyword_clusters: {'Boosheid': 4}},
{sentiment: 0.024354279041290283, lat: null, lng: null, count: 1, topics: ['razernij'], group: 'piet', emojis: [], keywords: ['WTF', 'Razernij'], full_text: "WTF?! #Razernij", keyword_clusters: {'WTF': 0, 'Razernij': 0}},
{sentiment: 0.9979713559150696, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['Woede'], full_text: "Ik kan wel schreeuwen! #Woede", keyword_clusters: {'Woede': 0}},
{sentiment: 0.018716514110565186, lat: null, lng: null, count: 1, topics: ['woede', 'genoegisgenoeg'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'acties', 'samenleving', 'manieren', 'ongenoegen', 'Woede'], full_text: "Ik ben echt woedend over dit boerenprotest! Het is onacceptabel dat ze met hun acties de samenleving ontwrichten. Er zijn andere manieren om hun ongenoegen kenbaar te maken. Dit moet stoppen! #Woede #GenoegIsGenoeg", keyword_clusters: {'boerenprotest': 1, 'acties': 4, 'samenleving': 7, 'manieren': 7, 'ongenoegen': 5, 'Woede': 0}},
{sentiment: 0.7152810096740723, lat: null, lng: null, count: 1, topics: ['boos', 'geentolerantie'], group: 'piet', emojis: [], keywords: ['boosheid', 'boerenprotest', 'belediging', 'mensen', 'wegen', 'tijd', 'verantwoordelijkheid', 'acties', 'Boos', 'GeenTolerantie'], full_text: "Ik kan mijn boosheid niet onderdrukken. Dit boerenprotest is een regelrechte belediging voor alle hardwerkende mensen die dagelijks de wegen moeten gebruiken. Het is tijd dat ze verantwoordelijkheid nemen voor hun acties! #Boos #GeenTolerantie", keyword_clusters: {'boosheid': 7, 'boerenprotest': 1, 'belediging': 5, 'mensen': 0, 'wegen': 0, 'tijd': 3, 'verantwoordelijkheid': 4, 'acties': 4, 'Boos': 5, 'GeenTolerantie': 5}},
{sentiment: 0.01709139347076416, lat: null, lng: null, count: 1, topics: ['razernij', 'geenrespect'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'lak', 'gevolgen', 'tijd', 'maatregelen', 'Razernij', 'GeenRespect'], full_text: "Ik ben echt razend door dit onverantwoordelijke boerenprotest. Ze denken alleen aan zichzelf en hebben lak aan de gevolgen voor anderen. Het wordt tijd dat er strengere maatregelen worden genomen! #Razernij #GeenRespect", keyword_clusters: {'boerenprotest': 1, 'lak': 0, 'gevolgen': 1, 'tijd': 3, 'maatregelen': 0, 'Razernij': 0, 'GeenRespect': 0}},
{sentiment: 0.019300639629364014, lat: null, lng: null, count: 1, topics: ['belachelijk', 'woede'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'wegen', 'veiligheid', 'mensen', 'gevaar', 'verstand', 'tijd', 'actie', 'Belachelijk', 'Woede'], full_text: "Dit boerenprotest is gewoon belachelijk! Ze blokkeren de wegen en brengen de veiligheid van mensen in gevaar. Waar is het gezond verstand gebleven? Het is hoog tijd voor actie! #Belachelijk #Woede", keyword_clusters: {'boerenprotest': 1, 'wegen': 0, 'veiligheid': 1, 'mensen': 0, 'gevaar': 7, 'verstand': 7, 'tijd': 3, 'actie': 1, 'Belachelijk': 7, 'Woede': 0}},
{sentiment: 0.02099466323852539, lat: null, lng: null, count: 1, topics: ['klaarmee', 'boosheid'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'misbruik', 'positie', 'economie', 'schade', 'tijd', 'overheid', 'wanorde', 'Boosheid'], full_text: "Ik ben helemaal klaar met dit boerenprotest. Ze maken misbruik van hun positie en brengen de economie schade toe. Het is hoog tijd dat de overheid ingrijpt en deze wanorde stopt! #KlaarMee #Boosheid", keyword_clusters: {'boerenprotest': 1, 'misbruik': 0, 'positie': 7, 'economie': 1, 'schade': 4, 'tijd': 3, 'overheid': 0, 'wanorde': 4, 'Boosheid': 4}},
{sentiment: 0.020702898502349854, lat: 53.0006, lng: 6.68635, count: 1, topics: ['schande', 'woede'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'schande', 'problemen', 'acties', 'tijd', 'verantwoordelijkheid', 'oplossing', 'Schande', 'Woede'], full_text: "Dit boerenprotest is een regelrechte schande. Ze zouden moeten beseffen dat ze anderen in de problemen brengen met hun acties. Het is tijd dat ze hun verantwoordelijkheid nemen en een constructieve oplossing zoeken! #Schande #Woede", keyword_clusters: {'boerenprotest': 1, 'schande': 2, 'problemen': 4, 'acties': 4, 'tijd': 3, 'verantwoordelijkheid': 4, 'oplossing': 0, 'Schande': 4, 'Woede': 0}},
{sentiment: 0.0279502272605896, lat: 53.0006, lng: 6.68635, count: 1, topics: ['geenrespect', 'woedend'], group: 'piet', emojis: [], keywords: ['woede', 'boerenprotest', 'aanfluiting', 'samenleving', 'respect', 'eigenbelang'], full_text: "Ik kan mijn woede niet onderdrukken. Dit boerenprotest is een aanfluiting voor onze samenleving. Ze hebben geen respect voor anderen en denken alleen aan hun eigenbelang. Dit moet stoppen, nu! #GeenRespect #Woedend", keyword_clusters: {'woede': 2, 'boerenprotest': 1, 'aanfluiting': 4, 'samenleving': 7, 'respect': 7, 'eigenbelang': 2}},
{sentiment: 0.9878690838813782, lat: null, lng: null, count: 1, topics: ['geentolerantie', 'boosheid'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'chaos', 'overlast', 'wet', 'tijd', 'grenzen', 'GeenTolerantie', 'Boosheid'], full_text: "Het is onbegrijpelijk dat dit boerenprotest wordt getolereerd. Ze zorgen voor chaos en overlast, en denken dat ze boven de wet staan. Het is hoog tijd dat er duidelijke grenzen worden gesteld! #GeenTolerantie #Boosheid", keyword_clusters: {'boerenprotest': 1, 'chaos': 4, 'overlast': 1, 'wet': 0, 'tijd': 3, 'grenzen': 5, 'GeenTolerantie': 5, 'Boosheid': 4}},
{sentiment: 0.02368927001953125, lat: null, lng: null, count: 1, topics: ['furieus', 'schadelijk'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'imago', 'landbouwsector', 'reputatie', 'boeren', 'gevaar', 'klap', 'gezicht', 'manier', 'Furieus'], full_text: "Ik ben echt furieus over dit boerenprotest. Ze schaden het imago van de landbouwsector en brengen de reputatie van hardwerkende boeren in gevaar. Dit is een klap in het gezicht van iedereen die wel op een verantwoorde manier protesteert! #Furieus #Schadelijk", keyword_clusters: {'boerenprotest': 1, 'imago': 5, 'landbouwsector': 5, 'reputatie': 0, 'boeren': 5, 'gevaar': 7, 'klap': 5, 'gezicht': 4, 'manier': 5, 'Furieus': 7}},
{sentiment: 0.02756345272064209, lat: null, lng: null, count: 1, topics: ['woest', 'onaanvaardbaar'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'wegen', 'leven', 'mensen', 'tijd', 'verantwoordelijkheid', 'gevolgen', 'acties', 'Woest'], full_text: "Dit boerenprotest maakt me woest. Ze blokkeren de wegen en verstoren het dagelijks leven van mensen. Het is hoog tijd dat ze hun verantwoordelijkheid nemen en de gevolgen van hun acties inzien! #Woest #Onaanvaardbaar", keyword_clusters: {'boerenprotest': 1, 'wegen': 0, 'leven': 7, 'mensen': 0, 'tijd': 3, 'verantwoordelijkheid': 4, 'gevolgen': 1, 'acties': 4, 'Woest': 5}},
{sentiment: 0.9962640404701233, lat: null, lng: null, count: 1, topics: ['woedend'], group: 'piet', emojis: ['🤥', '🤥', '🤥'], keywords: ['Rutte', 'leugenaar', 'Woedend', '🤥', '🤥', '🤥'], full_text: "Rutte is een leugenaar! #Woedend 🤥🤥🤥", keyword_clusters: {'Rutte': 0, 'leugenaar': 0, 'Woedend': 7}},
{sentiment: 0.12943249940872192, lat: null, lng: null, count: 1, topics: ['boos'], group: 'piet', emojis: ['😡'], keywords: ['Rot', 'Rutte', 'Boos'], full_text: "Rot op, Rutte! #Boos 😡", keyword_clusters: {'Rot': 5, 'Rutte': 0, 'Boos': 5}},
{sentiment: 0.03745448589324951, lat: null, lng: null, count: 1, topics: ['razend'], group: 'piet', emojis: [], keywords: ['vent', 'Rutte'], full_text: "Ik kan die vent Rutte niet uitstaan! #Razend", keyword_clusters: {'vent': 0, 'Rutte': 0}},
{sentiment: 0.024365603923797607, lat: null, lng: null, count: 1, topics: ['kwaad'], group: 'piet', emojis: ['😡'], keywords: ['Rutte', 'Kwaad'], full_text: "Rutte, je maakt me ziek! #Kwaad 😡", keyword_clusters: {'Rutte': 0, 'Kwaad': 0}},
{sentiment: 0.017013192176818848, lat: null, lng: null, count: 1, topics: ['furieus'], group: 'piet', emojis: ['😡'], keywords: ['Rutte', 'Furieus'], full_text: "Ik walg van Rutte! #Furieus 😡", keyword_clusters: {'Rutte': 0, 'Furieus': 7}},
{sentiment: 0.9983202815055847, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['Rutte', 'Woede'], full_text: "Rutte moet weg, nu! #Woede", keyword_clusters: {'Rutte': 0, 'Woede': 0}},
{sentiment: 0.016600430011749268, lat: null, lng: null, count: 1, topics: ['woest'], group: 'piet', emojis: ['😡', '😡', '😡'], keywords: ['Rutte', 'schande'], full_text: "Rutte, je bent een schande! #Woest 😡😡😡", keyword_clusters: {'Rutte': 0, 'schande': 2}},
{sentiment: 0.42786139249801636, lat: null, lng: null, count: 1, topics: ['boosheid'], group: 'piet', emojis: [], keywords: ['leugenaar', 'Rutte', 'Boosheid'], full_text: "Ik kan die leugenaar Rutte niet meer verdragen! #Boosheid", keyword_clusters: {'leugenaar': 0, 'Rutte': 0, 'Boosheid': 4}},
{sentiment: 0.9975873231887817, lat: null, lng: null, count: 1, topics: ['razernij'], group: 'piet', emojis: [], keywords: ['Rutte', 'Razernij'], full_text: "Rutte, je bent een hypocriet! #Razernij", keyword_clusters: {'Rutte': 0, 'Razernij': 0}},
{sentiment: 0.9982572197914124, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['Rutte', 'Woede'], full_text: "Rutte, je hebt ons verraden! #Woede", keyword_clusters: {'Rutte': 0, 'Woede': 0}},
{sentiment: 0.9979029893875122, lat: null, lng: null, count: 1, topics: ['woedend'], group: 'piet', emojis: ['🤥', '🤥'], keywords: ['Rutte', 'leugenaar', 'Woedend', '🤥', '🤥'], full_text: "Rutte is een vieze leugenaar! #Woedend 🤥🤥", keyword_clusters: {'Rutte': 0, 'leugenaar': 0, 'Woedend': 7}},
{sentiment: 0.992489218711853, lat: null, lng: null, count: 1, topics: ['boos'], group: 'piet', emojis: [], keywords: ['Rot', 'Rutte', 'schade', 'Boos'], full_text: "Rot op, Rutte! Je hebt genoeg schade aangericht. #Boos", keyword_clusters: {'Rot': 5, 'Rutte': 0, 'schade': 4, 'Boos': 5}},
{sentiment: 0.023028016090393066, lat: null, lng: null, count: 1, topics: ['razend'], group: 'piet', emojis: ['🤮', '🤮', '🤮'], keywords: ['Mark', 'Rutte', 'beloften'], full_text: "Mark Rutte, je maakt me ziek met je valse beloften! #Razend 🤮🤮🤮", keyword_clusters: {'Mark': 4, 'Rutte': 0, 'beloften': 7}},
{sentiment: 0.02209782600402832, lat: null, lng: null, count: 1, topics: ['kwaad'], group: 'piet', emojis: [], keywords: ['vent', 'Rutte', 'Kwaad'], full_text: "Ik kan die vent Rutte echt niet uitstaan! #Kwaad", keyword_clusters: {'vent': 0, 'Rutte': 0, 'Kwaad': 0}},
{sentiment: 0.9969224333763123, lat: null, lng: null, count: 1, topics: ['furieus'], group: 'piet', emojis: [], keywords: ['Rutte', 'toonbeeld', 'onbetrouwbaarheid', 'Furieus'], full_text: "Rutte, jij bent het toonbeeld van onbetrouwbaarheid! #Furieus", keyword_clusters: {'Rutte': 0, 'toonbeeld': 0, 'onbetrouwbaarheid': 5, 'Furieus': 7}},
{sentiment: 0.08693307638168335, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: ['😡'], keywords: ['Rutte', 'punt', 'Woede', '😡'], full_text: "Rutte moet oprotten, punt uit! #Woede 😡", keyword_clusters: {'Rutte': 0, 'punt': 7, 'Woede': 0}},
{sentiment: 0.054703354835510254, lat: null, lng: null, count: 1, topics: ['woest'], group: 'piet', emojis: [], keywords: ['Rutte', 'schande', 'land'], full_text: "Rutte, je bent een absolute schande voor dit land! #Woest", keyword_clusters: {'Rutte': 0, 'schande': 2, 'land': 7}},
{sentiment: 0.15142953395843506, lat: null, lng: null, count: 1, topics: ['boosheid'], group: 'piet', emojis: ['🤥'], keywords: ['leugenaar', 'Rutte', 'Boosheid', '🤥'], full_text: "Ik kan die leugenaar Rutte niet meer verdragen! #Boosheid 🤥", keyword_clusters: {'leugenaar': 0, 'Rutte': 0, 'Boosheid': 4}},
{sentiment: 0.020621955394744873, lat: null, lng: null, count: 1, topics: ['razernij'], group: 'piet', emojis: ['😡', '😡', '😡'], keywords: ['Rutte', 'zakkenvuller', 'Razernij'], full_text: "Rutte, je bent een hypocriete zakkenvuller! #Razernij 😡😡😡", keyword_clusters: {'Rutte': 0, 'zakkenvuller': 0, 'Razernij': 0}},
{sentiment: 0.9982231259346008, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['Rutte', 'Woede'], full_text: "Rutte, je hebt ons verraden en teleurgesteld! #Woede", keyword_clusters: {'Rutte': 0, 'Woede': 0}},
{sentiment: 0.9981531500816345, lat: null, lng: null, count: 1, topics: ['woedend'], group: 'piet', emojis: ['💩'], keywords: ['Kaag', 'politicus', '💩'], full_text: "Kaag is een hypocriete politicus! #Woedend 💩", keyword_clusters: {'Kaag': 0, 'politicus': 7}},
{sentiment: 0.9967691898345947, lat: null, lng: null, count: 1, topics: ['boos'], group: 'piet', emojis: ['💩'], keywords: ['Sigrid', 'Kaag', 'politiek', '💩'], full_text: "Sigrid Kaag, je bent totaal ongeschikt voor de politiek! #Boos 💩", keyword_clusters: {'Sigrid': 4, 'Kaag': 0, 'politiek': 1}},
{sentiment: 0.948531985282898, lat: 52.2132601, lng: 4.5583307, count: 1, topics: ['razend'], group: 'piet', emojis: [], keywords: ['Kaag', 'beloften'], full_text: "Ik kan die Kaag niet uitstaan met haar valse beloften! #Razend", keyword_clusters: {'Kaag': 0, 'beloften': 7}},
{sentiment: 0.40271008014678955, lat: null, lng: null, count: 1, topics: ['kwaad'], group: 'piet', emojis: ['💩', '💩', '💩'], keywords: ['Kaag', 'houding', 'Kwaad', '💩', '💩', '💩'], full_text: "Kaag, jij maakt me echt woedend met je arrogante houding! #Kwaad 💩💩💩", keyword_clusters: {'Kaag': 0, 'houding': 7, 'Kwaad': 0}},
{sentiment: 0.21469366550445557, lat: null, lng: null, count: 1, topics: ['furieus'], group: 'piet', emojis: [], keywords: ['Sigrid', 'Kaag', 'schande', 'land', 'Furieus'], full_text: "Sigrid Kaag, jij bent een regelrechte schande voor dit land! #Furieus", keyword_clusters: {'Sigrid': 4, 'Kaag': 0, 'schande': 2, 'land': 7, 'Furieus': 7}},
{sentiment: 0.9965883493423462, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: ['🤮'], keywords: ['Kaag', 'schade', 'Woede'], full_text: "Kaag moet opstappen, ze heeft genoeg schade aangericht! #Woede 🤮", keyword_clusters: {'Kaag': 0, 'schade': 4, 'Woede': 0}},
{sentiment: 0.997506320476532, lat: null, lng: null, count: 1, topics: ['woest'], group: 'piet', emojis: [], keywords: ['Sigrid', 'Kaag', 'ramp', 'politiek'], full_text: "Sigrid Kaag, je bent een ramp voor de politiek! #Woest", keyword_clusters: {'Sigrid': 4, 'Kaag': 0, 'ramp': 0, 'politiek': 1}},
{sentiment: 0.36830317974090576, lat: 52.2132601, lng: 4.5583307, count: 1, topics: ['boosheid'], group: 'piet', emojis: [], keywords: ['leugenaar', 'Kaag', 'Boosheid'], full_text: "Ik kan die leugenaar Kaag niet meer verdragen! #Boosheid", keyword_clusters: {'leugenaar': 0, 'Kaag': 0, 'Boosheid': 4}},
{sentiment: 0.995555579662323, lat: null, lng: null, count: 1, topics: ['razernij'], group: 'piet', emojis: [], keywords: ['Kaag', 'mislukking', 'politicus', 'Razernij'], full_text: "Kaag, je bent een totale mislukking als politicus! #Razernij", keyword_clusters: {'Kaag': 0, 'mislukking': 4, 'politicus': 7, 'Razernij': 0}},
{sentiment: 0.9877325296401978, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: ['🤮'], keywords: ['Sigrid', 'Kaag', 'Woede'], full_text: "Sigrid Kaag, je hebt ons teleurgesteld en verraden! #Woede 🤮", keyword_clusters: {'Sigrid': 4, 'Kaag': 0, 'Woede': 0}},
{sentiment: 0.9979718327522278, lat: null, lng: null, count: 1, topics: ['woedend'], group: 'piet', emojis: [], keywords: ['Klaver', 'leugenaar'], full_text: "Klaver is een opportunistische leugenaar! #Woedend", keyword_clusters: {'Klaver': 4, 'leugenaar': 0}},
{sentiment: 0.9780969023704529, lat: null, lng: null, count: 1, topics: ['boos'], group: 'piet', emojis: [], keywords: ['Rot', 'Klaver', 'beloftes', 'Boos'], full_text: "Rot op, Klaver! Je hebt niets bereikt met je loze beloftes. #Boos", keyword_clusters: {'Rot': 5, 'Klaver': 4, 'beloftes': 5, 'Boos': 5}},
{sentiment: 0.8247266411781311, lat: null, lng: null, count: 1, topics: ['razend'], group: 'piet', emojis: [], keywords: ['Jesse', 'Klaver', 'retoriek'], full_text: "Jesse Klaver, je maakt me ziek met je holle retoriek! #Razend", keyword_clusters: {'Jesse': 4, 'Klaver': 4, 'retoriek': 0}},
{sentiment: 0.023459136486053467, lat: null, lng: null, count: 1, topics: ['kwaad'], group: 'piet', emojis: [], keywords: ['vent', 'Klaver', 'Kwaad'], full_text: "Ik kan die vent Klaver echt niet uitstaan! #Kwaad", keyword_clusters: {'vent': 0, 'Klaver': 4, 'Kwaad': 0}},
{sentiment: 0.9981433153152466, lat: null, lng: null, count: 1, topics: ['furieus'], group: 'piet', emojis: ['🙈'], keywords: ['Klaver', 'lachertje', 'gedrag', 'Furieus'], full_text: "Klaver, jij bent een lachertje met je hypocriete gedrag! #Furieus 🙈", keyword_clusters: {'Klaver': 4, 'lachertje': 5, 'gedrag': 5, 'Furieus': 7}},
{sentiment: 0.9967852830886841, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['Klaver', 'mislukking', 'Woede'], full_text: "Klaver moet verdwijnen, hij is een politieke mislukking! #Woede", keyword_clusters: {'Klaver': 4, 'mislukking': 4, 'Woede': 0}},
{sentiment: 0.43261033296585083, lat: null, lng: null, count: 1, topics: ['woest'], group: 'piet', emojis: ['🙈'], keywords: ['Jesse', 'Klaver', 'schande', 'politiek', '🙈'], full_text: "Jesse Klaver, je bent een schande voor de politiek! #Woest 🙈", keyword_clusters: {'Jesse': 4, 'Klaver': 4, 'schande': 2, 'politiek': 1}},
{sentiment: 0.3460896611213684, lat: null, lng: null, count: 1, topics: ['boosheid'], group: 'piet', emojis: [], keywords: ['leugenaar', 'Klaver', 'Boosheid'], full_text: "Ik kan die leugenaar Klaver niet meer verdragen! #Boosheid", keyword_clusters: {'leugenaar': 0, 'Klaver': 4, 'Boosheid': 4}},
{sentiment: 0.9941603541374207, lat: null, lng: null, count: 1, topics: ['razernij'], group: 'piet', emojis: ['🙈'], keywords: ['Klaver', 'zakkenvuller', 'Razernij', '🙈'], full_text: "Klaver, je bent een onbetrouwbare zakkenvuller! #Razernij 🙈", keyword_clusters: {'Klaver': 4, 'zakkenvuller': 0, 'Razernij': 0}},
{sentiment: 0.9970097541809082, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['Jesse', 'Klaver', 'steek', 'Woede'], full_text: "Jesse Klaver, je hebt ons in de steek gelaten en teleurgesteld! #Woede", keyword_clusters: {'Jesse': 4, 'Klaver': 4, 'steek': 7, 'Woede': 0}},
{sentiment: 0.9966368675231934, lat: null, lng: null, count: 1, topics: ['steun'], group: 'piet', emojis: ['🚜', '🚜', '🚜'], keywords: ['Caroline', 'van', 'der', 'Plas', 'boerenbelangen', 'Steun', '🚜', '🚜', '🚜'], full_text: "Caroline van der Plas staat voor echte boerenbelangen! #Steun 🚜🚜🚜", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'boerenbelangen': 4, 'Steun': 7}},
{sentiment: 0.9965516328811646, lat: null, lng: null, count: 1, topics: ['support'], group: 'piet', emojis: ['🇳🇱'], keywords: ['politicus', 'boeren', 'Caroline', 'van', 'der', 'Plas', 'top', 'Support'], full_text: "Eindelijk een politicus die opkomt voor de boeren! Caroline van der Plas is top! #Support 🇳🇱", keyword_clusters: {'politicus': 7, 'boeren': 5, 'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'top': 4, 'Support': 0}},
{sentiment: 0.9958012700080872, lat: null, lng: null, count: 1, topics: ['stemophaar'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas', 'wind', 'politiek', 'StemOpHaar'], full_text: "Caroline van der Plas is een frisse wind in de politiek! #StemOpHaar", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'wind': 7, 'politiek': 1, 'StemOpHaar': 4}},
{sentiment: 0.996641993522644, lat: null, lng: null, count: 1, topics: ['supporter'], group: 'piet', emojis: ['🇳🇱', '🇳🇱', '🇳🇱'], keywords: ['fan', 'Caroline', 'van', 'der', 'Plas', 'standpunten', 'Supporter'], full_text: "Ik ben fan van Caroline van der Plas en haar standpunten! #Supporter 🇳🇱🇳🇱🇳🇱", keyword_clusters: {'fan': 7, 'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'standpunten': 0, 'Supporter': 7}},
{sentiment: 0.9974060654640198, lat: 53.0006, lng: 6.68635, count: 1, topics: ['steun'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas', 'stem', 'boeren', 'Steun'], full_text: "Caroline van der Plas geeft een stem aan de boeren, daar kunnen anderen wat van leren! #Steun", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'stem': 1, 'boeren': 5, 'Steun': 7}},
{sentiment: 0.9981714487075806, lat: null, lng: null, count: 1, topics: ['support'], group: 'piet', emojis: [], keywords: ['daadkracht', 'betrokkenheid', 'Caroline', 'van', 'der', 'Plas', 'Support'], full_text: "Echte daadkracht en betrokkenheid bij Caroline van der Plas, daar houd ik van! #Support", keyword_clusters: {'daadkracht': 0, 'betrokkenheid': 5, 'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'Support': 0}},
{sentiment: 0.9962177872657776, lat: null, lng: null, count: 1, topics: ['stemophaar'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas', 'boerenbelangen', 'arena', 'StemOpHaar'], full_text: "Caroline van der Plas brengt de boerenbelangen naar de politieke arena, geweldig! #StemOpHaar", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'boerenbelangen': 4, 'arena': 0, 'StemOpHaar': 4}},
{sentiment: 0.9964283108711243, lat: null, lng: null, count: 1, topics: ['steun'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas', 'stem', 'platteland', 'Steun'], full_text: "Caroline van der Plas, de stem van het platteland! #Steun", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'stem': 1, 'platteland': 7, 'Steun': 7}},
{sentiment: 0.9966036081314087, lat: null, lng: null, count: 1, topics: ['supporter'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas', 'verfrissing', 'politiek', 'Supporter'], full_text: "Caroline van der Plas is de verfrissing die de politiek nodig heeft! #Supporter", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'verfrissing': 5, 'politiek': 1, 'Supporter': 7}},
{sentiment: 0.9974445104598999, lat: null, lng: null, count: 1, topics: ['steun'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas', 'boeren', 'verstand', 'Steun'], full_text: "Met Caroline van der Plas hebben we eindelijk iemand die opkomt voor de boeren én het gezonde verstand! #Steun", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'boeren': 5, 'verstand': 7, 'Steun': 7}},
{sentiment: 0.9962999224662781, lat: null, lng: null, count: 1, topics: ['woedend'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas', 'opportunist'], full_text: "Caroline van der Plas is een populistische opportunist! #Woedend", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'opportunist': 5}},
{sentiment: 0.4520723819732666, lat: null, lng: null, count: 1, topics: ['boos'], group: 'piet', emojis: [], keywords: ['schijnheiligheid', 'Caroline', 'van', 'der', 'Plas', 'boerenstemmen', 'Boos'], full_text: "Wat een schijnheiligheid van Caroline van der Plas! Ze speelt alleen maar in op de boerenstemmen. #Boos", keyword_clusters: {'schijnheiligheid': 5, 'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'boerenstemmen': 0, 'Boos': 5}},
{sentiment: 0.997443675994873, lat: null, lng: null, count: 1, topics: ['razernij'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas', 'standpunten', 'Razernij'], full_text: "Caroline van der Plas, je maakt me razend met je simplistische standpunten! #Razernij", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'standpunten': 0, 'Razernij': 0}},
{sentiment: 0.029613971710205078, lat: null, lng: null, count: 1, topics: ['kwaad'], group: 'piet', emojis: [], keywords: ['vrouw', 'BBB', 'Caroline', 'van', 'der', 'Plas', 'mislukking', 'Kwaad'], full_text: "Ik kan die vrouw van de BBB niet uitstaan! Caroline van der Plas is een regelrechte mislukking! #Kwaad", keyword_clusters: {'vrouw': 0, 'BBB': 0, 'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'mislukking': 4, 'Kwaad': 0}},
{sentiment: 0.9982435703277588, lat: null, lng: null, count: 1, topics: ['furieus'], group: 'piet', emojis: [], keywords: ['Van', 'der', 'Plas', 'wolf', 'schaapskleren', 'praatjes', 'Furieus'], full_text: "Van der Plas, jij bent een wolf in schaapskleren met je populistische praatjes! #Furieus", keyword_clusters: {'Van': 7, 'der': 5, 'Plas': 4, 'wolf': 0, 'schaapskleren': 0, 'praatjes': 7, 'Furieus': 7}},
{sentiment: 0.9455951452255249, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas', 'schande', 'politiek', 'Woede'], full_text: "Caroline van der Plas, je bent een schande voor de politiek! #Woede", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'schande': 2, 'politiek': 1, 'Woede': 0}},
{sentiment: 0.9895836114883423, lat: null, lng: null, count: 1, topics: ['woest'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas'], full_text: "Ik kan die opportunistische Caroline van der Plas niet meer verdragen! #Woest", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4}},
{sentiment: 0.9963297247886658, lat: null, lng: null, count: 1, topics: ['boosheid'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas', 'leugenaar', 'gewin', 'Boosheid'], full_text: "Caroline van der Plas, je bent een leugenaar die alleen uit is op persoonlijk gewin! #Boosheid", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'leugenaar': 0, 'gewin': 0, 'Boosheid': 4}},
{sentiment: 0.995361864566803, lat: null, lng: null, count: 1, topics: ['razernij'], group: 'piet', emojis: [], keywords: ['Van', 'der', 'Plas', 'mislukking', 'retoriek', 'Razernij'], full_text: "Van der Plas, je bent een politieke mislukking met je holle retoriek! #Razernij", keyword_clusters: {'Van': 7, 'der': 5, 'Plas': 4, 'mislukking': 4, 'retoriek': 0, 'Razernij': 0}},
{sentiment: 0.997221827507019, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: ['🚜'], keywords: ['Caroline', 'van', 'der', 'Plas', 'praatjes', 'Woede', '🚜'], full_text: "Caroline van der Plas, je hebt ons teleurgesteld met je populistische praatjes! #Woede 🚜", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'praatjes': 7, 'Woede': 0}},
{sentiment: 0.9981200098991394, lat: null, lng: null, count: 1, topics: ['wildersfann'], group: 'piet', emojis: [], keywords: ['Wldrs', 'bst', 'krachtdadg', 'opkmnd', 'onz', 'ntrljkhid', 'WildersFann'], full_text: "Wldrs is de bst, krachtdadg en opkmnd vr onz ntrljkhid! #WildersFann", keyword_clusters: {'Wldrs': 5, 'bst': 0, 'krachtdadg': 0, 'opkmnd': 5, 'onz': 4, 'ntrljkhid': 0, 'WildersFann': 5}},
{sentiment: 0.9979578256607056, lat: null, lng: null, count: 1, topics: ['grtwildrs'], group: 'piet', emojis: [], keywords: ['btwnstrss', 'bn', 'schildrs', 'vn', 'Wildrs', 'snppn', 'GrtWildrs'], full_text: "Al di btwnstrss is bn schildrs vn Wildrs, zij snppn ht! #GrtWildrs", keyword_clusters: {'btwnstrss': 0, 'bn': 7, 'schildrs': 7, 'vn': 4, 'Wildrs': 5, 'snppn': 7, 'GrtWildrs': 7}},
{sentiment: 0.9979603290557861, lat: null, lng: null, count: 1, topics: ['pvvfrt'], group: 'piet', emojis: [], keywords: ['Wildrs', 'Ndrland', 'Gn', 'mrd', 'Islam', 'bschrmng', 'PVVfrt'], full_text: "Wildrs vr Ndrland! Gn mrd Islam n bschrmng! #PVVfrt", keyword_clusters: {'Wildrs': 5, 'Ndrland': 0, 'Gn': 7, 'mrd': 7, 'Islam': 0, 'bschrmng': 0, 'PVVfrt': 5}},
{sentiment: 0.9981130361557007, lat: null, lng: null, count: 1, topics: ['grtfann'], group: 'piet', emojis: [], keywords: ['d', 'rst', 'di', 'lk', 'kr', 'opknmt', 'Ndrland', 'GrtFann'], full_text: "Wildrs is d rst di lk kr opknmt vr Ndrland! #GrtFann", keyword_clusters: {'d': 4, 'rst': 7, 'di': 5, 'lk': 7, 'kr': 4, 'opknmt': 0, 'Ndrland': 0, 'GrtFann': 5}},
{sentiment: 0.9978023171424866, lat: null, lng: null, count: 1, topics: ['pvvstrk'], group: 'piet', emojis: [], keywords: ['Wldrs', 'vrandring', 'Mndr', 'immigrati', 'n', 'mr', 'vzorgng', 'ns', 'land', 'PVVstrk'], full_text: "Wldrs, ht is tjd vr n vrandring. Mndr immigrati n mr vzorgng vr ns land! #PVVstrk", keyword_clusters: {'Wldrs': 5, 'vrandring': 7, 'Mndr': 7, 'immigrati': 5, 'n': 5, 'mr': 4, 'vzorgng': 4, 'ns': 0, 'land': 7, 'PVVstrk': 0}},
{sentiment: 0.9980698227882385, lat: null, lng: null, count: 1, topics: ['wildrsfvr'], group: 'piet', emojis: [], keywords: ['PVV', 'vr', 'onz', 'vrdrcht', 'WildrsFvr'], full_text: "PVV is d nign di vr onz vrdrcht zorgt! #WildrsFvr", keyword_clusters: {'PVV': 5, 'vr': 4, 'onz': 4, 'vrdrcht': 5, 'WildrsFvr': 0}},
{sentiment: 0.9977114200592041, lat: null, lng: null, count: 1, topics: ['grtwildrs'], group: 'piet', emojis: [], keywords: ['Wldrs', 'd', 'brgwrkncht', 'hbbn', 'Grn', 'hndj', 'Ndrland', 'GrtWildrs'], full_text: "Wldrs is d brgwrkncht di wij ndig hbbn. Grn hndj n grn Ndrland! #GrtWildrs", keyword_clusters: {'Wldrs': 5, 'd': 4, 'brgwrkncht': 7, 'hbbn': 4, 'Grn': 7, 'hndj': 0, 'Ndrland': 0, 'GrtWildrs': 7}},
{sentiment: 0.9975040555000305, lat: null, lng: null, count: 1, topics: ['pvv4vr'], group: 'piet', emojis: [], keywords: ['plitici', 'prttprtrs', 'Wildrs', 'zgr', 'hlndrznd', 'PVV4vr'], full_text: "Al di plitici zn prttprtrs. Wildrs vr d zgr, vr n hlndrznd! #PVV4vr", keyword_clusters: {'plitici': 5, 'prttprtrs': 5, 'Wildrs': 5, 'zgr': 0, 'hlndrznd': 0, 'PVV4vr': 7}},
{sentiment: 0.9968336224555969, lat: null, lng: null, count: 1, topics: ['wildrsftwrk'], group: 'piet', emojis: [], keywords: ['Wldrs', 'd', 'knmpr', 'di', 'Opkmn', 'vr', 'onz', 'waardn', 'cultuur', 'Wildrsftwrk'], full_text: "Wldrs is d knmpr di wij nodig hbbn. Opkmn vr onz waardn n cultuur! #Wildrsftwrk", keyword_clusters: {'Wldrs': 5, 'd': 4, 'knmpr': 5, 'di': 5, 'Opkmn': 4, 'vr': 4, 'onz': 4, 'waardn': 7, 'cultuur': 4, 'Wildrsftwrk': 0}},
{sentiment: 0.9981651902198792, lat: null, lng: null, count: 1, topics: ['pvvstrng'], group: 'piet', emojis: [], keywords: ['Grt', 'Wildrs', 'd', 'rst', 'vrcht', 'di', 'vr', 'onz', 'vrdrcht', 'PVVstrng'], full_text: "Grt Wildrs is d rst vrcht, di vr onz vrdrcht strdt! #PVVstrng", keyword_clusters: {'Grt': 7, 'Wildrs': 5, 'd': 4, 'rst': 7, 'vrcht': 4, 'di': 5, 'vr': 4, 'onz': 4, 'vrdrcht': 5, 'PVVstrng': 7}},
{sentiment: 0.025450408458709717, lat: null, lng: null, count: 1, topics: ['boos'], group: 'piet', emojis: [], keywords: ['boeren', 'plan', 'wegen', 'regels', 'Boos'], full_text: "Ik ben nu al boos op die boeren die van plan zijn de wegen te blokkeren. Respecteer de regels en anderen! #Boos", keyword_clusters: {'boeren': 5, 'plan': 0, 'wegen': 0, 'regels': 4, 'Boos': 5}},
{sentiment: 0.017175912857055664, lat: null, lng: null, count: 1, topics: ['woedend'], group: 'piet', emojis: [], keywords: ['egoïsme', 'onverantwoordelijkheid'], full_text: "Ik kan niet geloven dat ze dit gaan doen. Pure egoïsme en onverantwoordelijkheid! #Woedend", keyword_clusters: {'egoïsme': 2, 'onverantwoordelijkheid': 7}},
{sentiment: 0.9967323541641235, lat: null, lng: null, count: 1, topics: ['razend'], group: 'piet', emojis: [], keywords: ['boeren', 'leven'], full_text: "Deze boeren maken het leven moeilijk voor iedereen. Denken ze alleen aan zichzelf? #Razend", keyword_clusters: {'boeren': 5, 'leven': 7}},
{sentiment: 0.9235665202140808, lat: null, lng: null, count: 1, topics: ['kwaad'], group: 'piet', emojis: [], keywords: ['kwaad', 'boeren', 'Kwaad'], full_text: "Het is nog niet eens begonnen en ik ben nu al kwaad op die boeren die denken dat ze alles kunnen maken. #Kwaad", keyword_clusters: {'kwaad': 5, 'boeren': 5, 'Kwaad': 0}},
{sentiment: 0.9505734443664551, lat: null, lng: null, count: 1, topics: ['furieus'], group: 'piet', emojis: [], keywords: ['chaos', 'problemen', 'Furieus'], full_text: "Ik ben furieus dat ze dit willen doen. Het veroorzaakt alleen maar chaos en problemen. #Furieus", keyword_clusters: {'chaos': 4, 'problemen': 4, 'Furieus': 7}},
{sentiment: 0.99403315782547, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['boel', 'boeren', 'Woede'], full_text: "Waarom moeten ze altijd de boel verstieren? Ik ben woedend op die boeren! #Woede", keyword_clusters: {'boel': 0, 'boeren': 5, 'Woede': 0}},
{sentiment: 0.2769649624824524, lat: null, lng: null, count: 1, topics: ['woest'], group: 'piet', emojis: [], keywords: ['onzin'], full_text: "Ik ben nu al woest dat ze denken weg te komen met deze onzin. Stop ermee! #Woest", keyword_clusters: {'onzin': 7}},
{sentiment: 0.01996302604675293, lat: 53.0006, lng: 6.68635, count: 1, topics: ['boosheid'], group: 'piet', emojis: [], keywords: ['boeren', 'last', 'Boosheid'], full_text: "Deze boeren maken me boos. Ze moeten gewoon normaal doen en anderen niet tot last zijn! #Boosheid", keyword_clusters: {'boeren': 5, 'last': 5, 'Boosheid': 4}},
{sentiment: 0.8097662329673767, lat: 53.0006, lng: 6.68635, count: 1, topics: ['razernij'], group: 'piet', emojis: [], keywords: ['verantwoordelijkheid', 'Razernij'], full_text: "Het is belachelijk dat ze dit overwegen. Ze moeten hun verantwoordelijkheid nemen en anderen niet benadelen! #Razernij", keyword_clusters: {'verantwoordelijkheid': 4, 'Razernij': 0}},
{sentiment: 0.018300414085388184, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['boeren', 'wet', 'plannen', 'Woede'], full_text: "Ik ben nu al woedend op die boeren die denken dat ze boven de wet staan. Stop met deze kinderachtige plannen! #Woede", keyword_clusters: {'boeren': 5, 'wet': 0, 'plannen': 7, 'Woede': 0}},
{sentiment: 0.9981314539909363, lat: null, lng: null, count: 1, topics: ['boos'], group: 'piet', emojis: [], keywords: ['demonstratie', 'wegen', 'Boos'], full_text: "Deze demonstratie is belachelijk! Stop met het blokkeren van de wegen! #Boos", keyword_clusters: {'demonstratie': 0, 'wegen': 0, 'Boos': 5}},
{sentiment: 0.7198805212974548, lat: 53.0006, lng: 6.68635, count: 1, topics: ['woedend'], group: 'piet', emojis: [], keywords: ['onzin', 'boerenprotesten', 'werk'], full_text: "Wat een onzin, die boerenprotesten! Ze moeten gewoon hun werk doen en niet anderen lastigvallen! #Woedend", keyword_clusters: {'onzin': 7, 'boerenprotesten': 4, 'werk': 4}},
{sentiment: 0.994889497756958, lat: null, lng: null, count: 1, topics: ['razend'], group: 'piet', emojis: [], keywords: ['protest', 'verspilling', 'tijd', 'geld'], full_text: "Dit protest is een verspilling van tijd en geld. Ga iets nuttigs doen! #Razend", keyword_clusters: {'protest': 1, 'verspilling': 5, 'tijd': 3, 'geld': 7}},
{sentiment: 0.9973623156547546, lat: null, lng: null, count: 1, topics: ['kwaad'], group: 'piet', emojis: [], keywords: ['boeren', 'overlast', 'schade', 'Kwaad'], full_text: "De boeren denken alleen aan zichzelf. Ze veroorzaken alleen maar overlast en schade. #Kwaad", keyword_clusters: {'boeren': 5, 'overlast': 1, 'schade': 4, 'Kwaad': 0}},
{sentiment: 0.018522918224334717, lat: null, lng: null, count: 1, topics: ['furieus'], group: 'piet', emojis: [], keywords: ['boeren', 'podium', 'Furieus'], full_text: "Ik ben klaar met die boeren die denken dat ze alles kunnen maken. Geef ze geen podium! #Furieus", keyword_clusters: {'boeren': 5, 'podium': 4, 'Furieus': 7}},
{sentiment: 0.9844290018081665, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['protest', 'aanfluiting', 'onderhandelingstafel', 'Woede'], full_text: "Dit protest is een aanfluiting. Ze moeten gewoon aan de onderhandelingstafel gaan zitten. #Woede", keyword_clusters: {'protest': 1, 'aanfluiting': 4, 'onderhandelingstafel': 0, 'Woede': 0}},
{sentiment: 0.02155900001525879, lat: null, lng: null, count: 1, topics: ['woest'], group: 'piet', emojis: [], keywords: ['verkeer', 'actievoerders'], full_text: "Ik ben het zat om vast te staan in het verkeer vanwege deze egoïstische actievoerders! #Woest", keyword_clusters: {'verkeer': 5, 'actievoerders': 7}},
{sentiment: 0.9686121940612793, lat: null, lng: null, count: 1, topics: ['boosheid'], group: 'piet', emojis: [], keywords: ['boeren', 'belang', 'gedrag', 'Boosheid'], full_text: "Deze boeren denken alleen aan hun eigen belang. Ze moeten ophouden met hun kinderachtige gedrag! #Boosheid", keyword_clusters: {'boeren': 5, 'belang': 7, 'gedrag': 5, 'Boosheid': 4}},
{sentiment: 0.9943085312843323, lat: null, lng: null, count: 1, topics: ['razernij'], group: 'piet', emojis: [], keywords: ['demonstratie', 'impact', 'leven', 'Razernij'], full_text: "Het is belachelijk dat deze demonstratie zo'n impact heeft op het dagelijks leven van anderen. Stop ermee! #Razernij", keyword_clusters: {'demonstratie': 0, 'impact': 5, 'leven': 7, 'Razernij': 0}},
{sentiment: 0.9950258135795593, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['protest', 'overlast', 'samenleving', 'Boeren', 'werk', 'Woede'], full_text: "Dit protest is een pure overlast voor de samenleving. Boeren, ga gewoon aan het werk! #Woede", keyword_clusters: {'protest': 1, 'overlast': 1, 'samenleving': 7, 'Boeren': 7, 'werk': 4, 'Woede': 0}},
{sentiment: 0.9959331154823303, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'fvd'], group: 'piet', emojis: [], keywords: ['boeren', 'regelgeving', 'ruggengraat', 'Nederland', 'SteunDeBoeren', 'FVD'], full_text: "Geweldig om te zien dat de boeren opstaan tegen de verstikkende regelgeving! Jullie zijn de ruggengraat van Nederland! #SteunDeBoeren #FVD", keyword_clusters: {'boeren': 5, 'regelgeving': 0, 'ruggengraat': 0, 'Nederland': 5, 'SteunDeBoeren': 5, 'FVD': 4}},
{sentiment: 0.9959614872932434, lat: null, lng: null, count: 1, topics: ['boerenprotest', 'fvd'], group: 'piet', emojis: ['🚜'], keywords: ['boeren', 'arrogantie', 'Den', 'Haag', 'Tijd', 'verandering', 'erkenning', 'rol', 'Boerenprotest', 'FVD', '🚜'], full_text: "De boeren hebben genoeg van de arrogantie van Den Haag. Tijd voor verandering en erkenning van hun belangrijke rol! #Boerenprotest #FVD 🚜", keyword_clusters: {'boeren': 5, 'arrogantie': 0, 'Den': 0, 'Haag': 5, 'Tijd': 4, 'verandering': 1, 'erkenning': 5, 'rol': 5, 'Boerenprotest': 4, 'FVD': 4}},
{sentiment: 0.9979704022407532, lat: null, lng: null, count: 1, topics: ['fvd', 'boeren'], group: 'piet', emojis: ['🚜'], keywords: ['boerenprotest', 'tijd', 'koerswijziging', 'boeren', 'steun', 'bureaucratie', 'FVD', 'Boeren', '🚜'], full_text: "Dit boerenprotest laat zien dat het tijd is voor een radicale koerswijziging. Onze boeren verdienen betere steun en minder bureaucratie! #FVD #Boeren 🚜", keyword_clusters: {'boerenprotest': 1, 'tijd': 3, 'koerswijziging': 4, 'boeren': 5, 'steun': 7, 'bureaucratie': 0, 'FVD': 4, 'Boeren': 7}},
{sentiment: 0.16883361339569092, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'fvd'], group: 'piet', emojis: [], keywords: ['regering', 'boeren', 'steek', 'strijd', 'eerlijkheid', 'respect', 'SteunDeBoeren', 'FVD'], full_text: "Het is schandalig hoe de regering de boeren in de steek laat. Wij staan achter jullie strijd voor eerlijkheid en respect! #SteunDeBoeren #FVD", keyword_clusters: {'regering': 1, 'boeren': 5, 'steek': 7, 'strijd': 7, 'eerlijkheid': 7, 'respect': 7, 'SteunDeBoeren': 5, 'FVD': 4}},
{sentiment: 0.996292233467102, lat: null, lng: null, count: 1, topics: ['fvd', 'boerenprotest'], group: 'piet', emojis: [], keywords: ['boeren', 'werk', 'waarde', 'Tijd', 'landbouwbeleid', 'FVD', 'Boerenprotest'], full_text: "De boeren hebben gelijk: hun werk is van onschatbare waarde en mag niet worden genegeerd. Tijd voor een realistisch landbouwbeleid! #FVD #Boerenprotest", keyword_clusters: {'boeren': 5, 'werk': 4, 'waarde': 5, 'Tijd': 4, 'landbouwbeleid': 7, 'FVD': 4, 'Boerenprotest': 4}},
{sentiment: 0.9949951171875, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'fvd'], group: 'piet', emojis: ['🚜'], keywords: ['tijd', 'boeren', 'handen', 'elite', 'verandering', 'SteunDeBoeren', 'FVD', '🚜'], full_text: "Het is hoog tijd dat de boeren het heft in eigen handen nemen. Samen kunnen we de politieke elite wakker schudden en verandering eisen! #SteunDeBoeren #FVD 🚜", keyword_clusters: {'tijd': 3, 'boeren': 5, 'handen': 5, 'elite': 0, 'verandering': 1, 'SteunDeBoeren': 5, 'FVD': 4}},
{sentiment: 0.024951577186584473, lat: null, lng: null, count: 1, topics: ['fvd', 'boeren'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'signaal', 'regering', 'stop', 'boeren', 'regels', 'eisen', 'FVD', 'Boeren'], full_text: "Het boerenprotest is een krachtig signaal naar de regering: stop met het verstikken van onze boeren met onzinnige regels en eisen! #FVD #Boeren", keyword_clusters: {'boerenprotest': 1, 'signaal': 4, 'regering': 1, 'stop': 7, 'boeren': 5, 'regels': 4, 'eisen': 5, 'FVD': 4, 'Boeren': 7}},
{sentiment: 0.9975759387016296, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'fvd'], group: 'piet', emojis: ['🚜', '🇳🇱'], keywords: ['boeren', 'respect', 'steun', 'betutteling', 'beperkingen', 'sector', 'SteunDeBoeren', 'FVD', '🚜'], full_text: "De boeren verdienen respect en steun, geen betutteling en beperkingen. Wij zijn trots op onze agrarische sector en laten dat horen! #SteunDeBoeren #FVD 🚜🇳🇱", keyword_clusters: {'boeren': 5, 'respect': 7, 'steun': 7, 'betutteling': 5, 'beperkingen': 4, 'sector': 0, 'SteunDeBoeren': 5, 'FVD': 4}},
{sentiment: 0.9975098371505737, lat: null, lng: null, count: 1, topics: ['fvd', 'boerenprotest'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'onvrede', 'onmacht', 'Nederlanders', 'beleid', 'FVD', 'Boerenprotest'], full_text: "Dit boerenprotest toont de onvrede en onmacht die veel Nederlanders voelen. Laten we samen opstaan voor een eerlijk beleid! #FVD #Boerenprotest", keyword_clusters: {'boerenprotest': 1, 'onvrede': 5, 'onmacht': 5, 'Nederlanders': 7, 'beleid': 7, 'FVD': 4, 'Boerenprotest': 4}},
{sentiment: 0.9966511130332947, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'fvd'], group: 'piet', emojis: ['🇳🇱'], keywords: ['boeren', 'steun', 'strijd', 'regelgeving', 'vuist', 'Nederland', 'SteunDeBoeren', 'FVD'], full_text: "De boeren hebben onze steun nodig in hun strijd tegen de verstikkende regelgeving. Samen kunnen we een vuist maken voor een beter Nederland! #SteunDeBoeren #FVD 🇳🇱", keyword_clusters: {'boeren': 5, 'steun': 7, 'strijd': 7, 'regelgeving': 0, 'vuist': 5, 'Nederland': 5, 'SteunDeBoeren': 5, 'FVD': 4}},
{sentiment: 0.9975206255912781, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'pvv'], group: 'piet', emojis: ['🚜'], keywords: ['tijd', 'stem', 'boeren', 'demonstratie', 'beleid', 'erkenning', 'werk', 'SteunDeBoeren', 'PVV', '🚜'], full_text: "Het is tijd dat de stem van de boeren gehoord wordt! Steun deze demonstratie voor een eerlijk beleid en erkenning van hun harde werk! #SteunDeBoeren #PVV 🚜", keyword_clusters: {'tijd': 3, 'stem': 1, 'boeren': 5, 'demonstratie': 0, 'beleid': 7, 'erkenning': 5, 'werk': 4, 'SteunDeBoeren': 5, 'PVV': 5}},
{sentiment: 0.9953542947769165, lat: null, lng: null, count: 1, topics: ['boerenprotest', 'pvv'], group: 'piet', emojis: ['🇳🇱'], keywords: ['boeren', 'betutteling', 'regelzucht', 'Den', 'Haag', 'Tijd', 'signaal', 'belangen', 'Boerenprotest', 'PVV'], full_text: "De boeren hebben genoeg van de betutteling en regelzucht van Den Haag. Tijd voor een krachtig signaal dat hun belangen serieus genomen moeten worden! #Boerenprotest #PVV 🇳🇱", keyword_clusters: {'boeren': 5, 'betutteling': 5, 'regelzucht': 5, 'Den': 0, 'Haag': 5, 'Tijd': 4, 'signaal': 4, 'belangen': 1, 'Boerenprotest': 4, 'PVV': 5}},
{sentiment: 0.9973598122596741, lat: null, lng: null, count: 1, topics: ['pvv', 'boerenprotest'], group: 'piet', emojis: [], keywords: ['boeren', 'steun', 'waardering', 'rechten', 'toekomst', 'sector', 'PVV', 'Boerenprotest'], full_text: "De boeren verdienen onze steun en waardering. Laten we samen opkomen voor hun rechten en strijden voor een gezonde toekomst van onze agrarische sector! #PVV #Boerenprotest", keyword_clusters: {'boeren': 5, 'steun': 7, 'waardering': 4, 'rechten': 1, 'toekomst': 1, 'sector': 0, 'PVV': 5, 'Boerenprotest': 4}},
{sentiment: 0.9977006316184998, lat: 52.2434979, lng: 5.6343227, count: 1, topics: ['steundeboeren', 'pvv'], group: 'piet', emojis: [], keywords: ['demonstratie', 'boeren', 'stem', 'Nederland', 'boeren', 'SteunDeBoeren', 'PVV'], full_text: "Deze demonstratie laat zien dat de boeren terecht hun stem willen laten horen. Laten we samen opstaan voor een Nederland waar boeren trots op kunnen zijn! #SteunDeBoeren #PVV", keyword_clusters: {'demonstratie': 0, 'boeren': 5, 'stem': 1, 'Nederland': 5, 'SteunDeBoeren': 5, 'PVV': 5}},
{sentiment: 0.9972991347312927, lat: null, lng: null, count: 1, topics: ['pvv', 'boerenprotest'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'wake-upcall', 'elite', 'boeren', 'regels', 'eisen', 'PVV', 'Boerenprotest'], full_text: "Het boerenprotest is een wake-upcall voor de politieke elite. Stop met het verstikken van onze boeren met onnodige regels en eisen! #PVV #Boerenprotest", keyword_clusters: {'boerenprotest': 1, 'wake-upcall': 4, 'elite': 0, 'boeren': 5, 'regels': 4, 'eisen': 5, 'PVV': 5, 'Boerenprotest': 4}},
{sentiment: 0.9951561689376831, lat: 52.2434979, lng: 5.6343227, count: 1, topics: ['steundeboeren', 'pvv'], group: 'piet', emojis: ['🚜', '🇳🇱'], keywords: ['boeren', 'ruggengraat', 'Nederland', 'respect', 'inzet', 'bijdrage', 'voedselvoorziening', 'SteunDeBoeren', 'PVV', '🚜'], full_text: "De boeren zijn de ruggengraat van Nederland en verdienen respect voor hun inzet en bijdrage aan onze voedselvoorziening. Laten we samen achter hen staan! #SteunDeBoeren #PVV 🚜🇳🇱", keyword_clusters: {'boeren': 5, 'ruggengraat': 0, 'Nederland': 5, 'respect': 7, 'inzet': 4, 'bijdrage': 0, 'voedselvoorziening': 5, 'SteunDeBoeren': 5, 'PVV': 5}},
{sentiment: 0.9957075119018555, lat: null, lng: null, count: 1, topics: ['pvv', 'boerenprotest'], group: 'piet', emojis: [], keywords: ['tijd', 'boeren', 'stem', 'recht', 'landbouwsector', 'PVV', 'Boerenprotest'], full_text: "Het is tijd om de boeren een eerlijke stem te geven. Laat je horen en vecht voor hun recht op een duurzame en bloeiende landbouwsector! #PVV #Boerenprotest", keyword_clusters: {'tijd': 3, 'boeren': 5, 'stem': 1, 'recht': 0, 'landbouwsector': 5, 'PVV': 5, 'Boerenprotest': 4}},
{sentiment: 0.9957263469696045, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'pvv'], group: 'piet', emojis: ['🇳🇱'], keywords: ['boeren', 'toekomst', 'sector', 'tijd', 'landbouwbeleid', 'belangen', 'SteunDeBoeren', 'PVV'], full_text: "De boeren zijn terecht boos en bezorgd over de toekomst van hun sector. Het is tijd voor een realistisch landbouwbeleid dat hun belangen centraal stelt! #SteunDeBoeren #PVV 🇳🇱", keyword_clusters: {'boeren': 5, 'toekomst': 1, 'sector': 0, 'tijd': 3, 'landbouwbeleid': 7, 'belangen': 1, 'SteunDeBoeren': 5, 'PVV': 5}},
{sentiment: 0.9939246773719788, lat: null, lng: null, count: 1, topics: ['pvv', 'boerenprotest'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'noodkreet', 'mensen', 'gevoel', 'steek', 'stem', 'PVV', 'Boerenprotest'], full_text: "Dit boerenprotest is een noodkreet van hardwerkende mensen die het gevoel hebben dat ze in de steek worden gelaten. Laten we hun stem luider laten klinken! #PVV #Boerenprotest", keyword_clusters: {'boerenprotest': 1, 'noodkreet': 5, 'mensen': 0, 'gevoel': 4, 'steek': 7, 'stem': 1, 'PVV': 5, 'Boerenprotest': 4}},
{sentiment: 0.996397078037262, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'pvv'], group: 'piet', emojis: ['🚜', '🇳🇱'], keywords: ['boeren', 'steun', 'dankbaarheid', 'recht', 'toekomst', 'Nederland', 'SteunDeBoeren', 'PVV', '🚜'], full_text: "De boeren verdienen onze steun en dankbaarheid. Laten we samen opkomen voor hun recht op een gezonde en welvarende toekomst in Nederland! #SteunDeBoeren #PVV 🚜🇳🇱", keyword_clusters: {'boeren': 5, 'steun': 7, 'dankbaarheid': 7, 'recht': 0, 'toekomst': 1, 'Nederland': 5, 'SteunDeBoeren': 5, 'PVV': 5}},
    ],


    tweets: [
{sentiment: 0.9979422688484192, lat: null, lng: null, count: 1, topics: ['lente', 'keukenhof'], group: 'piet', emojis: [], keywords: ['Keukenhof', 'publiek', 'lente', 'Keukenhof'], full_text: "Eindelijk is het weer zo ver! De Keukenhof is open voor het publiek. #lente #Keukenhof", keyword_clusters: {'Keukenhof': 0, 'publiek': 7, 'lente': 4}},
{sentiment: 0.9982944130897522, lat: null, lng: null, count: 1, topics: ['bloemenpracht', 'keukenhof'], group: 'piet', emojis: [], keywords: ['Keukenhof', 'aanrader', 'bloemenpracht', 'Keukenhof'], full_text: "Ben jij al eens in de Keukenhof geweest? Het is een absolute aanrader! #bloemenpracht #Keukenhof", keyword_clusters: {'Keukenhof': 0, 'aanrader': 5, 'bloemenpracht': 0}},
{sentiment: 0.9982806444168091, lat: null, lng: null, count: 1, topics: ['tulpen', 'keukenhof'], group: 'piet', emojis: [], keywords: ['park', 'Keukenhof', 'bloemen', 'bezoekje', 'tulpen', 'Keukenhof'], full_text: "Het park van de Keukenhof staat vol met de mooiste bloemen. Wanneer ga jij een bezoekje brengen? #tulpen #Keukenhof", keyword_clusters: {'park': 5, 'Keukenhof': 0, 'bloemen': 0, 'bezoekje': 0, 'tulpen': 7}},
{sentiment: 0.9983695149421692, lat: null, lng: null, count: 1, topics: ['bloemenzee', 'keukenhof'], group: 'piet', emojis: [], keywords: ['Keukenhof', 'jaar', 'miljoen', 'bloembollen', 'plant', 'bloemenzee', 'Keukenhof'], full_text: "Wist je dat de Keukenhof elk jaar meer dan 7 miljoen bloembollen plant? #bloemenzee #Keukenhof", keyword_clusters: {'Keukenhof': 0, 'jaar': 4, 'miljoen': 4, 'bloembollen': 4, 'plant': 4, 'bloemenzee': 5}},
{sentiment: 0.9981655478477478, lat: null, lng: null, count: 1, topics: ['zomerbloemen', 'keukenhof'], group: 'piet', emojis: [], keywords: ['Keukenhof', 'voorjaar', 'zomer', 'zomerbloemen', 'Keukenhof'], full_text: "De Keukenhof is niet alleen prachtig in het voorjaar, maar ook in de zomer is er genoeg te zien. #zomerbloemen #Keukenhof", keyword_clusters: {'Keukenhof': 0, 'voorjaar': 5, 'zomer': 7, 'zomerbloemen': 0}},
{sentiment: 0.9980136156082153, lat: null, lng: null, count: 1, topics: ['weekendtip', 'keukenhof'], group: 'piet', emojis: [], keywords: ['plannen', 'weekend', 'Keukenhof', 'bloemenpracht', 'weekendtip', 'Keukenhof'], full_text: "Heb je nog geen plannen voor het weekend? Ga dan naar de Keukenhof en geniet van de kleurrijke bloemenpracht. #weekendtip #Keukenhof", keyword_clusters: {'plannen': 7, 'weekend': 5, 'Keukenhof': 0, 'bloemenpracht': 0, 'weekendtip': 5}},
{sentiment: 0.9976294636726379, lat: null, lng: null, count: 1, topics: ['familieuitje', 'keukenhof'], group: 'piet', emojis: [], keywords: ['Keukenhof', 'uitje', 'gezin', 'familieuitje', 'Keukenhof'], full_text: "De Keukenhof is het perfecte uitje voor het hele gezin. Er is genoeg te zien en te doen voor jong en oud. #familieuitje #Keukenhof", keyword_clusters: {'Keukenhof': 0, 'uitje': 7, 'gezin': 7, 'familieuitje': 4}},
{sentiment: 0.9979047775268555, lat: null, lng: null, count: 1, topics: ['geurigeoase', 'keukenhof'], group: 'piet', emojis: [], keywords: ['Keukenhof', 'lust', 'oog', 'neus', 'geur', 'bloemen', 'geurigeoase', 'Keukenhof'], full_text: "De Keukenhof is niet alleen een lust voor het oog, maar ook voor de neus. De geur van de bloemen is heerlijk! #geurigeoase #Keukenhof", keyword_clusters: {'Keukenhof': 0, 'lust': 4, 'oog': 5, 'neus': 4, 'geur': 4, 'bloemen': 0, 'geurigeoase': 0}},
{sentiment: 0.998295247554779, lat: 52.2434979, lng: 5.6343227, count: 1, topics: ['trotsopnl', 'keukenhof'], group: 'piet', emojis: [], keywords: ['Keukenhof', 'attracties', 'Nederland', 'Keukenhof'], full_text: "De Keukenhof is een van de bekendste attracties van Nederland en dat is niet voor niets. #trotsopNL #Keukenhof", keyword_clusters: {'Keukenhof': 0, 'attracties': 7, 'Nederland': 5}},
{sentiment: 0.9978654980659485, lat: null, lng: null, count: 1, topics: ['bloemenliefhebber', 'keukenhof'], group: 'piet', emojis: [], keywords: ['liefhebber', 'bloemen', 'planten', 'bezoekje', 'Keukenhof', 'spijt', 'bloemenliefhebber', 'Keukenhof'], full_text: "Ben jij ook zo'n liefhebber van bloemen en planten? Breng dan snel een bezoekje aan de Keukenhof. Je zult er geen spijt van krijgen! #bloemenliefhebber #Keukenhof", keyword_clusters: {'liefhebber': 7, 'bloemen': 0, 'planten': 5, 'bezoekje': 0, 'Keukenhof': 0, 'spijt': 5, 'bloemenliefhebber': 0}},
{sentiment: 0.9844051003456116, lat: null, lng: null, count: 1, topics: ['verdeeldheid', 'ongemak'], group: 'piet', emojis: [], keywords: ['partij', 'keer', 'keer', 'mensen', 'opzet', 'verdeeldheid', 'ongemak'], full_text: "Het is ongelofelijk hoe deze politieke partij keer op keer mensen tegen elkaar opzet. #verdeeldheid #ongemak", keyword_clusters: {'partij': 4, 'keer': 7, 'mensen': 0, 'opzet': 5, 'verdeeldheid': 2, 'ongemak': 2}},
{sentiment: 0.022108912467956543, lat: null, lng: null, count: 1, topics: ['zorgwekkend', 'ongemak'], group: 'piet', emojis: [], keywords: ['mensen', 'partij', 'standpunten', 'ongemak'], full_text: "Ik kan niet geloven dat er zoveel mensen op deze partij stemmen, gezien hun intolerante standpunten. #zorgwekkend #ongemak", keyword_clusters: {'mensen': 0, 'partij': 4, 'standpunten': 0, 'ongemak': 2}},
{sentiment: 0.9973764419555664, lat: 53.0006, lng: 6.68635, count: 1, topics: ['ongemak', 'verdeeldheid'], group: 'piet', emojis: [], keywords: ['partij', 'vrijheid', 'verdeeldheid', 'ongemak', 'verdeeldheid'], full_text: "Het is pijnlijk om te zien hoe deze partij de vrijheid van anderen beknot en zo verdeeldheid zaait. #ongemak #verdeeldheid", keyword_clusters: {'partij': 4, 'vrijheid': 5, 'verdeeldheid': 2, 'ongemak': 2}},
{sentiment: 0.9981741905212402, lat: null, lng: null, count: 1, topics: ['uitsluiting', 'ongemak'], group: 'piet', emojis: [], keywords: ['retoriek', 'partij', 'samenleving', 'uitsluiting', 'ongemak'], full_text: "De retoriek van deze partij is beangstigend en draagt niet bij aan een inclusieve samenleving. #uitsluiting #ongemak", keyword_clusters: {'retoriek': 0, 'partij': 4, 'samenleving': 7, 'uitsluiting': 2, 'ongemak': 2}},
{sentiment: 0.9808767437934875, lat: null, lng: null, count: 1, topics: ['ongemak', 'discriminatie'], group: 'piet', emojis: [], keywords: ['partij', 'achterban', 'ongemak', 'discriminatie'], full_text: "Het is verontrustend dat deze partij openlijk discrimineert en toch zo'n grote achterban heeft. #ongemak #discriminatie", keyword_clusters: {'partij': 4, 'achterban': 4, 'ongemak': 2, 'discriminatie': 2}},
{sentiment: 0.9951432943344116, lat: null, lng: null, count: 1, topics: ['kwetsbaarheid', 'ongemak'], group: 'piet', emojis: [], keywords: ['manier', 'partij', 'groepen', 'samenleving', 'kwetsbaarheid', 'ongemak'], full_text: "Ik voel me ongemakkelijk bij de manier waarop deze partij omgaat met kwetsbare groepen in de samenleving. #kwetsbaarheid #ongemak", keyword_clusters: {'manier': 5, 'partij': 4, 'groepen': 5, 'samenleving': 7, 'kwetsbaarheid': 2, 'ongemak': 2}},
{sentiment: 0.06812775135040283, lat: null, lng: null, count: 1, topics: ['angstzaaierij', 'ongemak'], group: 'piet', emojis: [], keywords: ['partij', 'verdeeldheid', 'angst', 'angstzaaierij', 'ongemak'], full_text: "Het lijkt wel alsof deze partij erop uit is om verdeeldheid te zaaien en angst te creëren. #angstzaaierij #ongemak", keyword_clusters: {'partij': 4, 'verdeeldheid': 2, 'angst': 2, 'angstzaaierij': 2, 'ongemak': 2}},
{sentiment: 0.05385720729827881, lat: null, lng: null, count: 1, topics: ['ongemak', 'radicalisme'], group: 'piet', emojis: [], keywords: ['mensen', 'verstand', 'partij', 'standpunten', 'ongemak', 'radicalisme'], full_text: "Ik kan me niet voorstellen dat mensen met gezond verstand op deze partij stemmen, gezien hun radicale standpunten. #ongemak #radicalisme", keyword_clusters: {'mensen': 0, 'verstand': 7, 'partij': 4, 'standpunten': 0, 'ongemak': 2, 'radicalisme': 2}},
{sentiment: 0.9969203472137451, lat: null, lng: null, count: 1, topics: ['polarisatie', 'ongemak'], group: 'piet', emojis: [], keywords: ['partij', 'ruimte', 'debat', 'polarisatie', 'polarisatie', 'ongemak'], full_text: "Het is teleurstellend dat deze partij zoveel ruimte krijgt in het publieke debat, terwijl ze alleen maar polarisatie veroorzaken. #polarisatie #ongemak", keyword_clusters: {'partij': 4, 'ruimte': 5, 'debat': 7, 'polarisatie': 2, 'ongemak': 2}},
{sentiment: 0.9942400455474854, lat: null, lng: null, count: 1, topics: ['zorgelijk', 'ongemak'], group: 'piet', emojis: [], keywords: ['opkomst', 'partij', 'gevolgen', 'democratie', 'samenleving', 'ongemak'], full_text: "Ik ben echt bezorgd over de opkomst van deze partij en de gevolgen die dat kan hebben voor onze democratie en samenleving. #zorgelijk #ongemak", keyword_clusters: {'opkomst': 7, 'partij': 4, 'gevolgen': 1, 'democratie': 5, 'samenleving': 7, 'ongemak': 2}},
{sentiment: 0.9982354640960693, lat: null, lng: null, count: 1, topics: ['indirectetijdsaanduiding'], group: 'piet', emojis: [], keywords: ['indirectetijdsaanduiding'], full_text: "Ik hoop dat alles goed met je gaat, nadat we elkaar voor het laatst hebben gesproken. #indirectetijdsaanduiding", keyword_clusters: {'indirectetijdsaanduiding': 4}},
{sentiment: 0.9976682066917419, lat: null, lng: null, count: 1, topics: ['indirectetijdsaanduiding'], group: 'piet', emojis: [], keywords: ['boek', 'tijd', 'indirectetijdsaanduiding'], full_text: "Het is zo lang geleden dat ik een boek heb gelezen. Misschien wordt het weer tijd om er eentje te pakken. #indirectetijdsaanduiding", keyword_clusters: {'boek': 4, 'tijd': 3, 'indirectetijdsaanduiding': 4}},
{sentiment: 0.9982408285140991, lat: null, lng: null, count: 1, topics: ['indirectetijdsaanduiding'], group: 'piet', emojis: [], keywords: ['kind', 'strand', 'indirectetijdsaanduiding'], full_text: "Ik herinner me nog dat ik als kind altijd naar het strand wilde, maar nu kom ik er bijna nooit meer. #indirectetijdsaanduiding", keyword_clusters: {'kind': 5, 'strand': 7, 'indirectetijdsaanduiding': 4}},
{sentiment: 0.9977832436561584, lat: null, lng: null, count: 1, topics: ['indirectetijdsaanduiding'], group: 'piet', emojis: [], keywords: ['prijzen', 'boodschappen', 'producten', 'indirectetijdsaanduiding'], full_text: "Het lijkt wel alsof de prijzen van boodschappen steeds hoger worden, terwijl het nog maar kort geleden is dat ik voor dezelfde producten veel minder betaalde. #indirectetijdsaanduiding", keyword_clusters: {'prijzen': 0, 'boodschappen': 4, 'producten': 0, 'indirectetijdsaanduiding': 4}},
{sentiment: 0.997918426990509, lat: null, lng: null, count: 1, topics: ['indirectetijdsaanduiding'], group: 'piet', emojis: [], keywords: ['tijd', 'tijd', 'momentje', 'indirectetijdsaanduiding'], full_text: "De afgelopen tijd heb ik steeds minder tijd voor mezelf gehad. Ik moet echt weer eens wat vaker een momentje voor mezelf nemen. #indirectetijdsaanduiding", keyword_clusters: {'tijd': 3, 'momentje': 5, 'indirectetijdsaanduiding': 4}},
{sentiment: 0.9982604384422302, lat: null, lng: null, count: 1, topics: ['indirectetijdsaanduiding'], group: 'piet', emojis: [], keywords: ['vriend', 'tijdje', 'berichtje', 'indirectetijdsaanduiding'], full_text: "Ik ben benieuwd hoe het met die ene vriend gaat die ik al een tijdje niet meer heb gesproken. Misschien moet ik hem weer eens een berichtje sturen. #indirectetijdsaanduiding", keyword_clusters: {'vriend': 7, 'tijdje': 7, 'berichtje': 4, 'indirectetijdsaanduiding': 4}},
{sentiment: 0.9978395700454712, lat: null, lng: null, count: 1, topics: ['indirectetijdsaanduiding'], group: 'piet', emojis: [], keywords: ['regen', 'tijd', 'indirectetijdsaanduiding'], full_text: "Vroeger vond ik het heerlijk om in de regen te spelen, maar de laatste tijd ben ik altijd bang om ziek te worden als ik nat word. #indirectetijdsaanduiding", keyword_clusters: {'regen': 5, 'tijd': 3, 'indirectetijdsaanduiding': 4}},
{sentiment: 0.9976606369018555, lat: null, lng: null, count: 1, topics: ['indirectetijdsaanduiding'], group: 'piet', emojis: [], keywords: ['dagen', 'avond', 'indirectetijdsaanduiding'], full_text: "Het lijkt wel alsof de dagen steeds sneller voorbijgaan. Voor ik het weet is het alweer avond. #indirectetijdsaanduiding", keyword_clusters: {'dagen': 4, 'avond': 0, 'indirectetijdsaanduiding': 4}},
{sentiment: 0.9977850914001465, lat: null, lng: null, count: 1, topics: ['indirectetijdsaanduiding'], group: 'piet', emojis: [], keywords: ['student', 'tijd', 'schema', 'indirectetijdsaanduiding'], full_text: "Ik herinner me nog dat ik als student altijd tijd had om te sporten, maar nu vind ik het lastig om het in mijn drukke schema in te passen. #indirectetijdsaanduiding", keyword_clusters: {'student': 7, 'tijd': 3, 'schema': 4, 'indirectetijdsaanduiding': 4}},
{sentiment: 0.9976154565811157, lat: null, lng: null, count: 1, topics: ['indirectetijdsaanduiding'], group: 'piet', emojis: [], keywords: ['baan', 'maanden', 'indirectetijdsaanduiding'], full_text: "Het voelt als gisteren dat ik aan mijn nieuwe baan begon, maar inmiddels zijn er alweer maanden voorbijgevlogen. #indirectetijdsaanduiding", keyword_clusters: {'baan': 0, 'maanden': 4, 'indirectetijdsaanduiding': 4}},
{sentiment: 0.998254120349884, lat: 52.370216, lng: 4.895168, count: 1, topics: ['protest', 'datum', 'tijd', 'locatie'], group: 'piet', emojis: [], keywords: ['zaterdag', '15:00', 'uur', 'protest', 'plaats', 'Dam', 'Amsterdam', 'wetgeving', 'protest', 'datum', 'tijd', 'locatie'], full_text: "Komende zaterdag om 15:00 uur vindt er een protest plaats op de Dam in Amsterdam tegen de nieuwe wetgeving. #protest #datum #tijd #locatie", keyword_clusters: {'zaterdag': 0, '15:00': 0, 'uur': 0, 'protest': 1, 'plaats': 0, 'Dam': 7, 'Amsterdam': 0, 'wetgeving': 0, 'datum': 3, 'tijd': 3, 'locatie': 3}},
{sentiment: 0.9981043338775635, lat: null, lng: null, count: 1, topics: ['voetbal', 'datum', 'tijd', 'locatie'], group: 'piet', emojis: [], keywords: ['juni', 'uur', 'elftal', 'wedstrijd', 'Portugal', 'Johan', 'Cruijff', 'ArenA', 'Amsterdam', 'voetbal', 'datum', 'tijd', 'locatie'], full_text: "Op 5 juni om 20:00 uur speelt het Nederlands elftal een belangrijke wedstrijd tegen Portugal in de Johan Cruijff ArenA in Amsterdam. #voetbal #datum #tijd #locatie", keyword_clusters: {'juni': 7, 'uur': 0, 'elftal': 7, 'wedstrijd': 5, 'Portugal': 4, 'Johan': 5, 'Cruijff': 7, 'ArenA': 7, 'Amsterdam': 0, 'voetbal': 6, 'datum': 3, 'tijd': 3, 'locatie': 3}},
{sentiment: 0.9982705116271973, lat: null, lng: null, count: 1, topics: ['lezing', 'datum', 'tijd', 'locatie'], group: 'piet', emojis: [], keywords: ['donderdag', 'uur', 'lezing', 'duurzaamheid', 'bibliotheek', 'Utrecht', 'lezing', 'datum', 'tijd', 'locatie'], full_text: "Aanstaande donderdag om 19:30 uur geef ik een lezing over duurzaamheid in de bibliotheek van Utrecht. #lezing #datum #tijd #locatie", keyword_clusters: {'donderdag': 0, 'uur': 0, 'lezing': 6, 'duurzaamheid': 1, 'bibliotheek': 5, 'Utrecht': 7, 'datum': 3, 'tijd': 3, 'locatie': 3}},
{sentiment: 0.9976430535316467, lat: null, lng: null, count: 1, topics: ['keukenhof', 'datum', 'tijd', 'locatie'], group: 'piet', emojis: [], keywords: ['september', 'uur', 'Keukenhof', 'deuren', 'publiek', 'Keukenhof', 'datum', 'tijd', 'locatie'], full_text: "Op 23 september om 12:00 uur opent de Keukenhof haar deuren weer voor het publiek. Mis het niet! #Keukenhof #datum #tijd #locatie", keyword_clusters: {'september': 5, 'uur': 0, 'Keukenhof': 0, 'deuren': 4, 'publiek': 7, 'datum': 3, 'tijd': 3, 'locatie': 3}},
{sentiment: 0.9981197714805603, lat: null, lng: null, count: 1, topics: ['concert', 'datum', 'tijd', 'locatie'], group: 'piet', emojis: [], keywords: ['mei', 'uur', 'concert', 'plaats', 'Rotterdams', 'Philharmonisch', 'Orkest', 'De', 'Doelen', 'Rotterdam', 'concert', 'datum', 'tijd', 'locatie'], full_text: "Op 14 mei om 19:00 uur vindt er een concert plaats van het Rotterdams Philharmonisch Orkest in De Doelen in Rotterdam. #concert #datum #tijd #locatie", keyword_clusters: {'mei': 4, 'uur': 0, 'concert': 6, 'plaats': 0, 'Rotterdams': 0, 'Philharmonisch': 0, 'Orkest': 0, 'De': 4, 'Doelen': 7, 'Rotterdam': 0, 'datum': 3, 'tijd': 3, 'locatie': 3}},
{sentiment: 0.9984696507453918, lat: null, lng: null, count: 1, topics: ['workshop', 'datum', 'tijd', 'locatie'], group: 'piet', emojis: [], keywords: ['uur', 'workshop', 'storytelling', 'Tropenmuseum', 'Amsterdam', 'workshop', 'datum', 'tijd', 'locatie'], full_text: "Morgenochtend om 9:00 uur geef ik een workshop over storytelling in het Tropenmuseum in Amsterdam. #workshop #datum #tijd #locatie", keyword_clusters: {'uur': 0, 'workshop': 6, 'storytelling': 7, 'Tropenmuseum': 4, 'Amsterdam': 0, 'datum': 3, 'tijd': 3, 'locatie': 3}},
{sentiment: 0.9982026815414429, lat: null, lng: null, count: 1, topics: ['buurtbarbecue', 'datum', 'tijd', 'locatie'], group: 'piet', emojis: [], keywords: ['juli', 'uur', 'buurtbarbecue', 'plaats', 'park', 'Kerkstraat', 'Den', 'Haag', 'buurtbarbecue', 'datum', 'tijd', 'locatie'], full_text: "Op 17 juli om 14:00 uur vindt er een buurtbarbecue plaats in het park bij de Kerkstraat in Den Haag. Neem allemaal iets lekkers mee! #buurtbarbecue #datum #tijd #locatie", keyword_clusters: {'juli': 5, 'uur': 0, 'buurtbarbecue': 6, 'plaats': 0, 'park': 5, 'Kerkstraat': 5, 'Den': 0, 'Haag': 5, 'datum': 3, 'tijd': 3, 'locatie': 3}},
{sentiment: 0.9981649518013, lat: 51.4392648, lng: 5.478633, count: 1, topics: ['marathon', 'datum', 'tijd', 'locatie'], group: 'piet', emojis: [], keywords: ['oktober', 'uur', 'marathon', 'Eindhoven', 'lopers', 'marathon', 'datum', 'tijd', 'locatie'], full_text: "Op 9 oktober om 10:00 uur wordt de jaarlijkse marathon van Eindhoven gelopen. Kom jij ook de lopers aanmoedigen? #marathon #datum #tijd #locatie", keyword_clusters: {'oktober': 5, 'uur': 0, 'marathon': 6, 'Eindhoven': 5, 'lopers': 0, 'datum': 3, 'tijd': 3, 'locatie': 3}},
{sentiment: 0.9981934428215027, lat: null, lng: null, count: 1, topics: ['rommelmarkt', 'datum', 'tijd', 'locatie'], group: 'piet', emojis: [], keywords: ['augustus', 'uur', 'rommelmarkt', 'plaats', 'Kinkerstraat', 'Amsterdam', 'spulletjes', 'rommelmarkt', 'datum', 'tijd', 'locatie'], full_text: "Op 21 augustus om 13:00 uur vindt er een rommelmarkt plaats in de Kinkerstraat in Amsterdam. Kom langs en scoor de leukste spulletjes! #rommelmarkt #datum #tijd #locatie", keyword_clusters: {'augustus': 5, 'uur': 0, 'rommelmarkt': 6, 'plaats': 0, 'Kinkerstraat': 4, 'Amsterdam': 0, 'spulletjes': 0, 'datum': 3, 'tijd': 3, 'locatie': 3}},
{sentiment: 0.9984134435653687, lat: null, lng: null, count: 1, topics: ['tentoonstelling', 'datum', 'tijd', 'locatie'], group: 'piet', emojis: [], keywords: ['week', 'dinsdag', 'uur', 'tentoonstelling', 'Rijksmuseum', 'Amsterdam', 'tentoonstelling', 'datum', 'tijd', 'locatie'], full_text: "Volgende week dinsdag om 18:00 uur opent de nieuwe tentoonstelling in het Rijksmuseum in Amsterdam. Mis het niet! #tentoonstelling #datum #tijd #locatie", keyword_clusters: {'week': 0, 'dinsdag': 7, 'uur': 0, 'tentoonstelling': 6, 'Rijksmuseum': 4, 'Amsterdam': 0, 'datum': 3, 'tijd': 3, 'locatie': 3}},
{sentiment: 0.9980883002281189, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'milieu', 'uitbreiding', 'ongerustheid'], group: 'piet', emojis: [], keywords: ['impact', 'uitbreiding', 'Schiphol', 'milieu', 'Schiphol', 'milieu', 'uitbreiding', 'ongerustheid'], full_text: "Wanneer gaan we eens nadenken over de impact van de uitbreiding van Schiphol op ons milieu? #Schiphol #milieu #uitbreiding #ongerustheid", keyword_clusters: {'impact': 5, 'uitbreiding': 1, 'Schiphol': 5, 'milieu': 1, 'ongerustheid': 1}},
{sentiment: 0.9975945353507996, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'milieu', 'leefomgeving'], group: 'piet', emojis: [], keywords: ['groei', 'Schiphol', 'koste', 'leefomgeving', 'Schiphol', 'uitbreiding', 'milieu', 'leefomgeving'], full_text: "Het is toch van de zotte dat we de groei van Schiphol blijven faciliteren, terwijl we weten dat het ten koste gaat van onze leefomgeving? #Schiphol #uitbreiding #milieu #leefomgeving", keyword_clusters: {'groei': 4, 'Schiphol': 5, 'koste': 5, 'leefomgeving': 1, 'uitbreiding': 1, 'milieu': 1}},
{sentiment: 0.9967895150184631, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'overlast', 'vervuiling'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'overlast', 'vervuiling', 'Schiphol', 'uitbreiding', 'overlast', 'vervuiling'], full_text: "De uitbreiding van Schiphol zorgt alleen maar voor meer overlast en vervuiling. Wanneer gaan we eens stoppen met groeien? #Schiphol #uitbreiding #overlast #vervuiling", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'overlast': 1, 'vervuiling': 1}},
{sentiment: 0.9977472424507141, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'duurzaamheid', 'alternatieven'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'prioriteit', 'alternatieven', 'Schiphol', 'uitbreiding', 'duurzaamheid', 'alternatieven'], full_text: "Het is onbegrijpelijk dat we de uitbreiding van Schiphol nog steeds als prioriteit zien, terwijl we moeten investeren in duurzame alternatieven. #Schiphol #uitbreiding #duurzaamheid #alternatieven", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'prioriteit': 4, 'alternatieven': 1, 'duurzaamheid': 1}},
{sentiment: 0.9977443218231201, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'economie', 'milieu', 'gezondheid'], group: 'piet', emojis: [], keywords: ['voordelen', 'uitbreiding', 'Schiphol', 'nadelen', 'milieu', 'gezondheid', 'Schiphol', 'uitbreiding', 'economie', 'milieu', 'gezondheid'], full_text: "We moeten ons afvragen of de economische voordelen van de uitbreiding van Schiphol opwegen tegen de nadelen voor ons milieu en onze gezondheid. #Schiphol #uitbreiding #economie #milieu #gezondheid", keyword_clusters: {'voordelen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'nadelen': 5, 'milieu': 1, 'gezondheid': 1, 'economie': 1}},
{sentiment: 0.03124547004699707, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'vliegbewegingen', 'geluidsoverlast'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'vliegbewegingen', 'geluidsoverlast', 'tijd', 'waanzin', 'Schiphol', 'uitbreiding', 'vliegbewegingen', 'geluidsoverlast'], full_text: "De uitbreiding van Schiphol betekent alleen maar meer vliegbewegingen en meer geluidsoverlast. Het is tijd om te stoppen met deze waanzin. #Schiphol #uitbreiding #vliegbewegingen #geluidsoverlast", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'vliegbewegingen': 1, 'geluidsoverlast': 1, 'tijd': 3, 'waanzin': 4}},
{sentiment: 0.9954130053520203, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'milieu', 'duurzaamheid', 'alternatieven'], group: 'piet', emojis: [], keywords: ['milieu', 'uitbreiding', 'Schiphol', 'alternatieven', 'Schiphol', 'uitbreiding', 'milieu', 'duurzaamheid', 'alternatieven'], full_text: "Als we echt om ons milieu geven, dan moeten we de uitbreiding van Schiphol stoppen en ons richten op duurzame alternatieven. #Schiphol #uitbreiding #milieu #duurzaamheid #alternatieven", keyword_clusters: {'milieu': 1, 'uitbreiding': 1, 'Schiphol': 5, 'alternatieven': 1, 'duurzaamheid': 1}},
{sentiment: 0.9978235960006714, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'klimaatverandering', 'gevolgen'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'gevolgen', 'klimaatverandering', 'luchtvaart', 'Schiphol', 'uitbreiding', 'klimaatverandering', 'gevolgen'], full_text: "Het is niet alleen de uitbreiding van Schiphol die ons zorgen baart, maar ook de gevolgen van klimaatverandering die door de luchtvaart worden versterkt. #Schiphol #uitbreiding #klimaatverandering #gevolgen", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'gevolgen': 1, 'klimaatverandering': 1, 'luchtvaart': 7}},
{sentiment: 0.9973099231719971, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding', 'overlast', 'belangen'], group: 'piet', emojis: [], keywords: ['mensen', 'last', 'uitbreiding', 'Schiphol', 'tijd', 'belangen', 'Schiphol', 'uitbreiding', 'overlast', 'belangen'], full_text: "Wanneer gaan we eens luisteren naar de mensen die dagelijks last hebben van de uitbreiding van Schiphol? Het wordt tijd om hun belangen serieus te nemen. #Schiphol #uitbreiding #overlast #belangen", keyword_clusters: {'mensen': 0, 'last': 5, 'uitbreiding': 1, 'Schiphol': 5, 'tijd': 3, 'belangen': 1, 'overlast': 1}},
{sentiment: 0.9974865913391113, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'gezondheid', 'milieu', 'leefomgeving'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'koste', 'gezondheid', 'milieu', 'leefomgeving', 'tijd', 'groei', 'Schiphol', 'uitbreiding', 'gezondheid', 'milieu', 'leefomgeving'], full_text: "De uitbreiding van Schiphol gaat ten koste van onze gezondheid, ons milieu en onze leefomgeving. Het is tijd om te stoppen met deze onverantwoorde groei. #Schiphol #uitbreiding #gezondheid #milieu #leefomgeving", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'koste': 5, 'gezondheid': 1, 'milieu': 1, 'leefomgeving': 1, 'tijd': 3, 'groei': 4}},
{sentiment: 0.9973983764648438, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'milieu', 'gezondheid', 'samensterk'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'stemmen', 'milieu', 'gezondheid', 'Schiphol', 'uitbreiding', 'milieu', 'gezondheid', 'samensterk'], full_text: "Samen kunnen we ervoor zorgen dat de uitbreiding van Schiphol wordt gestopt. Laten we onze stemmen horen en opkomen voor ons milieu en onze gezondheid! #Schiphol #uitbreiding #milieu #gezondheid #samensterk", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'stemmen': 5, 'milieu': 1, 'gezondheid': 1, 'samensterk': 1}},
{sentiment: 0.9965665340423584, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'gezamenlijk', 'zorgen'], group: 'piet', emojis: [], keywords: ['Schiphol', 'krachten', 'zorgen', 'Schiphol', 'uitbreiding', 'zorgen'], full_text: "Als we niets doen, blijft Schiphol groeien en gaan wij eronder lijden. Laten we daarom de krachten bundelen en onze zorgen uiten. #Schiphol #uitbreiding #gezamenlijk #zorgen", keyword_clusters: {'Schiphol': 5, 'krachten': 0, 'zorgen': 1, 'uitbreiding': 1}},
{sentiment: 0.9975689053535461, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding', 'actie', 'duurzaamheid'], group: 'piet', emojis: [], keywords: ['tijd', 'actie', 'uitbreiding', 'Schiphol', 'toekomst', 'groei', 'Schiphol', 'uitbreiding', 'actie', 'duurzaamheid'], full_text: "Het is tijd om in actie te komen tegen de uitbreiding van Schiphol. Laten we samen strijden voor een duurzame toekomst en ons verzetten tegen onverantwoorde groei. #Schiphol #uitbreiding #actie #duurzaamheid", keyword_clusters: {'tijd': 3, 'actie': 1, 'uitbreiding': 1, 'Schiphol': 5, 'toekomst': 1, 'groei': 4, 'duurzaamheid': 1}},
{sentiment: 0.9975088834762573, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding', 'opstand', 'leefomgeving', 'toekomst'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'opstand', 'leefomgeving', 'toekomst', 'Schiphol', 'uitbreiding', 'opstand', 'leefomgeving', 'toekomst'], full_text: "We moeten ons niet neerleggen bij de uitbreiding van Schiphol, maar juist in opstand komen. Laten we samen vechten voor een gezonde leefomgeving en een leefbare toekomst! #Schiphol #uitbreiding #opstand #leefomgeving #toekomst", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'opstand': 1, 'leefomgeving': 1, 'toekomst': 1}},
{sentiment: 0.9972165822982788, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'samenwerken', 'welzijn', 'toekomst'], group: 'piet', emojis: [], keywords: ['stem', 'uitbreiding', 'Schiphol', 'welzijn', 'toekomst', 'Schiphol', 'uitbreiding', 'welzijn', 'toekomst'], full_text: "Alleen samen kunnen we ervoor zorgen dat onze stem gehoord wordt en dat de uitbreiding van Schiphol gestopt wordt. Laten we ons verenigen en opkomen voor ons welzijn en onze toekomst! #Schiphol #uitbreiding #samenwerken #welzijn #toekomst", keyword_clusters: {'stem': 1, 'uitbreiding': 1, 'Schiphol': 5, 'welzijn': 1, 'toekomst': 1}},
{sentiment: 0.9975564479827881, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding', 'samenwerking', 'milieu', 'gezondheid'], group: 'piet', emojis: [], keywords: ['tijd', 'krachten', 'uitbreiding', 'Schiphol', 'milieu', 'gezondheid', 'leefomgeving', 'Schiphol', 'uitbreiding', 'samenwerking', 'milieu', 'gezondheid'], full_text: "Het is tijd om de krachten te bundelen en ons te verzetten tegen de uitbreiding van Schiphol. Laten we samenwerken en opkomen voor ons milieu, onze gezondheid en onze leefomgeving! #Schiphol #uitbreiding #samenwerking #milieu #gezondheid", keyword_clusters: {'tijd': 3, 'krachten': 0, 'uitbreiding': 1, 'Schiphol': 5, 'milieu': 1, 'gezondheid': 1, 'leefomgeving': 1, 'samenwerking': 1}},
{sentiment: 0.9967575669288635, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding', 'verzet', 'leefomgeving', 'gezondheid', 'duurzaamheid'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'leefomgeving', 'gezondheid', 'spel', 'actie', 'toekomst', 'Schiphol', 'uitbreiding', 'verzet', 'leefomgeving', 'gezondheid', 'duurzaamheid'], full_text: "We moeten ons verzetten tegen de uitbreiding van Schiphol, omdat onze leefomgeving en onze gezondheid op het spel staan. Laten we samen actie ondernemen voor een duurzame toekomst! #Schiphol #uitbreiding #verzet #leefomgeving #gezondheid #duurzaamheid", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'leefomgeving': 1, 'gezondheid': 1, 'spel': 4, 'actie': 1, 'toekomst': 1, 'verzet': 1, 'duurzaamheid': 1}},
{sentiment: 0.9974716901779175, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding', 'gezamenlijk', 'stem', 'toekomst'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'stem', 'toekomst', 'Schiphol', 'uitbreiding', 'stem', 'toekomst'], full_text: "Als we samen opstaan tegen de uitbreiding van Schiphol, dan kunnen we ervoor zorgen dat onze stem gehoord wordt. Laten we ons verenigen en opkomen voor een gezonde en leefbare toekomst! #Schiphol #uitbreiding #gezamenlijk #stem #toekomst", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'stem': 1, 'toekomst': 1}},
{sentiment: 0.9966080188751221, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding', 'samenwerking', 'welzijn', 'toekomst', 'kinderen'], group: 'piet', emojis: [], keywords: ['tijd', 'handen', 'uitbreiding', 'Schiphol', 'welzijn', 'toekomst', 'kinderen', 'Schiphol', 'uitbreiding', 'samenwerking', 'welzijn', 'toekomst', 'kinderen'], full_text: "Het is tijd om de handen ineen te slaan en ons te verzetten tegen de uitbreiding van Schiphol. Laten we samenwerken en opkomen voor ons welzijn en de toekomst van onze kinderen! #Schiphol #uitbreiding #samenwerking #welzijn #toekomst #kinderen", keyword_clusters: {'tijd': 3, 'handen': 5, 'uitbreiding': 1, 'Schiphol': 5, 'welzijn': 1, 'toekomst': 1, 'kinderen': 1, 'samenwerking': 1}},
{sentiment: 0.9972231388092041, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding', 'samen'], group: 'piet', emojis: [], keywords: ['actie', 'uitbreiding', 'Schiphol', 'leefomgeving', 'gezondheid', 'toekomst', 'Schiphol', 'uitbreiding'], full_text: "Als we samen actie ondernemen tegen de uitbreiding van Schiphol, dan kunnen we ervoor zorgen dat onze leefomgeving en onze gezondheid beschermd worden. Laten we ons verenigen en opkomen voor een duurzame toekomst! #Schiphol #uitbreiding #samen", keyword_clusters: {'actie': 1, 'uitbreiding': 1, 'Schiphol': 5, 'leefomgeving': 1, 'gezondheid': 1, 'toekomst': 1}},
{sentiment: 0.9972460269927979, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'kunstproject', 'milieubewustzijn', 'verandering'], group: 'piet', emojis: [], keywords: ['creativiteit', 'uitbreiding', 'Schiphol', 'gemeenschapskunstproject', 'bewustzijn', 'verandering', 'Schiphol', 'kunstproject', 'milieubewustzijn', 'verandering'], full_text: "Laten we samen onze creativiteit inzetten om de uitbreiding van Schiphol te stoppen! Doe mee aan onze gemeenschapskunstproject om bewustzijn te creëren en verandering te inspireren. #Schiphol #kunstproject #milieubewustzijn #verandering", keyword_clusters: {'creativiteit': 1, 'uitbreiding': 1, 'Schiphol': 5, 'gemeenschapskunstproject': 5, 'bewustzijn': 1, 'verandering': 1, 'kunstproject': 1, 'milieubewustzijn': 1}},
{sentiment: 0.997856080532074, lat: null, lng: null, count: 1, topics: ['schiphol', 'kunstproject', 'stem', 'welzijn'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'kunstproject', 'stem', 'uitbreiding', 'omgeving', 'welzijn', 'Schiphol', 'kunstproject', 'stem', 'welzijn'], full_text: "Ben je bezorgd over de uitbreiding van Schiphol? Doe dan mee aan ons kunstproject om jouw stem te laten horen! Laat ons zien wat deze uitbreiding betekent voor onze omgeving en ons welzijn. #Schiphol #kunstproject #stem #welzijn", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'kunstproject': 1, 'stem': 1, 'omgeving': 0, 'welzijn': 1}},
{sentiment: 0.9978073239326477, lat: null, lng: null, count: 1, topics: ['schiphol', 'kunstproject', 'samenwerking', 'bewustzijn'], group: 'piet', emojis: [], keywords: ['creativiteit', 'middel', 'verandering', 'gemeenschapskunstproject', 'bewustzijn', 'planeet', 'welzijn', 'Schiphol', 'kunstproject', 'samenwerking', 'bewustzijn'], full_text: "Onze creativiteit kan een krachtig middel zijn om verandering teweeg te brengen. Laten we samenwerken aan een gemeenschapskunstproject om bewustzijn te creëren en op te komen voor onze planeet en ons welzijn. #Schiphol #kunstproject #samenwerking #bewustzijn", keyword_clusters: {'creativiteit': 1, 'middel': 7, 'verandering': 1, 'gemeenschapskunstproject': 5, 'bewustzijn': 1, 'planeet': 1, 'welzijn': 1, 'Schiphol': 5, 'kunstproject': 1, 'samenwerking': 1}},
{sentiment: 0.9981301426887512, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'kunstproject', 'stem', 'leefomgeving', 'gezondheid'], group: 'piet', emojis: [], keywords: ['stem', 'kunstproject', 'gevolgen', 'uitbreiding', 'Schiphol', 'leefomgeving', 'gezondheid', 'Schiphol', 'kunstproject', 'stem', 'leefomgeving', 'gezondheid'], full_text: "Zorg ervoor dat jouw stem wordt gehoord! Doe mee aan ons kunstproject om de negatieve gevolgen van de uitbreiding van Schiphol voor onze leefomgeving en gezondheid te laten zien. #Schiphol #kunstproject #stem #leefomgeving #gezondheid", keyword_clusters: {'stem': 1, 'kunstproject': 1, 'gevolgen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'leefomgeving': 1, 'gezondheid': 1}},
{sentiment: 0.9978759288787842, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'kunstproject', 'inspiratie', 'verandering'], group: 'piet', emojis: [], keywords: ['Kunst', 'kracht', 'mensen', 'gemeenschapskunstproject', 'problemen', 'uitbreiding', 'Schiphol', 'kaarten', 'verandering', 'Schiphol', 'kunstproject', 'inspiratie', 'verandering'], full_text: "Kunst heeft de kracht om mensen te raken en te inspireren. Doe mee aan ons gemeenschapskunstproject om de problemen rondom de uitbreiding van Schiphol aan te kaarten en verandering te bewerkstelligen. #Schiphol #kunstproject #inspiratie #verandering", keyword_clusters: {'Kunst': 7, 'kracht': 5, 'mensen': 0, 'gemeenschapskunstproject': 5, 'problemen': 4, 'uitbreiding': 1, 'Schiphol': 5, 'kaarten': 7, 'verandering': 1, 'kunstproject': 1, 'inspiratie': 1}},
{sentiment: 0.997028648853302, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'kunstproject', 'samenwerking', 'leefbaretoekomst'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'kunstproject', 'bewustzijn', 'toekomst', 'Schiphol', 'kunstproject', 'samenwerking', 'leefbaretoekomst'], full_text: "We moeten ons verenigen en creatief zijn om de uitbreiding van Schiphol te stoppen. Laten we samenwerken aan een kunstproject om bewustzijn te creëren en op te komen voor een leefbare toekomst. #Schiphol #kunstproject #samenwerking #leefbaretoekomst", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'kunstproject': 1, 'bewustzijn': 1, 'toekomst': 1, 'samenwerking': 1, 'leefbaretoekomst': 1}},
{sentiment: 0.9982016086578369, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'kunstproject', 'beeldtaal', 'welzijn', 'leefomgeving'], group: 'piet', emojis: [], keywords: ['beeld', 'woorden', 'gemeenschapskunstproject', 'gevolgen', 'uitbreiding', 'Schiphol', 'welzijn', 'leefomgeving', 'Schiphol', 'kunstproject', 'beeldtaal', 'welzijn', 'leefomgeving'], full_text: "Een beeld zegt meer dan duizend woorden. Laten we samenwerken aan een gemeenschapskunstproject om de gevolgen van de uitbreiding van Schiphol voor ons welzijn en onze leefomgeving te visualiseren. #Schiphol #kunstproject #beeldtaal #welzijn #leefomgeving", keyword_clusters: {'beeld': 5, 'woorden': 7, 'gemeenschapskunstproject': 5, 'gevolgen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'welzijn': 1, 'leefomgeving': 1, 'kunstproject': 1, 'beeldtaal': 1}},
{sentiment: 0.9977414608001709, lat: null, lng: null, count: 1, topics: ['schiphol', 'kunstproject', 'verbeeldingskracht', 'verandering'], group: 'piet', emojis: [], keywords: ['Creativiteit', 'verbeeldingskracht', 'combinatie', 'strijd', 'uitbreiding', 'Schiphol', 'kunstproject', 'stem', 'verandering', 'Schiphol', 'kunstproject', 'verbeeldingskracht', 'verandering'], full_text: "Creativiteit en verbeeldingskracht kunnen een krachtige combinatie zijn in de strijd tegen de uitbreiding van Schiphol. Doe mee aan ons kunstproject om jouw stem te laten horen en verandering te bewerkstelligen! #Schiphol #kunstproject #verbeeldingskracht #verandering", keyword_clusters: {'Creativiteit': 0, 'verbeeldingskracht': 1, 'combinatie': 7, 'strijd': 7, 'uitbreiding': 1, 'Schiphol': 5, 'kunstproject': 1, 'stem': 1, 'verandering': 1}},
{sentiment: 0.9970616698265076, lat: null, lng: null, count: 1, topics: ['schiphol', 'kunstproject', 'opstaan', 'welzijn', 'planeet'], group: 'piet', emojis: [], keywords: ['tijd', 'stem', 'uitbreiding', 'Schiphol', 'creativiteit', 'kunstproject', 'uitbreiding', 'welzijn', 'planeet', 'Schiphol', 'kunstproject', 'welzijn', 'planeet'], full_text: "Het is tijd om op te staan en onze stem te laten horen tegen de uitbreiding van Schiphol. Laten we onze creativiteit inzetten voor een kunstproject om te laten zien hoe deze uitbreiding ons welzijn en onze planeet bedreigt. #Schiphol #kunstproject #opstaan #welzijn #planeet", keyword_clusters: {'tijd': 3, 'stem': 1, 'uitbreiding': 1, 'Schiphol': 5, 'creativiteit': 1, 'kunstproject': 1, 'welzijn': 1, 'planeet': 1}},
{sentiment: 0.998319685459137, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['gemeenschapskunstproject', 'gevolgen', 'uitbreiding', 'Schiphol', 'verandering'], full_text: "Doe mee aan ons gemeenschapskunstproject om de negatieve gevolgen van de uitbreiding van Schiphol te laten zien en verandering te", keyword_clusters: {'gemeenschapskunstproject': 5, 'gevolgen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'verandering': 1}},
{sentiment: 0.9981728792190552, lat: null, lng: null, count: 1, topics: ['schiphol', 'kunstproject', 'bewustzijn', 'haarlemmermeer'], group: 'piet', emojis: [], keywords: ['agenda', 'zaterdag', 'april', 'gemeenschapskunstproject', 'bewustzijn', 'uitbreiding', 'Schiphol', 'park', 'Raadhuis', 'Haarlemmermeer', 'stem', 'Schiphol', 'kunstproject', 'bewustzijn', 'Haarlemmermeer'], full_text: "Zet het in je agenda: op zaterdag 8 april organiseren we ons gemeenschapskunstproject om bewustzijn te creëren over de uitbreiding van Schiphol. Kom naar het park bij het Raadhuis van Haarlemmermeer en laat jouw stem horen! #Schiphol #kunstproject #bewustzijn #Haarlemmermeer", keyword_clusters: {'agenda': 7, 'zaterdag': 0, 'april': 7, 'gemeenschapskunstproject': 5, 'bewustzijn': 1, 'uitbreiding': 1, 'Schiphol': 5, 'park': 5, 'Raadhuis': 5, 'Haarlemmermeer': 0, 'stem': 1, 'kunstproject': 1}},
{sentiment: 0.9982038736343384, lat: null, lng: null, count: 1, topics: ['schiphol', 'kunstproject', 'gemeenschap', 'amsterdamsebos'], group: 'piet', emojis: [], keywords: ['kunstenaars', 'zondag', 'april', 'kunstproject', 'uitbreiding', 'Schiphol', 'uur', 'ingang', 'Bos', 'Schiphol', 'kunstproject', 'gemeenschap', 'AmsterdamseBos'], full_text: "We roepen alle kunstenaars en gemeenschapsleden op om zich bij ons aan te sluiten op zondag 16 april voor ons kunstproject tegen de uitbreiding van Schiphol. We verzamelen om 11.00 uur bij de ingang van het Amsterdamse Bos. #Schiphol #kunstproject #gemeenschap #AmsterdamseBos", keyword_clusters: {'kunstenaars': 4, 'zondag': 5, 'april': 7, 'kunstproject': 1, 'uitbreiding': 1, 'Schiphol': 5, 'uur': 0, 'ingang': 5, 'Bos': 7, 'gemeenschap': 1, 'AmsterdamseBos': 7}},
{sentiment: 0.9982407093048096, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'kunstproject', 'leefomgeving'], group: 'piet', emojis: [], keywords: ['zaterdag', 'april', 'kunstproject', 'gevolgen', 'uitbreiding', 'Schiphol', 'leefomgeving', 'uur', 'Kasteel', 'Keukenhof', 'Lisse', 'stem', 'Schiphol', 'kunstproject', 'leefomgeving'], full_text: "Op zaterdag 22 april organiseren we een kunstproject om de negatieve gevolgen van de uitbreiding van Schiphol op onze leefomgeving aan te kaarten. We ontmoeten elkaar om 13.00 uur bij het Kasteel Keukenhof in Lisse. Kom en laat je stem horen! #Schiphol #kunstproject #leefomgeving", keyword_clusters: {'zaterdag': 0, 'april': 7, 'kunstproject': 1, 'gevolgen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'leefomgeving': 1, 'uur': 0, 'Kasteel': 7, 'Keukenhof': 0, 'Lisse': 4, 'stem': 1}},
{sentiment: 0.9979793429374695, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'kunstproject', 'creativiteit', 'schipholplaza'], group: 'piet', emojis: [], keywords: ['kunstproject', 'uitbreiding', 'Schiphol', 'zondag', 'april', 'uur', 'rotonde', 'Schiphol', 'Plaza', 'creativiteit', 'Schiphol', 'kunstproject', 'creativiteit', 'SchipholPlaza'], full_text: "Maak je klaar voor ons kunstproject tegen de uitbreiding van Schiphol op zondag 30 april! We verzamelen om 10.00 uur bij de rotonde bij Schiphol Plaza. Kom en laat jouw creativiteit zien! #Schiphol #kunstproject #creativiteit #SchipholPlaza", keyword_clusters: {'kunstproject': 1, 'uitbreiding': 1, 'Schiphol': 5, 'zondag': 5, 'april': 7, 'uur': 0, 'rotonde': 5, 'Plaza': 7, 'creativiteit': 1, 'SchipholPlaza': 7}},
{sentiment: 0.9981683492660522, lat: null, lng: null, count: 1, topics: ['schiphol', 'kunstproject', 'bewustzijn', 'amstelveen'], group: 'piet', emojis: [], keywords: ['hulp', 'gemeenschapskunstproject', 'zaterdag', 'mei', 'bewustzijn', 'uitbreiding', 'Schiphol', 'uur', 'Stadshuis', 'Amstelveen', 'Schiphol', 'kunstproject', 'bewustzijn', 'Amstelveen'], full_text: "We hebben jouw hulp nodig! Doe mee aan ons gemeenschapskunstproject op zaterdag 6 mei om bewustzijn te creëren over de uitbreiding van Schiphol. We verzamelen om 12.00 uur bij het Stadshuis in Amstelveen. #Schiphol #kunstproject #bewustzijn #Amstelveen", keyword_clusters: {'hulp': 5, 'gemeenschapskunstproject': 5, 'zaterdag': 0, 'mei': 4, 'bewustzijn': 1, 'uitbreiding': 1, 'Schiphol': 5, 'uur': 0, 'Stadshuis': 7, 'Amstelveen': 4, 'kunstproject': 1}},
{sentiment: 0.9981517195701599, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'kunstproject', 'gezondheid', 'amsterdamsebos'], group: 'piet', emojis: [], keywords: ['zondag', 'mei', 'kunstproject', 'gevolgen', 'uitbreiding', 'Schiphol', 'gezondheid', 'uur', 'Bos', 'Schiphol', 'kunstproject', 'gezondheid', 'AmsterdamseBos'], full_text: "Op zondag 14 mei organiseren we een kunstproject om de negatieve gevolgen van de uitbreiding van Schiphol op onze gezondheid aan te kaarten. We ontmoeten elkaar om 15.00 uur bij het Amsterdamse Bos. Kom en doe mee! #Schiphol #kunstproject #gezondheid #AmsterdamseBos", keyword_clusters: {'zondag': 5, 'mei': 4, 'kunstproject': 1, 'gevolgen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'gezondheid': 1, 'uur': 0, 'Bos': 7, 'AmsterdamseBos': 7}},
{sentiment: 0.9982700347900391, lat: null, lng: null, count: 1, topics: ['schiphol', 'kunstproject', 'bewustzijn', 'vondelpark'], group: 'piet', emojis: [], keywords: ['stem', 'uitbreiding', 'Schiphol', 'kunstproject', 'zaterdag', 'mei', 'bewustzijn', 'kwestie', 'uur', 'ingang', 'Vondelpark', 'Schiphol', 'kunstproject', 'bewustzijn', 'Vondelpark'], full_text: "Laat jouw stem horen tegen de uitbreiding van Schiphol! Kom naar ons kunstproject op zaterdag 20 mei en help ons bewustzijn te creëren over deze kwestie. We verzamelen om 14.00 uur bij de ingang van het Vondelpark. #Schiphol #kunstproject #bewustzijn #Vondelpark", keyword_clusters: {'stem': 1, 'uitbreiding': 1, 'Schiphol': 5, 'kunstproject': 1, 'zaterdag': 0, 'mei': 4, 'bewustzijn': 1, 'kwestie': 0, 'uur': 0, 'ingang': 5, 'Vondelpark': 0}},
{sentiment: 0.9982511401176453, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'kunstproject', 'verandering', 'haarlemmermeer'], group: 'piet', emojis: [], keywords: ['gemeenschapskunstproject', 'zondag', 'mei', 'uitbreiding', 'Schiphol', 'kaarten', 'verandering', 'uur', 'Stadshuis', 'Haarlemmermeer', 'Schiphol', 'kunstproject', 'verandering', 'Haarlemmermeer'], full_text: "Doe mee aan ons gemeenschapskunstproject op zondag 28 mei om de uitbreiding van Schiphol aan te kaarten en verandering te bewerkstelligen. We verzamelen om 11.00 uur bij het Stadshuis in Haarlemmermeer. #Schiphol #kunstproject #verandering #Haarlemmermeer", keyword_clusters: {'gemeenschapskunstproject': 5, 'zondag': 5, 'mei': 4, 'uitbreiding': 1, 'Schiphol': 5, 'kaarten': 7, 'verandering': 1, 'uur': 0, 'Stadshuis': 7, 'Haarlemmermeer': 0, 'kunstproject': 1}},
{sentiment: 0.9984332919120789, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['creativiteit', 'kunstproject', 'uitbreiding'], full_text: "Kom en laat jouw creativiteit zien op ons kunstproject tegen de uitbreiding", keyword_clusters: {'creativiteit': 1, 'kunstproject': 1, 'uitbreiding': 1}},
{sentiment: 0.997960090637207, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'expansion', 'economy', 'jobs'], group: 'piet', emojis: [], keywords: ['Schiphol', 'protests', 'are', 'shortsighted', 'us', 'all', 'Schiphol', 'jobs'], full_text: "The expansion of Schiphol is crucial for our economy and job market. These protests are shortsighted and only serve to hurt us all. #Schiphol #expansion #economy #jobs", keyword_clusters: {'Schiphol': 5, 'protests': 3, 'are': 0, 'shortsighted': 4, 'us': 7, 'all': 4, 'jobs': 6}},
{sentiment: 0.9981411695480347, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'expansion', 'travel', 'convenience'], group: 'piet', emojis: [], keywords: ['traveler', 'Protests', 'Schiphol'], full_text: "As a frequent traveler, I depend on Schiphol for efficient and convenient travel. Protests against expansion only harm the experience for everyone. #Schiphol #expansion #travel #convenience", keyword_clusters: {'traveler': 5, 'Protests': 7, 'Schiphol': 5}},
{sentiment: 0.9978519678115845, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'expansion', 'competition', 'future'], group: 'piet', emojis: [], keywords: ['The', 'Schiphol', 'protests', 'hold', 'Schiphol'], full_text: "The growth of Schiphol is necessary to keep up with global competition and meet the demands of the future. Don't let these protests hold us back. #Schiphol #expansion #competition #future", keyword_clusters: {'The': 4, 'Schiphol': 5, 'protests': 3, 'hold': 4}},
{sentiment: 0.9978861212730408, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'expansion', 'benefits', 'progress'], group: 'piet', emojis: [], keywords: ['Schiphol', 'progress', 'Schiphol', 'progress'], full_text: "I support the expansion of Schiphol and the benefits it will bring to our city and country. Don't let a small group of protestors stop progress. #Schiphol #expansion #benefits #progress", keyword_clusters: {'Schiphol': 5, 'progress': 3}},
{sentiment: 0.9977706670761108, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'expansion', 'greatergood', 'protests'], group: 'piet', emojis: [], keywords: ['protestors', 'Schiphol', 'Schiphol', 'greatergood', 'protests'], full_text: "These protestors are only thinking about their own interests and not the greater good. The expansion of Schiphol will benefit everyone in the long run. #Schiphol #expansion #greatergood #protests", keyword_clusters: {'protestors': 0, 'Schiphol': 5, 'greatergood': 3, 'protests': 3}},
{sentiment: 0.9976724982261658, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'expansion', 'travelers', 'progress'], group: 'piet', emojis: [], keywords: ['Schiphol', 'travelers', 'operations', 'these', 'protests', 'hinder', 'Schiphol', 'travelers', 'progress'], full_text: "The expansion of Schiphol is necessary to accommodate the growing number of travelers and ensure efficient operations. Let's not let these protests hinder our progress. #Schiphol #expansion #travelers #progress", keyword_clusters: {'Schiphol': 5, 'travelers': 3, 'operations': 0, 'these': 4, 'protests': 3, 'hinder': 7, 'progress': 3}},
{sentiment: 0.9979196190834045, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'expansion', 'aviationindustry', 'protests'], group: 'piet', emojis: [], keywords: ['Schiphol', 'behind', 'because', 'protests', 'Schiphol', 'aviationindustry', 'protests'], full_text: "The expansion of Schiphol is a necessary step to keep up with the changing demands of the aviation industry. Let's not fall behind because of protests. #Schiphol #expansion #aviationindustry #protests", keyword_clusters: {'Schiphol': 5, 'behind': 4, 'because': 0, 'protests': 3, 'aviationindustry': 3}},
{sentiment: 0.9965640902519226, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'expansion', 'longtermbenefits', 'protests'], group: 'piet', emojis: [], keywords: ['The', 'protestors', 'Schiphol', 'longtermbenefits', 'protests'], full_text: "The protestors against Schiphol expansion are only thinking about their own immediate concerns and not the long-term benefits for our country. Let's not let them hold us back. #Schiphol #expansion #longtermbenefits #protests", keyword_clusters: {'The': 4, 'protestors': 0, 'Schiphol': 5, 'longtermbenefits': 3, 'protests': 3}},
{sentiment: 0.9982200264930725, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'expansion', 'internationaltrade', 'protests'], group: 'piet', emojis: [], keywords: ['Schiphol', 'for', 'international', 'trade', 'protests', 'Schiphol', 'protests'], full_text: "As a business owner, I rely on the convenience and accessibility of Schiphol for international trade. These protests only hurt our ability to compete globally. #Schiphol #expansion #internationaltrade #protests", keyword_clusters: {'Schiphol': 5, 'for': 7, 'international': 4, 'trade': 0, 'protests': 3}},
{sentiment: 0.997617781162262, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'expansion', 'jobsgrowth', 'progress'], group: 'piet', emojis: [], keywords: ['Schiphol', 'protestors', 'stand', 'progress', 'Schiphol', 'progress'], full_text: "The expansion of Schiphol will bring jobs and economic growth to our country. Don't let these protestors stand in the way of progress. #Schiphol #expansion #jobsgrowth #progress", keyword_clusters: {'Schiphol': 5, 'protestors': 0, 'stand': 0, 'progress': 3}},
{sentiment: 0.9974773526191711, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'economie', 'werkgelegenheid'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'economie', 'werkgelegenheid', 'protesten', 'Schiphol', 'uitbreiding', 'economie', 'werkgelegenheid'], full_text: "De uitbreiding van Schiphol is cruciaal voor onze economie en de werkgelegenheid. Deze protesten zijn kortzichtig en schaden ons allemaal. #Schiphol #uitbreiding #economie #werkgelegenheid", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'economie': 1, 'werkgelegenheid': 1, 'protesten': 1}},
{sentiment: 0.9979285001754761, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'reizen', 'gemak'], group: 'piet', emojis: [], keywords: ['reiziger', 'Schiphol', 'reizen', 'Protesten', 'uitbreiding', 'ervaring', 'Schiphol', 'uitbreiding', 'gemak'], full_text: "Als frequente reiziger ben ik afhankelijk van Schiphol voor efficiënte en handige reizen. Protesten tegen uitbreiding schaden alleen de ervaring voor iedereen. #Schiphol #uitbreiding #reizen #gemak", keyword_clusters: {'reiziger': 4, 'Schiphol': 5, 'reizen': 1, 'Protesten': 0, 'uitbreiding': 1, 'ervaring': 7, 'gemak': 1}},
{sentiment: 0.9971712231636047, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'concurrentie', 'toekomst'], group: 'piet', emojis: [], keywords: ['groei', 'Schiphol', 'concurrentie', 'eisen', 'toekomst', 'protesten', 'Schiphol', 'uitbreiding', 'concurrentie', 'toekomst'], full_text: "De groei van Schiphol is noodzakelijk om de wereldwijde concurrentie bij te houden en te voldoen aan de eisen van de toekomst. Laat deze protesten ons niet tegenhouden. #Schiphol #uitbreiding #concurrentie #toekomst", keyword_clusters: {'groei': 4, 'Schiphol': 5, 'concurrentie': 1, 'eisen': 5, 'toekomst': 1, 'protesten': 1, 'uitbreiding': 1}},
{sentiment: 0.9976332187652588, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'voordelen', 'vooruitgang'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'voordelen', 'stad', 'land', 'groep', 'protestanten', 'vooruitgang', 'Schiphol', 'uitbreiding', 'voordelen', 'vooruitgang'], full_text: "Ik steun de uitbreiding van Schiphol en de voordelen die het zal brengen voor onze stad en ons land. Laat een kleine groep protestanten de vooruitgang niet stoppen. #Schiphol #uitbreiding #voordelen #vooruitgang", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'voordelen': 1, 'stad': 4, 'land': 7, 'groep': 5, 'protestanten': 7, 'vooruitgang': 1}},
{sentiment: 0.9976223111152649, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'grotergoed', 'protesten'], group: 'piet', emojis: [], keywords: ['demonstranten', 'belangen', 'uitbreiding', 'Schiphol', 'termijn', 'Schiphol', 'uitbreiding', 'grotergoed', 'protesten'], full_text: "Deze demonstranten denken alleen aan hun eigen belangen en niet aan het grotere goed. De uitbreiding van Schiphol zal op de lange termijn iedereen ten goede komen. #Schiphol #uitbreiding #grotergoed #protesten", keyword_clusters: {'demonstranten': 5, 'belangen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'termijn': 5, 'grotergoed': 1, 'protesten': 1}},
{sentiment: 0.9974943399429321, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'reizigers', 'vooruitgang'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'aantal', 'reizigers', 'operaties', 'protesten', 'vooruitgang', 'Schiphol', 'uitbreiding', 'reizigers', 'vooruitgang'], full_text: "De uitbreiding van Schiphol is nodig om het groeiende aantal reizigers te accommoderen en efficiënte operaties te garanderen. Laten we deze protesten onze vooruitgang niet belemmeren. #Schiphol #uitbreiding #reizigers #vooruitgang", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'aantal': 5, 'reizigers': 1, 'operaties': 5, 'protesten': 1, 'vooruitgang': 1}},
{sentiment: 0.9974610805511475, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'luchtvaartindustrie', 'protesten'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'stap', 'eisen', 'luchtvaartindustrie', 'protesten', 'Schiphol', 'uitbreiding', 'luchtvaartindustrie', 'protesten'], full_text: "De uitbreiding van Schiphol is een noodzakelijke stap om aan de veranderende eisen van de luchtvaartindustrie te voldoen. Laten we niet achterblijven vanwege protesten. #Schiphol #uitbreiding #luchtvaartindustrie #protesten", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'stap': 0, 'eisen': 5, 'luchtvaartindustrie': 1, 'protesten': 1}},
{sentiment: 0.9964770674705505, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'langetermijnvoordelen', 'protesten'], group: 'piet', emojis: [], keywords: ['protestanten', 'uitbreiding', 'Schiphol', 'zorgen', 'langetermijnvoordelen', 'land', 'Schiphol', 'uitbreiding', 'langetermijnvoordelen', 'protesten'], full_text: "De protestanten tegen de uitbreiding van Schiphol denken alleen aan hun eigen directe zorgen en niet aan de langetermijnvoordelen voor ons land. Laten we ons niet laten tegenhouden. #Schiphol #uitbreiding #langetermijnvoordelen #protesten", keyword_clusters: {'protestanten': 7, 'uitbreiding': 1, 'Schiphol': 5, 'zorgen': 1, 'langetermijnvoordelen': 1, 'land': 7, 'protesten': 1}},
{sentiment: 0.9975799322128296, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'internationalehandel', 'protesten'], group: 'piet', emojis: [], keywords: ['bedrijfseigenaar', 'gemak', 'toegankelijkheid', 'Schiphol', 'handel', 'protesten', 'vermogen', 'Schiphol', 'uitbreiding', 'internationalehandel', 'protesten'], full_text: "Als bedrijfseigenaar ben ik afhankelijk van het gemak en de toegankelijkheid van Schiphol voor internationale handel. Deze protesten schaden alleen ons vermogen om wereldwijd te concurreren. #Schiphol #uitbreiding #internationalehandel #protesten", keyword_clusters: {'bedrijfseigenaar': 4, 'gemak': 1, 'toegankelijkheid': 7, 'Schiphol': 5, 'handel': 2, 'protesten': 1, 'vermogen': 5, 'uitbreiding': 1, 'internationalehandel': 1}},
{sentiment: 0.9971659779548645, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'banengroei', 'vooruitgang'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'banen', 'groei', 'land', 'protestanten', 'vooruitgang', 'weg', 'Schiphol', 'uitbreiding', 'banengroei', 'vooruitgang'], full_text: "De uitbreiding van Schiphol zal banen en economische groei naar ons land brengen. Laat deze protestanten de vooruitgang niet in de weg staan. #Schiphol #uitbreiding #banengroei #vooruitgang", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'banen': 0, 'groei': 4, 'land': 7, 'protestanten': 7, 'vooruitgang': 1, 'weg': 0, 'banengroei': 1}},
{sentiment: 0.9946433305740356, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol', 'expansie', 'angst'], group: 'piet', emojis: [], keywords: ['angst', 'Schiphol', 'reizen', 'stopSchiphol', 'expansie', 'angst'], full_text: "Oh nee, ik heb zo'n angst dat Schiphol te groot wordt en we allemaal betere en goedkopere reizen krijgen. #stopSchiphol #expansie #angst", keyword_clusters: {'angst': 2, 'Schiphol': 5, 'reizen': 1, 'stopSchiphol': 4, 'expansie': 2}},
{sentiment: 0.9940378665924072, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol', 'sluiting', 'paardenwagen'], group: 'piet', emojis: [], keywords: ['Schiphol', 'dagen', 'paard', 'wagen', 'stopSchiphol', 'sluiting', 'paardenwagen'], full_text: "Laten we Schiphol gewoon sluiten, dan kunnen we allemaal terugkeren naar de goede oude dagen van het paard en wagen. #stopSchiphol #sluiting #paardenwagen", keyword_clusters: {'Schiphol': 5, 'dagen': 4, 'paard': 0, 'wagen': 5, 'stopSchiphol': 4, 'sluiting': 2, 'paardenwagen': 2}},
{sentiment: 0.9955151677131653, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol', 'expansie', 'planeet'], group: 'piet', emojis: [], keywords: ['Schiphol', 'planeet', 'stopSchiphol', 'expansie', 'planeet'], full_text: "Ik ben zo bang dat Schiphol zo groot wordt dat het een eigen planeet wordt. #stopSchiphol #expansie #planeet", keyword_clusters: {'Schiphol': 5, 'planeet': 1, 'stopSchiphol': 4, 'expansie': 2}},
{sentiment: 0.9571096897125244, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol', 'expansie', 'handbagage'], group: 'piet', emojis: [], keywords: ['Schiphol', 'handbagage', 'stopSchiphol', 'expansie', 'handbagage'], full_text: "Als we Schiphol niet stoppen, worden we binnenkort allemaal verplicht om te reizen met alleen handbagage. Horror! #stopSchiphol #expansie #handbagage", keyword_clusters: {'Schiphol': 5, 'handbagage': 2, 'stopSchiphol': 4, 'expansie': 2}},
{sentiment: 0.9927594661712646, lat: 51.5879873, lng: 4.9194511, count: 1, topics: ['stopschiphol', 'expansie', 'souvenirwinkels'], group: 'piet', emojis: [], keywords: ['Schiphol', 'plaats', 'rijen', 'souvenirwinkels', 'stopSchiphol', 'expansie', 'souvenirwinkels'], full_text: "Ik ben zo bang dat als Schiphol blijft groeien, we geen plaats meer hebben voor de eindeloze rijen souvenirwinkels. #stopSchiphol #expansie #souvenirwinkels", keyword_clusters: {'Schiphol': 5, 'plaats': 0, 'rijen': 5, 'souvenirwinkels': 2, 'stopSchiphol': 4, 'expansie': 2}},
{sentiment: 0.9950318336486816, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol', 'expansie', 'boardingpas'], group: 'piet', emojis: [], keywords: ['Schiphol', 'boardingpas', 'stopSchiphol', 'expansie', 'boardingpas'], full_text: "Als we Schiphol niet stoppen, dan zijn we straks allemaal verplicht om een boardingpas te tatoeëren. #stopSchiphol #expansie #boardingpas", keyword_clusters: {'Schiphol': 5, 'boardingpas': 2, 'stopSchiphol': 4, 'expansie': 2}},
{sentiment: 0.018787682056427002, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol', 'expansie', 'brainwashen'], group: 'piet', emojis: ['🤬'], keywords: ['Schiphol', 'stopSchiphol', 'expansie', '🤬'], full_text: "Ik denk dat Schiphol ons allemaal probeert te brainwashen om te denken dat reizen leuk is. We moeten stoppen voordat het te laat is! #stopSchiphol #expansie #brainwashen 🤬", keyword_clusters: {'Schiphol': 5, 'stopSchiphol': 4, 'expansie': 2}},
{sentiment: 0.016252577304840088, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol', 'expansie', 'fietsvakantie'], group: 'piet', emojis: ['🤬'], keywords: ['fiets', 'vakantiebestemming', 'Schiphol', 'stopSchiphol', 'expansie', 'fietsvakantie', '🤬'], full_text: "Laten we allemaal op de fiets naar onze vakantiebestemming gaan, want als we Schiphol blijven laten groeien, wordt reizen straks belachelijk gemakkelijk en betaalbaar. #stopSchiphol #expansie #fietsvakantie 🤬", keyword_clusters: {'fiets': 0, 'vakantiebestemming': 0, 'Schiphol': 5, 'stopSchiphol': 4, 'expansie': 2, 'fietsvakantie': 2}},
{sentiment: 0.020576119422912598, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol', 'expansie', 'vechten'], group: 'piet', emojis: ['🤬'], keywords: ['Schiphol', 'zitplaats', 'vliegtuig', 'plekken', 'stopSchiphol', 'expansie', '🤬'], full_text: "Als Schiphol blijft groeien, dan zullen we binnenkort moeten vechten om een zitplaats in het vliegtuig. Laten we stoppen voordat we allemaal blauwe plekken hebben. #stopSchiphol #expansie #vechten 🤬", keyword_clusters: {'Schiphol': 5, 'zitplaats': 7, 'vliegtuig': 0, 'plekken': 7, 'stopSchiphol': 4, 'expansie': 2}},
{sentiment: 0.015767812728881836, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol', 'expansie', 'fouilleren'], group: 'piet', emojis: ['🤬'], keywords: ['Schiphol', 'aankomst', 'bestemming', 'bevolking', 'stopSchiphol', 'expansie', '🤬'], full_text: "Als we Schiphol niet stoppen, dan zijn we binnenkort allemaal verplicht om bij aankomst op onze bestemming te worden gefouilleerd door de lokale bevolking. #stopSchiphol #expansie #fouilleren 🤬", keyword_clusters: {'Schiphol': 5, 'aankomst': 0, 'bestemming': 0, 'bevolking': 0, 'stopSchiphol': 4, 'expansie': 2}},
{sentiment: 0.012651324272155762, lat: null, lng: null, count: 1, topics: ['politiek', 'droeftoeter'], group: 'piet', emojis: ['🤮'], keywords: ['droeftoeters', 'tegenpartij', 'idee', 'politiek', 'droeftoeter', '🤮'], full_text: "Die droeftoeters van de tegenpartij hebben geen idee wat ze doen. #politiek #droeftoeter 🤮", keyword_clusters: {'droeftoeters': 7, 'tegenpartij': 4, 'idee': 5, 'politiek': 1, 'droeftoeter': 1}},
{sentiment: 0.016921937465667725, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'economie'], group: 'piet', emojis: ['🤬'], keywords: ['mensen', 'voordelen', 'Schiphol', 'plaats', 'effecten', 'Schiphol', 'protesten', 'economie', '🤬'], full_text: "Het is schokkend om te zien hoeveel mensen bereid zijn om de economische voordelen van Schiphol te negeren en zich in plaats daarvan te concentreren op de vermeende negatieve effecten. #Schiphol #protesten #economie 🤬", keyword_clusters: {'mensen': 0, 'voordelen': 1, 'Schiphol': 5, 'plaats': 0, 'effecten': 4, 'protesten': 1, 'economie': 1}},
{sentiment: 0.04712212085723877, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'hypocrisie'], group: 'piet', emojis: ['🤮'], keywords: ['hypocrisie', 'protestanten', 'Schiphol', 'wereld', 'luchthaven', 'Schiphol', 'protesten', 'hypocrisie'], full_text: "De hypocrisie van sommige protestanten tegen Schiphol is verbazingwekkend. Ze vliegen zelf de hele wereld over, maar klagen over de luchthaven als het hen uitkomt. #Schiphol #protesten #hypocrisie 🤮", keyword_clusters: {'hypocrisie': 1, 'protestanten': 7, 'Schiphol': 5, 'wereld': 7, 'luchthaven': 3, 'protesten': 1}},
{sentiment: 0.02866685390472412, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'rellen'], group: 'piet', emojis: ['🤮'], keywords: ['mensen', 'protesten', 'Schiphol', 'excuus', 'rotzooi', 'Schiphol', 'protesten', 'rellen'], full_text: "Het is triest om te zien hoe sommige mensen de protesten tegen Schiphol gebruiken als een excuus om gewoon lekker te rellen en rotzooi te trappen. #Schiphol #protesten #rellen 🤮", keyword_clusters: {'mensen': 0, 'protesten': 1, 'Schiphol': 5, 'excuus': 5, 'rotzooi': 0, 'rellen': 1}},
{sentiment: 0.030030786991119385, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'overdrijven'], group: 'piet', emojis: ['🤮'], keywords: ['mensen', 'uitbreiding', 'Schiphol', 'protestanten', 'retoriek', "doemscenario's", 'Schiphol', 'protesten'], full_text: "Het is begrijpelijk dat mensen bezorgd zijn over de uitbreiding van Schiphol, maar sommige protestanten gaan veel te ver met hun overdreven retoriek en doemscenario's. #Schiphol #protesten #overdrijven 🤮", keyword_clusters: {'mensen': 0, 'uitbreiding': 1, 'Schiphol': 5, 'protestanten': 7, 'retoriek': 0, "doemscenario's": 4, 'protesten': 1}},
{sentiment: 0.9980827569961548, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'politiek'], group: 'piet', emojis: [], keywords: ['politici', 'protesten', 'Schiphol', 'agenda', 'Schiphol', 'protesten', 'politiek'], full_text: "Het is frustrerend om te zien dat sommige politici de protesten tegen Schiphol misbruiken om hun eigen agenda te promoten en zichzelf te profileren. #Schiphol #protesten #politiek", keyword_clusters: {'politici': 0, 'protesten': 1, 'Schiphol': 5, 'agenda': 7, 'politiek': 1}},
{sentiment: 0.02570521831512451, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'economie'], group: 'piet', emojis: ['🤬'], keywords: ['protestanten', 'idee', 'voordelen', 'Schiphol', 'land', 'termijnbelangen', 'Schiphol', 'protesten', 'economie', '🤬'], full_text: "Het is duidelijk dat sommige protestanten geen idee hebben van de economische voordelen van Schiphol voor ons land. Ze denken alleen aan hun eigen korte termijnbelangen. #Schiphol #protesten #economie 🤬", keyword_clusters: {'protestanten': 7, 'idee': 5, 'voordelen': 1, 'Schiphol': 5, 'land': 7, 'termijnbelangen': 4, 'protesten': 1, 'economie': 1}},
{sentiment: 0.9979729056358337, lat: 52.2434979, lng: 5.6343227, count: 1, topics: ['schiphol', 'protesten', 'reclame'], group: 'piet', emojis: [], keywords: ['protesten', 'Schiphol', 'anti-reclamecampagne', 'Nederland', 'poging', 'luchthaven', 'Schiphol', 'protesten', 'reclame'], full_text: "De protesten tegen Schiphol lijken meer op een anti-reclamecampagne voor Nederland dan op een serieuze poging om de luchthaven te veranderen. #Schiphol #protesten #reclame", keyword_clusters: {'protesten': 1, 'Schiphol': 5, 'anti-reclamecampagne': 5, 'Nederland': 5, 'poging': 5, 'luchthaven': 3, 'reclame': 1}},
{sentiment: 0.9979608058929443, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'media'], group: 'piet', emojis: [], keywords: ['media', 'protesten', 'Schiphol', 'sensatie', 'kijkcijfers', 'plaats', 'verslag', 'Schiphol', 'protesten', 'media'], full_text: "Het is teleurstellend dat sommige media de protesten tegen Schiphol alleen maar gebruiken om sensatie te maken en kijkcijfers te scoren, in plaats van een eerlijk en evenwichtig verslag te doen. #Schiphol #protesten #media", keyword_clusters: {'media': 1, 'protesten': 1, 'Schiphol': 5, 'sensatie': 7, 'kijkcijfers': 4, 'plaats': 0, 'verslag': 7}},
{sentiment: 0.9980092644691467, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'wetenschap'], group: 'piet', emojis: ['😤'], keywords: ['protestanten', 'wetenschappers', 'experts', 'gebied', 'luchtvaart', 'milieu', 'Schiphol', 'protesten', 'wetenschap', '😤'], full_text: "Het is onbegrijpelijk dat sommige protestanten denken dat ze het beter weten dan wetenschappers en experts op het gebied van luchtvaart en milieu. #Schiphol #protesten #wetenschap 😤", keyword_clusters: {'protestanten': 7, 'wetenschappers': 4, 'experts': 4, 'gebied': 4, 'luchtvaart': 7, 'milieu': 1, 'Schiphol': 5, 'protesten': 1, 'wetenschap': 1}},
{sentiment: 0.035902440547943115, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'oplossingen'], group: 'piet', emojis: ['😤'], keywords: ['tijd', 'Schiphol', 'plaats', 'oplossingen', 'problemen', 'Schiphol', 'protesten', 'oplossingen'], full_text: "Het is tijd dat we ophouden met het demoniseren van Schiphol en in plaats daarvan werken aan constructieve oplossingen voor de problemen die het met zich meebrengt. #Schiphol #protesten #oplossingen 😤", keyword_clusters: {'tijd': 3, 'Schiphol': 5, 'plaats': 0, 'oplossingen': 1, 'problemen': 4, 'protesten': 1}},
{sentiment: 0.9898415207862854, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'economie'], group: 'piet', emojis: ['😤'], keywords: ['Protesten', 'Schiphol', 'voordelen', 'Schiphol', 'protesten', 'economie', '😤'], full_text: "Protesten tegen Schiphol negeren economische voordelen. #Schiphol #protesten #economie 😤", keyword_clusters: {'Protesten': 0, 'Schiphol': 5, 'voordelen': 1, 'protesten': 1, 'economie': 1}},
{sentiment: 0.3340680003166199, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'hypocrisie'], group: 'piet', emojis: ['😤'], keywords: ['Hypocrisie', 'protestanten', 'Schiphol', 'Schiphol', 'protesten', 'hypocrisie'], full_text: "Hypocrisie van sommige protestanten tegen Schiphol is verbazingwekkend. #Schiphol #protesten #hypocrisie 😤", keyword_clusters: {'Hypocrisie': 7, 'protestanten': 7, 'Schiphol': 5, 'protesten': 1, 'hypocrisie': 1}},
{sentiment: 0.9927714467048645, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'rellen'], group: 'piet', emojis: ['😤'], keywords: ['protesten', 'Schiphol', 'rellen', 'Schiphol', 'protesten', 'rellen'], full_text: "Sommige protesten tegen Schiphol ontaarden in rellen. #Schiphol #protesten #rellen 😤", keyword_clusters: {'protesten': 1, 'Schiphol': 5, 'rellen': 1}},
{sentiment: 0.015464842319488525, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'overdrijven'], group: 'piet', emojis: ['🤬'], keywords: ['Protesten', 'Schiphol', "risico's", 'Schiphol', 'protesten', '🤬'], full_text: "Protesten tegen Schiphol overdrijven risico's. #Schiphol #protesten #overdrijven 🤬", keyword_clusters: {'Protesten': 0, 'Schiphol': 5, "risico's": 7, 'protesten': 1}},
{sentiment: 0.16762375831604004, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'politiek'], group: 'piet', emojis: ['😤'], keywords: ['Politici', 'protesten', 'Schiphol', 'Schiphol', 'protesten', 'politiek'], full_text: "Politici misbruiken protesten tegen Schiphol. #Schiphol #protesten #politiek 😤", keyword_clusters: {'Politici': 5, 'protesten': 1, 'Schiphol': 5, 'politiek': 1}},
{sentiment: 0.02780371904373169, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'economie'], group: 'piet', emojis: ['🤬'], keywords: ['Protesten', 'Schiphol', 'voordelen', 'Schiphol', 'protesten', 'economie', '🤬'], full_text: "Protesten tegen Schiphol negeren economische voordelen. #Schiphol #protesten #economie 🤬", keyword_clusters: {'Protesten': 0, 'Schiphol': 5, 'voordelen': 1, 'protesten': 1, 'economie': 1}},
{sentiment: 0.8980523943901062, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'reclame'], group: 'piet', emojis: ['🤬'], keywords: ['Protesten', 'Schiphol', 'anti-reclame', 'Nederland', 'Schiphol', 'protesten', 'reclame', '🤬'], full_text: "Protesten tegen Schiphol zijn anti-reclame voor Nederland. #Schiphol #protesten #reclame 🤬", keyword_clusters: {'Protesten': 0, 'Schiphol': 5, 'anti-reclame': 4, 'Nederland': 5, 'protesten': 1, 'reclame': 1}},
{sentiment: 0.019188404083251953, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'media'], group: 'piet', emojis: ['🤬'], keywords: ['Media', 'sensatie', 'protesten', 'Schiphol', 'Schiphol', 'protesten', 'media', '🤬'], full_text: "Media maken sensatie van protesten tegen Schiphol. #Schiphol #protesten #media 🤬", keyword_clusters: {'Media': 7, 'sensatie': 7, 'protesten': 1, 'Schiphol': 5, 'media': 1}},
{sentiment: 0.018695056438446045, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'wetenschap'], group: 'piet', emojis: ['🤬'], keywords: ['Protestanten', 'experts', 'Schiphol', 'protesten', 'wetenschap', '🤬'], full_text: "Protestanten denken het beter te weten dan experts. #Schiphol #protesten #wetenschap 🤬", keyword_clusters: {'Protestanten': 0, 'experts': 4, 'Schiphol': 5, 'protesten': 1, 'wetenschap': 1}},
{sentiment: 0.9978664517402649, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'oplossingen'], group: 'piet', emojis: [], keywords: ['demonisering', 'Schiphol', 'werk', 'oplossingen', 'Schiphol', 'protesten', 'oplossingen'], full_text: "Stop demonisering van Schiphol, werk aan oplossingen. #Schiphol #protesten #oplossingen", keyword_clusters: {'demonisering': 4, 'Schiphol': 5, 'werk': 4, 'oplossingen': 1, 'protesten': 1}},
{sentiment: 0.9980364441871643, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'economie', 'weer'], group: 'piet', emojis: [], keywords: ['protesten', 'Schiphol', 'voordelen', 'Schiphol', 'protesten', 'economie', 'weer'], full_text: "Weer protesten tegen Schiphol, terwijl economische voordelen overduidelijk zijn. #Schiphol #protesten #economie #weer", keyword_clusters: {'protesten': 1, 'Schiphol': 5, 'voordelen': 1, 'economie': 1, 'weer': 1}},
{sentiment: 0.9982659220695496, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'hypocrisie', 'ook'], group: 'piet', emojis: [], keywords: ['Hypocrisie', 'protestanten', 'Schiphol', 'Schiphol', 'protesten', 'hypocrisie'], full_text: "Hypocrisie van sommige protestanten tegen Schiphol is verbazingwekkend, ook nu weer. #Schiphol #protesten #hypocrisie #ook", keyword_clusters: {'Hypocrisie': 7, 'protestanten': 7, 'Schiphol': 5, 'protesten': 1, 'hypocrisie': 1}},
{sentiment: 0.01563405990600586, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'rellen', 'ook'], group: 'piet', emojis: ['🤬'], keywords: ['Protesten', 'Schiphol', 'keer', 'rellen', 'Schiphol', 'protesten', 'rellen', '🤬'], full_text: "Protesten tegen Schiphol ontaarden ook deze keer weer in rellen. #Schiphol #protesten #rellen #ook 🤬", keyword_clusters: {'Protesten': 0, 'Schiphol': 5, 'keer': 7, 'rellen': 1, 'protesten': 1}},
{sentiment: 0.9962130784988403, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'overdrijven', 'weer'], group: 'piet', emojis: [], keywords: ['Protesten', 'Schiphol', "risico's", 'Schiphol', 'protesten'], full_text: "Protesten tegen Schiphol overdrijven risico's, weer. #Schiphol #protesten #overdrijven #weer", keyword_clusters: {'Protesten': 0, 'Schiphol': 5, "risico's": 7, 'protesten': 1}},
{sentiment: 0.016129136085510254, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'politiek', 'ook'], group: 'piet', emojis: ['🤬'], keywords: ['Politici', 'keer', 'protesten', 'Schiphol', 'Schiphol', 'protesten', 'politiek', '🤬'], full_text: "Politici misbruiken ook dit keer weer protesten tegen Schiphol. #Schiphol #protesten #politiek #ook 🤬", keyword_clusters: {'Politici': 5, 'keer': 7, 'protesten': 1, 'Schiphol': 5, 'politiek': 1}},
{sentiment: 0.997676432132721, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'economie', 'weer'], group: 'piet', emojis: [], keywords: ['protesten', 'Schiphol', 'voordelen', 'Schiphol', 'protesten', 'economie', 'weer'], full_text: "Weer protesten tegen Schiphol die economische voordelen negeren. #Schiphol #protesten #economie #weer", keyword_clusters: {'protesten': 1, 'Schiphol': 5, 'voordelen': 1, 'economie': 1, 'weer': 1}},
{sentiment: 0.9977840781211853, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'protesten', 'reclame', 'ook'], group: 'piet', emojis: [], keywords: ['Protesten', 'Schiphol', 'anti-reclame', 'Nederland', 'Schiphol', 'protesten', 'reclame'], full_text: "Protesten tegen Schiphol zijn anti-reclame voor Nederland, ook nu weer. #Schiphol #protesten #reclame #ook", keyword_clusters: {'Protesten': 0, 'Schiphol': 5, 'anti-reclame': 4, 'Nederland': 5, 'protesten': 1, 'reclame': 1}},
{sentiment: 0.9981589913368225, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'media', 'weer'], group: 'piet', emojis: [], keywords: ['Media', 'sensatie', 'protesten', 'Schiphol', 'Schiphol', 'protesten', 'media'], full_text: "Media maken sensatie van protesten tegen Schiphol, ook weer. #Schiphol #protesten #media #weer", keyword_clusters: {'Media': 7, 'sensatie': 7, 'protesten': 1, 'Schiphol': 5, 'media': 1}},
{sentiment: 0.99815434217453, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'wetenschap', 'ook'], group: 'piet', emojis: [], keywords: ['Protestanten', 'experts', 'Schiphol', 'protesten', 'wetenschap'], full_text: "Protestanten denken het beter te weten dan experts, ook nu weer. #Schiphol #protesten #wetenschap #ook", keyword_clusters: {'Protestanten': 0, 'experts': 4, 'Schiphol': 5, 'protesten': 1, 'wetenschap': 1}},
{sentiment: 0.9971464276313782, lat: null, lng: null, count: 1, topics: ['schiphol', 'protesten', 'oplossingen', 'ook'], group: 'piet', emojis: [], keywords: ['demonisering', 'Schiphol', 'werk', 'oplossingen', 'Schiphol', 'protesten', 'oplossingen'], full_text: "Stop demonisering van Schiphol, werk aan oplossingen, ook nu weer. #Schiphol #protesten #oplossingen #ook", keyword_clusters: {'demonisering': 4, 'Schiphol': 5, 'werk': 4, 'oplossingen': 1, 'protesten': 1}},
{sentiment: 0.9982669353485107, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['discussie', 'uitbreiding', 'Schiphol', 'onderwerp', 'tijd', 'gemoederen', 'Nederland', 'discussie', 'Twitter', 'media-site', 'mensen', 'achtergronden'], full_text: "De discussie over de uitbreiding van Schiphol is een onderwerp dat al geruime tijd de gemoederen bezighoudt in Nederland. Het is dan ook niet verwonderlijk dat deze discussie zich ook op Twitter afspeelt. Op deze social media-site komen mensen met verschillende achtergronden en m", keyword_clusters: {'discussie': 5, 'uitbreiding': 1, 'Schiphol': 5, 'onderwerp': 7, 'tijd': 3, 'gemoederen': 5, 'Nederland': 5, 'Twitter': 7, 'media-site': 5, 'mensen': 0, 'achtergronden': 0}},
{sentiment: 0.9977003931999207, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Twitter-discussie', 'tweedeling', 'mensen', 'uitbreiding', 'Schiphol', 'mensen', 'tweets', 'discussie'], full_text: "In deze Twitter-discussie zien we een duidelijke tweedeling tussen mensen die tegen de uitbreiding van Schiphol zijn en mensen die hiervoor zijn. Dit wordt onderstreept door de verschillende tweets die in deze discussie zijn geplaatst.", keyword_clusters: {'Twitter-discussie': 0, 'tweedeling': 7, 'mensen': 0, 'uitbreiding': 1, 'Schiphol': 5, 'tweets': 4, 'discussie': 5}},
{sentiment: 0.9976925849914551, lat: 53.0006, lng: 6.68635, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['tweets', 'mensen', 'milieueffecten', 'uitbreiding', 'Schiphol', 'feit', 'luchtverkeer', 'bijdrage', 'uitstoot', 'broeikasgassen', 'klimaatverandering', 'overlast'], full_text: "Zo zijn er tweets van mensen die zich zorgen maken over de milieueffecten van de uitbreiding van Schiphol. Zij wijzen op het feit dat het luchtverkeer een grote bijdrage levert aan de uitstoot van broeikasgassen en daarmee aan klimaatverandering. Anderen benadrukken de overlast d", keyword_clusters: {'tweets': 4, 'mensen': 0, 'milieueffecten': 0, 'uitbreiding': 1, 'Schiphol': 5, 'feit': 7, 'luchtverkeer': 0, 'bijdrage': 0, 'uitstoot': 7, 'broeikasgassen': 5, 'klimaatverandering': 1, 'overlast': 1}},
{sentiment: 0.9974164962768555, lat: 53.0006, lng: 6.68635, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['kant', 'tweets', 'mensen', 'voordelen', 'uitbreiding', 'Schiphol', 'Schiphol', 'motor', 'Nederland', 'positie', 'vin'], full_text: "Aan de andere kant zijn er tweets van mensen die wijzen op de economische voordelen van de uitbreiding van Schiphol. Zij wijzen erop dat Schiphol een belangrijke economische motor is voor Nederland en dat het belangrijk is om deze positie te behouden en te versterken. Anderen vin", keyword_clusters: {'kant': 0, 'tweets': 4, 'mensen': 0, 'voordelen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'motor': 0, 'Nederland': 5, 'positie': 7, 'vin': 0}},
{sentiment: 0.9949796795845032, lat: 53.0006, lng: 6.68635, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['standpunten', 'tweets', 'mensen', 'protesten', 'uitbreiding', 'Schiphol', 'protesten', 'protesten', 'rellen'], full_text: "Naast deze standpunten zijn er ook tweets van mensen die zich afzetten tegen de protesten tegen de uitbreiding van Schiphol. Zij noemen deze protesten overdreven of zelfs hypocriet. Anderen wijzen erop dat de protesten soms uitmonden in rellen en dat dit niet bijdraagt aan een zi", keyword_clusters: {'standpunten': 0, 'tweets': 4, 'mensen': 0, 'protesten': 1, 'uitbreiding': 1, 'Schiphol': 5, 'rellen': 1}},
{sentiment: 0.9984112977981567, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Twitter-discussie', 'uitbreiding', 'Schiphol', 'onderwerp', 'mensen', 'discussie', 'discussie', 'manier', 'o'], full_text: "De Twitter-discussie over de uitbreiding van Schiphol laat zien dat dit een onderwerp is dat zeer gevoelig ligt bij veel mensen. De discussie is soms gepassioneerd en soms fel, maar het is belangrijk dat deze discussie blijft plaatsvinden. Alleen op die manier kunnen we tot een o", keyword_clusters: {'Twitter-discussie': 0, 'uitbreiding': 1, 'Schiphol': 5, 'onderwerp': 7, 'mensen': 0, 'discussie': 5, 'manier': 5, 'o': 0}},
{sentiment: 0.9978002905845642, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['discussie', 'Twitter', 'ruimte', 'nuance', 'dialoog', 'samenleving', 'grond', 'oplossingen', 'ied'], full_text: "Het is opvallend dat de discussie op Twitter vaak gepolariseerd is en dat er weinig ruimte lijkt te zijn voor nuance en dialoog. Het zou goed zijn als we ons als samenleving meer zouden richten op het vinden van gemeenschappelijke grond en het zoeken naar oplossingen die voor ied", keyword_clusters: {'discussie': 5, 'Twitter': 7, 'ruimte': 5, 'nuance': 5, 'dialoog': 0, 'samenleving': 7, 'grond': 4, 'oplossingen': 1, 'ied': 4}},
{sentiment: 0.9982870221138, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Twitter-discussie', 'uitbreiding', 'Schiphol', 'onderwerp', 'onderwerp', 'economie', 'milieu', 'manier', 'oplossing'], full_text: "Kortom, de Twitter-discussie over de uitbreiding van Schiphol is een belangrijk onderwerp dat ons allemaal aangaat. Het is een onderwerp dat niet alleen gaat over economie en milieu, maar ook over onze manier van samenleven. Laten we daarom blijven praten en zoeken naar oplossing", keyword_clusters: {'Twitter-discussie': 0, 'uitbreiding': 1, 'Schiphol': 5, 'onderwerp': 7, 'economie': 1, 'milieu': 1, 'manier': 5, 'oplossing': 0}},
{sentiment: 0.9974160194396973, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopgroei', 'luchthaven'], group: 'piet', emojis: [], keywords: ['Schiphol', 'stopgroei', 'luchthaven'], full_text: "Eindelijk iemand die het snapt! Schiphol moet niet verder uitbreiden. #stopgroei #luchthaven", keyword_clusters: {'Schiphol': 5, 'stopgroei': 3, 'luchthaven': 3}},
{sentiment: 0.9977384805679321, lat: 52.1594747, lng: 4.4908843, count: 1, topics: ['gezondeleefomgeving', 'duurzaamheid'], group: 'piet', emojis: [], keywords: ['artikel', 'belangen', 'gezondheid', 'mens', 'milieu', 'gezondeleefomgeving', 'duurzaamheid'], full_text: "Ik ben het helemaal eens met dit artikel. We moeten ons niet laten leiden door economische belangen, maar door de gezondheid van mens en milieu. #gezondeleefomgeving #duurzaamheid", keyword_clusters: {'artikel': 5, 'belangen': 1, 'gezondheid': 1, 'mens': 4, 'milieu': 1, 'gezondeleefomgeving': 1, 'duurzaamheid': 1}},
{sentiment: 0.9970145225524902, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['milieubewustzijn', 'duurzametoekomst'], group: 'piet', emojis: [], keywords: ['tijd', 'uitbreiding', 'Schiphol', 'koste', 'welzijn', 'alternatieven', 'oplossing', 'milieubewustzijn', 'duurzametoekomst'], full_text: "Het wordt tijd dat we ons realiseren dat een uitbreiding van Schiphol ten koste gaat van ons welzijn. Minder vliegen en meer investeren in duurzame alternatieven is de enige oplossing. #milieubewustzijn #duurzametoekomst", keyword_clusters: {'tijd': 3, 'uitbreiding': 1, 'Schiphol': 5, 'koste': 5, 'welzijn': 1, 'alternatieven': 1, 'oplossing': 0, 'milieubewustzijn': 1, 'duurzametoekomst': 1}},
{sentiment: 0.9964518547058105, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['gezondeleefomgeving', 'rechtvaardigheid'], group: 'piet', emojis: [], keywords: ['overlast', 'Schiphol', 'belangen', 'groep', 'gezondeleefomgeving', 'rechtvaardigheid'], full_text: "Eens! De overlast van Schiphol is al groot genoeg. We moeten ons niet laten gijzelen door de belangen van een selecte groep. #gezondeleefomgeving #rechtvaardigheid", keyword_clusters: {'overlast': 1, 'Schiphol': 5, 'belangen': 1, 'groep': 5, 'gezondeleefomgeving': 1, 'rechtvaardigheid': 1}},
{sentiment: 0.9942373037338257, lat: null, lng: null, count: 1, topics: ['bewustzijn', 'natuurbehoud'], group: 'piet', emojis: [], keywords: ['mensen', 'voorstander', "Schiphol's", 'uitbreiding', 'tijd', 'mens', 'natuur', 'groei', 'bewustzijn', 'natuurbehoud'], full_text: "Ik kan niet begrijpen waarom er nog steeds mensen zijn die voorstander zijn van Schiphol's uitbreiding. Het is tijd om te kiezen voor de mens en de natuur, niet voor economische groei. #bewustzijn #natuurbehoud", keyword_clusters: {'mensen': 0, 'voorstander': 0, "Schiphol's": 5, 'uitbreiding': 1, 'tijd': 3, 'mens': 4, 'natuur': 1, 'groei': 4, 'bewustzijn': 1, 'natuurbehoud': 1}},
{sentiment: 0.9966247081756592, lat: null, lng: null, count: 1, topics: ['gezondleven', 'duurzametoekomst'], group: 'piet', emojis: [], keywords: ['gehoor', 'roep', 'leefomgeving', 'trend', 'toekomst', 'duurzametoekomst'], full_text: "Eindelijk wordt er gehoor gegeven aan de roep om een gezonde leefomgeving. Laten we deze trend voortzetten en ons inzetten voor een duurzame toekomst. #gezondleven #duurzametoekomst", keyword_clusters: {'gehoor': 4, 'roep': 5, 'leefomgeving': 1, 'trend': 5, 'toekomst': 1, 'duurzametoekomst': 1}},
{sentiment: 0.9977110624313354, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['milieubewustzijn', 'duurzaamheid'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'milieu', 'leefbaarheid', 'omgeving', 'tijd', 'toekomst', 'milieubewustzijn', 'duurzaamheid'], full_text: "De uitbreiding van Schiphol is niet alleen slecht voor het milieu, maar ook voor de leefbaarheid van de omgeving. Het wordt tijd dat we ons richten op een groenere en duurzamere toekomst. #milieubewustzijn #duurzaamheid", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'milieu': 1, 'leefbaarheid': 1, 'omgeving': 0, 'tijd': 3, 'toekomst': 1, 'milieubewustzijn': 1, 'duurzaamheid': 1}},
{sentiment: 0.9979023933410645, lat: null, lng: null, count: 1, topics: ['bewustzijn', 'natuurbehoud'], group: 'piet', emojis: [], keywords: ['kinderen', 'kleinkinderen', 'omgeving', 'actie', 'uitbreiding', 'Schiphol', 'bewustzijn', 'natuurbehoud'], full_text: "Als we willen dat onze kinderen en kleinkinderen ook kunnen genieten van een gezonde en groene omgeving, dan moeten we nu in actie komen tegen de uitbreiding van Schiphol. #bewustzijn #natuurbehoud", keyword_clusters: {'kinderen': 1, 'kleinkinderen': 5, 'omgeving': 0, 'actie': 1, 'uitbreiding': 1, 'Schiphol': 5, 'bewustzijn': 1, 'natuurbehoud': 1}},
{sentiment: 0.9975214600563049, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['milieubewustzijn', 'duurzaamtoekomstperspectief'], group: 'piet', emojis: [], keywords: ['groei', 'Schiphol', 'koste', 'leefomgeving', 'uitbreiding', 'toekomst', 'milieubewustzijn'], full_text: "De groei van Schiphol gaat ten koste van onze leefomgeving. Laten we ons daarom verzetten tegen de uitbreiding en ons inzetten voor een groenere en duurzamere toekomst. #milieubewustzijn #duurzaamtoekomstperspectief", keyword_clusters: {'groei': 4, 'Schiphol': 5, 'koste': 5, 'leefomgeving': 1, 'uitbreiding': 1, 'toekomst': 1, 'milieubewustzijn': 1}},
{sentiment: 0.9974914789199829, lat: null, lng: null, count: 1, topics: ['gezondleven', 'rechtvaardigheid'], group: 'piet', emojis: [], keywords: ['tijd', 'groei', 'gezondheid', 'welzijn', 'rechtvaardigheid'], full_text: "Het is tijd dat we ons afvragen wat we werkelijk belangrijk vinden. Is economische groei belangrijker dan onze gezondheid en ons welzijn? Ik denk van niet. #gezondleven #rechtvaardigheid", keyword_clusters: {'tijd': 3, 'groei': 4, 'gezondheid': 1, 'welzijn': 1, 'rechtvaardigheid': 1}},
{sentiment: 0.9981562495231628, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'milieu', 'leefbaarheid', 'veiligheid'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'gevolgen', 'milieu', 'leefbaarheid', 'veiligheid', 'omgeving', 'Schiphol', 'milieu', 'leefbaarheid', 'veiligheid'], full_text: "De uitbreiding van Schiphol heeft gevolgen voor het milieu, de leefbaarheid en de veiligheid in de omgeving. #Schiphol #milieu #leefbaarheid #veiligheid", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'gevolgen': 1, 'milieu': 1, 'leefbaarheid': 1, 'veiligheid': 1, 'omgeving': 0}},
{sentiment: 0.99739009141922, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'overlast', 'uitbreiding', 'omwonenden'], group: 'piet', emojis: [], keywords: ['Schiphol', 'overlast', 'omwonenden', 'Schiphol', 'overlast', 'uitbreiding', 'omwonenden'], full_text: "Waarom moet Schiphol uitbreiden als er al zoveel overlast is voor omwonenden? #Schiphol #overlast #uitbreiding #omwonenden", keyword_clusters: {'Schiphol': 5, 'overlast': 1, 'omwonenden': 1, 'uitbreiding': 1}},
{sentiment: 0.9982325434684753, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding', 'burgers', 'zorgen'], group: 'piet', emojis: [], keywords: ['tijd', 'regering', 'zorgen', 'burgers', 'uitbreiding', 'Schiphol', 'Schiphol', 'uitbreiding', 'burgers', 'zorgen'], full_text: "Het is tijd dat de regering meer luistert naar de zorgen van burgers over de uitbreiding van Schiphol. #Schiphol #uitbreiding #burgers #zorgen", keyword_clusters: {'tijd': 3, 'regering': 1, 'zorgen': 1, 'burgers': 1, 'uitbreiding': 1, 'Schiphol': 5}},
{sentiment: 0.996890127658844, lat: 52.1594747, lng: 4.4908843, count: 1, topics: ['schiphol', 'uitbreiding', 'geluidsoverlast', 'luchtvervuiling'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'geluidsoverlast', 'luchtvervuiling', 'Schiphol', 'uitbreiding', 'geluidsoverlast', 'luchtvervuiling'], full_text: "De uitbreiding van Schiphol zal leiden tot meer geluidsoverlast en luchtvervuiling, wat onacceptabel is. #Schiphol #uitbreiding #geluidsoverlast #luchtvervuiling", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'geluidsoverlast': 1, 'luchtvervuiling': 1}},
{sentiment: 0.9978184700012207, lat: null, lng: null, count: 1, topics: ['schiphol', 'gezondheid', 'milieu', 'economischegroei'], group: 'piet', emojis: [], keywords: ['gezondheid', 'milieu', 'groei', 'Schiphol', 'gezondheid', 'milieu', 'economischegroei'], full_text: "Wanneer zullen onze gezondheid en het milieu belangrijker zijn dan economische groei? #Schiphol #gezondheid #milieu #economischegroei", keyword_clusters: {'gezondheid': 1, 'milieu': 1, 'groei': 4, 'Schiphol': 5, 'economischegroei': 1}},
{sentiment: 0.9976577758789062, lat: null, lng: null, count: 1, topics: ['schiphol', 'alternatieven', 'treinverbindingen', 'regionaleluchthavens'], group: 'piet', emojis: [], keywords: ['tijd', 'alternatieven', 'uitbreiding', 'Schiphol', 'treinverbindingen', 'investeringen', 'luchthavens', 'Schiphol', 'alternatieven', 'treinverbindingen', 'regionaleluchthavens'], full_text: "Het is tijd dat we alternatieven vinden voor de uitbreiding van Schiphol, zoals betere treinverbindingen en investeringen in regionale luchthavens. #Schiphol #alternatieven #treinverbindingen #regionaleluchthavens", keyword_clusters: {'tijd': 3, 'alternatieven': 1, 'uitbreiding': 1, 'Schiphol': 5, 'treinverbindingen': 1, 'investeringen': 5, 'luchthavens': 5, 'regionaleluchthavens': 1}},
{sentiment: 0.9979974627494812, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'belangen', 'burgers', 'regering'], group: 'piet', emojis: [], keywords: ['regering', 'belangen', 'Schiphol', 'burgers', 'Schiphol', 'belangen', 'burgers', 'regering'], full_text: "Het lijkt erop dat de regering de belangen van Schiphol boven die van de burgers stelt. Dit moet veranderen. #Schiphol #belangen #burgers #regering", keyword_clusters: {'regering': 1, 'belangen': 1, 'Schiphol': 5, 'burgers': 1}},
{sentiment: 0.9979731440544128, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding', 'langetermijngevolgen', 'nadentijd'], group: 'piet', emojis: [], keywords: ['tijd', 'termijn', 'gevolgen', 'uitbreiding', 'Schiphol', 'Schiphol', 'uitbreiding', 'langetermijngevolgen', 'nadentijd'], full_text: "Het is tijd dat we nadenken over de lange termijn gevolgen van de uitbreiding van Schiphol, voordat het te laat is. #Schiphol #uitbreiding #langetermijngevolgen #nadentijd", keyword_clusters: {'tijd': 3, 'termijn': 5, 'gevolgen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'langetermijngevolgen': 1, 'nadentijd': 1}},
{sentiment: 0.9967449903488159, lat: null, lng: null, count: 1, topics: ['schiphol', 'overlast', 'omwonenden', 'uitbreiding'], group: 'piet', emojis: [], keywords: ['Schiphol', 'overlast', 'omwonenden', 'plaats', 'Schiphol', 'overlast', 'omwonenden', 'uitbreiding'], full_text: "Schiphol zou meer moeten doen om de overlast voor omwonenden te verminderen, in plaats van uit te breiden. #Schiphol #overlast #omwonenden #uitbreiding", keyword_clusters: {'Schiphol': 5, 'overlast': 1, 'omwonenden': 1, 'plaats': 0, 'uitbreiding': 1}},
{sentiment: 0.9980865716934204, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding', 'gevolgen', 'duurzameoplossingen'], group: 'piet', emojis: [], keywords: ['gevolgen', 'uitbreiding', 'Schiphol', 'oplossingen', 'Schiphol', 'uitbreiding', 'gevolgen', 'duurzameoplossingen'], full_text: "Het is belangrijk dat we blijven praten over de gevolgen van de uitbreiding van Schiphol en op zoek gaan naar duurzame oplossingen. #Schiphol #uitbreiding #gevolgen #duurzameoplossingen", keyword_clusters: {'gevolgen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'oplossingen': 1, 'duurzameoplossingen': 1}},
{sentiment: 0.998178243637085, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['communities', 'looking'], full_text: "Hey guys! I wanted to take a moment to talk about the recent discussion surrounding Schiphol's expansion plans. As much as I love to travel, we need to consider the impact that this will have on the environment and the surrounding communities. We need to start looking at sustaina", keyword_clusters: {'communities': 0, 'looking': 4}},
{sentiment: 0.9979304075241089, lat: 52.2434979, lng: 5.6343227, count: 1, topics: ['schiphol', 'vvd', 'economie'], group: 'piet', emojis: [], keywords: ['Schiphol', 'motor', 'Nederland', 'VVD', 'uitbreiding', 'luchthaven', 'oog', 'duurzaamheid', 'leefbaarheid', 'regio', 'Schiphol', 'VVD', 'economie'], full_text: "Schiphol is een belangrijke economische motor voor Nederland en de VVD ondersteunt de verdere uitbreiding van de luchthaven, met oog voor duurzaamheid en leefbaarheid in de regio. #Schiphol #VVD #economie", keyword_clusters: {'Schiphol': 5, 'motor': 0, 'Nederland': 5, 'VVD': 5, 'uitbreiding': 1, 'luchthaven': 3, 'oog': 5, 'duurzaamheid': 1, 'leefbaarheid': 1, 'regio': 7, 'economie': 1}},
{sentiment: 0.9980742931365967, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd', 'economie', 'connectiviteit'], group: 'piet', emojis: [], keywords: ['zorgen', 'omwonenden', 'uitbreiding', 'Schiphol', 'economie', 'connectiviteit', 'VVD', 'ontwikkeling', 'luchthaven', 'Schiphol', 'VVD', 'economie', 'connectiviteit'], full_text: "Wij begrijpen de zorgen van omwonenden, maar de uitbreiding van Schiphol is essentieel voor onze economie en internationale connectiviteit. De VVD steunt dan ook de verdere ontwikkeling van de luchthaven. #Schiphol #VVD #economie #connectiviteit", keyword_clusters: {'zorgen': 1, 'omwonenden': 1, 'uitbreiding': 1, 'Schiphol': 5, 'economie': 1, 'connectiviteit': 1, 'VVD': 5, 'ontwikkeling': 0, 'luchthaven': 3}},
{sentiment: 0.9978869557380676, lat: 52.2434979, lng: 5.6343227, count: 1, topics: ['schiphol', 'vvd', 'luchtvaart'], group: 'piet', emojis: [], keywords: ['luchtvaartsector', 'belang', 'Nederland', 'positie', 'wereld', 'Schiphol', 'VVD', 'ontwikkeling', 'Schiphol', 'VVD', 'luchtvaart'], full_text: "De luchtvaartsector is van groot belang voor Nederland en onze positie in de wereld. Een groeiende Schiphol is daarom noodzakelijk en de VVD staat achter deze ontwikkeling. #Schiphol #VVD #luchtvaart", keyword_clusters: {'luchtvaartsector': 0, 'belang': 7, 'Nederland': 5, 'positie': 7, 'wereld': 7, 'Schiphol': 5, 'VVD': 5, 'ontwikkeling': 0, 'luchtvaart': 7}},
{sentiment: 0.9978328347206116, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd', 'werkgelegenheid', 'economie'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'economie', 'werkgelegenheid', 'VVD', 'ontwikkeling', 'luchthaven', 'Schiphol', 'VVD', 'werkgelegenheid', 'economie'], full_text: "De uitbreiding van Schiphol is niet alleen goed voor onze economie, maar biedt ook veel werkgelegenheid. De VVD staat daarom achter de verdere ontwikkeling van de luchthaven. #Schiphol #VVD #werkgelegenheid #economie", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'economie': 1, 'werkgelegenheid': 1, 'VVD': 5, 'ontwikkeling': 0, 'luchthaven': 3}},
{sentiment: 0.9972975850105286, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd', 'duurzaamheid', 'leefbaarheid'], group: 'piet', emojis: [], keywords: ['VVD', 'groei', 'Schiphol', 'investeringen', 'duurzaamheid', 'overlast', 'omwonenden', 'economie', 'omgeving', 'Schiphol', 'VVD', 'duurzaamheid', 'leefbaarheid'], full_text: "De VVD vindt dat de groei van Schiphol moet samengaan met investeringen in duurzaamheid en het verminderen van de overlast voor omwonenden. Zo kunnen we een sterke economie combineren met een leefbare omgeving. #Schiphol #VVD #duurzaamheid #leefbaarheid", keyword_clusters: {'VVD': 5, 'groei': 4, 'Schiphol': 5, 'investeringen': 5, 'duurzaamheid': 1, 'overlast': 1, 'omwonenden': 1, 'economie': 1, 'omgeving': 0, 'leefbaarheid': 1}},
{sentiment: 0.9980875849723816, lat: 51.5794077, lng: 5.7135574, count: 1, topics: ['schiphol', 'vvd', 'handel', 'transport'], group: 'piet', emojis: [], keywords: ['Schiphol', 'knooppunt', 'handel', 'transport', 'VVD', 'luchthaven', 'bijdrage', 'economie', 'Schiphol', 'VVD', 'handel', 'transport'], full_text: "Schiphol is een belangrijk knooppunt voor internationale handel en transport. De VVD wil daarom dat de luchthaven zich verder kan ontwikkelen en zo een positieve bijdrage kan leveren aan onze economie. #Schiphol #VVD #handel #transport", keyword_clusters: {'Schiphol': 5, 'knooppunt': 4, 'handel': 2, 'transport': 2, 'VVD': 5, 'luchthaven': 3, 'bijdrage': 0, 'economie': 1}},
{sentiment: 0.9979727864265442, lat: null, lng: null, count: 1, topics: ['schiphol', 'vvd', 'veiligheid', 'gezondheid'], group: 'piet', emojis: [], keywords: ['VVD', 'uitbreiding', 'Schiphol', 'oog', 'veiligheid', 'gezondheid', 'omwonenden', 'reizigers', 'aandacht', 'impact', 'luchthaven', 'omgeving', 'Schiphol', 'VVD', 'veiligheid', 'gezondheid'], full_text: "De VVD steunt de uitbreiding van Schiphol, maar wel met oog voor de veiligheid en gezondheid van omwonenden en reizigers. Er moet blijvend aandacht zijn voor de impact van de luchthaven op de omgeving. #Schiphol #VVD #veiligheid #gezondheid", keyword_clusters: {'VVD': 5, 'uitbreiding': 1, 'Schiphol': 5, 'oog': 5, 'veiligheid': 1, 'gezondheid': 1, 'omwonenden': 1, 'reizigers': 1, 'aandacht': 5, 'impact': 5, 'luchthaven': 3, 'omgeving': 0}},
{sentiment: 0.9979459643363953, lat: null, lng: null, count: 1, topics: ['schiphol', 'vvd', 'overlast', 'innovatie'], group: 'piet', emojis: [], keywords: ['Schiphol', 'economie', 'connectiviteit', 'VVD', 'omwonenden', 'overlast', 'oplossingen', 'overlast', 'Schiphol', 'VVD', 'overlast', 'innovatie'], full_text: "Schiphol is belangrijk voor onze economie en internationale connectiviteit, maar de VVD begrijpt ook dat omwonenden overlast ervaren. We moeten daarom blijven investeren in innovatieve oplossingen om deze overlast te verminderen. #Schiphol #VVD #overlast #innovatie", keyword_clusters: {'Schiphol': 5, 'economie': 1, 'connectiviteit': 1, 'VVD': 5, 'omwonenden': 1, 'overlast': 1, 'oplossingen': 1, 'innovatie': 1}},
{sentiment: 0.9977728724479675, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd', 'duurzaamheid', 'efficiëntie'], group: 'piet', emojis: [], keywords: ['VVD', 'ontwikkeling', 'Schiphol', 'luchthaven', 'technologieën', 'innovaties', 'doelstelling', 'Schiphol', 'VVD', 'duurzaamheid', 'efficiëntie'], full_text: "De VVD steunt de ontwikkeling van Schiphol als duurzame en efficiënte luchthaven. We moeten blijven investeren in moderne technologieën en innovaties om deze doelstelling te bereiken. #Schiphol #VVD #duurzaamheid #efficiëntie", keyword_clusters: {'VVD': 5, 'ontwikkeling': 0, 'Schiphol': 5, 'luchthaven': 3, 'technologieën': 5, 'innovaties': 5, 'doelstelling': 7, 'duurzaamheid': 1, 'efficiëntie': 1}},
{sentiment: 0.9979515671730042, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd', 'economie', 'concurrentiepositie'], group: 'piet', emojis: [], keywords: ['VVD', 'kracht', 'economie', 'rol', 'Schiphol', 'ontwikkeling', 'luchthaven', 'concurrentiepositie', 'welvaart', 'Schiphol', 'VVD', 'economie', 'concurrentiepositie'], full_text: "De VVD gelooft in de kracht van de Nederlandse economie en de rol van Schiphol daarin. De verdere ontwikkeling van de luchthaven is daarom belangrijk voor onze internationale concurrentiepositie en welvaart. #Schiphol #VVD #economie #concurrentiepositie", keyword_clusters: {'VVD': 5, 'kracht': 5, 'economie': 1, 'rol': 5, 'Schiphol': 5, 'ontwikkeling': 0, 'luchthaven': 3, 'concurrentiepositie': 1, 'welvaart': 5}},
{sentiment: 0.9968979358673096, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'duurzaamheid', 'groenlinks'], group: 'piet', emojis: [], keywords: ['groei', 'Schiphol', 'tijd', 'natuur', 'leefomgeving', 'GroenLinks', 'alternatieven', 'Schiphol', 'duurzaamheid', 'GroenLinks'], full_text: "De groei van Schiphol moet worden afgeremd, het is tijd om de natuur en de leefomgeving te beschermen. GroenLinks kiest voor duurzame alternatieven! #Schiphol #duurzaamheid #GroenLinks", keyword_clusters: {'groei': 4, 'Schiphol': 5, 'tijd': 3, 'natuur': 1, 'leefomgeving': 1, 'GroenLinks': 0, 'alternatieven': 1, 'duurzaamheid': 1}},
{sentiment: 0.9975517392158508, lat: null, lng: null, count: 1, topics: ['duurzaamheid', 'schiphol', 'groenlinks'], group: 'piet', emojis: [], keywords: ['GroenLinks', 'uitbreiding', 'Schiphol', 'tijd', 'toekomst', 'luchtvaartsector', 'banden', 'duurzaamheid', 'Schiphol', 'GroenLinks'], full_text: "GroenLinks maakt zich zorgen over de uitbreiding van Schiphol. Het is hoog tijd dat we kiezen voor een duurzame toekomst en de luchtvaartsector aan banden leggen. #duurzaamheid #Schiphol #GroenLinks", keyword_clusters: {'GroenLinks': 0, 'uitbreiding': 1, 'Schiphol': 5, 'tijd': 3, 'toekomst': 1, 'luchtvaartsector': 0, 'banden': 4, 'duurzaamheid': 1}},
{sentiment: 0.9969410300254822, lat: null, lng: null, count: 1, topics: ['schiphol', 'duurzaamheid', 'groenlinks'], group: 'piet', emojis: [], keywords: ['alternatieven', 'Schiphol', 'uitstoot', 'CO2', 'stoffen', 'leefomgeving', 'toekomst', 'Schiphol', 'duurzaamheid', 'GroenLinks'], full_text: "Wij vinden het belangrijk om te investeren in duurzame alternatieven voor Schiphol. De uitstoot van CO2 en andere schadelijke stoffen moet worden teruggedrongen, voor een betere leefomgeving en gezonde toekomst. #Schiphol #duurzaamheid #GroenLinks", keyword_clusters: {'alternatieven': 1, 'Schiphol': 5, 'uitstoot': 7, 'CO2': 7, 'stoffen': 4, 'leefomgeving': 1, 'toekomst': 1, 'duurzaamheid': 1, 'GroenLinks': 0}},
{sentiment: 0.9975900650024414, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'duurzaamheid', 'groenlinks'], group: 'piet', emojis: [], keywords: ['Schiphol', 'luchtvervuiler', 'Nederland', 'GroenLinks', 'uitbreiding', 'Schiphol', 'alternatieven', 'Schiphol', 'duurzaamheid', 'GroenLinks'], full_text: "Schiphol is de grootste luchtvervuiler van Nederland. GroenLinks wil de uitbreiding van Schiphol stoppen en investeren in duurzame alternatieven. #Schiphol #duurzaamheid #GroenLinks", keyword_clusters: {'Schiphol': 5, 'luchtvervuiler': 7, 'Nederland': 5, 'GroenLinks': 0, 'uitbreiding': 1, 'alternatieven': 1, 'duurzaamheid': 1}},
{sentiment: 0.9980593323707581, lat: null, lng: null, count: 1, topics: ['schiphol', 'duurzaamheid', 'groenlinks'], group: 'piet', emojis: [], keywords: ['GroenLinks', 'belangen', 'luchtvaartsector', 'gezondheid', 'welzijn', 'omwonenden', 'milieu', 'Schiphol', 'duurzaamheid', 'GroenLinks'], full_text: "GroenLinks vindt dat de belangen van de luchtvaartsector niet boven die van de gezondheid en het welzijn van de omwonenden en het milieu mogen staan. #Schiphol #duurzaamheid #GroenLinks", keyword_clusters: {'GroenLinks': 0, 'belangen': 1, 'luchtvaartsector': 0, 'gezondheid': 1, 'welzijn': 1, 'omwonenden': 1, 'milieu': 1, 'Schiphol': 5, 'duurzaamheid': 1}},
{sentiment: 0.9946672916412354, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'duurzaamheid', 'groenlinks'], group: 'piet', emojis: [], keywords: ['Schiphol', 'GroenLinks', 'halt', 'groei', 'oplossingen', 'Schiphol', 'duurzaamheid', 'GroenLinks'], full_text: "Het is onverantwoord om Schiphol verder uit te breiden. GroenLinks wil een halt toeroepen aan de groei en kiezen voor duurzame oplossingen. #Schiphol #duurzaamheid #GroenLinks", keyword_clusters: {'Schiphol': 5, 'GroenLinks': 0, 'halt': 7, 'groei': 4, 'oplossingen': 1, 'duurzaamheid': 1}},
{sentiment: 0.9977307915687561, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'duurzaamheid', 'groenlinks'], group: 'piet', emojis: [], keywords: ['Schiphol', 'geluidsoverlast', 'luchtvervuiling', 'GroenLinks', 'einde', 'groei', 'Schiphol', 'alternatieven', 'Schiphol', 'duurzaamheid', 'GroenLinks'], full_text: "Schiphol leidt tot geluidsoverlast en luchtvervuiling. GroenLinks wil daarom een einde maken aan de groei van Schiphol en inzetten op duurzame alternatieven. #Schiphol #duurzaamheid #GroenLinks", keyword_clusters: {'Schiphol': 5, 'geluidsoverlast': 1, 'luchtvervuiling': 1, 'GroenLinks': 0, 'einde': 0, 'groei': 4, 'alternatieven': 1, 'duurzaamheid': 1}},
{sentiment: 0.9978602528572083, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'duurzaamheid', 'groenlinks'], group: 'piet', emojis: [], keywords: ['GroenLinks', 'Schiphol', 'leefomgeving', 'toekomst', 'stop', 'groei', 'alternatieven', 'Schiphol', 'duurzaamheid', 'GroenLinks'], full_text: "GroenLinks wil dat Schiphol bijdraagt aan een gezonde leefomgeving en een duurzame toekomst. Daarom kiezen wij voor een stop op de groei en duurzame alternatieven. #Schiphol #duurzaamheid #GroenLinks", keyword_clusters: {'GroenLinks': 0, 'Schiphol': 5, 'leefomgeving': 1, 'toekomst': 1, 'stop': 7, 'groei': 4, 'alternatieven': 1, 'duurzaamheid': 1}},
{sentiment: 0.997775137424469, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'duurzaamheid', 'groenlinks'], group: 'piet', emojis: [], keywords: ['tijd', 'verandering', 'GroenLinks', 'groei', 'Schiphol', 'alternatieven', 'Schiphol', 'duurzaamheid', 'GroenLinks'], full_text: "Het is tijd voor verandering. GroenLinks wil af van de groei van Schiphol en investeren in duurzame alternatieven. #Schiphol #duurzaamheid #GroenLinks", keyword_clusters: {'tijd': 3, 'verandering': 1, 'GroenLinks': 0, 'groei': 4, 'Schiphol': 5, 'alternatieven': 1, 'duurzaamheid': 1}},
{sentiment: 0.997346043586731, lat: null, lng: null, count: 1, topics: ['schiphol', 'duurzaamheid', 'groenlinks'], group: 'piet', emojis: [], keywords: ['Schiphol', 'overlast', 'vervuiling', 'uitbreiding', 'GroenLinks', 'toekomst', 'planeet', 'lucht', 'Schiphol', 'duurzaamheid', 'GroenLinks'], full_text: "Schiphol veroorzaakt veel overlast en vervuiling, daarom moet de uitbreiding worden gestopt. GroenLinks kiest voor een duurzame toekomst, waarin we onze planeet beschermen en gezonde lucht inademen. #Schiphol #duurzaamheid #GroenLinks", keyword_clusters: {'Schiphol': 5, 'overlast': 1, 'vervuiling': 1, 'uitbreiding': 1, 'GroenLinks': 0, 'toekomst': 1, 'planeet': 1, 'lucht': 0, 'duurzaamheid': 1}},
{sentiment: 0.998011589050293, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'duurzaamheid', 'leefomgeving'], group: 'piet', emojis: [], keywords: ["CU'er", 'belang', 'duurzaamheid', 'behoud', 'leefomgeving', 'groei', 'Schiphol', 'koste', 'milieu', 'Schiphol', 'duurzaamheid', 'leefomgeving'], full_text: "Als CU'er geloof ik in het belang van duurzaamheid en behoud van onze leefomgeving. Een verantwoorde groei van Schiphol is mogelijk, maar niet ten koste van het milieu. #Schiphol #duurzaamheid #leefomgeving", keyword_clusters: {"CU'er": 4, 'belang': 7, 'duurzaamheid': 1, 'behoud': 7, 'leefomgeving': 1, 'groei': 4, 'Schiphol': 5, 'koste': 5, 'milieu': 1}},
{sentiment: 0.9981096982955933, lat: null, lng: null, count: 1, topics: ['schiphol', 'economie', 'leefbaarheid'], group: 'piet', emojis: [], keywords: ['CU', 'voorstander', 'groei', 'Schiphol', 'belangen', 'leefbaarheid', 'omwonenden', 'Schiphol', 'economie', 'leefbaarheid'], full_text: "De CU is voorstander van een evenwichtige groei van Schiphol, waarbij zowel de economische belangen als de leefbaarheid van omwonenden worden meegewogen. #Schiphol #economie #leefbaarheid", keyword_clusters: {'CU': 7, 'voorstander': 0, 'groei': 4, 'Schiphol': 5, 'belangen': 1, 'leefbaarheid': 1, 'omwonenden': 1, 'economie': 1}},
{sentiment: 0.9981603026390076, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'economie', 'milieu'], group: 'piet', emojis: [], keywords: ['Schiphol', 'economie', 'koste', 'gezondheid', 'omwonenden', 'milieu', 'CU', 'groei', 'afspraken', 'luchtvaartsector', 'Schiphol', 'economie', 'milieu'], full_text: "Het is belangrijk dat Schiphol bijdraagt aan een gezonde economie, maar niet ten koste van de gezondheid van omwonenden en het milieu. Daarom pleit de CU voor een verantwoorde groei en goede afspraken met de luchtvaartsector. #Schiphol #economie #milieu", keyword_clusters: {'Schiphol': 5, 'economie': 1, 'koste': 5, 'gezondheid': 1, 'omwonenden': 1, 'milieu': 1, 'CU': 7, 'groei': 4, 'afspraken': 7, 'luchtvaartsector': 0}},
{sentiment: 0.9978653788566589, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'duurzaamheid', 'toekomst'], group: 'piet', emojis: [], keywords: ["CU'er", 'toekomst', 'kinderen', 'groei', 'Schiphol', 'luchtvaartsector', 'technologieën', 'Schiphol', 'duurzaamheid', 'toekomst'], full_text: "Als CU'er sta ik voor een duurzame toekomst voor onze kinderen. Een verantwoorde groei van Schiphol is daarbij essentieel, waarbij we de luchtvaartsector stimuleren om te investeren in duurzame technologieën. #Schiphol #duurzaamheid #toekomst", keyword_clusters: {"CU'er": 4, 'toekomst': 1, 'kinderen': 1, 'groei': 4, 'Schiphol': 5, 'luchtvaartsector': 0, 'technologieën': 5, 'duurzaamheid': 1}},
{sentiment: 0.9980396628379822, lat: null, lng: null, count: 1, topics: ['schiphol', 'omwonenden', 'leefbaarheid'], group: 'piet', emojis: [], keywords: ['CU', 'belangen', 'omwonenden', 'leefklimaat', 'Schiphol', 'groei', 'luchthaven', 'leefbaarheid', 'omwonenden', 'Schiphol', 'omwonenden', 'leefbaarheid'], full_text: "De CU maakt zich hard voor de belangen van omwonenden en pleit voor een goed leefklimaat rondom Schiphol. Een verantwoorde groei van de luchthaven is alleen mogelijk als de leefbaarheid van omwonenden wordt gewaarborgd. #Schiphol #omwonenden #leefbaarheid", keyword_clusters: {'CU': 7, 'belangen': 1, 'omwonenden': 1, 'leefklimaat': 7, 'Schiphol': 5, 'groei': 4, 'luchthaven': 3, 'leefbaarheid': 1}},
{sentiment: 0.9979639053344727, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'veiligheid', 'gezondheid'], group: 'piet', emojis: ['😜'], keywords: ['CU', 'groei', 'Schiphol', 'koste', 'veiligheid', 'gezondheid', 'omwonenden', 'groei', 'afspraken', 'beleid', 'overlast', 'Schiphol', 'veiligheid', 'gezondheid', '😜'], full_text: "De CU vindt dat de groei van Schiphol niet ten koste mag gaan van de veiligheid en gezondheid van omwonenden. We staan daarom voor verantwoorde groei met goede afspraken en een actief beleid om de overlast te verminderen. #Schiphol #veiligheid #gezondheid 😜", keyword_clusters: {'CU': 7, 'groei': 4, 'Schiphol': 5, 'koste': 5, 'veiligheid': 1, 'gezondheid': 1, 'omwonenden': 1, 'afspraken': 7, 'beleid': 7, 'overlast': 1}},
{sentiment: 0.9981252551078796, lat: null, lng: null, count: 1, topics: ['schiphol', 'economie', 'duurzaamhe'], group: 'piet', emojis: [], keywords: ['CU', 'balans', 'economie', 'leefbaarheid', 'pleit', 'groei', 'Schiphol', 'luchtvaartsector', 'verantwoordelijkheid', 'gebied', 'duurzaamheid', 'impact', 'Schiphol', 'economie', 'duurzaamhe'], full_text: "De CU wil een balans tussen economie en leefbaarheid en pleit voor een verantwoorde groei van Schiphol. Daarbij is het belangrijk dat de luchtvaartsector ook haar verantwoordelijkheid neemt op het gebied van duurzaamheid en maatschappelijke impact. #Schiphol #economie #duurzaamhe", keyword_clusters: {'CU': 7, 'balans': 0, 'economie': 1, 'leefbaarheid': 1, 'pleit': 7, 'groei': 4, 'Schiphol': 5, 'luchtvaartsector': 0, 'verantwoordelijkheid': 4, 'gebied': 4, 'duurzaamheid': 1, 'impact': 5, 'duurzaamhe': 1}},
{sentiment: 0.9980220794677734, lat: null, lng: null, count: 1, topics: ['schiphol', 'leefomgeving', 'rechtvaardigheid'], group: 'piet', emojis: [], keywords: ['CU', 'samenleving', 'recht', 'leefomgeving', 'groei', 'Schiphol', 'belangen', 'omwonenden', 'milieu', 'Schiphol', 'leefomgeving', 'rechtvaardigheid'], full_text: "De CU staat voor een rechtvaardige samenleving en vindt dat iedereen recht heeft op een gezonde leefomgeving. Daarom pleiten wij voor een verantwoorde groei van Schiphol, waarbij de belangen van omwonenden en het milieu worden meegewogen. #Schiphol #leefomgeving #rechtvaardigheid", keyword_clusters: {'CU': 7, 'samenleving': 7, 'recht': 0, 'leefomgeving': 1, 'groei': 4, 'Schiphol': 5, 'belangen': 1, 'omwonenden': 1, 'milieu': 1, 'rechtvaardigheid': 1}},
{sentiment: 0.9979493021965027, lat: 52.1594747, lng: 4.4908843, count: 1, topics: ['schiphol', 'klimaatverandering', 'duurzaamheid'], group: 'piet', emojis: [], keywords: ['CU', 'groei', 'Schiphol', 'CO2-uitstoot', 'klimaatverandering', 'investeringen', 'technologieën', 'groei', 'luchthaven', 'Schiphol', 'klimaatverandering', 'duurzaamheid'], full_text: "De CU vindt dat de groei van Schiphol niet mag leiden tot extra CO2-uitstoot en klimaatverandering. Daarom pleiten wij voor investeringen in duurzame technologieën en een verantwoorde groei van de luchthaven. #Schiphol #klimaatverandering #duurzaamheid", keyword_clusters: {'CU': 7, 'groei': 4, 'Schiphol': 5, 'CO2-uitstoot': 0, 'klimaatverandering': 1, 'investeringen': 5, 'technologieën': 5, 'luchthaven': 3, 'duurzaamheid': 1}},
{sentiment: 0.9983127117156982, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ["CU'er", 'oog', 'belangen', 'omwonenden', 'belangen', 'Schip'], full_text: "Als CU'er vind ik het belangrijk dat we oog hebben voor de belangen van omwonenden én de economische belangen van Schip", keyword_clusters: {"CU'er": 4, 'oog': 5, 'belangen': 1, 'omwonenden': 1, 'Schip': 0}},
{sentiment: 0.9968181848526001, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'd66'], group: 'piet', emojis: [], keywords: ['groei', 'Schiphol', 'ontwikkeling', 'voordelen', 'Schiphol', 'vermindering', 'geluidsoverlast', 'CO2-uitstoot', 'Schiphol'], full_text: "Als D66 willen we de groei van Schiphol niet stoppen, maar wel zorgen voor duurzame ontwikkeling. Laten we de economische voordelen van Schiphol behouden, terwijl we ons richten op vermindering van geluidsoverlast en CO2-uitstoot. #Schiphol #D66", keyword_clusters: {'groei': 4, 'Schiphol': 5, 'ontwikkeling': 0, 'voordelen': 1, 'vermindering': 5, 'geluidsoverlast': 1, 'CO2-uitstoot': 0}},
{sentiment: 0.9974908828735352, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'd66'], group: 'piet', emojis: [], keywords: ['D66', 'aanpak', 'Schiphol', 'leefbaarheid', 'omgeving', 'toekomst', 'Schiphol'], full_text: "Bij D66 geloven we in een gebalanceerde aanpak. Schiphol kan groeien, maar niet zonder dat we ons richten op het verbeteren van de leefbaarheid in de omgeving. We moeten samenwerken voor een duurzame toekomst! #Schiphol #D66", keyword_clusters: {'D66': 0, 'aanpak': 7, 'Schiphol': 5, 'leefbaarheid': 1, 'omgeving': 0, 'toekomst': 1}},
{sentiment: 0.9978678226470947, lat: null, lng: null, count: 1, topics: ['schiphol', 'd66'], group: 'piet', emojis: ['😜'], keywords: ['D66', 'aanpak', 'Schiphol-groei', 'oplossingen', 'geluidsoverlast', 'milieuschade', 'voordelen', 'ontwikkeling', 'Schiphol', 'D66', '😜'], full_text: "D66 staat voor een verantwoordelijke aanpak van de Schiphol-groei. Laten we kijken naar innovatieve oplossingen voor geluidsoverlast en milieuschade, zodat we zowel economische voordelen als duurzame ontwikkeling kunnen realiseren. #Schiphol #D66 😜", keyword_clusters: {'D66': 0, 'aanpak': 7, 'Schiphol-groei': 7, 'oplossingen': 1, 'geluidsoverlast': 1, 'milieuschade': 5, 'voordelen': 1, 'ontwikkeling': 0, 'Schiphol': 5}},
{sentiment: 0.9971568584442139, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'd66'], group: 'piet', emojis: [], keywords: ['tijd', 'groei', 'Schiphol', 'D66', 'oplossingen', 'geluidsoverlast', 'effecten', 'milieu', 'voordelen', 'Schiphol', 'Schiphol'], full_text: "Het is tijd om de groei van Schiphol duurzaam aan te pakken. D66 wil investeren in slimme oplossingen voor geluidsoverlast en de negatieve effecten op het milieu, terwijl we de economische voordelen van Schiphol behouden. #Schiphol #D66", keyword_clusters: {'tijd': 3, 'groei': 4, 'Schiphol': 5, 'D66': 0, 'oplossingen': 1, 'geluidsoverlast': 1, 'effecten': 4, 'milieu': 1, 'voordelen': 1}},
{sentiment: 0.9978247880935669, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'd66'], group: 'piet', emojis: [], keywords: ['D66', 'potentie', 'Schiphol', 'motor', 'effecten', 'innovatie', 'duurzaamheid', 'Schiphol'], full_text: "D66 ziet de potentie van Schiphol als een belangrijke economische motor, maar we moeten ook de negatieve effecten erkennen. Laten we ons richten op innovatie en duurzaamheid, zodat we zowel economisch als milieutechnisch vooruit kunnen. #Schiphol #D66", keyword_clusters: {'D66': 0, 'potentie': 0, 'Schiphol': 5, 'motor': 0, 'effecten': 4, 'innovatie': 1, 'duurzaamheid': 1}},
{sentiment: 0.9978376030921936, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'd66'], group: 'piet', emojis: [], keywords: ['D66', 'toekomst', 'Schiphol', 'omgeving', 'groei', 'leefbaarheid', 'milieu', 'Schiphol'], full_text: "Bij D66 geloven we in een gezonde toekomst voor Schiphol en de omgeving. Dat betekent dat we duurzame groei mogelijk moeten maken, terwijl we tegelijkertijd de leefbaarheid en het milieu beschermen. #Schiphol #D66", keyword_clusters: {'D66': 0, 'toekomst': 1, 'Schiphol': 5, 'omgeving': 0, 'groei': 4, 'leefbaarheid': 1, 'milieu': 1}},
{sentiment: 0.997334897518158, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'd66'], group: 'piet', emojis: [], keywords: ['Schiphol', 'aanpak', 'D66', 'technologie', 'innovatie', 'geluidsoverlast', 'milieuschade', 'voordelen', 'Schiphol', 'Schiphol'], full_text: "Als het gaat om Schiphol, moeten we ons richten op een gebalanceerde aanpak. D66 wil investeren in technologie en innovatie om de geluidsoverlast en milieuschade te verminderen, terwijl we de economische voordelen van Schiphol behouden. #Schiphol #D66", keyword_clusters: {'Schiphol': 5, 'aanpak': 7, 'D66': 0, 'technologie': 7, 'innovatie': 1, 'geluidsoverlast': 1, 'milieuschade': 5, 'voordelen': 1}},
{sentiment: 0.9978240728378296, lat: null, lng: null, count: 1, topics: ['schiphol', 'd66'], group: 'piet', emojis: [], keywords: ['D66', 'Schiphol-groei', 'ontwikkeling', 'toekomst', 'voorspoed', 'bescherming', 'milieu', 'Schiphol'], full_text: "D66 wil de Schiphol-groei niet stoppen, maar we willen wel zorgen voor een duurzame ontwikkeling. Laten we samenwerken voor een betere toekomst, waarin we economische voorspoed combineren met bescherming van het milieu. #Schiphol #D66", keyword_clusters: {'D66': 0, 'Schiphol-groei': 7, 'ontwikkeling': 0, 'toekomst': 1, 'voorspoed': 0, 'bescherming': 7, 'milieu': 1, 'Schiphol': 5}},
{sentiment: 0.9977415800094604, lat: null, lng: null, count: 1, topics: ['schiphol', 'd66'], group: 'piet', emojis: ['😜'], keywords: ['Schiphol-groei', 'kans', 'toekomst', 'D66', 'kans', 'ontwikkeling', 'innovatie', 'vooruitgang', 'Schiphol', 'D66', '😜'], full_text: "Het is belangrijk om de Schiphol-groei te zien als een kans om te investeren in de toekomst. Bij D66 willen we deze kans grijpen door te investeren in duurzame ontwikkeling en innovatie, zodat we zowel economisch als milieutechnisch vooruitgang boeken. #Schiphol #D66 😜", keyword_clusters: {'Schiphol-groei': 7, 'kans': 0, 'toekomst': 1, 'D66': 0, 'ontwikkeling': 0, 'innovatie': 1, 'vooruitgang': 1, 'Schiphol': 5}},
{sentiment: 0.997843861579895, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['D66', 'toekomst', 'Schiphol', 'omgeving', 'aanpak', 'voordelen', 'Schip'], full_text: "D66 staat voor een gezonde en duurzame toekomst voor Schiphol en de omgeving. Laten we samenwerken aan een gebalanceerde aanpak, waarin we de economische voordelen van Schip", keyword_clusters: {'D66': 0, 'toekomst': 1, 'Schiphol': 5, 'omgeving': 0, 'aanpak': 7, 'voordelen': 1, 'Schip': 0}},
{sentiment: 0.9978724718093872, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['cda', 'schiphol'], group: 'piet', emojis: [], keywords: ['CDA', 'toekomst', 'Nederland', 'Schiphol', 'oog', 'mens', 'milieu', 'CDA', 'Schiphol'], full_text: "Als CDA geloven we in een duurzame toekomst voor Nederland. Schiphol moet daarom verantwoord blijven groeien, met oog voor mens en milieu. #CDA #Schiphol", keyword_clusters: {'CDA': 4, 'toekomst': 1, 'Nederland': 5, 'Schiphol': 5, 'oog': 5, 'mens': 4, 'milieu': 1}},
{sentiment: 0.9971428513526917, lat: null, lng: null, count: 1, topics: ['cda', 'schiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'motor', 'land', 'koste', 'gezondheid', 'welzijn', 'omwonenden', 'CDA', 'oplossing', 'CDA', 'Schiphol'], full_text: "Schiphol is een belangrijke economische motor voor ons land, maar dit mag niet ten koste gaan van de gezondheid en het welzijn van omwonenden. CDA zet zich in voor een gebalanceerde oplossing. #CDA #Schiphol", keyword_clusters: {'Schiphol': 5, 'motor': 0, 'land': 7, 'koste': 5, 'gezondheid': 1, 'welzijn': 1, 'omwonenden': 1, 'CDA': 4, 'oplossing': 0}},
{sentiment: 0.9979530572891235, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['cda', 'schiphol'], group: 'piet', emojis: ['😜'], keywords: ['Schiphol', 'geluidsoverlast', 'omwonenden', 'CDA', 'maatregelen', 'aantal', 'nachtvluchten', 'CDA', 'Schiphol', '😜'], full_text: "Het is belangrijk dat Schiphol de geluidsoverlast voor omwonenden beperkt. CDA steunt dan ook de maatregelen om het aantal nachtvluchten terug te dringen. #CDA #Schiphol 😜", keyword_clusters: {'Schiphol': 5, 'geluidsoverlast': 1, 'omwonenden': 1, 'CDA': 4, 'maatregelen': 0, 'aantal': 5, 'nachtvluchten': 5}},
{sentiment: 0.9980577826499939, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['cda', 'schiphol'], group: 'piet', emojis: [], keywords: ['CDA', 'Schiphol', 'luchthaven', 'investeringen', 'bussen', 'brandstoffen', 'CDA', 'Schiphol'], full_text: "CDA vindt dat Schiphol zich moet blijven ontwikkelen als duurzame luchthaven, met investeringen in bijvoorbeeld elektrische bussen en duurzame brandstoffen. #CDA #Schiphol", keyword_clusters: {'CDA': 4, 'Schiphol': 5, 'luchthaven': 3, 'investeringen': 5, 'bussen': 5, 'brandstoffen': 0}},
{sentiment: 0.9973963499069214, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['cda', 'schiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'leefbaarheid', 'gemeenten', 'CDA', 'CDA', 'Schiphol'], full_text: "Het is belangrijk dat Schiphol verantwoord blijft groeien, zonder de leefbaarheid van omliggende gemeenten aan te tasten. CDA blijft zich hiervoor inzetten. #CDA #Schiphol", keyword_clusters: {'Schiphol': 5, 'leefbaarheid': 1, 'gemeenten': 0, 'CDA': 4}},
{sentiment: 0.9975560903549194, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['cda', 'schiphol'], group: 'piet', emojis: [], keywords: ['CDA', 'voorstander', 'verdeling', 'vluchten', 'luchthavens', 'druk', 'Schiphol', 'overlast', 'omwonenden', 'CDA', 'Schiphol'], full_text: "CDA is voorstander van een evenwichtige verdeling van vluchten over verschillende luchthavens, om de druk op Schiphol te verminderen en de overlast voor omwonenden te beperken. #CDA #Schiphol", keyword_clusters: {'CDA': 4, 'voorstander': 0, 'verdeling': 5, 'vluchten': 0, 'luchthavens': 5, 'druk': 4, 'Schiphol': 5, 'overlast': 1, 'omwonenden': 1}},
{sentiment: 0.9977618455886841, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['cda', 'schiphol'], group: 'piet', emojis: [], keywords: ['overlast', 'Schiphol', 'omwonenden', 'jaren', 'probleem', 'CDA', 'leefbaarheid', 'geluidsisolatie', 'woningen', 'CDA', 'Schiphol'], full_text: "De overlast van Schiphol voor omwonenden is al jaren een groot probleem. CDA vindt dat er meer gedaan moet worden om de leefbaarheid te verbeteren, bijvoorbeeld door geluidsisolatie van woningen. #CDA #Schiphol", keyword_clusters: {'overlast': 1, 'Schiphol': 5, 'omwonenden': 1, 'jaren': 7, 'probleem': 4, 'CDA': 4, 'leefbaarheid': 1, 'geluidsisolatie': 0, 'woningen': 5}},
{sentiment: 0.998058021068573, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['cda', 'schiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'innovatie', 'duurzaamheid', 'luchtvaartsector', 'CDA', 'ontwikkelingen', 'CDA', 'Schiphol'], full_text: "Het is belangrijk dat Schiphol blijft investeren in innovatie en duurzaamheid, om de luchtvaartsector toekomstbestendig te maken. CDA steunt deze ontwikkelingen. #CDA #Schiphol", keyword_clusters: {'Schiphol': 5, 'innovatie': 1, 'duurzaamheid': 1, 'luchtvaartsector': 0, 'CDA': 4, 'ontwikkelingen': 4}},
{sentiment: 0.9973748922348022, lat: null, lng: null, count: 1, topics: ['cda', 'schiphol'], group: 'piet', emojis: [], keywords: ['CDA', 'leefomgeving', 'omwonenden', 'Schiphol', 'waarde', 'luchthaven', 'oog', 'CDA', 'Schiphol'], full_text: "CDA blijft zich inzetten voor een veilige en gezonde leefomgeving voor omwonenden van Schiphol, zonder de economische waarde van de luchthaven uit het oog te verliezen. #CDA #Schiphol", keyword_clusters: {'CDA': 4, 'leefomgeving': 1, 'omwonenden': 1, 'Schiphol': 5, 'waarde': 5, 'luchthaven': 3, 'oog': 5}},
{sentiment: 0.9979044198989868, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['cda', 'schiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'omwonenden', 'overheden', 'overlast', 'oplossing', 'CDA', 'samenwerking', 'CDA', 'Schiphol'], full_text: "Het is belangrijk dat Schiphol samenwerkt met omwonenden en lokale overheden om de overlast te verminderen en tot een gebalanceerde oplossing te komen. CDA ondersteunt deze samenwerking. #CDA #Schiphol", keyword_clusters: {'Schiphol': 5, 'omwonenden': 1, 'overheden': 4, 'overlast': 1, 'oplossing': 0, 'CDA': 4, 'samenwerking': 1}},
{sentiment: 0.9973498582839966, lat: null, lng: null, count: 1, topics: ['schiphol', 'sp'], group: 'piet', emojis: [], keywords: ['Schiphol', 'koste', 'gezondheid', 'milieu', 'SP', 'vliegverkeer', 'Schiphol', 'SP'], full_text: "Schiphol mag niet ten koste gaan van onze gezondheid en het milieu! SP zet zich in voor duurzaam vliegverkeer. #Schiphol #SP", keyword_clusters: {'Schiphol': 5, 'koste': 5, 'gezondheid': 1, 'milieu': 1, 'SP': 0, 'vliegverkeer': 5}},
{sentiment: 0.9979543685913086, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'sp'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'overlast', 'vervuiling', 'SP', 'Nederland', 'Schiphol', 'SP'], full_text: "De uitbreiding van Schiphol leidt tot meer overlast en vervuiling. SP kiest voor een groen en gezond Nederland. #Schiphol #SP", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'overlast': 1, 'vervuiling': 1, 'SP': 0, 'Nederland': 5}},
{sentiment: 0.9968910813331604, lat: null, lng: null, count: 1, topics: ['schiphol', 'sp'], group: 'piet', emojis: [], keywords: ['Schiphol', 'regels', 'verantwoordelijkheid', 'gevolgen', 'uitbreiding', 'SP', 'eerlijkheid', 'rechtvaardigheid', 'Schiphol', 'SP'], full_text: "Schiphol moet zich aan regels houden en verantwoordelijkheid nemen voor de gevolgen van haar uitbreiding. SP staat voor eerlijkheid en rechtvaardigheid. #Schiphol #SP", keyword_clusters: {'Schiphol': 5, 'regels': 4, 'verantwoordelijkheid': 4, 'gevolgen': 1, 'uitbreiding': 1, 'SP': 0, 'eerlijkheid': 7, 'rechtvaardigheid': 1}},
{sentiment: 0.9967498779296875, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'sp'], group: 'piet', emojis: [], keywords: ['winsten', 'Schiphol', 'koste', 'gezondheid', 'milieu', 'SP', 'verdeling', 'lasten', 'Schiphol', 'SP'], full_text: "Het is onacceptabel dat de winsten van Schiphol ten koste gaan van onze gezondheid en het milieu. SP pleit voor een rechtvaardigere verdeling van de lasten en lusten. #Schiphol #SP", keyword_clusters: {'winsten': 5, 'Schiphol': 5, 'koste': 5, 'gezondheid': 1, 'milieu': 1, 'SP': 0, 'verdeling': 5, 'lasten': 5}},
{sentiment: 0.9976613521575928, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'sp'], group: 'piet', emojis: [], keywords: ['SP', 'Schiphol', 'verantwoordelijkheid', 'gezondheid', 'milieu', 'Schiphol', 'SP'], full_text: "SP vindt dat Schiphol moet worden afgerekend op haar maatschappelijke verantwoordelijkheid. Het gaat immers om onze gezondheid en het milieu. #Schiphol #SP", keyword_clusters: {'SP': 0, 'Schiphol': 5, 'verantwoordelijkheid': 4, 'gezondheid': 1, 'milieu': 1}},
{sentiment: 0.9981417655944824, lat: null, lng: null, count: 1, topics: ['schiphol', 'sp'], group: 'piet', emojis: [], keywords: ['Schiphol', 'afspraken', 'overlast', 'vervuiling', 'SP', 'Nederland', 'buurt', 'Schiphol', 'Schiphol', 'SP'], full_text: "Schiphol moet zich houden aan afspraken en zorgen voor minder overlast en vervuiling. SP gaat voor een leefbaar Nederland, ook in de buurt van Schiphol. #Schiphol #SP", keyword_clusters: {'Schiphol': 5, 'afspraken': 7, 'overlast': 1, 'vervuiling': 1, 'SP': 0, 'Nederland': 5, 'buurt': 5}},
{sentiment: 0.9978960752487183, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'sp'], group: 'piet', emojis: ['😜'], keywords: ['uitbreiding', 'Schiphol', 'milieu', 'economie', 'SP', 'groei', 'werkgelegenheid', 'Schiphol', 'SP', '😜'], full_text: "De uitbreiding van Schiphol is niet alleen schadelijk voor ons milieu, maar ook voor de economie. SP kiest voor duurzame groei en werkgelegenheid. #Schiphol #SP 😜", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'milieu': 1, 'economie': 1, 'SP': 0, 'groei': 4, 'werkgelegenheid': 1}},
{sentiment: 0.9979490637779236, lat: null, lng: null, count: 1, topics: ['schiphol', 'sp'], group: 'piet', emojis: [], keywords: ['SP', 'Schiphol', 'belangen', 'mens', 'natuur', 'Schiphol', 'SP'], full_text: "SP strijdt voor een rechtvaardig en duurzaam Schiphol, waarbij de belangen van mens en natuur voorop staan. #Schiphol #SP", keyword_clusters: {'SP': 0, 'Schiphol': 5, 'belangen': 1, 'mens': 4, 'natuur': 1}},
{sentiment: 0.9977753758430481, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'sp'], group: 'piet', emojis: [], keywords: ['Schiphol', 'wensen', 'samenleving', 'SP', 'Schiphol', 'toekomst', 'Schiphol', 'SP'], full_text: "Schiphol moet zich aanpassen aan de wensen van de samenleving, niet andersom. SP gaat voor een Schiphol dat bijdraagt aan een groene en gezonde toekomst. #Schiphol #SP", keyword_clusters: {'Schiphol': 5, 'wensen': 4, 'samenleving': 7, 'SP': 0, 'toekomst': 1}},
{sentiment: 0.997525155544281, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'sp'], group: 'piet', emojis: [], keywords: ['SP', 'uitbreiding', 'Schiphol', 'koste', 'gezondheid', 'milieu', 'leefomgeving', 'luchtvaart', 'Schiphol', 'SP'], full_text: "SP is tegen de uitbreiding van Schiphol als dat ten koste gaat van onze gezondheid, het milieu en onze leefomgeving. Wij kiezen voor een verantwoorde en duurzame luchtvaart. #Schiphol #SP", keyword_clusters: {'SP': 0, 'uitbreiding': 1, 'Schiphol': 5, 'koste': 5, 'gezondheid': 1, 'milieu': 1, 'leefomgeving': 1, 'luchtvaart': 7}},
{sentiment: 0.9971351623535156, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: ['🤔'], keywords: ['uitbreiding', 'Schiphol', 'voorbeeld', 'politici', 'oren', 'bedrijven', 'FvD', 'groei', '🤔'], full_text: "De uitbreiding van Schiphol is een typisch voorbeeld van politici die hun oren laten hangen naar de grote bedrijven. FvD zegt nee tegen deze ongebreidelde groei! 🤔", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'voorbeeld': 4, 'politici': 0, 'oren': 4, 'bedrijven': 7, 'FvD': 0, 'groei': 4}},
{sentiment: 0.9980250597000122, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: ['🤔'], keywords: ['Schiphol', 'overlast', 'FvD', 'uitbreiding', 'belangen', 'omwonenden'], full_text: "Schiphol heeft al genoeg overlast veroorzaakt. FvD zal er alles aan doen om de uitbreiding tegen te houden en de belangen van omwonenden te beschermen. 🤔", keyword_clusters: {'Schiphol': 5, 'overlast': 1, 'FvD': 0, 'uitbreiding': 1, 'belangen': 1, 'omwonenden': 1}},
{sentiment: 0.9959421753883362, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: ['🤔'], keywords: ['Schiphollen', 'Nederland', 'FvD', 'leefomgeving', 'uitbreiding'], full_text: "Wij willen geen Schiphollen in Nederland! FvD gaat voor een gezonde leefomgeving en zal zich inzetten om de uitbreiding te stoppen. 🤔", keyword_clusters: {'Schiphollen': 7, 'Nederland': 5, 'FvD': 0, 'leefomgeving': 1, 'uitbreiding': 1}},
{sentiment: 0.9972570538520813, lat: 52.1594747, lng: 4.4908843, count: 1, topics: [], group: 'piet', emojis: ['🤔'], keywords: ['FvD', 'grenzen', 'groei', 'Schiphol', 'uitbreiding', 'overlast', 'vervuiling'], full_text: "FvD vindt dat de grenzen van groei van Schiphol zijn bereikt. Verdere uitbreiding is onwenselijk en zal leiden tot nog meer overlast en vervuiling. 🤔", keyword_clusters: {'FvD': 0, 'grenzen': 5, 'groei': 4, 'Schiphol': 5, 'uitbreiding': 1, 'overlast': 1, 'vervuiling': 1}},
{sentiment: 0.9972621202468872, lat: 52.2434979, lng: 5.6343227, count: 1, topics: [], group: 'piet', emojis: ['🤔'], keywords: ['tijd', 'luchtvaartindustrie', 'Nederland', 'FvD', 'alternatieven', 'uitbreiding', 'Schiphol'], full_text: "Het is tijd om de luchtvaartindustrie in Nederland te hervormen. FvD pleit voor duurzame alternatieven en zal zich verzetten tegen de uitbreiding van Schiphol. 🤔", keyword_clusters: {'tijd': 3, 'luchtvaartindustrie': 1, 'Nederland': 5, 'FvD': 0, 'alternatieven': 1, 'uitbreiding': 1, 'Schiphol': 5}},
{sentiment: 0.9978579878807068, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: ['🤔'], keywords: ['groei', 'Schiphol', 'koste', 'leefomgeving', 'FvD', 'ontwikkeling', 'luchtvaartsector'], full_text: "Wij willen geen ongebreidelde groei van Schiphol ten koste van onze leefomgeving. FvD staat voor een evenwichtige en duurzame ontwikkeling van de luchtvaartsector. 🤔", keyword_clusters: {'groei': 4, 'Schiphol': 5, 'koste': 5, 'leefomgeving': 1, 'FvD': 0, 'ontwikkeling': 0, 'luchtvaartsector': 0}},
{sentiment: 0.9971438050270081, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'oplossing', 'luchtvaartproblematiek', 'FvD', 'innovatie', 'duurzaamheid', 'groei'], full_text: "De uitbreiding van Schiphol is geen oplossing voor de luchtvaartproblematiek. FvD wil inzetten op innovatie en duurzaamheid, niet op groei.", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'oplossing': 0, 'luchtvaartproblematiek': 7, 'FvD': 0, 'innovatie': 1, 'duurzaamheid': 1, 'groei': 4}},
{sentiment: 0.998022198677063, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: ['😜'], keywords: ['FvD', 'uitbreiding', 'Schiphol', 'leefomgeving', 'toekomst', '😜'], full_text: "FvD zegt nee tegen de uitbreiding van Schiphol en ja tegen een gezonde leefomgeving voor ons allemaal. Laten we samenwerken aan een duurzame toekomst. 😜", keyword_clusters: {'FvD': 0, 'uitbreiding': 1, 'Schiphol': 5, 'leefomgeving': 1, 'toekomst': 1}},
{sentiment: 0.9976837635040283, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: ['🤔'], keywords: ['Schiphol', 'koste', 'gezondheid', 'leefomgeving', 'FvD', 'ontwikkeling', 'luchtvaartindustrie'], full_text: "Schiphol moet niet ten koste gaan van onze gezondheid en leefomgeving. FvD zal blijven strijden voor een evenwichtige ontwikkeling van de luchtvaartindustrie. 🤔", keyword_clusters: {'Schiphol': 5, 'koste': 5, 'gezondheid': 1, 'leefomgeving': 1, 'FvD': 0, 'ontwikkeling': 0, 'luchtvaartindustrie': 1}},
{sentiment: 0.9978418350219727, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: ['🤔', '🤔', '🤔'], keywords: ['FvD', 'uitbreiding', 'Schiphol', 'zorgen', 'effecten', 'klimaat', 'gezondheid', 'omwonenden', 'aanpak'], full_text: "FvD is tegen de uitbreiding van Schiphol omdat we ons zorgen maken over de effecten op het klimaat en de gezondheid van omwonenden. Wij pleiten voor een verstandige en duurzame aanpak. 🤔🤔🤔", keyword_clusters: {'FvD': 0, 'uitbreiding': 1, 'Schiphol': 5, 'zorgen': 1, 'effecten': 4, 'klimaat': 1, 'gezondheid': 1, 'omwonenden': 1, 'aanpak': 7}},
{sentiment: 0.9952853322029114, lat: 52.2434979, lng: 5.6343227, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['grootheidswaanzin', 'Schiphol', 'Nederland', 'PVV', 'Schiphol'], full_text: "Stop de grootheidswaanzin van Schiphol! Nederland is vol genoeg! #PVV #Schiphol", keyword_clusters: {'grootheidswaanzin': 0, 'Schiphol': 5, 'Nederland': 5, 'PVV': 5}},
{sentiment: 0.9965358972549438, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['pvv'], group: 'piet', emojis: [], keywords: ['overlast', 'Schiphol', 'PVV', 'belangen', 'omwonenden', 'milieu'], full_text: "De overlast van Schiphol moet stoppen! De #PVV staat voor de belangen van omwonenden en het milieu!", keyword_clusters: {'overlast': 1, 'Schiphol': 5, 'PVV': 5, 'belangen': 1, 'omwonenden': 1, 'milieu': 1}},
{sentiment: 0.9979994893074036, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'tijd', 'alternatieven', 'PVV', 'Schiphol'], full_text: "Waarom moet Schiphol zo nodig groeien? Het is de hoogste tijd om te investeren in alternatieven! #PVV #Schiphol", keyword_clusters: {'Schiphol': 5, 'tijd': 3, 'alternatieven': 1, 'PVV': 5}},
{sentiment: 0.9974071383476257, lat: null, lng: null, count: 1, topics: ['pvv'], group: 'piet', emojis: [], keywords: ['PVV', 'uitbreiding', 'Schiphol'], full_text: "De #PVV zegt nee tegen verdere uitbreiding van Schiphol! Genoeg is genoeg!", keyword_clusters: {'PVV': 5, 'uitbreiding': 1, 'Schiphol': 5}},
{sentiment: 0.9975098371505737, lat: null, lng: null, count: 1, topics: ['pvv'], group: 'piet', emojis: [], keywords: ['Schiphol', 'koste', 'leefbaarheid', 'omgeving', 'klimaat', 'PVV', 'toekomst'], full_text: "Schiphol gaat ten koste van de leefbaarheid in de omgeving en het klimaat! De #PVV kiest voor een duurzame toekomst!", keyword_clusters: {'Schiphol': 5, 'koste': 5, 'leefbaarheid': 1, 'omgeving': 0, 'klimaat': 1, 'PVV': 5, 'toekomst': 1}},
{sentiment: 0.9915122389793396, lat: null, lng: null, count: 1, topics: ['schiphol', 'pvv'], group: 'piet', emojis: [], keywords: ['Schiphol', 'lak', 'omwonenden', 'milieu', 'PVV', 'lobby', 'luchtvaartindustrie'], full_text: "#Schiphol heeft lak aan omwonenden en het milieu! De #PVV laat zich niet intimideren door de lobby van de luchtvaartindustrie!", keyword_clusters: {'Schiphol': 5, 'lak': 0, 'omwonenden': 1, 'milieu': 1, 'PVV': 5, 'lobby': 0, 'luchtvaartindustrie': 1}},
{sentiment: 0.995776891708374, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['pvv'], group: 'piet', emojis: [], keywords: ['PVV', 'macht', 'Schiphol', 'toekomst', 'land', 'inwoners'], full_text: "De #PVV wil de macht van Schiphol breken en kiezen voor een betere toekomst voor ons land en haar inwoners!", keyword_clusters: {'PVV': 5, 'macht': 5, 'Schiphol': 5, 'toekomst': 1, 'land': 7, 'inwoners': 5}},
{sentiment: 0.9949967861175537, lat: null, lng: null, count: 1, topics: ['pvv'], group: 'piet', emojis: [], keywords: ['Schiphol', 'bedreiging', 'gezondheid', 'welzijn', 'PVV', 'belangen', 'burgers'], full_text: "Schiphol is een bedreiging voor onze gezondheid en ons welzijn! De #PVV staat voor de belangen van de burgers!", keyword_clusters: {'Schiphol': 5, 'bedreiging': 7, 'gezondheid': 1, 'welzijn': 1, 'PVV': 5, 'belangen': 1, 'burgers': 1}},
{sentiment: 0.9972483515739441, lat: null, lng: null, count: 1, topics: ['pvv'], group: 'piet', emojis: [], keywords: ['tijd', 'regulering', 'Schiphol', 'PVV', 'rechten', 'omwonenden'], full_text: "Het is tijd voor een strengere regulering van Schiphol! De #PVV komt op voor de rechten van omwonenden!", keyword_clusters: {'tijd': 3, 'regulering': 7, 'Schiphol': 5, 'PVV': 5, 'rechten': 1, 'omwonenden': 1}},
{sentiment: 0.9973537921905518, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['pvv'], group: 'piet', emojis: [], keywords: ['PVV', 'overlast', 'Schiphol', 'alternatieven', 'spreiding', 'vluchten'], full_text: "De #PVV wil de overlast van Schiphol verminderen door te investeren in duurzame alternatieven en betere spreiding van vluchten!", keyword_clusters: {'PVV': 5, 'overlast': 1, 'Schiphol': 5, 'alternatieven': 1, 'spreiding': 4, 'vluchten': 0}},
{sentiment: 0.08231359720230103, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['Nederland', 'waanzin', 'Schiphol', 'uitbreiding', 'plaats', 'luchthavens', 'PVV', 'Schiphol'], full_text: "Nederland moet stoppen met de waanzin van Schiphol uitbreiding en in plaats daarvan investeren in onze eigen luchthavens! #PVV #Schiphol", keyword_clusters: {'Nederland': 5, 'waanzin': 4, 'Schiphol': 5, 'uitbreiding': 1, 'plaats': 0, 'luchthavens': 5, 'PVV': 5}},
{sentiment: 0.9958004355430603, lat: null, lng: null, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'lawaaieriger', 'Tijd', 'belang', 'burgers', 'uitbreiding', 'PVV', 'Schiphol'], full_text: "Schiphol wordt steeds groter en lawaaieriger. Tijd om het belang van burgers voorop te stellen en de uitbreiding te stoppen! #PVV #Schiphol", keyword_clusters: {'Schiphol': 5, 'lawaaieriger': 4, 'Tijd': 4, 'belang': 7, 'burgers': 1, 'uitbreiding': 1, 'PVV': 5}},
{sentiment: 0.9959254264831543, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['lawaai', 'vervuiling', 'handvol', 'banen', 'uitbreiding', 'Schiphol', 'PVV', 'Schiphol'], full_text: "Waarom moeten we nog meer lawaai en vervuiling tolereren voor een handvol banen? Stop de uitbreiding van Schiphol nu! #PVV #Schiphol", keyword_clusters: {'lawaai': 5, 'vervuiling': 1, 'handvol': 0, 'banen': 0, 'uitbreiding': 1, 'Schiphol': 5, 'PVV': 5}},
{sentiment: 0.09149640798568726, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'aanval', 'gezondheid', 'welzijn', 'omwonenden', 'PVV', 'Schiphol'], full_text: "De uitbreiding van Schiphol is een directe aanval op de gezondheid en het welzijn van de omwonenden. Dit moet stoppen! #PVV #Schiphol", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'aanval': 7, 'gezondheid': 1, 'welzijn': 1, 'omwonenden': 1, 'PVV': 5}},
{sentiment: 0.9968543648719788, lat: null, lng: null, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['lobby', 'luchtvaartmaatschappijen', 'belangen', 'burgers', 'PVV', 'Schiphol'], full_text: "We moeten stoppen met het blindelings volgen van de lobby van de luchtvaartmaatschappijen en opkomen voor de belangen van onze burgers! #PVV #Schiphol", keyword_clusters: {'lobby': 0, 'luchtvaartmaatschappijen': 5, 'belangen': 1, 'burgers': 1, 'PVV': 5, 'Schiphol': 5}},
{sentiment: 0.9970958232879639, lat: null, lng: null, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'vrijbrief', 'lucht', 'water', 'grond', 'Tijd', 'uitbreiding', 'PVV', 'Schiphol'], full_text: "Schiphol mag niet langer een vrijbrief krijgen om onze lucht, water en grond te vervuilen. Tijd om de uitbreiding te stoppen! #PVV #Schiphol", keyword_clusters: {'Schiphol': 5, 'vrijbrief': 0, 'lucht': 0, 'water': 7, 'grond': 4, 'Tijd': 4, 'uitbreiding': 1, 'PVV': 5}},
{sentiment: 0.9943475127220154, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'voorbeeld', 'arrogantie', 'politiek', 'stem', 'burgers', 'uitbreiding', 'PVV', 'Schiphol'], full_text: "De uitbreiding van Schiphol is een typisch voorbeeld van de arrogantie van de politiek. We moeten luisteren naar de stem van de burgers en de uitbreiding stoppen! #PVV #Schiphol", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'voorbeeld': 4, 'arrogantie': 0, 'politiek': 1, 'stem': 1, 'burgers': 1, 'PVV': 5}},
{sentiment: 0.9970591068267822, lat: null, lng: null, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['tijd', 'grenzen', 'uitbreiding', 'Schiphol', 'land', 'overlast', 'vervuiling', 'PVV', 'Schiphol'], full_text: "Het is tijd om de grenzen te sluiten voor de uitbreiding van Schiphol. We moeten ons land beschermen tegen de overlast en vervuiling! #PVV #Schiphol", keyword_clusters: {'tijd': 3, 'grenzen': 5, 'uitbreiding': 1, 'Schiphol': 5, 'land': 7, 'overlast': 1, 'vervuiling': 1, 'PVV': 5}},
{sentiment: 0.017795145511627197, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'druk', 'infrastructuur', 'Tijd', 'halt', 'waanzin', 'PVV', 'Schiphol'], full_text: "De uitbreiding van Schiphol zal alleen maar meer druk leggen op onze toch al overvolle infrastructuur. Tijd om een halt toe te roepen aan deze waanzin! #PVV #Schiphol", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'druk': 4, 'infrastructuur': 5, 'Tijd': 4, 'halt': 7, 'waanzin': 4, 'PVV': 5}},
{sentiment: 0.9968907237052917, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['pvv', 'schiphol'], group: 'piet', emojis: [], keywords: ['Nederland', 'behoefte', 'luchthavenbeleid', 'rekening', 'leefbaarheid', 'steden', 'dorpen', 'uitbreiding', 'Schiphol', 'PVV', 'Schiphol'], full_text: "Nederland heeft behoefte aan een luchthavenbeleid dat rekening houdt met de leefbaarheid van onze steden en dorpen. Stop de uitbreiding van Schiphol nu! #PVV #Schiphol", keyword_clusters: {'Nederland': 5, 'behoefte': 7, 'luchthavenbeleid': 5, 'rekening': 5, 'leefbaarheid': 1, 'steden': 7, 'dorpen': 5, 'uitbreiding': 1, 'Schiphol': 5, 'PVV': 5}},
{sentiment: 0.9981873631477356, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['been', 'more', 'Stay', 'developments'], full_text: "Schiphol's expansion has been a hot topic for years now, but it seems the debate is more intense than ever before. Stay tuned for updates on the latest developments.", keyword_clusters: {'been': 5, 'more': 7, 'Stay': 5, 'developments': 7}},
{sentiment: 0.9983869791030884, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['As', 'concerns', 'expanding', 'Schiphol', 'Airport', 'listen'], full_text: "As concerns over climate change grow, many are questioning the wisdom of expanding Schiphol Airport. Will the government listen?", keyword_clusters: {'As': 4, 'concerns': 5, 'expanding': 0, 'Schiphol': 5, 'Airport': 4, 'listen': 7}},
{sentiment: 0.9983534812927246, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['The', 'Schiphol', 'sustainability'], full_text: "The Schiphol expansion debate is not just about noise pollution and air quality. It's also a question of economic growth vs. sustainability.", keyword_clusters: {'The': 4, 'Schiphol': 5, 'sustainability': 7}},
{sentiment: 0.9971712231636047, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Experts'], full_text: "Experts say that the government's plans for Schiphol's expansion are not in line with the Netherlands' climate targets. Will the politicians take note?", keyword_clusters: {}},
{sentiment: 0.997805655002594, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol', 'rages', 'plan'], full_text: "As the Schiphol expansion debate rages on, residents and environmental groups are speaking out against what they see as an unsustainable and dangerous plan.", keyword_clusters: {'Schiphol': 5, 'rages': 7, 'plan': 0}},
{sentiment: 0.9981367588043213, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['the', 'deadline', 'tensions', 'listen', 'concerns', 'the', 'people'], full_text: "With the deadline for public comment on the Schiphol expansion plans fast approaching, tensions are rising. Will the government listen to the concerns of the people?", keyword_clusters: {'the': 5, 'deadline': 0, 'tensions': 7, 'listen': 7, 'concerns': 5, 'people': 4}},
{sentiment: 0.9981105327606201, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol', 'heats'], full_text: "As the Schiphol expansion debate heats up, it's clear that this is a critical moment for the future of aviation in the Netherlands.", keyword_clusters: {'Schiphol': 5, 'heats': 7}},
{sentiment: 0.9980637431144714, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: [], full_text: "Many are questioning whether the economic benefits of Schiphol's expansion outweigh the potential environmental and health risks.", keyword_clusters: {}},
{sentiment: 0.998157799243927, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['The', 'Schiphol', 'sustainability'], full_text: "The Schiphol expansion debate highlights the delicate balance between economic growth and sustainability in the Netherlands.", keyword_clusters: {'The': 4, 'Schiphol': 5, 'sustainability': 7}},
{sentiment: 0.9982448816299438, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['deadline', 'interests'], full_text: "As the deadline for public comment on the Schiphol expansion approaches, it's clear that the government will have to navigate a complex web of economic and environmental interests.", keyword_clusters: {'deadline': 0, 'interests': 5}},
{sentiment: 0.9983538389205933, lat: null, lng: null, count: 1, topics: ['schiphol', 'milieu', 'vliegtuigspotter'], group: 'piet', emojis: [], keywords: ['vliegtuigspotter', 'gevolgen', 'Schiphol-uitbreiding', 'milieu', 'omgeving', 'Schiphol', 'milieu', 'vliegtuigspotter'], full_text: "Als vliegtuigspotter maak ik me zorgen over de gevolgen van de Schiphol-uitbreiding voor het milieu en de omgeving. #Schiphol #milieu #vliegtuigspotter", keyword_clusters: {'vliegtuigspotter': 1, 'gevolgen': 1, 'Schiphol-uitbreiding': 0, 'milieu': 1, 'omgeving': 0, 'Schiphol': 5}},
{sentiment: 0.997066080570221, lat: 52.1594747, lng: 4.4908843, count: 1, topics: ['schiphol', 'geluidsoverlast', 'vliegtuigspotter'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'vluchten', 'geluidsoverlast', 'nieuws', 'vliegtuigspotters', 'Schiphol', 'geluidsoverlast', 'vliegtuigspotter'], full_text: "De uitbreiding van Schiphol zal leiden tot meer vluchten en meer geluidsoverlast. Geen goed nieuws voor vliegtuigspotters. #Schiphol #geluidsoverlast #vliegtuigspotter", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'vluchten': 0, 'geluidsoverlast': 1, 'nieuws': 5, 'vliegtuigspotters': 4, 'vliegtuigspotter': 1}},
{sentiment: 0.32450586557388306, lat: null, lng: null, count: 1, topics: ['schiphol', 'natuur', 'vliegtuigspotter'], group: 'piet', emojis: [], keywords: ['Schiphol-uitbreiding', 'koste', 'natuur', 'leefomgeving', 'vliegtuigspotter', 'Schiphol', 'natuur', 'vliegtuigspotter'], full_text: "Het is triest om te zien hoe de Schiphol-uitbreiding ten koste gaat van de natuur en de leefomgeving. Als vliegtuigspotter kan ik dit niet accepteren. #Schiphol #natuur #vliegtuigspotter", keyword_clusters: {'Schiphol-uitbreiding': 0, 'koste': 5, 'natuur': 1, 'leefomgeving': 1, 'vliegtuigspotter': 1, 'Schiphol': 5}},
{sentiment: 0.9976640939712524, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'milieu', 'vliegtuigspotter'], group: 'piet', emojis: [], keywords: ['balans', 'groei', 'impact', 'milieu', 'omgeving', 'uitbreiding', 'Schiphol', 'balans', 'Schiphol', 'milieu', 'vliegtuigspotter'], full_text: "Het is belangrijk om een balans te vinden tussen economische groei en de impact op het milieu en de omgeving. Helaas lijkt de uitbreiding van Schiphol deze balans te verstoren. #Schiphol #milieu #vliegtuigspotter", keyword_clusters: {'balans': 0, 'groei': 4, 'impact': 5, 'milieu': 1, 'omgeving': 0, 'uitbreiding': 1, 'Schiphol': 5, 'vliegtuigspotter': 1}},
{sentiment: 0.9972368478775024, lat: 52.1594747, lng: 4.4908843, count: 1, topics: ['schiphol', 'geluidsoverlast', 'vliegtuigspotter'], group: 'piet', emojis: ['😂'], keywords: ['Schiphol-uitbreiding', 'vliegtuigen', 'hoofd', 'rust', 'Schiphol', 'geluidsoverlast', 'vliegtuigspotter'], full_text: "Ik ben bang dat de Schiphol-uitbreiding zal leiden tot meer vliegtuigen die over mijn hoofd vliegen en de rust verstoren. #Schiphol #geluidsoverlast #vliegtuigspotter 😂", keyword_clusters: {'Schiphol-uitbreiding': 0, 'vliegtuigen': 0, 'hoofd': 7, 'rust': 5, 'Schiphol': 5, 'geluidsoverlast': 1, 'vliegtuigspotter': 1}},
{sentiment: 0.9981527924537659, lat: null, lng: null, count: 1, topics: ['schiphol', 'milieu', 'vliegtuigspotter'], group: 'piet', emojis: ['😂'], keywords: ['vliegtuigspotter', 'schoonheid', 'vliegtuigen', 'luchtvaart', 'gevolgen', 'industrie', 'milieu', 'Schiphol', 'milieu', 'vliegtuigspotter'], full_text: "Als vliegtuigspotter geniet ik van de schoonheid van vliegtuigen en de luchtvaart, maar ik ben me ook bewust van de gevolgen van deze industrie voor het milieu. #Schiphol #milieu #vliegtuigspotter 😂", keyword_clusters: {'vliegtuigspotter': 1, 'schoonheid': 7, 'vliegtuigen': 0, 'luchtvaart': 7, 'gevolgen': 1, 'industrie': 7, 'milieu': 1, 'Schiphol': 5}},
{sentiment: 0.997992753982544, lat: 52.1594747, lng: 4.4908843, count: 1, topics: ['schiphol', 'milieu', 'vliegtuigspotter'], group: 'piet', emojis: ['😂'], keywords: ['uitbreiding', 'Schiphol', 'luchtvervuiling', 'klimaatverandering', 'vliegtuigspotter', 'toekomst', 'planeet', 'Schiphol', 'milieu', 'vliegtuigspotter'], full_text: "De uitbreiding van Schiphol zal leiden tot meer luchtvervuiling en klimaatverandering. Als vliegtuigspotter ben ik bezorgd over de toekomst van onze planeet. #Schiphol #milieu #vliegtuigspotter 😂", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'luchtvervuiling': 1, 'klimaatverandering': 1, 'vliegtuigspotter': 1, 'toekomst': 1, 'planeet': 1, 'milieu': 1}},
{sentiment: 0.9981881976127625, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'milieu', 'vliegtuigspotter'], group: 'piet', emojis: ['😂'], keywords: ['uitbreiding', 'Schiphol', 'gevolgen', 'omgeving', 'rest', 'Nederland', 'vliegtuigspotter', 'boodschap', 'Schiphol', 'milieu', 'vliegtuigspotter'], full_text: "Het is belangrijk om te beseffen dat de uitbreiding van Schiphol niet alleen gevolgen heeft voor de directe omgeving, maar ook voor de rest van Nederland. Als vliegtuigspotter voel ik me verantwoordelijk om deze boodschap te delen. #Schiphol #milieu #vliegtuigspotter 😂", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'gevolgen': 1, 'omgeving': 0, 'rest': 0, 'Nederland': 5, 'vliegtuigspotter': 1, 'boodschap': 5, 'milieu': 1}},
{sentiment: 0.9976537823677063, lat: 52.1594747, lng: 4.4908843, count: 1, topics: ['schiphol', 'vertraging', 'vliegtuigspotter'], group: 'piet', emojis: ['😂'], keywords: ['uitbreiding', 'Schiphol', 'drukte', 'luchthaven', 'vertragingen', 'passagiers', 'vliegtuigspotter', 'probleem', 'Schiphol', 'vertraging', 'vliegtuigspotter'], full_text: "De uitbreiding van Schiphol zal leiden tot meer drukte op de luchthaven en meer vertragingen voor passagiers. Als vliegtuigspotter zie ik dit als een probleem dat moet worden aangepakt. #Schiphol #vertraging #vliegtuigspotter 😂", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'drukte': 7, 'luchthaven': 3, 'vertragingen': 5, 'passagiers': 4, 'vliegtuigspotter': 1, 'probleem': 4, 'vertraging': 1}},
{sentiment: 0.9977107048034668, lat: null, lng: null, count: 1, topics: ['schiphol', 'milieu', 'vliegtuigspotter'], group: 'piet', emojis: ['😂'], keywords: ['rekening', 'gevolgen', 'Schiphol-uitbreiding', 'milieu', 'omgeving', 'luchtvaartindustrie', 'geheel', 'vliegtuigspotter', 'oplossing', 'Schiphol', 'milieu', 'vliegtuigspotter'], full_text: "Ik hoop dat er rekening wordt gehouden met de gevolgen van de Schiphol-uitbreiding voor het milieu, de omgeving en de luchtvaartindustrie als geheel. Als vliegtuigspotter ben ik bereid om bij te dragen aan een oplossing. #Schiphol #milieu #vliegtuigspotter 😂", keyword_clusters: {'rekening': 5, 'gevolgen': 1, 'Schiphol-uitbreiding': 0, 'milieu': 1, 'omgeving': 0, 'luchtvaartindustrie': 1, 'geheel': 5, 'vliegtuigspotter': 1, 'oplossing': 0, 'Schiphol': 5}},
{sentiment: 0.9980869293212891, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding'], group: 'piet', emojis: ['😂'], keywords: ['vliegtuigspotter', 'voorstander', 'vliegbewegingen', 'Schiphol', 'koste', 'Schiphol', 'uitbreiding'], full_text: "Als vliegtuigspotter ben ik natuurlijk voorstander van meer vliegbewegingen op Schiphol, maar niet ten koste van alles! #Schiphol #uitbreiding 😂", keyword_clusters: {'vliegtuigspotter': 1, 'voorstander': 0, 'vliegbewegingen': 1, 'Schiphol': 5, 'koste': 5, 'uitbreiding': 1}},
{sentiment: 0.9980123043060303, lat: null, lng: null, count: 1, topics: ['vliegtuigspotter', 'economie'], group: 'piet', emojis: ['😂'], keywords: ['zorgen', 'uitbreiding', 'Schiphol', 'luchtvaart', 'sector', 'Nederland', 'vliegtuigspotter', 'economie'], full_text: "Ik begrijp de zorgen over de uitbreiding van Schiphol, maar laten we niet vergeten dat de luchtvaart een belangrijke economische sector is voor Nederland. #vliegtuigspotter #economie 😂", keyword_clusters: {'zorgen': 1, 'uitbreiding': 1, 'Schiphol': 5, 'luchtvaart': 7, 'sector': 0, 'Nederland': 5, 'vliegtuigspotter': 1, 'economie': 1}},
{sentiment: 0.9978231191635132, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'werkgelegenheid'], group: 'piet', emojis: ['😂', '😂', '😂'], keywords: ['mensen', 'overlast', 'vliegtuigen', 'Schiphol', 'werkgelegenheid', 'Schiphol', 'werkgelegenheid'], full_text: "Ik snap dat mensen overlast ervaren van de vliegtuigen, maar laten we niet vergeten dat Schiphol ook voor veel werkgelegenheid zorgt. #Schiphol #werkgelegenheid 😂😂😂", keyword_clusters: {'mensen': 0, 'overlast': 1, 'vliegtuigen': 0, 'Schiphol': 5, 'werkgelegenheid': 1}},
{sentiment: 0.9966711401939392, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['vliegtuigspotter', 'schiphol'], group: 'piet', emojis: ['😂'], keywords: ['oplossing', 'Schiphol', 'overlast', 'omwonenden', 'vliegtuigspotter', 'Schiphol'], full_text: "Ik hoop dat er een oplossing wordt gevonden waarbij Schiphol kan uitbreiden én de overlast voor omwonenden wordt verminderd. #vliegtuigspotter #Schiphol 😂", keyword_clusters: {'oplossing': 0, 'Schiphol': 5, 'overlast': 1, 'omwonenden': 1, 'vliegtuigspotter': 1}},
{sentiment: 0.9979368448257446, lat: null, lng: null, count: 1, topics: ['duurzaamheid', 'schiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'hub', 'luchtvaartmaatschappijen', 'vliegtuigspotters', 'positie', 'manier', 'duurzaamheid', 'Schiphol'], full_text: "Schiphol is een belangrijke hub voor veel luchtvaartmaatschappijen en vliegtuigspotters. Laten we ervoor zorgen dat deze positie behouden blijft, maar wel op een duurzame manier. #duurzaamheid #Schiphol", keyword_clusters: {'Schiphol': 5, 'hub': 4, 'luchtvaartmaatschappijen': 5, 'vliegtuigspotters': 4, 'positie': 7, 'manier': 5, 'duurzaamheid': 1}},
{sentiment: 0.9975134134292603, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['vliegtuigspotter', 'duurzaamheid'], group: 'piet', emojis: [], keywords: ['vliegtuigspotter', 'vliegtuigtype', 'Schiphol', 'toekomst', 'planeet', 'vliegtuigspotter', 'duurzaamheid'], full_text: "Als vliegtuigspotter ben ik altijd blij als er weer een nieuw vliegtuigtype op Schiphol landt of opstijgt. Maar laten we niet vergeten dat we ook moeten denken aan de toekomst van onze planeet. #vliegtuigspotter #duurzaamheid", keyword_clusters: {'vliegtuigspotter': 1, 'vliegtuigtype': 5, 'Schiphol': 5, 'toekomst': 1, 'planeet': 1, 'duurzaamheid': 1}},
{sentiment: 0.9981305003166199, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vliegtuigspotter'], group: 'piet', emojis: [], keywords: ['balans', 'belangen', 'Schiphol', 'overlast', 'omwonenden', 'vliegtuigspotter', 'steun', 'zoektocht', 'oplossing', 'Schiphol', 'vliegtuigspotter'], full_text: "Het is belangrijk dat er een goede balans wordt gevonden tussen de economische belangen van Schiphol en de overlast voor omwonenden. Als vliegtuigspotter steun ik deze zoektocht naar een oplossing. #Schiphol #vliegtuigspotter", keyword_clusters: {'balans': 0, 'belangen': 1, 'Schiphol': 5, 'overlast': 1, 'omwonenden': 1, 'vliegtuigspotter': 1, 'steun': 7, 'zoektocht': 7, 'oplossing': 0}},
{sentiment: 0.9978095889091492, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'bereikbaarheid'], group: 'piet', emojis: [], keywords: ['Schiphol', 'rol', 'luchtverkeer', 'bereikbaarheid', 'Nederland', 'manieren', 'overlast', 'Schiphol', 'bereikbaarheid'], full_text: "Laten we niet vergeten dat Schiphol een belangrijke rol speelt in het internationale luchtverkeer en daarmee ook voor de bereikbaarheid van Nederland. Maar laten we ook zoeken naar manieren om de overlast te verminderen. #Schiphol #bereikbaarheid", keyword_clusters: {'Schiphol': 5, 'rol': 5, 'luchtverkeer': 0, 'bereikbaarheid': 7, 'Nederland': 5, 'manieren': 7, 'overlast': 1}},
{sentiment: 0.9981279969215393, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['vliegtuigspotter', 'duurzaamheid'], group: 'piet', emojis: [], keywords: ['vliegtuigspotter', 'balans', 'voordelen', 'Schiphol', 'impact', 'milieu', 'toekomst', 'luchtvaart', 'vliegtuigspotter', 'duurzaamheid'], full_text: "Als vliegtuigspotter vind ik het belangrijk dat er een goede balans is tussen de economische voordelen van Schiphol en de impact op het milieu. Laten we samenwerken aan een duurzame toekomst voor de luchtvaart. #vliegtuigspotter #duurzaamheid", keyword_clusters: {'vliegtuigspotter': 1, 'balans': 0, 'voordelen': 1, 'Schiphol': 5, 'impact': 5, 'milieu': 1, 'toekomst': 1, 'luchtvaart': 7, 'duurzaamheid': 1}},
{sentiment: 0.9981818199157715, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vliegtuigspotter'], group: 'piet', emojis: [], keywords: ['mensen', 'overlast', 'vliegtuigen', 'Schiphol', 'vliegtuigspotter', 'positie', 'Schiphol', 'luchthavens', 'wereld', 'oplossing', 'toekomst', 'Schiphol', 'vliegtuigspotter'], full_text: "Ik begrijp dat mensen overlast ervaren van de vliegtuigen op Schiphol, maar als vliegtuigspotter ben ik ook trots op de positie van Schiphol als één van de belangrijkste luchthavens ter wereld. Laten we samenwerken aan een oplossing voor de toekomst. #Schiphol #vliegtuigspotter", keyword_clusters: {'mensen': 0, 'overlast': 1, 'vliegtuigen': 0, 'Schiphol': 5, 'vliegtuigspotter': 1, 'positie': 7, 'luchthavens': 5, 'wereld': 7, 'oplossing': 0, 'toekomst': 1}},
{sentiment: 0.9966506361961365, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'uitbreiding'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'achtertuin', 'Schiphol', 'uitbreiding'], full_text: "Geen uitbreiding van Schiphol in mijn achtertuin! #Schiphol #uitbreiding", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'achtertuin': 7}},
{sentiment: 0.9971696734428406, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'overlast'], group: 'piet', emojis: [], keywords: ['omwonenden', 'overlast', 'Schiphol', 'uitbreiding', 'Schiphol', 'overlast'], full_text: "Waarom moeten wij als omwonenden de overlast van Schiphol accepteren? Geen uitbreiding! #Schiphol #overlast", keyword_clusters: {'omwonenden': 1, 'overlast': 1, 'Schiphol': 5, 'uitbreiding': 1}},
{sentiment: 0.9931930899620056, lat: null, lng: null, count: 1, topics: ['schiphol', 'woonplezier'], group: 'piet', emojis: [], keywords: ['groei', 'Schiphol', 'woongenot', 'spel', 'Schiphol', 'woonplezier'], full_text: "Stop de groei van Schiphol, ons woongenot staat op het spel #Schiphol #woonplezier", keyword_clusters: {'groei': 4, 'Schiphol': 5, 'woongenot': 4, 'spel': 4, 'woonplezier': 5}},
{sentiment: 0.9978544116020203, lat: null, lng: null, count: 1, topics: ['schiphol', 'gezondheid'], group: 'piet', emojis: [], keywords: ['Schiphol', 'koste', 'gezondheid', 'leefomgeving', 'Schiphol', 'gezondheid'], full_text: "Schiphol mag niet ten koste gaan van onze gezondheid en leefomgeving #Schiphol #gezondheid", keyword_clusters: {'Schiphol': 5, 'koste': 5, 'gezondheid': 1, 'leefomgeving': 1}},
{sentiment: 0.9982860684394836, lat: null, lng: null, count: 1, topics: ['schiphol', 'uitbreiding'], group: 'piet', emojis: [], keywords: ['Schiphol', 'uitbreiding', 'luchthaven', 'Schiphol', 'uitbreiding'], full_text: "Ik ben niet tegen Schiphol, maar wel tegen de uitbreiding van de luchthaven #Schiphol #uitbreiding", keyword_clusters: {'Schiphol': 5, 'uitbreiding': 1, 'luchthaven': 3}},
{sentiment: 0.9963951706886292, lat: null, lng: null, count: 1, topics: ['schiphol', 'vervuiling'], group: 'piet', emojis: [], keywords: ['herrie', 'vervuiling', 'Schiphol', 'uitbreiding', 'Schiphol', 'vervuiling'], full_text: "Het is genoeg geweest met de herrie en vervuiling van Schiphol, geen uitbreiding! #Schiphol #vervuiling", keyword_clusters: {'herrie': 4, 'vervuiling': 1, 'Schiphol': 5, 'uitbreiding': 1}},
{sentiment: 0.9674670696258545, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'drukte'], group: 'piet', emojis: [], keywords: ['drukte', 'overlast', 'Schiphol', 'uitbreiding', 'optie', 'Schiphol'], full_text: "De drukte en overlast van Schiphol zijn nu al niet te doen, uitbreiding is geen optie! #Schiphol #drukte", keyword_clusters: {'drukte': 7, 'overlast': 1, 'Schiphol': 5, 'uitbreiding': 1, 'optie': 4}},
{sentiment: 0.9978522062301636, lat: null, lng: null, count: 1, topics: ['schiphol', 'afspraak'], group: 'piet', emojis: [], keywords: ['Schiphol', 'afspraken', 'sprake', 'uitbreiding', 'Schiphol', 'afspraak'], full_text: "Schiphol moet zich eerst aan de gemaakte afspraken houden, voordat er sprake kan zijn van uitbreiding #Schiphol #afspraak", keyword_clusters: {'Schiphol': 5, 'afspraken': 7, 'sprake': 4, 'uitbreiding': 1, 'afspraak': 4}},
{sentiment: 0.9979478716850281, lat: null, lng: null, count: 1, topics: ['schiphol', 'woonrust'], group: 'piet', emojis: [], keywords: ['rust', 'ruimte', 'luchthaven', 'buurt', 'Schiphol', 'woonrust'], full_text: "Wij willen rust en ruimte om te wonen, geen groeiende luchthaven in onze buurt #Schiphol #woonrust", keyword_clusters: {'rust': 5, 'ruimte': 5, 'luchthaven': 3, 'buurt': 5, 'Schiphol': 5, 'woonrust': 5}},
{sentiment: 0.9981032609939575, lat: null, lng: null, count: 1, topics: ['schiphol', 'economie'], group: 'piet', emojis: [], keywords: ['voordelen', 'Schiphol', 'schade', 'leefomgeving', 'Schiphol', 'economie'], full_text: "Wij zijn niet tegen de economische voordelen van Schiphol, maar wel tegen de schade aan onze leefomgeving #Schiphol #economie.", keyword_clusters: {'voordelen': 1, 'Schiphol': 5, 'schade': 4, 'leefomgeving': 1, 'economie': 1}},
{sentiment: 0.9977891445159912, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol'], full_text: "Schiphol expansion means more carbon emissions. We need to reduce our footprint, not increase it.", keyword_clusters: {'Schiphol': 5}},
{sentiment: 0.9851502776145935, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['worsens', 'expanding', 'Schiphol'], full_text: "As climate change worsens, expanding Schiphol is a reckless move. It's time to prioritize our planet over profits.", keyword_clusters: {'worsens': 5, 'expanding': 0, 'Schiphol': 5}},
{sentiment: 0.9968873858451843, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol', 'expansion', 'the'], full_text: "We can't afford to ignore the impacts of aviation on our environment. Schiphol expansion is a step in the wrong direction.", keyword_clusters: {'Schiphol': 5, 'expansion': 3, 'the': 5}},
{sentiment: 0.99752277135849, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol'], full_text: "The expansion of Schiphol puts the health and safety of our planet at risk. We need to demand better solutions.", keyword_clusters: {'Schiphol': 5}},
{sentiment: 0.9978523254394531, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Expanding', 'Schiphol'], full_text: "Expanding Schiphol only benefits the aviation industry, not our planet. We need to find sustainable alternatives.", keyword_clusters: {'Expanding': 0, 'Schiphol': 5}},
{sentiment: 0.9974259734153748, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['already', 'suffering', 'Schiphol'], full_text: "Our air quality is already suffering. We can't afford to let Schiphol expand and make things worse.", keyword_clusters: {'already': 0, 'suffering': 4, 'Schiphol': 5}},
{sentiment: 0.99801105260849, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol'], full_text: "Climate change is real, and Schiphol expansion will only exacerbate the problem. We need to take action now.", keyword_clusters: {'Schiphol': 5}},
{sentiment: 0.9970541000366211, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['from', 'fossil', 'fuels', 'expansion'], full_text: "We need to transition away from fossil fuels, not encourage their use with Schiphol expansion.", keyword_clusters: {'from': 4, 'fossil': 7, 'fuels': 5, 'expansion': 3}},
{sentiment: 0.9982727766036987, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['prioritizing', 'the', 'Schiphol', 'expansion', 'this'], full_text: "We can't keep prioritizing profits over the health of our planet. Schiphol expansion is a prime example of this.", keyword_clusters: {'prioritizing': 5, 'the': 5, 'Schiphol': 5, 'expansion': 3, 'this': 0}},
{sentiment: 0.9979666471481323, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol', 'expansion'], full_text: "Schiphol expansion is a short-sighted decision that will have long-term impacts on our climate. We need to find better solutions.", keyword_clusters: {'Schiphol': 5, 'expansion': 3}},
{sentiment: 0.9640083909034729, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol', 'hoofd', 'uitbreiding'], full_text: "Schiphol groeit ons boven het hoofd. Stop de uitbreiding nu!", keyword_clusters: {'Schiphol': 5, 'hoofd': 7, 'uitbreiding': 1}},
{sentiment: 0.9963032007217407, lat: 52.3080392, lng: 4.7621975, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['vliegtuiglawaai', 'Schiphol', 'rekening', 'gezondheid', 'omwonenden'], full_text: "Ik slaap niet meer door al dat vliegtuiglawaai. Schiphol moet rekening houden met de gezondheid van de omwonenden.", keyword_clusters: {'vliegtuiglawaai': 5, 'Schiphol': 5, 'rekening': 5, 'gezondheid': 1, 'omwonenden': 1}},
{sentiment: 0.9977424144744873, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol', 'winst', 'gevolgen', 'milieu', 'groei'], full_text: "Schiphol denkt alleen aan de winst, niet aan de gevolgen voor het milieu. Stop de groei!", keyword_clusters: {'Schiphol': 5, 'winst': 4, 'gevolgen': 1, 'milieu': 1, 'groei': 4}},
{sentiment: 0.555110514163971, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['tuin', 'vliegverkeer'], full_text: "Ik kan niet eens meer rustig in mijn eigen tuin zitten door al dat vliegverkeer. Het moet anders.", keyword_clusters: {'tuin': 7, 'vliegverkeer': 5}},
{sentiment: 0.9854261875152588, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol', 'maling', 'tijd', 'actie'], full_text: "Schiphol neemt ons in de maling met hun zogenaamde 'duurzaamheidsplannen'. Het is tijd voor actie!", keyword_clusters: {'Schiphol': 5, 'maling': 5, 'tijd': 3, 'actie': 1}},
{sentiment: 0.9925454258918762, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['vluchten', 'overlast', 'groei', 'Schiphol'], full_text: "Meer vluchten betekent meer overlast. Stop de groei van Schiphol!", keyword_clusters: {'vluchten': 0, 'overlast': 1, 'groei': 4, 'Schiphol': 5}},
{sentiment: 0.9979165196418762, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['leefbaarheid', 'buurt', 'spel', 'tijd', 'verandering'], full_text: "De leefbaarheid van onze buurt staat op het spel. Het is tijd voor verandering.", keyword_clusters: {'leefbaarheid': 1, 'buurt': 5, 'spel': 4, 'tijd': 3, 'verandering': 1}},
{sentiment: 0.9978952407836914, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol', 'zorgen', 'omwonenden'], full_text: "Schiphol moet luisteren naar de zorgen van de omwonenden en stoppen met uitbreiden.", keyword_clusters: {'Schiphol': 5, 'zorgen': 1, 'omwonenden': 1}},
{sentiment: 0.9948427081108093, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['kinderen', 'lawaai'], full_text: "Mijn kinderen kunnen niet eens meer rustig buiten spelen door al dat lawaai. Genoeg is genoeg.", keyword_clusters: {'kinderen': 1, 'lawaai': 5}},
{sentiment: 0.9980948567390442, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['Schiphol', 'ramp', 'klimaat', 'uitbreiding'], full_text: "Schiphol is een ramp voor het klimaat. We moeten ons samen uitspreken tegen de uitbreiding.", keyword_clusters: {'Schiphol': 5, 'ramp': 0, 'klimaat': 1, 'uitbreiding': 1}},
{sentiment: 0.9945829510688782, lat: null, lng: null, count: 1, topics: ['schiphol', 'klimaat', 'protest'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'lucht', 'toekomst', 'Schiphol', 'klimaat', 'protest'], full_text: "Stop de uitbreiding van Schiphol! We willen gezonde lucht en een leefbare toekomst voor ons allemaal. #Schiphol #klimaat #protest", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'lucht': 0, 'toekomst': 1, 'klimaat': 1, 'protest': 1}},
{sentiment: 0.9975239634513855, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'milieu', 'protest'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'koste', 'milieu', 'gezondheid', 'stemmen', 'Schiphol', 'milieu', 'protest'], full_text: "Geen uitbreiding van Schiphol ten koste van ons milieu en onze gezondheid! Laten we opstaan ​​en onze stemmen laten horen! #Schiphol #milieu #protest", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'koste': 5, 'milieu': 1, 'gezondheid': 1, 'stemmen': 5, 'protest': 1}},
{sentiment: 0.9975981116294861, lat: null, lng: null, count: 1, topics: ['schiphol', 'luchtvervuiling', 'protest'], group: 'piet', emojis: [], keywords: ['Schiphol', 'gezondheidsschade', 'vervuiling', 'uitbreiding', 'overlast', 'Schiphol', 'luchtvervuiling', 'protest'], full_text: "Schiphol brengt ons gezondheidsschade en vervuiling. We willen geen uitbreiding van deze overlast! #Schiphol #luchtvervuiling #protest", keyword_clusters: {'Schiphol': 5, 'gezondheidsschade': 0, 'vervuiling': 1, 'uitbreiding': 1, 'overlast': 1, 'luchtvervuiling': 1, 'protest': 1}},
{sentiment: 0.9977350234985352, lat: null, lng: null, count: 1, topics: ['schiphol', 'toekomst', 'protest'], group: 'piet', emojis: [], keywords: ['kinderen', 'toekomst', 'uitbreiding', 'Schiphol', 'Schiphol', 'toekomst', 'protest'], full_text: "Onze kinderen verdienen een gezonde en leefbare toekomst. Daarom zeggen we nee tegen de uitbreiding van Schiphol! #Schiphol #toekomst #protest", keyword_clusters: {'kinderen': 1, 'toekomst': 1, 'uitbreiding': 1, 'Schiphol': 5, 'protest': 1}},
{sentiment: 0.9968722462654114, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'gezondheid', 'protest'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'prioriteit', 'gezondheid', 'milieu', 'Schiphol', 'gezondheid', 'protest'], full_text: "Stop de uitbreiding van Schiphol en geef prioriteit aan onze gezondheid en ons milieu! #Schiphol #gezondheid #protest", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'prioriteit': 4, 'gezondheid': 1, 'milieu': 1, 'protest': 1}},
{sentiment: 0.9964712858200073, lat: null, lng: null, count: 1, topics: ['schiphol', 'actie', 'protest'], group: 'piet', emojis: [], keywords: ['tijd', 'actie', 'uitbreiding', 'Schiphol', 'gezondheid', 'milieu', '\u200b\u200bop', 'spel', 'Schiphol', 'actie', 'protest'], full_text: "Het is tijd om actie te ondernemen tegen de uitbreiding van Schiphol. Onze gezondheid en ons milieu staan ​​op het spel! #Schiphol #actie #protest", keyword_clusters: {'tijd': 3, 'actie': 1, 'uitbreiding': 1, 'Schiphol': 5, 'gezondheid': 1, 'milieu': 1, '\u200b\u200bop': 4, 'spel': 4, 'protest': 1}},
{sentiment: 0.9966909885406494, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'rechten', 'protest'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'rechten', 'leefomgeving', 'Schiphol', 'rechten', 'protest'], full_text: "We moeten ons verzetten tegen de uitbreiding van Schiphol en opkomen voor onze rechten op een gezonde leefomgeving! #Schiphol #rechten #protest", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'rechten': 1, 'leefomgeving': 1, 'protest': 1}},
{sentiment: 0.9978330731391907, lat: null, lng: null, count: 1, topics: ['schiphol', 'luchtvervuiling', 'protest'], group: 'piet', emojis: [], keywords: ['luchtvervuiling', 'geluidsoverlast', 'gevolg', 'uitbreiding', 'Schiphol', 'Schiphol', 'luchtvervuiling', 'protest'], full_text: "Het is onaanvaardbaar dat we worden blootgesteld aan luchtvervuiling en geluidsoverlast als gevolg van de uitbreiding van Schiphol. #Schiphol #luchtvervuiling #protest", keyword_clusters: {'luchtvervuiling': 1, 'geluidsoverlast': 1, 'gevolg': 7, 'uitbreiding': 1, 'Schiphol': 5, 'protest': 1}},
{sentiment: 0.9963401556015015, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'duurzaamheid', 'protest'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'oplossing', 'alternatieven', 'Schiphol', 'duurzaamheid', 'protest'], full_text: "De uitbreiding van Schiphol is geen oplossing. Laten we samenwerken aan duurzame alternatieven! #Schiphol #duurzaamheid #protest", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'oplossing': 0, 'alternatieven': 1, 'duurzaamheid': 1, 'protest': 1}},
{sentiment: 0.9958193302154541, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'gezondheid', 'protest'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'gezondheid', 'milieu', 'gevaar', 'rechten', 'Schiphol', 'gezondheid', 'protest'], full_text: "We kunnen niet langer toestaan ​​dat de uitbreiding van Schiphol onze gezondheid en ons milieu in gevaar brengt. We moeten opkomen voor onze rechten! #Schiphol #gezondheid #protest", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'gezondheid': 1, 'milieu': 1, 'gevaar': 7, 'rechten': 1, 'protest': 1}},
{sentiment: 0.9975340366363525, lat: null, lng: null, count: 1, topics: ['schiphol'], group: 'piet', emojis: [], keywords: ['sfeer', 'Schiphol', 'protest', 'Demonstranten', 'dag', 'actie', 'verzet', 'uitbreiding', 'luchthaven'], full_text: "De sfeer begint te stijgen op de #Schiphol protest. Demonstranten maken zich op voor een lange dag van actie en verzet tegen de uitbreiding van de luchthaven.", keyword_clusters: {'sfeer': 7, 'Schiphol': 5, 'protest': 1, 'Demonstranten': 5, 'dag': 5, 'actie': 1, 'verzet': 1, 'uitbreiding': 1, 'luchthaven': 3}},
{sentiment: 0.9976801872253418, lat: null, lng: null, count: 1, topics: ['schiphol'], group: 'piet', emojis: [], keywords: ['The', 'atmosphere', 'Schiphol', 'protest', 'Demonstrators', 'the', 'airport'], full_text: "The atmosphere is starting to heat up at the #Schiphol protest. Demonstrators are getting ready for a long day of action and resistance against the expansion of the airport.", keyword_clusters: {'The': 4, 'atmosphere': 4, 'Schiphol': 5, 'protest': 1, 'Demonstrators': 5, 'the': 5, 'airport': 4}},
{sentiment: 0.9973390698432922, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['demonstranten', 'ingang', 'Schiphol', 'spandoeken', 'borden', 'uitbreiding', 'luchthaven', 'Schipholprotest'], full_text: "Honderden demonstranten verzamelen zich bij de ingang van Schiphol, met spandoeken en borden die de uitbreiding van de luchthaven afkeuren. #Schipholprotest", keyword_clusters: {'demonstranten': 5, 'ingang': 5, 'Schiphol': 5, 'spandoeken': 0, 'borden': 5, 'uitbreiding': 1, 'luchthaven': 3, 'Schipholprotest': 0}},
{sentiment: 0.9979106783866882, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['Hundreds', 'demonstrators', 'gathering', 'Schiphol', 'banners', 'the', 'airport', 'Schipholprotest'], full_text: "Hundreds of demonstrators are gathering at the entrance of Schiphol, with banners and signs denouncing the expansion of the airport. #Schipholprotest", keyword_clusters: {'Hundreds': 5, 'demonstrators': 0, 'gathering': 5, 'Schiphol': 5, 'banners': 0, 'the': 5, 'airport': 4, 'Schipholprotest': 0}},
{sentiment: 0.9972250461578369, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['demonstranten', 'toegang', 'luchthaven', 'politie', 'menigte', 'controle', 'Schipholprotest'], full_text: "De demonstranten blokkeren nu de toegang tot de luchthaven. De politie probeert de menigte onder controle te houden. #Schipholprotest", keyword_clusters: {'demonstranten': 5, 'toegang': 7, 'luchthaven': 3, 'politie': 5, 'menigte': 7, 'controle': 0, 'Schipholprotest': 0}},
{sentiment: 0.9968249797821045, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['The', 'demonstrators', 'airport', 'Schipholprotest'], full_text: "The demonstrators are now blocking access to the airport. The police are trying to keep the crowd under control. #Schipholprotest", keyword_clusters: {'The': 4, 'demonstrators': 0, 'airport': 4, 'Schipholprotest': 0}},
{sentiment: 0.9976164102554321, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['aantal', 'demonstranten', 'vliegveld', 'luchtverkeer', 'luchthavenmedewerkers', 'paniek', 'Schipholprotest'], full_text: "Een aantal demonstranten is erin geslaagd om het vliegveld op te komen en verstoort nu het luchtverkeer. De luchthavenmedewerkers zijn in paniek. #Schipholprotest", keyword_clusters: {'aantal': 5, 'demonstranten': 5, 'vliegveld': 0, 'luchtverkeer': 0, 'luchthavenmedewerkers': 4, 'paniek': 4, 'Schipholprotest': 0}},
{sentiment: 0.9970012307167053, lat: 52.2952203, lng: 6.5772427, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['air', 'traffic', 'Airport', 'Schipholprotest'], full_text: "Some demonstrators have managed to enter the airport and are now disrupting air traffic. Airport staff are in a panic. #Schipholprotest", keyword_clusters: {'air': 7, 'traffic': 4, 'Airport': 4, 'Schipholprotest': 0}},
{sentiment: 0.9979994893074036, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['politie', 'demonstranten', 'waterkanonnen', 'traangas', 'situatie', 'Schipholprotest'], full_text: "De politie probeert de demonstranten te verdrijven met waterkanonnen en traangas. De situatie is gespannen. #Schipholprotest", keyword_clusters: {'politie': 5, 'demonstranten': 5, 'waterkanonnen': 7, 'traangas': 4, 'situatie': 7, 'Schipholprotest': 0}},
{sentiment: 0.9972265362739563, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['the', 'water', 'gas', 'The', 'Schipholprotest'], full_text: "The police are trying to disperse the demonstrators with water cannons and tear gas. The situation is tense. #Schipholprotest", keyword_clusters: {'the': 5, 'water': 7, 'gas': 0, 'The': 4, 'Schipholprotest': 0}},
{sentiment: 0.9974613189697266, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['verzet', 'uitbreiding', 'Schiphol', 'demonstranten', 'land', 'luchthaven', 'stem', 'Schipholprotest'], full_text: "Het verzet tegen de uitbreiding van Schiphol blijft groeien, met demonstranten uit het hele land die naar de luchthaven zijn gekomen om hun stem te laten horen. #Schipholprotest", keyword_clusters: {'verzet': 1, 'uitbreiding': 1, 'Schiphol': 5, 'demonstranten': 5, 'land': 7, 'luchthaven': 3, 'stem': 1, 'Schipholprotest': 0}},
{sentiment: 0.9977298378944397, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['Schiphol', 'Schipholprotest'], full_text: "The resistance against the expansion of Schiphol continues to grow, with demonstrators from across the country coming to the airport to make their voices heard. #Schipholprotest", keyword_clusters: {'Schiphol': 5, 'Schipholprotest': 0}},
{sentiment: 0.9951972365379333, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['demonstranten', 'ingang', 'Tweede', 'Kamer', 'regering', 'uitbreiding', 'Schiphol', 'Schipholprotest'], full_text: "De demonstranten hebben zich nu verplaatst naar de ingang van de Tweede Kamer. Ze eisen dat de regering de uitbreiding van Schiphol stopzet. #Schipholprotest", keyword_clusters: {'demonstranten': 5, 'ingang': 5, 'Tweede': 7, 'Kamer': 5, 'regering': 1, 'uitbreiding': 1, 'Schiphol': 5, 'Schipholprotest': 0}},
{sentiment: 0.9975316524505615, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['The', 'demonstrators', 'Dutch', 'Parliament', 'They', 'Schiphol', 'Schipholprotest'], full_text: "The demonstrators have now moved to the entrance of the Dutch Parliament. They demand that the government stop the expansion of Schiphol. #Schipholprotest", keyword_clusters: {'The': 4, 'demonstrators': 0, 'Dutch': 7, 'Parliament': 5, 'They': 4, 'Schiphol': 5, 'Schipholprotest': 0}},
{sentiment: 0.9979702830314636, lat: null, lng: null, count: 1, topics: ['schiphol'], group: 'piet', emojis: [], keywords: ['groep', 'klimaatactivisten', 'Schiphol', 'protest', 'bezorgdheid', 'klimaatimpact', 'luchtvaartindustrie'], full_text: "Een groep klimaatactivisten heeft zich aangesloten bij de #Schiphol protest om hun bezorgdheid te uiten over de klimaatimpact van de luchtvaartindustrie.", keyword_clusters: {'groep': 5, 'klimaatactivisten': 0, 'Schiphol': 5, 'protest': 1, 'bezorgdheid': 4, 'klimaatimpact': 5, 'luchtvaartindustrie': 1}},
{sentiment: 0.9979780316352844, lat: null, lng: null, count: 1, topics: ['schiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'protest', 'concern'], full_text: "A group of climate activists has joined the #Schiphol protest to express their concern about the climate impact of the aviation industry.", keyword_clusters: {'Schiphol': 5, 'protest': 1, 'concern': 0}},
{sentiment: 0.9971411228179932, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['politie', 'luchthaven', 'demonstranten', 'protest', 'Schipholprotest'], full_text: "De politie heeft nu de luchthaven volledig afgesloten en arresteert demonstranten die weigeren te vertrekken. Het protest escaleert snel. #Schipholprotest", keyword_clusters: {'politie': 5, 'luchthaven': 3, 'demonstranten': 5, 'protest': 1, 'Schipholprotest': 0}},
{sentiment: 0.9940586090087891, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['arresting', 'demonstrators', 'The', 'protest', 'quickly', 'Schipholprotest'], full_text: "The police have now completely closed off the airport and are arresting demonstrators who refuse to leave. The protest is escalating quickly. #Schipholprotest", keyword_clusters: {'arresting': 0, 'demonstrators': 0, 'The': 4, 'protest': 1, 'quickly': 0, 'Schipholprotest': 0}},
{sentiment: 0.9977896213531494, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['Demonstranten', 'eieren', 'tomaten', 'politie', 'schipholprotest'], full_text: "Demonstranten gooien met eieren en tomaten naar de politie #schipholprotest", keyword_clusters: {'Demonstranten': 5, 'eieren': 0, 'tomaten': 0, 'politie': 5, 'schipholprotest': 0}},
{sentiment: 0.9976237416267395, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['vechtpartijen', 'tegenstanders', 'Schiphol', 'schipholprotest'], full_text: "Er zijn vechtpartijen uitgebroken tussen voor- en tegenstanders van Schiphol #schipholprotest", keyword_clusters: {'vechtpartijen': 4, 'tegenstanders': 7, 'Schiphol': 5, 'schipholprotest': 0}},
{sentiment: 0.9959902167320251, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['politie', 'waterkanonnen', 'menigte', 'schipholprotest'], full_text: "De politie zet waterkanonnen in om de menigte uit elkaar te drijven #schipholprotest", keyword_clusters: {'politie': 5, 'waterkanonnen': 7, 'menigte': 7, 'schipholprotest': 0}},
{sentiment: 0.9981166124343872, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['sfeer', 'schipholprotest', 'mensen'], full_text: "De sfeer bij het #schipholprotest is grimmig, veel mensen zijn boos en gefrustreerd", keyword_clusters: {'sfeer': 7, 'schipholprotest': 0, 'mensen': 0}},
{sentiment: 0.9974353909492493, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['barricades', 'demonstranten', 'politie', 'schipholprotest'], full_text: "Er zijn barricades opgeworpen door de demonstranten, de politie probeert deze te verwijderen #schipholprotest", keyword_clusters: {'barricades': 0, 'demonstranten': 5, 'politie': 5, 'schipholprotest': 0}},
{sentiment: 0.9979487061500549, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['politie', 'arrestaties', 'schipholprotest'], full_text: "De politie heeft meerdere arrestaties verricht bij het #schipholprotest", keyword_clusters: {'politie': 5, 'arrestaties': 5, 'schipholprotest': 0}},
{sentiment: 0.997797966003418, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['Demonstranten', 'vuurwerk', 'politie', 'situatie', 'controle', 'schipholprotest'], full_text: "Demonstranten hebben vuurwerk afgestoken, de politie probeert de situatie onder controle te krijgen #schipholprotest", keyword_clusters: {'Demonstranten': 5, 'vuurwerk': 5, 'politie': 5, 'situatie': 7, 'controle': 0, 'schipholprotest': 0}},
{sentiment: 0.9978589415550232, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['groep', 'demonstranten', 'Schiphol', 'terrein', 'politie', 'schipholprotest'], full_text: "Een groep demonstranten heeft geprobeerd het Schiphol terrein op te komen, dit is door de politie voorkomen #schipholprotest", keyword_clusters: {'groep': 5, 'demonstranten': 5, 'Schiphol': 5, 'terrein': 7, 'politie': 5, 'schipholprotest': 0}},
{sentiment: 0.9983024597167969, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['verkeerschaos', 'Schiphol', 'protesten', 'schipholprotest'], full_text: "De verkeerschaos rondom Schiphol neemt toe door de protesten #schipholprotest", keyword_clusters: {'verkeerschaos': 7, 'Schiphol': 5, 'protesten': 1, 'schipholprotest': 0}},
{sentiment: 0.9970857501029968, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['luchtvaartmaatschappijen', 'last', 'protesten', 'vluchten', 'schipholprotest'], full_text: "De luchtvaartmaatschappijen hebben veel last van de protesten, veel vluchten zijn vertraagd of geannuleerd #schipholprotest", keyword_clusters: {'luchtvaartmaatschappijen': 5, 'last': 5, 'protesten': 1, 'vluchten': 0, 'schipholprotest': 0}},
{sentiment: 0.9984729886054993, lat: null, lng: null, count: 1, topics: ['schiphol'], group: 'piet', emojis: [], keywords: ['opkomst', 'Schiphol', 'stem', 'duurzamer', 'Nederland'], full_text: "Wat een geweldige opkomst vandaag bij de #Schiphol protestmars! Bedankt aan iedereen die zijn stem heeft laten horen voor een duurzamer Nederland.", keyword_clusters: {'opkomst': 7, 'Schiphol': 5, 'stem': 1, 'duurzamer': 4, 'Nederland': 5}},
{sentiment: 0.9979183077812195, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol'], group: 'piet', emojis: [], keywords: ['dag', 'uitbreiding', 'Schiphol', 'gemeenschap', 'toekomst', 'stopSchiphol'], full_text: "Na een lange dag van protesteren tegen de uitbreiding van Schiphol ben ik trots op onze gemeenschap en blijf ik hoopvol voor een betere toekomst. #stopSchiphol", keyword_clusters: {'dag': 5, 'uitbreiding': 1, 'Schiphol': 5, 'gemeenschap': 1, 'toekomst': 1, 'stopSchiphol': 4}},
{sentiment: 0.9983782768249512, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['herinnering', 'burgers', 'staat', 'verandering', 'Schipholprotest'], full_text: "Vandaag was een krachtige herinnering dat burgers nog steeds in staat zijn om samen te komen en verandering te eisen. #Schipholprotest", keyword_clusters: {'herinnering': 0, 'burgers': 1, 'staat': 5, 'verandering': 1, 'Schipholprotest': 0}},
{sentiment: 0.9975220561027527, lat: null, lng: null, count: 1, topics: ['schipholprotestmars'], group: 'piet', emojis: [], keywords: ['Schipholprotestmars', 'vastberadenheid', 'energie', 'planeet', 'toekomst'], full_text: "Ik hoop dat degenen die vandaag deelnamen aan de #Schipholprotestmars de vastberadenheid en energie voelen om op te komen voor onze planeet en onze toekomst.", keyword_clusters: {'Schipholprotestmars': 5, 'vastberadenheid': 7, 'energie': 4, 'planeet': 1, 'toekomst': 1}},
{sentiment: 0.9958447813987732, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['stopschiphol'], group: 'piet', emojis: [], keywords: ['strijd', 'uitbreiding', 'Schiphol', 'duurzamer', 'Nederland', 'stopSchiphol'], full_text: "De strijd tegen de uitbreiding van Schiphol is nog lang niet voorbij, maar vandaag hebben we laten zien dat we vastbesloten zijn om te vechten voor een duurzamer Nederland. #stopSchiphol", keyword_clusters: {'strijd': 7, 'uitbreiding': 1, 'Schiphol': 5, 'duurzamer': 4, 'Nederland': 5, 'stopSchiphol': 4}},
{sentiment: 0.9982292056083679, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['mensen', 'doel', 'uitbreiding', 'Schiphol', 'Schipholprotest'], full_text: "Het was inspirerend om zoveel verschillende mensen samen te zien komen voor één doel: het stoppen van de uitbreiding van Schiphol. #Schipholprotest", keyword_clusters: {'mensen': 0, 'doel': 5, 'uitbreiding': 1, 'Schiphol': 5, 'Schipholprotest': 0}},
{sentiment: 0.9956099390983582, lat: null, lng: null, count: 1, topics: ['stopschiphol'], group: 'piet', emojis: [], keywords: ['moed', 'vastberadenheid', 'gevechten', 'wereld', 'stopSchiphol'], full_text: "We moeten de moed en vastberadenheid die we vandaag zagen, meenemen in de toekomstige gevechten voor een duurzamere wereld. #stopSchiphol", keyword_clusters: {'moed': 7, 'vastberadenheid': 7, 'gevechten': 5, 'wereld': 7, 'stopSchiphol': 4}},
{sentiment: 0.9981186985969543, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['signaal', 'leiders', 'vernietiging', 'planeet', 'Schipholprotest'], full_text: "Vandaag was een krachtig signaal aan onze leiders dat we niet langer stilzwijgend de vernietiging van onze planeet accepteren. #Schipholprotest", keyword_clusters: {'signaal': 4, 'leiders': 0, 'vernietiging': 4, 'planeet': 1, 'Schipholprotest': 0}},
{sentiment: 0.9984291195869446, lat: null, lng: null, count: 1, topics: ['schipholprotestmars', 'stopschiphol'], group: 'piet', emojis: [], keywords: ['gemeenschap', 'Schipholprotestmars', 'voorbeeld', 'verandering', 'wereld', 'stopSchiphol'], full_text: "De gemeenschap van vandaag bij de #Schipholprotestmars was een voorbeeld van de verandering die we willen zien in de wereld. #stopSchiphol", keyword_clusters: {'gemeenschap': 1, 'Schipholprotestmars': 5, 'voorbeeld': 4, 'verandering': 1, 'wereld': 7, 'stopSchiphol': 4}},
{sentiment: 0.9976892471313477, lat: null, lng: null, count: 1, topics: ['schipholprotest'], group: 'piet', emojis: [], keywords: ['stem', 'toekomst', 'einde', 'uitbreiding', 'Schiphol', 'Schipholprotest'], full_text: "Vandaag hebben we onze stem laten horen, maar we moeten blijven strijden voor een duurzamere toekomst en een einde aan de uitbreiding van Schiphol. #Schipholprotest", keyword_clusters: {'stem': 1, 'toekomst': 1, 'einde': 0, 'uitbreiding': 1, 'Schiphol': 5, 'Schipholprotest': 0}},
{sentiment: 0.9982441663742065, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['mensen', 'uitbreiding', 'Schiphol', 'mensen', 'zorgen', 'milieu'], full_text: "Het was indrukwekkend om te zien hoeveel mensen bij elkaar kwamen om te protesteren tegen de uitbreiding van Schiphol. Het geeft aan hoeveel mensen zich zorgen maken over het milieu.", keyword_clusters: {'mensen': 0, 'uitbreiding': 1, 'Schiphol': 5, 'zorgen': 1, 'milieu': 1}},
{sentiment: 0.99797123670578, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['protesten', 'emotie', 'Mensen', 'impact', 'luchthaven', 'leefomgeving'], full_text: "De protesten waren vreedzaam, maar er was wel veel emotie te zien. Mensen zijn duidelijk bezorgd over de impact van de luchthaven op hun leefomgeving.", keyword_clusters: {'protesten': 1, 'emotie': 0, 'Mensen': 0, 'impact': 5, 'luchthaven': 3, 'leefomgeving': 1}},
{sentiment: 0.9980666041374207, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['groepen', 'protesten', 'jong', 'achtergronden', 'draagvlak', 'kwestie'], full_text: "Het is opvallend dat er zoveel verschillende groepen aanwezig waren bij de protesten. Van jong tot oud en van allerlei achtergronden. Het geeft aan hoe breed het draagvlak is voor deze kwestie.", keyword_clusters: {'groepen': 5, 'protesten': 1, 'jong': 0, 'achtergronden': 0, 'draagvlak': 7, 'kwestie': 0}},
{sentiment: 0.9976469874382019, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['protesten', 'spandoeken', 'borden', 'boodschappen', 'uitbreiding', 'Schiphol', 'gezondheid', 'winst', 'mensen'], full_text: "Tijdens de protesten waren er veel spandoeken en borden te zien met boodschappen als \"Stop de uitbreiding van Schiphol\" en \"Onze gezondheid gaat voor winst\". Het laat zien dat mensen zich gehoord willen voelen.", keyword_clusters: {'protesten': 1, 'spandoeken': 0, 'borden': 5, 'boodschappen': 4, 'uitbreiding': 1, 'Schiphol': 5, 'gezondheid': 1, 'winst': 4, 'mensen': 0}},
{sentiment: 0.9983678460121155, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['aanwezigheid', 'politie', 'beveiliging', 'incidenten', 'protesten'], full_text: "De aanwezigheid van politie en beveiliging was duidelijk merkbaar, maar er waren geen incidenten tijdens de protesten. Dat is goed om te zien.", keyword_clusters: {'aanwezigheid': 7, 'politie': 5, 'beveiliging': 7, 'incidenten': 7, 'protesten': 1}},
{sentiment: 0.9976142644882202, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['protesten', 'signaal', 'vraag', 'politiek'], full_text: "Het is duidelijk dat de protesten een politiek signaal afgeven. De vraag is nu hoe de politiek hierop gaat reageren.", keyword_clusters: {'protesten': 1, 'signaal': 4, 'vraag': 0, 'politiek': 1}},
{sentiment: 0.9980995059013367, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['mensen', 'stem', 'protesten', 'kwestie', 'mensen'], full_text: "Het was indrukwekkend om te zien hoeveel mensen er bereid waren om hun stem te laten horen tijdens de protesten. Het toont aan hoe belangrijk deze kwestie is voor veel mensen.", keyword_clusters: {'mensen': 0, 'stem': 1, 'protesten': 1, 'kwestie': 0}},
{sentiment: 0.9982947707176208, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['protesten', 'sprekers', 'publiek', 'protesten', 'dimensie', 'saamhorigheid'], full_text: "Tijdens de protesten waren er ook veel sprekers aanwezig die het publiek toespraken. Het gaf de protesten een extra dimensie en zorgde voor meer saamhorigheid.", keyword_clusters: {'protesten': 1, 'sprekers': 5, 'publiek': 7, 'dimensie': 0, 'saamhorigheid': 0}},
{sentiment: 0.9981796741485596, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['protesten', 'impact', 'omgeving', 'politiek', 'opinie'], full_text: "Het is duidelijk dat de protesten veel impact hebben gehad. Niet alleen op de directe omgeving, maar ook op de politiek en de publieke opinie.", keyword_clusters: {'protesten': 1, 'impact': 5, 'omgeving': 0, 'politiek': 1, 'opinie': 7}},
{sentiment: 0.99769127368927, lat: null, lng: null, count: 1, topics: [], group: 'piet', emojis: [], keywords: ['protesten', 'draagvlak', 'strijd', 'klimaatverandering', 'mensen', 'straat', 'toekomst'], full_text: "De protesten hebben laten zien dat er veel draagvlak is voor de strijd tegen klimaatverandering en dat mensen bereid zijn om daarvoor de straat op te gaan. Dat is hoopgevend voor de toekomst.", keyword_clusters: {'protesten': 1, 'draagvlak': 7, 'strijd': 7, 'klimaatverandering': 1, 'mensen': 0, 'straat': 5, 'toekomst': 1}},
{sentiment: 0.9969612956047058, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd'], group: 'piet', emojis: [], keywords: ['acties', 'uitbreiding', 'Schiphol', 'economie', 'VVD', 'groei', 'luchthaven', 'toekomst', 'land', 'Schiphol', 'VVD'], full_text: "De acties tegen de uitbreiding van Schiphol zijn ongegrond en schaden onze economie. De VVD steunt de groei van onze luchthaven en zal blijven werken aan een duurzame toekomst voor ons land. #Schiphol #VVD", keyword_clusters: {'acties': 4, 'uitbreiding': 1, 'Schiphol': 5, 'economie': 1, 'VVD': 5, 'groei': 4, 'luchthaven': 3, 'toekomst': 1, 'land': 7}},
{sentiment: 0.9972809553146362, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd'], group: 'piet', emojis: [], keywords: ['balans', 'groei', 'duurzaamheid', 'uitbreiding', 'Schiphol', 'welvaart', 'banen', 'VVD', 'Nederland', 'Schiphol', 'VVD'], full_text: "Het is belangrijk dat we een balans vinden tussen economische groei en duurzaamheid. De uitbreiding van Schiphol draagt bij aan onze welvaart en creëert banen. De VVD zal blijven werken aan een leefbaar en duurzaam Nederland. #Schiphol #VVD", keyword_clusters: {'balans': 0, 'groei': 4, 'duurzaamheid': 1, 'uitbreiding': 1, 'Schiphol': 5, 'welvaart': 5, 'banen': 0, 'VVD': 5, 'Nederland': 5}},
{sentiment: 0.9979623556137085, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd'], group: 'piet', emojis: [], keywords: ['protesten', 'Schiphol', 'luchthaven', 'motor', 'land', 'VVD', 'groei', 'luchthaven', 'Schiphol', 'VVD'], full_text: "De protesten tegen Schiphol zijn begrijpelijk, maar we moeten niet vergeten dat de luchthaven een belangrijke economische motor is voor ons land. De VVD zal zich blijven inzetten voor duurzame groei van onze luchthaven. #Schiphol #VVD", keyword_clusters: {'protesten': 1, 'Schiphol': 5, 'luchthaven': 3, 'motor': 0, 'land': 7, 'VVD': 5, 'groei': 4}},
{sentiment: 0.9976917505264282, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'stap', 'economie', 'VVD', 'toekomst', 'land', 'Schiphol', 'VVD'], full_text: "De uitbreiding van Schiphol is een belangrijke stap voorwaarts voor onze economie. De VVD begrijpt dat niet iedereen het hiermee eens is, maar we moeten ons ook richten op de toekomst van ons land. #Schiphol #VVD", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'stap': 0, 'economie': 1, 'VVD': 5, 'toekomst': 1, 'land': 7}},
{sentiment: 0.997980535030365, lat: null, lng: null, count: 1, topics: ['schiphol', 'vvd'], group: 'piet', emojis: [], keywords: ['VVD', 'voorstander', 'groei', 'Schiphol', 'protesten', 'luchthaven', 'voordelen', 'land', 'Schiphol', 'VVD'], full_text: "De VVD is voorstander van duurzame groei van Schiphol. De protesten van vandaag zijn begrijpelijk, maar we moeten niet vergeten dat de luchthaven ook veel voordelen heeft voor ons land. #Schiphol #VVD", keyword_clusters: {'VVD': 5, 'voorstander': 0, 'groei': 4, 'Schiphol': 5, 'protesten': 1, 'luchthaven': 3, 'voordelen': 1, 'land': 7}},
{sentiment: 0.9972620010375977, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'economie', 'land', 'banen', 'VVD', 'proces', 'toekomst', 'Schiphol', 'VVD'], full_text: "De uitbreiding van Schiphol is nodig om de economie van ons land te laten groeien en banen te creëren. De VVD steunt dit proces en zal blijven werken aan een duurzame toekomst voor ons allemaal. #Schiphol #VVD", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'economie': 1, 'land': 7, 'banen': 0, 'VVD': 5, 'proces': 4, 'toekomst': 1}},
{sentiment: 0.9980893731117249, lat: 51.5794077, lng: 5.7135574, count: 1, topics: ['schiphol', 'vvd'], group: 'piet', emojis: [], keywords: ['protesten', 'Schiphol', 'luchthaven', 'rol', 'handel', 'toerisme', 'VVD', 'groei', 'Schiphol', 'Schiphol', 'VVD'], full_text: "De protesten tegen Schiphol zijn begrijpelijk, maar we moeten niet vergeten dat de luchthaven ook een belangrijke rol speelt in onze internationale handel en het toerisme. De VVD zal blijven werken aan duurzame groei van Schiphol. #Schiphol #VVD", keyword_clusters: {'protesten': 1, 'Schiphol': 5, 'luchthaven': 3, 'rol': 5, 'handel': 2, 'toerisme': 7, 'VVD': 5, 'groei': 4}},
{sentiment: 0.9978694915771484, lat: null, lng: null, count: 1, topics: ['schiphol', 'vvd'], group: 'piet', emojis: [], keywords: ['VVD', 'uitbreiding', 'Schiphol', 'oog', 'voordelen', 'economie', 'toekomst', 'land', 'Schiphol', 'VVD'], full_text: "De VVD begrijpt dat er zorgen zijn over de uitbreiding van Schiphol, maar we moeten ook oog hebben voor de voordelen voor onze economie. We zullen blijven werken aan een duurzame toekomst voor ons land. #Schiphol #VVD", keyword_clusters: {'VVD': 5, 'uitbreiding': 1, 'Schiphol': 5, 'oog': 5, 'voordelen': 1, 'economie': 1, 'toekomst': 1, 'land': 7}},
{sentiment: 0.9978193044662476, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['schiphol', 'vvd'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'economie', 'banen', 'VVD', 'proces', 'toekomst', 'Schiphol', 'VVD'], full_text: "De uitbreiding van Schiphol is goed voor onze economie en zorgt voor banen. De VVD steunt dit proces en zal blijven werken aan een duurzame toekomst voor ons allemaal. #Schiphol #VVD", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'economie': 1, 'banen': 0, 'VVD': 5, 'proces': 4, 'toekomst': 1}},
{sentiment: 0.9978792667388916, lat: null, lng: null, count: 1, topics: ['schiphol', 'vvd'], group: 'piet', emojis: [], keywords: ['VVD', 'zorgen', 'burgers', 'uitbreiding', 'Schiphol', 'voordelen', 'luchthaven', 'groei', 'Schiphol', 'Schiphol', 'VVD'], full_text: "De VVD begrijpt de zorgen van burgers over de uitbreiding van Schiphol, maar we moeten ons ook richten op de economische voordelen van de luchthaven. We zullen blijven werken aan duurzame groei van Schiphol. #Schiphol #VVD", keyword_clusters: {'VVD': 5, 'zorgen': 1, 'burgers': 1, 'uitbreiding': 1, 'Schiphol': 5, 'voordelen': 1, 'luchthaven': 3, 'groei': 4}},
{sentiment: 0.9969509840011597, lat: null, lng: null, count: 1, topics: ['klimaatprotest', 'schiphol', 'groenlinks'], group: 'piet', emojis: [], keywords: ['actievoerders', 'signaal', 'klimaatcrisis', 'Schiphol', 'GroenLinks', 'klimaatprotest', 'Schiphol'], full_text: "De actievoerders laten een duidelijk signaal horen: we moeten de klimaatcrisis serieus nemen en stoppen met het eindeloos uitbreiden van Schiphol. GroenLinks staat achter jullie! #klimaatprotest #Schiphol #groenlinks", keyword_clusters: {'actievoerders': 7, 'signaal': 4, 'klimaatcrisis': 4, 'Schiphol': 5, 'GroenLinks': 0, 'klimaatprotest': 5}},
{sentiment: 0.9981502294540405, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['groenlinks', 'schiphol', 'klimaatprotest'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'klimaatprobleem', 'probleem', 'leefbaarheid', 'omgeving', 'tijd', 'bewoners', 'natuur', 'Schiphol', 'klimaatprotest'], full_text: "De uitbreiding van Schiphol is niet alleen een klimaatprobleem, maar ook een probleem voor de leefbaarheid van de omgeving. Het wordt tijd dat we luisteren naar de lokale bewoners en de natuur. #groenlinks #Schiphol #klimaatprotest", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'klimaatprobleem': 5, 'probleem': 4, 'leefbaarheid': 1, 'omgeving': 0, 'tijd': 3, 'bewoners': 4, 'natuur': 1, 'klimaatprotest': 5}},
{sentiment: 0.9973727464675903, lat: null, lng: null, count: 1, topics: ['schiphol', 'klimaatprotest', 'groenlinks'], group: 'piet', emojis: [], keywords: ['tijd', 'groeidenken', 'nadruk', 'duurzaamheid', 'leefbaarheid', 'Schiphol', 'klimaatprotest'], full_text: "Het is tijd dat we afstappen van het groeidenken en de nadruk leggen op duurzaamheid en leefbaarheid. #Schiphol #klimaatprotest #groenlinks", keyword_clusters: {'tijd': 3, 'groeidenken': 5, 'nadruk': 5, 'duurzaamheid': 1, 'leefbaarheid': 1, 'Schiphol': 5, 'klimaatprotest': 5}},
{sentiment: 0.9981581568717957, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['groenlinks', 'klimaatprotest', 'schiphol'], group: 'piet', emojis: [], keywords: ['protestactie', 'Schiphol', 'mensen', 'zorgen', 'toekomst', 'planeet', 'tijd', 'toekomst', 'klimaatprotest', 'Schiphol'], full_text: "De protestactie bij Schiphol laat zien dat mensen zich zorgen maken over de toekomst van onze planeet. Het is tijd voor een groene en duurzame toekomst! #groenlinks #klimaatprotest #Schiphol", keyword_clusters: {'protestactie': 5, 'Schiphol': 5, 'mensen': 0, 'zorgen': 1, 'toekomst': 1, 'planeet': 1, 'tijd': 3, 'klimaatprotest': 5}},
{sentiment: 0.9973982572555542, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['groenlinks', 'klimaatprotest', 'schiphol'], group: 'piet', emojis: [], keywords: ['luchtvaartsector', 'bijdrage', 'aanpak', 'klimaatcrisis', 'Schiphol', 'klimaatprotest', 'Schiphol'], full_text: "De luchtvaartsector moet een bijdrage leveren aan de aanpak van de klimaatcrisis. Het eindeloos uitbreiden van Schiphol past daar niet bij. #groenlinks #klimaatprotest #Schiphol", keyword_clusters: {'luchtvaartsector': 0, 'bijdrage': 0, 'aanpak': 7, 'klimaatcrisis': 4, 'Schiphol': 5, 'klimaatprotest': 5}},
{sentiment: 0.9974109530448914, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['groenlinks', 'klimaatprotest', 'schiphol'], group: 'piet', emojis: [], keywords: ['actievoerders', 'tijd', 'koerswijziging', 'uitbreiding', 'Schiphol', 'planeet', 'klimaatprotest', 'Schiphol'], full_text: "De actievoerders laten zien dat het tijd is voor een koerswijziging. De uitbreiding van Schiphol is niet langer acceptabel als we onze planeet willen redden. #groenlinks #klimaatprotest #Schiphol", keyword_clusters: {'actievoerders': 7, 'tijd': 3, 'koerswijziging': 4, 'uitbreiding': 1, 'Schiphol': 5, 'planeet': 1, 'klimaatprotest': 5}},
{sentiment: 0.9977184534072876, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['groenlinks', 'klimaatprotest', 'schiphol'], group: 'piet', emojis: [], keywords: ['protestactie', 'Schiphol', 'teken', 'wand', 'weg', 'duurzaamheid', 'leefbaarheid', 'klimaatprotest', 'Schiphol'], full_text: "De protestactie bij Schiphol is een teken aan de wand: we kunnen niet doorgaan op de huidige weg. We moeten inzetten op duurzaamheid en leefbaarheid. #groenlinks #klimaatprotest #Schiphol", keyword_clusters: {'protestactie': 5, 'Schiphol': 5, 'teken': 4, 'wand': 7, 'weg': 0, 'duurzaamheid': 1, 'leefbaarheid': 1, 'klimaatprotest': 5}},
{sentiment: 0.9954577684402466, lat: null, lng: null, count: 1, topics: ['groenlinks', 'klimaatprotest', 'schiphol'], group: 'piet', emojis: [], keywords: ['tijd', 'planeet', 'natuur', 'leefomgeving', 'Schiphol', 'klimaatprotest', 'Schiphol'], full_text: "Het is tijd dat we ons realiseren dat we maar één planeet hebben. Laten we zuinig zijn op de natuur en de leefomgeving, en stoppen met het uitbreiden van Schiphol. #groenlinks #klimaatprotest #Schiphol", keyword_clusters: {'tijd': 3, 'planeet': 1, 'natuur': 1, 'leefomgeving': 1, 'Schiphol': 5, 'klimaatprotest': 5}},
{sentiment: 0.9976978898048401, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['groenlinks', 'klimaatprotest', 'schiphol'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'klimaatprobleem', 'geluidsprobleem', 'luchtvervuilingsprobleem', 'leefbaarheidsprobleem', 'klimaatprotest', 'Schiphol'], full_text: "De uitbreiding van Schiphol is een klimaatprobleem, een geluidsprobleem, een luchtvervuilingsprobleem, en een leefbaarheidsprobleem. We moeten ons afvragen of we dit willen. #groenlinks #klimaatprotest #Schiphol", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'klimaatprobleem': 5, 'geluidsprobleem': 4, 'luchtvervuilingsprobleem': 5, 'leefbaarheidsprobleem': 7, 'klimaatprotest': 5}},
{sentiment: 0.9963420033454895, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['groenlinks', 'klimaatprotest', 'schiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'planeet', 'tijd', 'nadruk', 'duurzaamheid', 'leefbaarheid', 'klimaatprotest', 'Schiphol'], full_text: "We kunnen niet doorgaan met het eindeloos uitbreiden van Schiphol als we onze planeet willen redden. Het is tijd dat we de nadruk leggen op duurzaamheid en leefbaarheid. #groenlinks #klimaatprotest #Schiphol", keyword_clusters: {'Schiphol': 5, 'planeet': 1, 'tijd': 3, 'nadruk': 5, 'duurzaamheid': 1, 'leefbaarheid': 1, 'klimaatprotest': 5}},
{sentiment: 0.9974519610404968, lat: null, lng: null, count: 1, topics: ['climatejustice', 'extinctionrebellion'], group: 'piet', emojis: [], keywords: ['recht', 'democratie', 'boodschap', 'Extinction', 'Rebellion', 'toekomst'], full_text: "Geweldloos protesteren is een recht in een democratie, laten we de boodschap van Extinction Rebellion serieus nemen en werken aan een duurzame toekomst voor iedereen! #ClimateJustice #ExtinctionRebellion", keyword_clusters: {'recht': 0, 'democratie': 5, 'boodschap': 5, 'Extinction': 5, 'Rebellion': 5, 'toekomst': 1}},
{sentiment: 0.9978047013282776, lat: null, lng: null, count: 1, topics: ['actnow', 'xrnl'], group: 'piet', emojis: [], keywords: ['acties', 'Extinction', 'Rebellion', 'verandering', 'klimaat', 'planeet', 'ActNow', 'XRNL'], full_text: "De acties van Extinction Rebellion laten zien dat we niet langer kunnen wachten op verandering, we moeten nu handelen voor het klimaat en onze planeet! #ActNow #XRNL", keyword_clusters: {'acties': 4, 'Extinction': 5, 'Rebellion': 5, 'verandering': 1, 'klimaat': 1, 'planeet': 1, 'ActNow': 0, 'XRNL': 0}},
{sentiment: 0.9978644251823425, lat: null, lng: null, count: 1, topics: ['climateemergency', 'xrnl'], group: 'piet', emojis: [], keywords: ['methoden', 'Extinction', 'Rebellion', 'actie', 'klimaatcrisis', 'ClimateEmergency', 'XRNL'], full_text: "Hoewel we het misschien niet eens zijn met de methoden van Extinction Rebellion, kunnen we niet ontkennen dat er dringende actie nodig is om de klimaatcrisis aan te pakken. #ClimateEmergency #XRNL", keyword_clusters: {'methoden': 4, 'Extinction': 5, 'Rebellion': 5, 'actie': 1, 'klimaatcrisis': 4, 'ClimateEmergency': 7, 'XRNL': 0}},
{sentiment: 0.9980927109718323, lat: null, lng: null, count: 1, topics: ['togetherfortheplanet', 'xrnl'], group: 'piet', emojis: [], keywords: ['tijd', 'klimaatcrisis', 'oproepen', 'Extinction', 'Rebellion', 'toekomst', 'generaties', 'XRNL'], full_text: "Het is tijd om de klimaatcrisis serieus te nemen en te luisteren naar de oproepen van Extinction Rebellion. We moeten samenwerken om een duurzame toekomst te creëren voor onszelf en toekomstige generaties! #TogetherForThePlanet #XRNL", keyword_clusters: {'tijd': 3, 'klimaatcrisis': 4, 'oproepen': 0, 'Extinction': 5, 'Rebellion': 5, 'toekomst': 1, 'generaties': 7, 'XRNL': 0}},
{sentiment: 0.9977206587791443, lat: null, lng: null, count: 1, topics: ['climateaction', 'xrnl'], group: 'piet', emojis: [], keywords: ['acties', 'Extinction', 'Rebellion', 'business', 'as', 'klimaat', 'ClimateAction', 'XRNL'], full_text: "De acties van Extinction Rebellion herinneren ons eraan dat we niet kunnen doorgaan met business as usual, we moeten nu handelen voor het klimaat! #ClimateAction #XRNL", keyword_clusters: {'acties': 4, 'Extinction': 5, 'Rebellion': 5, 'business': 0, 'as': 0, 'klimaat': 1, 'ClimateAction': 5, 'XRNL': 0}},
{sentiment: 0.9980164766311646, lat: null, lng: null, count: 1, topics: ['nonviolentprotest', 'xrnl'], group: 'piet', emojis: [], keywords: ['recht', 'acties', 'communicatie', 'verandering', 'XRNL'], full_text: "Hoewel we het recht hebben om te protesteren, moeten we altijd streven naar geweldloze acties en open communicatie om verandering teweeg te brengen. #NonViolentProtest #XRNL", keyword_clusters: {'recht': 0, 'acties': 4, 'communicatie': 5, 'verandering': 1, 'XRNL': 0}},
{sentiment: 0.9980971217155457, lat: null, lng: null, count: 1, topics: ['sustainabilityforall', 'xrnl'], group: 'piet', emojis: [], keywords: ['protesten', 'Extinction', 'Rebellion', 'signaal', 'klimaat', 'oplossingen', 'SustainabilityForAll', 'XRNL'], full_text: "De protesten van Extinction Rebellion zijn een belangrijk signaal dat we moeten handelen voor het klimaat, maar we moeten altijd blijven zoeken naar oplossingen die voor iedereen werken. #SustainabilityForAll #XRNL", keyword_clusters: {'protesten': 1, 'Extinction': 5, 'Rebellion': 5, 'signaal': 4, 'klimaat': 1, 'oplossingen': 1, 'SustainabilityForAll': 7, 'XRNL': 0}},
{sentiment: 0.9979738593101501, lat: null, lng: null, count: 1, topics: ['globalclimateaction', 'xrnl'], group: 'piet', emojis: [], keywords: ['klimaatcrisis', 'uitdaging', 'reactie', 'verandering', 'toekomst', 'GlobalClimateAction', 'XRNL'], full_text: "De klimaatcrisis is een mondiale uitdaging en vereist een gecoördineerde internationale reactie. We moeten samenwerken om verandering teweeg te brengen en een duurzame toekomst te creëren. #GlobalClimateAction #XRNL", keyword_clusters: {'klimaatcrisis': 4, 'uitdaging': 7, 'reactie': 0, 'verandering': 1, 'toekomst': 1, 'GlobalClimateAction': 0, 'XRNL': 0}},
{sentiment: 0.9979454874992371, lat: null, lng: null, count: 1, topics: ['timeforaction', 'xrnl'], group: 'piet', emojis: [], keywords: ['boodschap', 'Extinction', 'Rebellion', 'actie', 'klimaatcrisis', 'tijd', 'verandering', 'XRNL'], full_text: "\"We moeten de boodschap van Extinction Rebellion serieus nemen en actie ondernemen om de klimaatcrisis aan te pakken. Het is tijd voor verandering! #TimeForAction #XRNL", keyword_clusters: {'boodschap': 5, 'Extinction': 5, 'Rebellion': 5, 'actie': 1, 'klimaatcrisis': 4, 'tijd': 3, 'verandering': 1, 'XRNL': 0}},
{sentiment: 0.9978287816047668, lat: null, lng: null, count: 1, topics: ['takeresponsibility', 'xrnl'], group: 'piet', emojis: [], keywords: ['protesten', 'Extinction', 'Rebellion', 'oproep', 'actie', 'verantwoordelijkheid', 'toekomst', 'XRNL'], full_text: "De protesten van Extinction Rebellion zijn een oproep tot actie voor ons allemaal, laten we onze verantwoordelijkheid nemen en werken aan een duurzame toekomst voor iedereen. #TakeResponsibility #XRNL", keyword_clusters: {'protesten': 1, 'Extinction': 5, 'Rebellion': 5, 'oproep': 0, 'actie': 1, 'verantwoordelijkheid': 4, 'toekomst': 1, 'XRNL': 0}},
{sentiment: 0.9970280528068542, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['rebelforlife', 'schipholschiphol'], group: 'piet', emojis: [], keywords: ['tijd', 'tijd', 'actie', 'uitbreiding', 'Schiphol', 'projecten', 'rebelforlife', 'schipholschiphol'], full_text: "De tijd van praten is voorbij. Het is tijd voor actie. We blijven ons verzetten tegen de uitbreiding van Schiphol en andere klimaatverwoestende projecten. #rebelforlife #schipholschiphol", keyword_clusters: {'tijd': 3, 'actie': 1, 'uitbreiding': 1, 'Schiphol': 5, 'projecten': 5, 'rebelforlife': 3, 'schipholschiphol': 3}},
{sentiment: 0.9979355335235596, lat: null, lng: null, count: 1, topics: ['rebelforlife', 'schipholschiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'planeet', 'toekomst', 'kinderen', 'rebelforlife', 'schipholschiphol'], full_text: "We hebben vreedzaam geprotesteerd tegen Schiphol, maar het lijkt erop dat we niet worden gehoord. We blijven vechten voor onze planeet en de toekomst van onze kinderen. #rebelforlife #schipholschiphol", keyword_clusters: {'Schiphol': 5, 'planeet': 1, 'toekomst': 1, 'kinderen': 1, 'rebelforlife': 3, 'schipholschiphol': 3}},
{sentiment: 0.9940358400344849, lat: null, lng: null, count: 1, topics: ['rebelforlife', 'schipholschiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'jaren', 'keuze', 'straat', 'toekomst', 'rebelforlife', 'schipholschiphol'], full_text: "Schiphol, jullie hebben ons de afgelopen jaren genegeerd en nu hebben we geen andere keuze dan de straat op te gaan en te strijden voor onze toekomst. #rebelforlife #schipholschiphol", keyword_clusters: {'Schiphol': 5, 'jaren': 7, 'keuze': 4, 'straat': 5, 'toekomst': 1, 'rebelforlife': 3, 'schipholschiphol': 3}},
{sentiment: 0.996229350566864, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['rebelforlife', 'schipholschiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'eisen', 'volk', 'planeet', 'rebelforlife', 'schipholschiphol'], full_text: "Wij laten ons niet het zwijgen opleggen. Schiphol moet luisteren naar de eisen van het volk en stoppen met het vernietigen van onze planeet. #rebelforlife #schipholschiphol", keyword_clusters: {'Schiphol': 5, 'eisen': 5, 'volk': 0, 'planeet': 1, 'rebelforlife': 3, 'schipholschiphol': 3}},
{sentiment: 0.9964494705200195, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['rebelforlife', 'schipholschiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'activiteiten', 'vernietiging', 'planeet', 'rebelforlife', 'schipholschiphol'], full_text: "Wij zijn hier niet om te spelen. Schiphol moet stoppen met het uitbreiden van haar activiteiten en de vernietiging van onze planeet. #rebelforlife #schipholschiphol", keyword_clusters: {'Schiphol': 5, 'activiteiten': 7, 'vernietiging': 4, 'planeet': 1, 'rebelforlife': 3, 'schipholschiphol': 3}},
{sentiment: 0.9962018132209778, lat: null, lng: null, count: 1, topics: ['rebelforlife', 'schipholschiphol'], group: 'piet', emojis: [], keywords: ['tijd', 'rand', 'afgrond', 'planeet', 'rebelforlife', 'schipholschiphol'], full_text: "Het is tijd om wakker te worden en te beseffen dat we op de rand van de afgrond staan. We moeten nu handelen om onze planeet te redden. #rebelforlife #schipholschiphol", keyword_clusters: {'tijd': 3, 'rand': 4, 'afgrond': 7, 'planeet': 1, 'rebelforlife': 3, 'schipholschiphol': 3}},
{sentiment: 0.9944381713867188, lat: null, lng: null, count: 1, topics: ['rebelforlife', 'schipholschiphol'], group: 'piet', emojis: [], keywords: ['Schiphol', 'vervuilers', 'Nederland', 'tijd', 'verantwoordelijkheid', 'uitbreiding', 'industrie', 'rebelforlife', 'schipholschiphol'], full_text: "Schiphol is een van de grootste vervuilers van Nederland. Het is tijd om verantwoordelijkheid te nemen en te stoppen met de uitbreiding van deze destructieve industrie. #rebelforlife #schipholschiphol", keyword_clusters: {'Schiphol': 5, 'vervuilers': 4, 'Nederland': 5, 'tijd': 3, 'verantwoordelijkheid': 4, 'uitbreiding': 1, 'industrie': 7, 'rebelforlife': 3, 'schipholschiphol': 3}},
{sentiment: 0.9967851638793945, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['rebelforlife', 'schipholschiphol'], group: 'piet', emojis: [], keywords: ['uitbreiding', 'Schiphol', 'projecten', 'planeet', 'rebelforlife', 'schipholschiphol'], full_text: "We moeten ons verzetten tegen de uitbreiding van Schiphol en andere destructieve projecten, anders zal onze planeet verloren gaan. #rebelforlife #schipholschiphol", keyword_clusters: {'uitbreiding': 1, 'Schiphol': 5, 'projecten': 5, 'planeet': 1, 'rebelforlife': 3, 'schipholschiphol': 3}},
{sentiment: 0.9975270628929138, lat: 52.3080392, lng: 4.7621975, count: 1, topics: ['rebelforlife', 'schipholschiphol'], group: 'piet', emojis: [], keywords: ['tijd', 'waarheid', 'ogen', 'Schiphol', 'klimaatramp', 'actie', 'planeet', 'rebelforlife', 'schipholschiphol'], full_text: "Het is tijd om de waarheid onder ogen te zien: Schiphol is een klimaatramp. We moeten nu actie ondernemen om onze planeet te beschermen. #rebelforlife #schipholschiphol", keyword_clusters: {'tijd': 3, 'waarheid': 0, 'ogen': 0, 'Schiphol': 5, 'klimaatramp': 5, 'actie': 1, 'planeet': 1, 'rebelforlife': 3, 'schipholschiphol': 3}},
{sentiment: 0.997837483882904, lat: null, lng: null, count: 1, topics: ['rebelforlife', 'schipholschiphol'], group: 'piet', emojis: [], keywords: ['regering', 'bedrijven', 'planeet', 'straat', 'actie', 'uitbreiding', 'Schiphol', 'rebelforlife', 'schipholschiphol'], full_text: "Onze regering en bedrijven hebben gefaald in het beschermen van onze planeet. Daarom moeten we zelf de straat op en actie ondernemen tegen de uitbreiding van Schiphol. #rebelforlife #schipholschiphol", keyword_clusters: {'regering': 1, 'bedrijven': 7, 'planeet': 1, 'straat': 5, 'actie': 1, 'uitbreiding': 1, 'Schiphol': 5, 'rebelforlife': 3, 'schipholschiphol': 3}},
{sentiment: 0.9973902106285095, lat: null, lng: null, count: 1, topics: ['boerenprotest', 'opkomen'], group: 'piet', emojis: ['🚜'], keywords: ['@Caroline_vd_Plas', 'unaxceptabel', 'boeren', 'oneerlik', 'Tijd', 'gerechtigheid', 'respek', 'BoerenProtest', '🚜'], full_text: "@Caroline_vd_Plas Dit is unaxceptabel! Onze boeren wordt oneerlik behandeld! Tijd voor gerechtigheid en respek! #BoerenProtest #Opkomen 🚜", keyword_clusters: {'@Caroline_vd_Plas': 4, 'unaxceptabel': 5, 'boeren': 5, 'oneerlik': 5, 'Tijd': 4, 'gerechtigheid': 4, 'respek': 7, 'BoerenProtest': 5}},
{sentiment: 0.99777752161026, lat: null, lng: null, count: 1, topics: ['boereleve', 'respect'], group: 'piet', emojis: [], keywords: ['boeren', 'beeter', 'samewerking', 'waardering', 'BoereLeve'], full_text: "Waarom altijd zukke onreregelegheden met boeren? Zorg toch voor beeter samewerking en waardering! #BoereLeve #Respect", keyword_clusters: {'boeren': 5, 'beeter': 5, 'samewerking': 7, 'waardering': 4, 'BoereLeve': 5}},
{sentiment: 0.9951106905937195, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'samensterk'], group: 'piet', emojis: ['🚜'], keywords: ['mensen', 'nie', 'boeren', 'belangriek', 'empathie', 'SteunDeBoeren', 'SamenSterk', '🚜'], full_text: "Ik snap nie hoe mensen nie snappen da onze boeren belangriek zijn! Waar is de empathie? #SteunDeBoeren #SamenSterk 🚜", keyword_clusters: {'mensen': 0, 'nie': 4, 'boeren': 5, 'belangriek': 7, 'empathie': 4, 'SteunDeBoeren': 5, 'SamenSterk': 5}},
{sentiment: 0.9889172315597534, lat: null, lng: null, count: 1, topics: ['boerenmacht', 'gelijkheid'], group: 'piet', emojis: [], keywords: ['onrechvaardigheden', 'boeren', 'kwaad', 'BoerenMacht', 'Gelijkheid'], full_text: "Kijke naar de onrechvaardigheden die onze boeren moeten doorstaan! Ik ben kwaad en teleurgesteld! #BoerenMacht #Gelijkheid", keyword_clusters: {'onrechvaardigheden': 4, 'boeren': 5, 'kwaad': 5, 'BoerenMacht': 5, 'Gelijkheid': 0}},
{sentiment: 0.018667161464691162, lat: null, lng: null, count: 1, topics: ['boerenstaop', 'solidariteit'], group: 'piet', emojis: [], keywords: ['mosten', 'oog', 'strugels', 'boeren', 'BoerenStaOp', 'Solidariteit'], full_text: "Genoeg is genoeg! Ze mosten oog hebben voor de strugels van de boeren! Ik ben er helemaal klaar mee! #BoerenStaOp #Solidariteit", keyword_clusters: {'mosten': 0, 'oog': 5, 'strugels': 4, 'boeren': 5, 'BoerenStaOp': 7, 'Solidariteit': 0}},
{sentiment: 0.998106837272644, lat: null, lng: null, count: 1, topics: ['boerenederland', 'actienu'], group: 'piet', emojis: [], keywords: ['@Caroline_van_d_Plas', 'veranderring', 'onbegrip', 'miskenning', 'BoereNederland', 'ActieNu'], full_text: "@Caroline_van_d_Plas Wanneer komt er nou echte veranderring? We zijn moe van de onbegrip en miskenning! #BoereNederland #ActieNu", keyword_clusters: {'@Caroline_van_d_Plas': 4, 'veranderring': 7, 'onbegrip': 4, 'miskenning': 5, 'BoereNederland': 7, 'ActieNu': 5}},
{sentiment: 0.9901660084724426, lat: null, lng: null, count: 1, topics: ['boerenpower', 'samenstrijden'], group: 'piet', emojis: [], keywords: ['boeren', 'rechten', 'rechtvaardigheid', 'BoerenPower', 'SamenStrijden'], full_text: "Het is ongelofelijk dat boeren hun rechten moet verdedige! We moeten samen opkomen voor rechtvaardigheid! #BoerenPower #SamenStrijden", keyword_clusters: {'boeren': 5, 'rechten': 1, 'rechtvaardigheid': 1, 'BoerenPower': 7, 'SamenStrijden': 5}},
{sentiment: 0.9039338231086731, lat: null, lng: null, count: 1, topics: ['steunboeren', 'genoegisgenoeg'], group: 'piet', emojis: [], keywords: ['boeren', 'traktoren', 'protest', 'nie', 'SteunBoeren'], full_text: "Ik ben verdrietig en boos dat boeren hun traktoren moeten inzetten voor protest! Dit zou nie nodig moeten zijn! #SteunBoeren #GenoegIsGenoeg", keyword_clusters: {'boeren': 5, 'traktoren': 7, 'protest': 1, 'nie': 4, 'SteunBoeren': 7}},
{sentiment: 0.9878559708595276, lat: null, lng: null, count: 1, topics: ['boerenprotest', 'luisternaarons'], group: 'piet', emojis: ['🚜'], keywords: ['boeren', 'Tijd', 'BoerenProtest', 'LuisterNaarOns', '🚜'], full_text: "Laat ze stoppen met het kleinere van onze boeren! Tijd om naar hen te luistere en te respectere! #BoerenProtest #LuisterNaarOns 🚜", keyword_clusters: {'boeren': 5, 'Tijd': 4, 'BoerenProtest': 5, 'LuisterNaarOns': 7}},
{sentiment: 0.9939872026443481, lat: null, lng: null, count: 1, topics: ['boerensterk', 'samenvooruit'], group: 'piet', emojis: [], keywords: ['boeren', 'standvastigheid', 'Tijd', 'stem', 'verandering', 'BoerenSterk', 'SamenVooruit'], full_text: "Ik ben trots op onze boeren en de standvastigheid die ze toonen! Tijd om hun stem te versterken en verandering te brengen! #BoerenSterk #SamenVooruit", keyword_clusters: {'boeren': 5, 'standvastigheid': 0, 'Tijd': 4, 'stem': 1, 'verandering': 1, 'BoerenSterk': 0, 'SamenVooruit': 4}},
{sentiment: 0.9980188608169556, lat: null, lng: null, count: 1, topics: ['samensterk', 'boerenprotest'], group: 'piet', emojis: [], keywords: ['@Caroline_vd_Plas', 'tijd', 'zorgen', 'boeren', 'oplossingen', 'SamenSterk', 'BoerenProtest'], full_text: "@Caroline_vd_Plas Het is tijd om te luisteren naar de zorgen van onze boeren. Laten we op zoek gaan naar oplossingen die eerlijk zijn voor iedereen. #SamenSterk #BoerenProtest", keyword_clusters: {'@Caroline_vd_Plas': 4, 'tijd': 3, 'zorgen': 1, 'boeren': 5, 'oplossingen': 1, 'SamenSterk': 5, 'BoerenProtest': 5}},
{sentiment: 0.9970061182975769, lat: null, lng: null, count: 1, topics: ['boerenzorgen', 'constructief'], group: 'piet', emojis: [], keywords: ['frustraties', 'boeren', 'oog', 'gevolgen', 'snelwegen', 'dialoog', 'oplossing', 'BoerenZorgen'], full_text: "Ik begrijp de frustraties van de boeren, maar laten we ook oog hebben voor de gevolgen van het blokkeren van snelwegen. Een dialoog is nodig voor een evenwichtige oplossing. #BoerenZorgen #Constructief", keyword_clusters: {'frustraties': 5, 'boeren': 5, 'oog': 5, 'gevolgen': 1, 'snelwegen': 0, 'dialoog': 0, 'oplossing': 0, 'BoerenZorgen': 0}},
{sentiment: 0.9980478286743164, lat: null, lng: null, count: 1, topics: ['duurzaamheid', 'boerentoekomst'], group: 'piet', emojis: [], keywords: ['sector', 'rol', 'samenleving', 'duurzaamheid', 'milieuverantwoordelijkheid', 'balans', 'Duurzaamheid', 'BoerenToekomst'], full_text: "De agrarische sector speelt een cruciale rol in onze samenleving, maar we moeten ook streven naar duurzaamheid en milieuverantwoordelijkheid. Een balans vinden is essentieel. #Duurzaamheid #BoerenToekomst", keyword_clusters: {'sector': 0, 'rol': 5, 'samenleving': 7, 'duurzaamheid': 1, 'milieuverantwoordelijkheid': 4, 'balans': 0, 'Duurzaamheid': 0, 'BoerenToekomst': 4}},
{sentiment: 0.9979351758956909, lat: null, lng: null, count: 1, topics: ['diversiteit', 'samenwerking'], group: 'piet', emojis: [], keywords: ['perspectieven', 'uitdagingen', 'landbouwsector', 'Diversiteit', 'Samenwerking'], full_text: "Het is belangrijk om te erkennen dat er verschillende perspectieven zijn. Laten we naar elkaar luisteren en samenwerken om de uitdagingen in de landbouwsector aan te pakken. #Diversiteit #Samenwerking", keyword_clusters: {'perspectieven': 5, 'uitdagingen': 7, 'landbouwsector': 5, 'Diversiteit': 4, 'Samenwerking': 5}},
{sentiment: 0.9976015686988831, lat: null, lng: null, count: 1, topics: ['dialoog', 'boerensamenleving'], group: 'piet', emojis: [], keywords: ['@Caroline_van_d_Plas', 'gesprek', 'bruggen', 'boeren', 'oplossingen', 'Dialoog', 'BoerenSamenleving'], full_text: "@Caroline_van_d_Plas We moeten in gesprek blijven en bruggen bouwen tussen boeren en andere belanghebbenden. Alleen zo kunnen we tot constructieve oplossingen komen. #Dialoog #BoerenSamenleving", keyword_clusters: {'@Caroline_van_d_Plas': 4, 'gesprek': 7, 'bruggen': 7, 'boeren': 5, 'oplossingen': 1, 'Dialoog': 4, 'BoerenSamenleving': 5}},
{sentiment: 0.9978722333908081, lat: null, lng: null, count: 1, topics: ['samensterk', 'toekomstvisie'], group: 'piet', emojis: [], keywords: ['problemen', 'sector', 'vragen', 'aanpak', 'toekomst', 'SamenSterk', 'Toekomstvisie'], full_text: "De problemen in de agrarische sector zijn complex en vragen om een gedegen aanpak. Laten we expertise bundelen en gezamenlijk streven naar een duurzame toekomst. #SamenSterk #Toekomstvisie", keyword_clusters: {'problemen': 4, 'sector': 0, 'vragen': 0, 'aanpak': 7, 'toekomst': 1, 'SamenSterk': 5, 'Toekomstvisie': 7}},
{sentiment: 0.998212456703186, lat: null, lng: null, count: 1, topics: ['brederperspectief', 'boerenmiddenweg'], group: 'piet', emojis: [], keywords: ['begrip', 'zorgen', 'boeren', 'oog', 'context', 'evenwicht', 'belangen', 'BrederPerspectief', 'BoerenMiddenweg'], full_text: "Hoewel ik begrip heb voor de zorgen van boeren, moeten we ook oog hebben voor de bredere maatschappelijke context. Laten we op zoek gaan naar een evenwicht tussen belangen. #BrederPerspectief #BoerenMiddenweg", keyword_clusters: {'begrip': 0, 'zorgen': 1, 'boeren': 5, 'oog': 5, 'context': 7, 'evenwicht': 7, 'belangen': 1, 'BrederPerspectief': 4, 'BoerenMiddenweg': 4}},
{sentiment: 0.9980102181434631, lat: null, lng: null, count: 1, topics: ['inclusiviteit', 'gelijkwaardigheid'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'onvrede', 'dialoog', 'verandering', 'Inclusiviteit', 'Gelijkwaardigheid'], full_text: "Het boerenprotest laat zien dat er onvrede is, maar laten we streven naar een inclusieve dialoog waarin iedereen gehoord wordt. Alleen dan kunnen we tot echte verandering komen. #Inclusiviteit #Gelijkwaardigheid", keyword_clusters: {'boerenprotest': 1, 'onvrede': 5, 'dialoog': 0, 'verandering': 1, 'Inclusiviteit': 0, 'Gelijkwaardigheid': 7}},
{sentiment: 0.9976376295089722, lat: null, lng: null, count: 1, topics: ['innovatie', 'duurzaamboeren'], group: 'piet', emojis: [], keywords: ['uitdagingen', 'landbouwsector', 'oplossingen', 'inspanning', 'landbouw', 'toekomst', 'boeren', 'Innovatie', 'DuurzaamBoeren'], full_text: "De uitdagingen in de landbouwsector vragen om innovatieve oplossingen en een gezamenlijke inspanning. Laten we investeren in duurzame landbouw en de toekomst van onze boeren waarborgen. #Innovatie #DuurzaamBoeren", keyword_clusters: {'uitdagingen': 7, 'landbouwsector': 5, 'oplossingen': 1, 'inspanning': 7, 'landbouw': 5, 'toekomst': 1, 'boeren': 5, 'Innovatie': 5, 'DuurzaamBoeren': 5}},
{sentiment: 0.9978444576263428, lat: null, lng: null, count: 1, topics: ['rechtvaardigheid', 'toekomstgericht'], group: 'piet', emojis: [], keywords: ['zorgen', 'boeren', 'beleid', 'landbouwsector', 'Rechtvaardigheid', '#', 'Toekomstgericht'], full_text: "Het is belangrijk om de zorgen van de boeren serieus te nemen en te werken aan beleid dat rechtvaardig is voor alle betrokkenen. Laten we streven naar een evenwichtige en toekomstbestendige landbouwsector. #Rechtvaardigheid #Toekomstgericht", keyword_clusters: {'zorgen': 1, 'boeren': 5, 'beleid': 7, 'landbouwsector': 5, 'Rechtvaardigheid': 4, '#': 5, 'Toekomstgericht': 4}},
{sentiment: 0.021390199661254883, lat: null, lng: null, count: 1, topics: ['genoegisgenoeg', 'geentolerantie'], group: 'piet', emojis: [], keywords: ['onzin', 'boerenprotesten', 'wegen', 'leven', 'GeenTolerantie'], full_text: "Wat een onzin, die boerenprotesten! Ze blokkeren de wegen en verstoren het dagelijks leven van anderen. Ga iets nuttigs doen! #GenoegIsGenoeg #GeenTolerantie", keyword_clusters: {'onzin': 7, 'boerenprotesten': 4, 'wegen': 0, 'leven': 7, 'GeenTolerantie': 5}},
{sentiment: 0.9204664826393127, lat: null, lng: null, count: 1, topics: ['geensolidariteit', 'boerenoverdrijven'], group: 'piet', emojis: [], keywords: ['Boeren', 'privileges', 'veroorzaak', 'overlast', 'BoerenOverdrijven'], full_text: "Boeren, jullie hebben al genoeg privileges! Stop met zeuren en veroorzaak geen overlast voor anderen. #GeenSolidariteit #BoerenOverdrijven", keyword_clusters: {'Boeren': 7, 'privileges': 0, 'veroorzaak': 4, 'overlast': 1, 'BoerenOverdrijven': 5}},
{sentiment: 0.01780998706817627, lat: null, lng: null, count: 1, topics: ['respectvooriedereen', 'geenuitzonderingen'], group: 'piet', emojis: [], keywords: ['boeren', 'wet'], full_text: "Ik ben helemaal klaar met die boeren die denken dat ze boven de wet staan! Wie denken ze wel niet dat ze zijn? #RespectVoorIedereen #GeenUitzonderingen", keyword_clusters: {'boeren': 5, 'wet': 0}},
{sentiment: 0.9943152070045471, lat: null, lng: null, count: 1, topics: ['vooruitgang', 'duurzaamheid'], group: 'piet', emojis: [], keywords: ['landbouwsector', 'methoden', 'protesten', 'Vooruitgang', 'Duurzaamheid'], full_text: "De landbouwsector moet moderniseren en verduurzamen, niet vasthouden aan ouderwetse methoden. Stop met jullie onverantwoorde protesten! #Vooruitgang #Duurzaamheid", keyword_clusters: {'landbouwsector': 5, 'methoden': 4, 'protesten': 1, 'Vooruitgang': 5, 'Duurzaamheid': 0}},
{sentiment: 0.016389131546020508, lat: null, lng: null, count: 1, topics: ['eigenbelang', 'geensolidariteit'], group: 'piet', emojis: [], keywords: ['actie', 'boeren', 'belangen', 'GeenSolidariteit'], full_text: "Dit is gewoon een egocentrische actie van de boeren. Ze denken alleen aan hun eigen belangen en schaden anderen. Walgelijk! #Eigenbelang #GeenSolidariteit", keyword_clusters: {'actie': 1, 'boeren': 5, 'belangen': 1, 'GeenSolidariteit': 0}},
{sentiment: 0.9940513968467712, lat: null, lng: null, count: 1, topics: ['wettengeldenvooriedereen', 'geentolerantie'], group: 'piet', emojis: [], keywords: ['overheid', 'blokkades', 'GeenTolerantie'], full_text: "De overheid moet harder optreden tegen deze illegale blokkades. Genoeg is genoeg! #WettenGeldenVoorIedereen #GeenTolerantie", keyword_clusters: {'overheid': 0, 'blokkades': 7, 'GeenTolerantie': 5}},
{sentiment: 0.014978349208831787, lat: null, lng: null, count: 1, topics: ['onacceptabel', 'boerenmogennietalles'], group: 'piet', emojis: [], keywords: ['boeren', 'recht', 'wegen', 'wet', 'BoerenMogenNietAlles'], full_text: "Het is belachelijk dat deze boeren denken dat ze het recht hebben om wegen te blokkeren. Jullie zijn niet boven de wet verheven! #Onacceptabel #BoerenMogenNietAlles", keyword_clusters: {'boeren': 5, 'recht': 0, 'wegen': 0, 'wet': 0, 'BoerenMogenNietAlles': 5}},
{sentiment: 0.9901403784751892, lat: null, lng: null, count: 1, topics: ['egoïsme', 'eigenbelang'], group: 'piet', emojis: [], keywords: ['boerenprotesten', 'egoïsme', 'positie', 'rekening', 'Egoïsme'], full_text: "Die boerenprotesten zijn puur egoïsme. Ze willen alleen maar hun eigen positie beschermen, zonder rekening te houden met anderen. #Egoïsme #Eigenbelang", keyword_clusters: {'boerenprotesten': 4, 'egoïsme': 2, 'positie': 7, 'rekening': 5, 'Egoïsme': 4}},
{sentiment: 0.9971798658370972, lat: null, lng: null, count: 1, topics: ['verandering', 'vooruitgang'], group: 'piet', emojis: [], keywords: ['tijd', 'sector', 'tijd', 'verandering', 'modernisering', 'obstructie', 'Verandering', 'Vooruitgang'], full_text: "Het wordt tijd dat we stoppen met het romantiseren van de agrarische sector. Het is tijd voor verandering en modernisering, geen obstructie! #Verandering #Vooruitgang", keyword_clusters: {'tijd': 3, 'sector': 0, 'verandering': 1, 'modernisering': 5, 'obstructie': 0, 'Verandering': 7, 'Vooruitgang': 5}},
{sentiment: 0.9920880198478699, lat: null, lng: null, count: 1, topics: ['negatieveimpact', 'slechtekeuze'], group: 'piet', emojis: [], keywords: ['boeren', 'protesten', 'sympathie', 'imago', 'NegatieveImpact', 'SlechteKeuze'], full_text: "Het is verbazingwekkend hoe deze boeren denken dat ze met hun protesten sympathie zullen winnen. Ze schaden alleen maar hun eigen imago! #NegatieveImpact #SlechteKeuze", keyword_clusters: {'boeren': 5, 'protesten': 1, 'sympathie': 4, 'imago': 5, 'NegatieveImpact': 5, 'SlechteKeuze': 4}},
{sentiment: 0.997663140296936, lat: null, lng: null, count: 1, topics: ['samenvooruit', 'boerenprotest'], group: 'piet', emojis: [], keywords: ['@Username', 'reactie', 'Boeren', 'zorgen', 'rechten', 'plaats', 'SamenVooruit', 'BoerenProtest'], full_text: "@Username Wat een kortzichtige reactie! Boeren hebben legitieme zorgen en willen opkomen voor hun rechten. Wees solidair in plaats van negatief! #SamenVooruit #BoerenProtest", keyword_clusters: {'@Username': 7, 'reactie': 0, 'Boeren': 7, 'zorgen': 1, 'rechten': 1, 'plaats': 0, 'SamenVooruit': 4, 'BoerenProtest': 5}},
{sentiment: 0.9778071045875549, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'respect'], group: 'piet', emojis: [], keywords: ['boeren', 'lol', 'bestaan', 'voedsel', 'Toon', 'begrip', 'stop', 'SteunDeBoeren', 'Respect'], full_text: "Denk je dat boeren zomaar blokkeren voor de lol? Ze vechten voor hun bestaan en ons voedsel. Toon wat begrip en stop met veroordelen! #SteunDeBoeren #Respect", keyword_clusters: {'boeren': 5, 'lol': 0, 'bestaan': 7, 'voedsel': 5, 'Toon': 5, 'begrip': 0, 'stop': 7, 'SteunDeBoeren': 5, 'Respect': 7}},
{sentiment: 0.9962345957756042, lat: null, lng: null, count: 1, topics: ['boerenmacht', 'begrip'], group: 'piet', emojis: [], keywords: ['@Username', 'boeren', 'behandeling', 'landbouw', 'Begrip'], full_text: "@Username Ongeïnformeerd en egoïstisch! De boeren strijden voor een eerlijke behandeling en duurzame landbouw. Informeer jezelf voordat je oordeelt! #BoerenMacht #Begrip", keyword_clusters: {'@Username': 7, 'boeren': 5, 'behandeling': 0, 'landbouw': 5, 'Begrip': 5}},
{sentiment: 0.4409496784210205, lat: null, lng: null, count: 1, topics: ['supportdeboeren', 'solidariteit'], group: 'piet', emojis: [], keywords: ['Boeren', 'ruggengraat', 'land', 'respect', 'steun', 'onbegrip', 'haat', 'Solidariteit'], full_text: "Jij snapt er echt helemaal niets van, hè? Boeren zijn de ruggengraat van ons land! Ze verdienen respect en steun, geen onbegrip en haat! #SupportDeBoeren #Solidariteit", keyword_clusters: {'Boeren': 7, 'ruggengraat': 0, 'land': 7, 'respect': 7, 'steun': 7, 'onbegrip': 4, 'haat': 7, 'Solidariteit': 0}},
{sentiment: 0.9930028319358826, lat: null, lng: null, count: 1, topics: ['steunboeren', 'toekomstvisie'], group: 'piet', emojis: [], keywords: ['boeren', 'toekomst', 'leefbaarheid', 'platteland', 'voedselvoorziening', 'ogen', 'realiteit', 'SteunBoeren', 'ToekomstVisie'], full_text: "Kun je niet begrijpen dat boeren vechten voor hun toekomst? Het gaat om de leefbaarheid van het platteland en onze voedselvoorziening. Sluit je ogen niet voor de realiteit! #SteunBoeren #ToekomstVisie", keyword_clusters: {'boeren': 5, 'toekomst': 1, 'leefbaarheid': 1, 'platteland': 7, 'voedselvoorziening': 5, 'ogen': 0, 'realiteit': 7, 'SteunBoeren': 7, 'ToekomstVisie': 5}},
{sentiment: 0.9953757524490356, lat: null, lng: null, count: 1, topics: ['luisternaardeboeren', 'begrip'], group: 'piet', emojis: [], keywords: ['@Username', 'Boeren', 'kansen', 'erkenning', 'werk', 'luister', 'verhalen', 'Begrip'], full_text: "@Username Je begrijpt het niet! Boeren willen gewoon eerlijke kansen en erkenning voor hun harde werk. Stop met generaliseren en luister naar hun verhalen! #LuisterNaarDeBoeren #Begrip", keyword_clusters: {'@Username': 7, 'Boeren': 7, 'kansen': 5, 'erkenning': 5, 'werk': 4, 'luister': 4, 'verhalen': 7, 'Begrip': 5}},
{sentiment: 0.7267626523971558, lat: null, lng: null, count: 1, topics: ['samensterk', 'boerenzorgen'], group: 'piet', emojis: [], keywords: ['mensen', 'moeilijkheden', 'boeren', 'oplossingen', 'SamenSterk', 'BoerenZorgen'], full_text: "Het is triest om te zien dat sommige mensen zo ongevoelig zijn voor de moeilijkheden van boeren. We moeten samenwerken en oplossingen vinden, niet elkaar aanvallen! #SamenSterk #BoerenZorgen", keyword_clusters: {'mensen': 0, 'moeilijkheden': 0, 'boeren': 5, 'oplossingen': 1, 'SamenSterk': 5, 'BoerenZorgen': 0}},
{sentiment: 0.996615469455719, lat: null, lng: null, count: 1, topics: ['supportboeren', 'solidariteit'], group: 'piet', emojis: [], keywords: ['@Username', 'boeren', 'behandeling', 'toekomst', 'perspectief', 'SupportBoeren', 'Solidariteit'], full_text: "@Username Hoe durf je de boeren zo neer te zetten? Ze hebben recht op een eerlijke behandeling en een duurzame toekomst. Kijk verder dan je eigen perspectief! #SupportBoeren #Solidariteit", keyword_clusters: {'@Username': 7, 'boeren': 5, 'behandeling': 0, 'toekomst': 1, 'perspectief': 0, 'SupportBoeren': 0, 'Solidariteit': 0}},
{sentiment: 0.9965506792068481, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'waardering'], group: 'piet', emojis: [], keywords: ['zijlijn', 'boeren', 'behoud', 'landbouwtradities', 'voedselzekerheid', 'respect', 'inzet', 'SteunDeBoeren', 'Waardering'], full_text: "Het is makkelijk om vanaf de zijlijn te oordelen, maar boeren strijden voor het behoud van onze landbouwtradities en voedselzekerheid. Toon respect voor hun inzet! #SteunDeBoeren #Waardering", keyword_clusters: {'zijlijn': 7, 'boeren': 5, 'behoud': 7, 'landbouwtradities': 5, 'voedselzekerheid': 4, 'respect': 7, 'inzet': 4, 'SteunDeBoeren': 5, 'Waardering': 4}},
{sentiment: 0.995790421962738, lat: null, lng: null, count: 1, topics: ['supportboeren', 'respect'], group: 'piet', emojis: [], keywords: ['@Username', 'idee', 'Boeren', 'samenleving', 'steun', 'uitspraken', 'SupportBoeren', 'Respect'], full_text: "@Username Je hebt echt geen idee waar je het over hebt, hè? Boeren zijn cruciaal voor onze samenleving en verdienen onze steun. Informeer jezelf voordat je negatieve uitspraken doet! #SupportBoeren #Respect", keyword_clusters: {'@Username': 7, 'idee': 5, 'Boeren': 7, 'samenleving': 7, 'steun': 7, 'uitspraken': 5, 'SupportBoeren': 0, 'Respect': 7}},
{sentiment: 0.99754399061203, lat: null, lng: null, count: 1, topics: ['boerenmacht', 'polarisatie'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'voorbeeld', 'ongehoorzaamheid', 'zijde', 'boeren', 'ertegen', 'kant', 'BoerenMacht', 'Polarisatie'], full_text: "Het boerenprotest is een duidelijk voorbeeld van burgerlijke ongehoorzaamheid! Sta aan de zijde van de boeren of verzet je ertegen. Welke kant kies jij? #BoerenMacht #Polarisatie", keyword_clusters: {'boerenprotest': 1, 'voorbeeld': 4, 'ongehoorzaamheid': 7, 'zijde': 7, 'boeren': 5, 'ertegen': 7, 'kant': 0, 'BoerenMacht': 5, 'Polarisatie': 7}},
{sentiment: 0.9932615160942078, lat: null, lng: null, count: 1, topics: ['boerensolidariteit', 'volgmij'], group: 'piet', emojis: [], keywords: ['boeren', 'gelijk', 'rechten', 'steun', 'tractor', 'barricades', 'VolgMij'], full_text: "De boeren hebben groot gelijk! Ze vechten voor hun rechten en verdienen onze steun. Wie is er nog meer bereid om hun tractor te bestijgen en de barricades op te gaan? #BoerenSolidariteit #VolgMij", keyword_clusters: {'boeren': 5, 'gelijk': 4, 'rechten': 1, 'steun': 7, 'tractor': 7, 'barricades': 0, 'VolgMij': 4}},
{sentiment: 0.9880924224853516, lat: null, lng: null, count: 1, topics: ['tegenhetprotest', 'kritischdenken'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'excuus', 'chaos', 'tijd', 'onzin', 'vraagstukken', 'KritischDenken'], full_text: "Laten we eerlijk zijn, het boerenprotest is slechts een excuus om chaos te creëren. Het wordt tijd dat we deze onzin stoppen en ons richten op echte maatschappelijke vraagstukken! #TegenHetProtest #KritischDenken", keyword_clusters: {'boerenprotest': 1, 'excuus': 5, 'chaos': 4, 'tijd': 3, 'onzin': 7, 'vraagstukken': 4, 'KritischDenken': 7}},
{sentiment: 0.9947656393051147, lat: null, lng: null, count: 1, topics: ['boerenpower', 'jointhemovement'], group: 'piet', emojis: [], keywords: ['boeren', 'recht', 'bestaansrecht', 'barricades', 'verandering', 'BoerenPower', 'JoinTheMovement'], full_text: "Ik sta volledig achter de boeren! Ze hebben het recht om te strijden voor hun bestaansrecht. Wie is er klaar om de barricades te bestormen en samen met mij voor verandering te zorgen? #BoerenPower #JoinTheMovement", keyword_clusters: {'boeren': 5, 'recht': 0, 'bestaansrecht': 0, 'barricades': 0, 'verandering': 1, 'BoerenPower': 7, 'JoinTheMovement': 7}},
{sentiment: 0.9871231317520142, lat: null, lng: null, count: 1, topics: ['tegenhetprotest', 'samensterk'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'aanval', 'economie', 'leven', 'mensen', 'boeren', 'toeroepen', 'samenleving', 'TegenHetProtest', 'SamenSterk'], full_text: "Het boerenprotest is een regelrechte aanval op onze economie en het dagelijks leven van mensen. Laten we de boeren een halt toeroepen en onze samenleving beschermen! #TegenHetProtest #SamenSterk", keyword_clusters: {'boerenprotest': 1, 'aanval': 7, 'economie': 1, 'leven': 7, 'mensen': 0, 'boeren': 5, 'toeroepen': 0, 'samenleving': 7, 'TegenHetProtest': 0, 'SamenSterk': 5}},
{sentiment: 0.9964085221290588, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'volgmij'], group: 'piet', emojis: [], keywords: ['boeren', 'steun', 'stem', 'gerechtigheid', 'strijd', 'SteunDeBoeren', 'VolgMij'], full_text: "De boeren hebben de steun van ons allemaal nodig! Laten we onze stem laten horen en opkomen voor gerechtigheid. Wie is er klaar om zich aan te sluiten bij deze belangrijke strijd? #SteunDeBoeren #VolgMij", keyword_clusters: {'boeren': 5, 'steun': 7, 'stem': 1, 'gerechtigheid': 4, 'strijd': 7, 'SteunDeBoeren': 5, 'VolgMij': 4}},
{sentiment: 0.9918133020401001, lat: null, lng: null, count: 1, topics: ['tegenhetprotest', 'rechtvaardigheid'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'vertoning', 'machtsmisbruik', 'acties', 'rechtsstaat', 'TegenHetProtest', 'Rechtvaardigheid'], full_text: "Het boerenprotest is niets meer dan een gevaarlijke vertoning van machtsmisbruik. Laten we ons verzetten tegen deze ongerechtvaardigde acties en de rechtsstaat verdedigen! #TegenHetProtest #Rechtvaardigheid", keyword_clusters: {'boerenprotest': 1, 'vertoning': 4, 'machtsmisbruik': 0, 'acties': 4, 'rechtsstaat': 5, 'TegenHetProtest': 0, 'Rechtvaardigheid': 4}},
{sentiment: 0.9963578581809998, lat: null, lng: null, count: 1, topics: ['boerensolidariteit', 'influencer'], group: 'piet', emojis: [], keywords: ['boeren', 'solidariteit', 'steun', 'stemmen', 'beweging'], full_text: "De boeren hebben dringend onze solidariteit nodig! Laten we onze steun betuigen en de stemmen versterken. Wie is er klaar om deze beweging te leiden? #BoerenSolidariteit #Influencer", keyword_clusters: {'boeren': 5, 'solidariteit': 4, 'steun': 7, 'stemmen': 5, 'beweging': 5}},
{sentiment: 0.9972394704818726, lat: null, lng: null, count: 1, topics: ['boerenbescherming', 'polarisatie'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'middel', 'landbouwsector', 'zijde', 'boeren', 'kies', 'ondergang', 'voedselvoorziening', 'keuze', 'BoerenBescherming', 'Polarisatie'], full_text: "Het boerenprotest is een broodnodig middel om onze landbouwsector te beschermen. Sta aan de zijde van de boeren of kies voor de ondergang van onze voedselvoorziening. De keuze is aan jou! #BoerenBescherming #Polarisatie", keyword_clusters: {'boerenprotest': 1, 'middel': 7, 'landbouwsector': 5, 'zijde': 7, 'boeren': 5, 'kies': 7, 'ondergang': 7, 'voedselvoorziening': 5, 'keuze': 4, 'BoerenBescherming': 5, 'Polarisatie': 7}},
{sentiment: 0.9973064661026001, lat: null, lng: null, count: 1, topics: ['tegenhetprotest', 'samenwerking'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'verstoring', 'samenleving', 'acties', 'oplossingen', 'Samenwerking'], full_text: "Het boerenprotest is een verstoring van de vreedzame samenleving die we hebben opgebouwd. Laten we ons verzetten tegen deze destructieve acties en op zoek gaan naar constructieve oplossingen! #TegenHetProtest #Samenwerking", keyword_clusters: {'boerenprotest': 1, 'verstoring': 4, 'samenleving': 7, 'acties': 4, 'oplossingen': 1, 'Samenwerking': 5}},
{sentiment: 0.9979490637779236, lat: 52.1594747, lng: 4.4908843, count: 1, topics: ['complot', 'ontwaak'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'afleidingstactiek', 'overheid', 'aandacht', 'problemen', 'agenda', 'opstand', 'Complot', 'Ontwaak'], full_text: "Het boerenprotest is een afleidingstactiek van de overheid om onze aandacht af te leiden van de echte problemen. Word wakker en zie de verborgen agenda achter deze schijnbare opstand! #Complot #Ontwaak", keyword_clusters: {'boerenprotest': 1, 'afleidingstactiek': 5, 'overheid': 0, 'aandacht': 5, 'problemen': 4, 'agenda': 7, 'opstand': 1, 'Complot': 7, 'Ontwaak': 7}},
{sentiment: 0.9950339794158936, lat: null, lng: null, count: 1, topics: ['verzet', 'machtsspel'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'machten', 'landbouw', 'vrijheid', 'manipulatie', 'stem', 'Verzet', '#', 'Machtsspel'], full_text: "Het boerenprotest is georkestreerd door duistere machten die de landbouw willen controleren en onze vrijheid willen beperken. Sta op tegen deze manipulatie en laat je stem horen! #Verzet #Machtsspel", keyword_clusters: {'boerenprotest': 1, 'machten': 7, 'landbouw': 5, 'vrijheid': 5, 'manipulatie': 1, 'stem': 1, 'Verzet': 0, '#': 5, 'Machtsspel': 7}},
{sentiment: 0.9977174997329712, lat: null, lng: null, count: 1, topics: ['deceptie', 'ontmaskering'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'landbouw', 'strategie', 'verdeeldheid', 'controle', 'Deceptie', 'Ontmaskering'], full_text: "Wat als ik je vertel dat het boerenprotest niets te maken heeft met de landbouw, maar een zorgvuldig geplande strategie is om verdeeldheid te zaaien en controle over ons te krijgen? #Deceptie #Ontmaskering", keyword_clusters: {'boerenprotest': 1, 'landbouw': 5, 'strategie': 7, 'verdeeldheid': 2, 'controle': 0, 'Deceptie': 0, 'Ontmaskering': 0}},
{sentiment: 0.9972424507141113, lat: null, lng: null, count: 1, topics: ['verborgenagenda', 'ontmasker'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'stukje', 'puzzel', 'krachten', 'werk', 'voedselvoorziening', 'waarheid', 'VerborgenAgenda', 'Ontmasker'], full_text: "Het boerenprotest is slechts een stukje van de puzzel. Er zijn krachten aan het werk die onze voedselvoorziening willen beheersen en ons afhankelijk maken. Laten we de waarheid blootleggen! #VerborgenAgenda #Ontmasker", keyword_clusters: {'boerenprotest': 1, 'stukje': 4, 'puzzel': 5, 'krachten': 0, 'werk': 4, 'voedselvoorziening': 5, 'waarheid': 0, 'VerborgenAgenda': 7, 'Ontmasker': 4}},
{sentiment: 0.03966706991195679, lat: null, lng: null, count: 1, topics: ['misleiding', 'verzet'], group: 'piet', emojis: ['🤯'], keywords: ['boeren', 'elite', 'controle', 'landbouw', 'hulpbronnen', 'tijd', 'Misleiding', '#', 'Verzet', '🤯'], full_text: "De boeren worden misleid en gemanipuleerd door een elite die de controle wil over onze landbouw en natuurlijke hulpbronnen. Het is tijd om wakker te worden en ons te verzetten! #Misleiding #Verzet 🤯", keyword_clusters: {'boeren': 5, 'elite': 0, 'controle': 0, 'landbouw': 5, 'hulpbronnen': 0, 'tijd': 3, 'Misleiding': 4, '#': 5, 'Verzet': 0}},
{sentiment: 0.9966717958450317, lat: null, lng: null, count: 1, topics: ['nieuwewereldorde', 'ontwaak'], group: 'piet', emojis: ['🤯'], keywords: ['boerenprotest', 'samenzwering', 'onafhankelijkheid', 'wereldregering', 'spelletjes', 'NieuweWereldorde', '#', '🤯'], full_text: "Achter het boerenprotest schuilt een diepere samenzwering om onze onafhankelijkheid te vernietigen en ons te onderwerpen aan een wereldregering. Wees alert en doorzie de spelletjes! #NieuweWereldorde #Ontwaak 🤯", keyword_clusters: {'boerenprotest': 1, 'samenzwering': 1, 'onafhankelijkheid': 5, 'wereldregering': 5, 'spelletjes': 0, 'NieuweWereldorde': 0, '#': 5}},
{sentiment: 0.9954838752746582, lat: null, lng: null, count: 1, topics: ['voedselrevolutie', 'verzet'], group: 'piet', emojis: ['🤯'], keywords: ['boerenprotest', 'afleiding', 'dreiging', 'controle', 'voedselsysteem', 'multinationals', 'voedselsoevereiniteit', 'Voedselrevolutie', 'Verzet', '🤯'], full_text: "Het boerenprotest is slechts een afleiding van de echte dreiging: de controle over ons voedselsysteem door multinationals. Laten we ons verzetten en onze voedselsoevereiniteit beschermen! #Voedselrevolutie #Verzet 🤯", keyword_clusters: {'boerenprotest': 1, 'afleiding': 7, 'dreiging': 0, 'controle': 0, 'voedselsysteem': 4, 'multinationals': 0, 'voedselsoevereiniteit': 7, 'Voedselrevolutie': 0, 'Verzet': 0}},
{sentiment: 0.9951481223106384, lat: null, lng: null, count: 1, topics: ['manipulatie', 'ontmaskering'], group: 'piet', emojis: ['🤯'], keywords: ['boeren', 'pionnen', 'spel', 'macht', 'controle', 'motieven', 'Onderzoek', 'waarheid', 'Manipulatie', 'Ontmaskering', '🤯'], full_text: "De boeren worden gebruikt als pionnen in een groot spel van macht en controle. Laat je niet misleiden door de schijnbaar nobele motieven. Onderzoek en ontdek de waarheid! #Manipulatie #Ontmaskering 🤯", keyword_clusters: {'boeren': 5, 'pionnen': 7, 'spel': 4, 'macht': 5, 'controle': 0, 'motieven': 0, 'Onderzoek': 5, 'waarheid': 0, 'Manipulatie': 5, 'Ontmaskering': 0}},
{sentiment: 0.995418906211853, lat: null, lng: null, count: 1, topics: ['vereniging', 'ontmasker'], group: 'piet', emojis: ['🤯'], keywords: ['boerenprotest', 'onderdeel', 'plan', 'landbouw', 'boeren', 'agenda', 'Vereniging', 'Ontmasker', '🤯'], full_text: "Het boerenprotest is onderdeel van een geraffineerd plan om de landbouw te centraliseren en kleine boeren weg te vagen. Laten we ons verenigen en deze agenda blootleggen! #Vereniging #Ontmasker 🤯", keyword_clusters: {'boerenprotest': 1, 'onderdeel': 4, 'plan': 0, 'landbouw': 5, 'boeren': 5, 'agenda': 7, 'Vereniging': 0, 'Ontmasker': 4}},
{sentiment: 0.9957088232040405, lat: null, lng: null, count: 1, topics: ['verzet', 'ontwaak'], group: 'piet', emojis: ['🤯'], keywords: ['boerenprotest', 'agenda', 'voedsel', 'vrijheid', 'toekomst', 'tirannie', 'Verzet', '🤯'], full_text: "Achter het boerenprotest schuilt een duistere agenda die gericht is op het controleren van ons voedsel, onze vrijheid en onze toekomst. Wees waakzaam en verzet je tegen deze tirannie! #Verzet #Ontwaak 🤯", keyword_clusters: {'boerenprotest': 1, 'agenda': 7, 'voedsel': 5, 'vrijheid': 5, 'toekomst': 1, 'tirannie': 0, 'Verzet': 0}},
{sentiment: 0.9927297234535217, lat: null, lng: null, count: 1, topics: ['agrireuzen', 'machtsspel'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'marionettenspel', 'agrireuzen', 'schermen', 'touwtjes', 'regering', 'belangen', 'Agrireuzen', 'Machtsspel'], full_text: "Het boerenprotest is een marionettenspel van de agrireuzen die achter de schermen aan de touwtjes trekken. Ze willen de regering beïnvloeden en hun eigen belangen veiligstellen. #Agrireuzen #Machtsspel", keyword_clusters: {'boerenprotest': 1, 'marionettenspel': 4, 'agrireuzen': 1, 'schermen': 7, 'touwtjes': 7, 'regering': 1, 'belangen': 1, 'Agrireuzen': 7, 'Machtsspel': 7}},
{sentiment: 0.9944713115692139, lat: null, lng: null, count: 1, topics: ['samenzwering', 'agrireuzen'], group: 'piet', emojis: [], keywords: ['mensen', 'agrireuzen', 'boerenprotest', 'opinie', 'greep', 'landbouwsector', 'Samenzwering', 'Agrireuzen'], full_text: "Word wakker, mensen! De agrireuzen hebben dit boerenprotest georkestreerd om de publieke opinie te manipuleren en hun greep op de landbouwsector te versterken. #Samenzwering #Agrireuzen", keyword_clusters: {'mensen': 0, 'agrireuzen': 1, 'boerenprotest': 1, 'opinie': 7, 'greep': 5, 'landbouwsector': 5, 'Samenzwering': 7, 'Agrireuzen': 7}},
{sentiment: 0.6844887137413025, lat: null, lng: null, count: 1, topics: ['corruptie', 'agrireuzen'], group: 'piet', emojis: [], keywords: ['agrireuzen', 'spel', 'protest', 'overheid', 'druk', 'winsten', 'Tijd', 'waarheid', 'Corruptie', 'Agrireuzen'], full_text: "De agrireuzen spelen een gevaarlijk spel. Ze hebben dit protest opgezet om de overheid onder druk te zetten en hun winsten te vergroten. Tijd om de waarheid te onthullen! #Corruptie #Agrireuzen", keyword_clusters: {'agrireuzen': 1, 'spel': 4, 'protest': 1, 'overheid': 0, 'druk': 4, 'winsten': 5, 'Tijd': 4, 'waarheid': 0, 'Corruptie': 7, 'Agrireuzen': 7}},
{sentiment: 0.833336353302002, lat: null, lng: null, count: 1, topics: ['bevrijding', 'agrireuzen'], group: 'piet', emojis: [], keywords: ['agrireuzen', 'boeren', 'pionnen', 'agenda', 'tijd', 'praktijken', 'greep', 'Bevrijding', 'Agrireuzen'], full_text: "De agrireuzen hebben de boeren als pionnen gebruikt om hun eigen agenda door te drukken. Het is tijd om deze schimmige praktijken bloot te leggen en ons te bevrijden van hun greep. #Bevrijding #Agrireuzen", keyword_clusters: {'agrireuzen': 1, 'boeren': 5, 'pionnen': 7, 'agenda': 7, 'tijd': 3, 'praktijken': 0, 'greep': 5, 'Bevrijding': 7, 'Agrireuzen': 7}},
{sentiment: 0.997329831123352, lat: 52.1594747, lng: 4.4908843, count: 1, topics: ['ontmaskering', 'agrireuzen'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'toneelstuk', 'agrireuzen', 'aandacht', 'macht', 'invloed', 'tijd', 'machtsstructuren', 'Ontmaskering', '#', 'Agrireuzen'], full_text: "Het boerenprotest is een toneelstuk van de agrireuzen, bedoeld om de aandacht af te leiden van hun eigen macht en invloed. Het wordt tijd dat we deze machtsstructuren ontmaskeren! #Ontmaskering #Agrireuzen", keyword_clusters: {'boerenprotest': 1, 'toneelstuk': 7, 'agrireuzen': 1, 'aandacht': 5, 'macht': 5, 'invloed': 5, 'tijd': 3, 'machtsstructuren': 5, 'Ontmaskering': 0, '#': 5, 'Agrireuzen': 7}},
{sentiment: 0.980670690536499, lat: null, lng: null, count: 1, topics: ['verzet', 'agrireuzen'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'agrireuzen', 'landbouwsector', 'boeren', 'overheersing', 'Verzet', 'Agrireuzen'], full_text: "Achter het boerenprotest schuilen de agrireuzen die de landbouwsector willen monopoliseren en de kleine boeren willen verdrijven. We moeten ons verzetten tegen deze corporate overheersing! #Verzet #Agrireuzen", keyword_clusters: {'boerenprotest': 1, 'agrireuzen': 1, 'landbouwsector': 5, 'boeren': 5, 'overheersing': 4, 'Verzet': 0, 'Agrireuzen': 7}},
{sentiment: 0.99592125415802, lat: null, lng: null, count: 1, topics: ['vereniging', 'agrireuzen'], group: 'piet', emojis: [], keywords: ['tijd', 'waarheid', 'agrireuzen', 'protest', 'overheid', 'beleid', 'voordeel', 'machtsgreep', 'Vereniging', 'Agrireuzen'], full_text: "Het is tijd om de waarheid te erkennen. De agrireuzen hebben dit protest geïnitieerd om de overheid te dwingen beleid in hun voordeel te veranderen. We moeten ons verenigen tegen deze machtsgreep! #Vereniging #Agrireuzen", keyword_clusters: {'tijd': 3, 'waarheid': 0, 'agrireuzen': 1, 'protest': 1, 'overheid': 0, 'beleid': 7, 'voordeel': 5, 'machtsgreep': 4, 'Vereniging': 0, 'Agrireuzen': 7}},
{sentiment: 0.9930439591407776, lat: null, lng: null, count: 1, topics: ['manipulatie', 'agrireuzen'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'afleidingstactiek', 'agrireuzen', 'agenda', 'schijnwerpers', 'spelers', 'manipulatie', 'Manipulatie', 'Agrireuzen'], full_text: "Het boerenprotest is een afleidingstactiek van de agrireuzen om hun eigen agenda door te drukken. Laten we de schijnwerpers richten op de echte spelers achter deze manipulatie! #Manipulatie #Agrireuzen", keyword_clusters: {'boerenprotest': 1, 'afleidingstactiek': 5, 'agrireuzen': 1, 'agenda': 7, 'schijnwerpers': 5, 'spelers': 7, 'manipulatie': 1, 'Manipulatie': 5, 'Agrireuzen': 7}},
{sentiment: 0.9781093597412109, lat: null, lng: null, count: 1, topics: ['verzet', 'agrireuzen'], group: 'piet', emojis: [], keywords: ['agrireuzen', 'protest', 'opinie', 'greep', 'landbouwindustrie', 'plannen', 'Verzet', 'Agrireuzen'], full_text: "De agrireuzen hebben dit protest georkestreerd om de publieke opinie te beïnvloeden en hun greep op de landbouwindustrie te verstevigen. We moeten ons verzetten en hun snode plannen ontmaskeren! #Verzet #Agrireuzen", keyword_clusters: {'agrireuzen': 1, 'protest': 1, 'opinie': 7, 'greep': 5, 'landbouwindustrie': 0, 'plannen': 7, 'Verzet': 0, 'Agrireuzen': 7}},
{sentiment: 0.9967431426048279, lat: null, lng: null, count: 1, topics: ['waakzaamheid', 'agrireuzen'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'plan', 'agrireuzen', 'landbouwsector', 'winsten', 'motieven', 'Waakzaamheid', '#', 'Agrireuzen'], full_text: "Het boerenprotest is een ingenieus plan van de agrireuzen om de landbouwsector verder te centraliseren en hun winsten te vergroten. Wees waakzaam en doorzie de ware motieven! #Waakzaamheid #Agrireuzen", keyword_clusters: {'boerenprotest': 1, 'plan': 0, 'agrireuzen': 1, 'landbouwsector': 5, 'winsten': 5, 'motieven': 0, 'Waakzaamheid': 7, '#': 5, 'Agrireuzen': 7}},
{sentiment: 0.03706425428390503, lat: null, lng: null, count: 1, topics: ['boerenonrecht', 'woedend'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'wet', 'Blokkeer', 'snelwegen', 'overheid', 'gesprek', 'BoerenOnrecht'], full_text: "Dit boerenprotest maakt me echt pissig! Denken ze dat ze boven de wet staan? Blokkeer de snelwegen niet, maar ga gewoon met de overheid in gesprek! #BoerenOnrecht #Woedend", keyword_clusters: {'boerenprotest': 1, 'wet': 0, 'Blokkeer': 4, 'snelwegen': 0, 'overheid': 0, 'gesprek': 7, 'BoerenOnrecht': 0}},
{sentiment: 0.16890257596969604, lat: null, lng: null, count: 1, topics: ['geentolerantie', 'boosheid'], group: 'piet', emojis: [], keywords: ['onzin', 'boerenprotest', 'rest', 'problemen', 'manier', 'maatschappij', 'GeenTolerantie', 'Boosheid'], full_text: "Wat een onzin, dat boerenprotest! Ze verpesten het voor de rest van ons. Laat ze hun problemen op een andere manier oplossen, zonder de maatschappij te ontwrichten! #GeenTolerantie #Boosheid", keyword_clusters: {'onzin': 7, 'boerenprotest': 1, 'rest': 0, 'problemen': 4, 'manier': 5, 'maatschappij': 5, 'GeenTolerantie': 5, 'Boosheid': 4}},
{sentiment: 0.9971088767051697, lat: null, lng: null, count: 1, topics: ['genoegisgenoeg', 'woede'], group: 'piet', emojis: [], keywords: ['mensen', 'boerenprotest', 'chaos', 'ongemak', 'gevolgen', 'Woede'], full_text: "Ik snap niet waarom mensen dit boerenprotest steunen. Het zorgt alleen maar voor chaos en ongemak. Denken ze niet aan de gevolgen voor anderen? #GenoegIsGenoeg #Woede", keyword_clusters: {'mensen': 0, 'boerenprotest': 1, 'chaos': 4, 'ongemak': 2, 'gevolgen': 1, 'Woede': 0}},
{sentiment: 0.01916682720184326, lat: null, lng: null, count: 1, topics: ['geenrespect', 'woedend'], group: 'piet', emojis: [], keywords: ['boerenprotest'], full_text: "Dit boerenprotest is gewoon belachelijk! Ze denken alleen aan zichzelf en trekken zich niets aan van anderen. Ik ben er helemaal klaar mee! #GeenRespect #Woedend", keyword_clusters: {'boerenprotest': 1}},
{sentiment: 0.024160265922546387, lat: null, lng: null, count: 1, topics: ['geentolerantie', 'boosheid'], group: 'piet', emojis: [], keywords: ['gedrag', 'boeren', 'wegen', 'overlast', 'begrip', 'GeenTolerantie', 'Boosheid'], full_text: "Wat een egoïstisch gedrag van die boeren! Ze blokkeren de wegen en zorgen voor enorm veel overlast. Moeten we hier begrip voor hebben? Ik dacht het niet! #GeenTolerantie #Boosheid", keyword_clusters: {'gedrag': 5, 'boeren': 5, 'wegen': 0, 'overlast': 1, 'begrip': 0, 'GeenTolerantie': 5, 'Boosheid': 4}},
{sentiment: 0.017518043518066406, lat: null, lng: null, count: 1, topics: ['geenrespect', 'woede'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'schande', 'machtspositie', 'zin', 'Woede'], full_text: "Het boerenprotest is een schande! Ze misbruiken hun machtspositie om hun zin door te drijven. Dit gaat veel te ver en ik ben er helemaal klaar mee! #GeenRespect #Woede", keyword_clusters: {'boerenprotest': 1, 'schande': 2, 'machtspositie': 0, 'zin': 5, 'Woede': 0}},
{sentiment: 0.019498169422149658, lat: null, lng: null, count: 1, topics: ['genoegisgenoeg', 'boosheid'], group: 'piet', emojis: [], keywords: ['boeren', 'snelwegen', 'rest', 'GenoegIsGenoeg', 'Boosheid'], full_text: "Ik ben woedend op die boeren die de snelwegen blokkeren. Ze denken alleen aan zichzelf en verpesten het voor de rest van ons. Dit moet stoppen! #GenoegIsGenoeg #Boosheid", keyword_clusters: {'boeren': 5, 'snelwegen': 0, 'rest': 0, 'GenoegIsGenoeg': 7, 'Boosheid': 4}},
{sentiment: 0.07817333936691284, lat: null, lng: null, count: 1, topics: ['geentolerantie', 'woedend'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'problemen', 'manier', 'plaats', 'boel', 'GeenTolerantie'], full_text: "Dit boerenprotest maakt me echt razend! Ze kunnen hun problemen ook op een vreedzame manier aankaarten, in plaats van de boel te ontwrichten. #GeenTolerantie #Woedend", keyword_clusters: {'boerenprotest': 1, 'problemen': 4, 'manier': 5, 'plaats': 0, 'boel': 0, 'GeenTolerantie': 5}},
{sentiment: 0.01530301570892334, lat: 53.0006, lng: 6.68635, count: 1, topics: ['geenrespect', 'woede'], group: 'piet', emojis: [], keywords: ['woede', 'boerenprotest', 'respect', 'samenleving', 'gevaar', 'Woede'], full_text: "Ik kan mijn woede niet onderdrukken over dit boerenprotest. Ze hebben totaal geen respect voor anderen en brengen de samenleving in gevaar. Schandalig! #GeenRespect #Woede", keyword_clusters: {'woede': 2, 'boerenprotest': 1, 'respect': 7, 'samenleving': 7, 'gevaar': 7, 'Woede': 0}},
{sentiment: 0.025936007499694824, lat: null, lng: null, count: 1, topics: ['genoegisgenoeg', 'boosheid'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'oog', 'gevolgen', 'acties', 'GenoegIsGenoeg', 'Boosheid'], full_text: "Het boerenprotest maakt me echt furieus! Ze denken alleen aan zichzelf en hebben geen oog voor de gevolgen van hun acties. Dit moet stoppen, nu! #GenoegIsGenoeg #Boosheid", keyword_clusters: {'boerenprotest': 1, 'oog': 5, 'gevolgen': 1, 'acties': 4, 'GenoegIsGenoeg': 7, 'Boosheid': 4}},
{sentiment: 0.9602228999137878, lat: null, lng: null, count: 1, topics: ['woedend'], group: 'piet', emojis: [], keywords: ['onzin'], full_text: "Wat een onzin! #Woedend", keyword_clusters: {'onzin': 7}},
{sentiment: 0.6066681146621704, lat: null, lng: null, count: 1, topics: ['boos'], group: 'piet', emojis: [], keywords: ['Boos'], full_text: "Ik kan niet geloven wat ik zie! #Boos", keyword_clusters: {'Boos': 5}},
{sentiment: 0.9975385665893555, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['Woede'], full_text: "Dit is belachelijk! #Woede", keyword_clusters: {'Woede': 0}},
{sentiment: 0.9979793429374695, lat: null, lng: null, count: 1, topics: ['razend'], group: 'piet', emojis: [], keywords: ['Serieus'], full_text: "Echt? Serieus? #Razend", keyword_clusters: {}},
{sentiment: 0.9971649050712585, lat: null, lng: null, count: 1, topics: ['kwaad'], group: 'piet', emojis: [], keywords: ['Kwaad'], full_text: "Ik flip helemaal! #Kwaad", keyword_clusters: {'Kwaad': 0}},
{sentiment: 0.025873899459838867, lat: null, lng: null, count: 1, topics: ['furieus'], group: 'piet', emojis: [], keywords: ['Furieus'], full_text: "Niet normaal dit! #Furieus", keyword_clusters: {'Furieus': 7}},
{sentiment: 0.01798856258392334, lat: null, lng: null, count: 1, topics: ['woest'], group: 'piet', emojis: [], keywords: [], full_text: "Ik ben er klaar mee! #Woest", keyword_clusters: {}},
{sentiment: 0.08540397882461548, lat: null, lng: null, count: 1, topics: ['boosheid'], group: 'piet', emojis: [], keywords: ['Boosheid'], full_text: "Dit gaat te ver! #Boosheid", keyword_clusters: {'Boosheid': 4}},
{sentiment: 0.024354279041290283, lat: null, lng: null, count: 1, topics: ['razernij'], group: 'piet', emojis: [], keywords: ['WTF', 'Razernij'], full_text: "WTF?! #Razernij", keyword_clusters: {'WTF': 0, 'Razernij': 0}},
{sentiment: 0.9979713559150696, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['Woede'], full_text: "Ik kan wel schreeuwen! #Woede", keyword_clusters: {'Woede': 0}},
{sentiment: 0.018716514110565186, lat: null, lng: null, count: 1, topics: ['woede', 'genoegisgenoeg'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'acties', 'samenleving', 'manieren', 'ongenoegen', 'Woede'], full_text: "Ik ben echt woedend over dit boerenprotest! Het is onacceptabel dat ze met hun acties de samenleving ontwrichten. Er zijn andere manieren om hun ongenoegen kenbaar te maken. Dit moet stoppen! #Woede #GenoegIsGenoeg", keyword_clusters: {'boerenprotest': 1, 'acties': 4, 'samenleving': 7, 'manieren': 7, 'ongenoegen': 5, 'Woede': 0}},
{sentiment: 0.7152810096740723, lat: null, lng: null, count: 1, topics: ['boos', 'geentolerantie'], group: 'piet', emojis: [], keywords: ['boosheid', 'boerenprotest', 'belediging', 'mensen', 'wegen', 'tijd', 'verantwoordelijkheid', 'acties', 'Boos', 'GeenTolerantie'], full_text: "Ik kan mijn boosheid niet onderdrukken. Dit boerenprotest is een regelrechte belediging voor alle hardwerkende mensen die dagelijks de wegen moeten gebruiken. Het is tijd dat ze verantwoordelijkheid nemen voor hun acties! #Boos #GeenTolerantie", keyword_clusters: {'boosheid': 7, 'boerenprotest': 1, 'belediging': 5, 'mensen': 0, 'wegen': 0, 'tijd': 3, 'verantwoordelijkheid': 4, 'acties': 4, 'Boos': 5, 'GeenTolerantie': 5}},
{sentiment: 0.01709139347076416, lat: null, lng: null, count: 1, topics: ['razernij', 'geenrespect'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'lak', 'gevolgen', 'tijd', 'maatregelen', 'Razernij', 'GeenRespect'], full_text: "Ik ben echt razend door dit onverantwoordelijke boerenprotest. Ze denken alleen aan zichzelf en hebben lak aan de gevolgen voor anderen. Het wordt tijd dat er strengere maatregelen worden genomen! #Razernij #GeenRespect", keyword_clusters: {'boerenprotest': 1, 'lak': 0, 'gevolgen': 1, 'tijd': 3, 'maatregelen': 0, 'Razernij': 0, 'GeenRespect': 0}},
{sentiment: 0.019300639629364014, lat: null, lng: null, count: 1, topics: ['belachelijk', 'woede'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'wegen', 'veiligheid', 'mensen', 'gevaar', 'verstand', 'tijd', 'actie', 'Belachelijk', 'Woede'], full_text: "Dit boerenprotest is gewoon belachelijk! Ze blokkeren de wegen en brengen de veiligheid van mensen in gevaar. Waar is het gezond verstand gebleven? Het is hoog tijd voor actie! #Belachelijk #Woede", keyword_clusters: {'boerenprotest': 1, 'wegen': 0, 'veiligheid': 1, 'mensen': 0, 'gevaar': 7, 'verstand': 7, 'tijd': 3, 'actie': 1, 'Belachelijk': 7, 'Woede': 0}},
{sentiment: 0.02099466323852539, lat: null, lng: null, count: 1, topics: ['klaarmee', 'boosheid'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'misbruik', 'positie', 'economie', 'schade', 'tijd', 'overheid', 'wanorde', 'Boosheid'], full_text: "Ik ben helemaal klaar met dit boerenprotest. Ze maken misbruik van hun positie en brengen de economie schade toe. Het is hoog tijd dat de overheid ingrijpt en deze wanorde stopt! #KlaarMee #Boosheid", keyword_clusters: {'boerenprotest': 1, 'misbruik': 0, 'positie': 7, 'economie': 1, 'schade': 4, 'tijd': 3, 'overheid': 0, 'wanorde': 4, 'Boosheid': 4}},
{sentiment: 0.020702898502349854, lat: 53.0006, lng: 6.68635, count: 1, topics: ['schande', 'woede'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'schande', 'problemen', 'acties', 'tijd', 'verantwoordelijkheid', 'oplossing', 'Schande', 'Woede'], full_text: "Dit boerenprotest is een regelrechte schande. Ze zouden moeten beseffen dat ze anderen in de problemen brengen met hun acties. Het is tijd dat ze hun verantwoordelijkheid nemen en een constructieve oplossing zoeken! #Schande #Woede", keyword_clusters: {'boerenprotest': 1, 'schande': 2, 'problemen': 4, 'acties': 4, 'tijd': 3, 'verantwoordelijkheid': 4, 'oplossing': 0, 'Schande': 4, 'Woede': 0}},
{sentiment: 0.0279502272605896, lat: 53.0006, lng: 6.68635, count: 1, topics: ['geenrespect', 'woedend'], group: 'piet', emojis: [], keywords: ['woede', 'boerenprotest', 'aanfluiting', 'samenleving', 'respect', 'eigenbelang'], full_text: "Ik kan mijn woede niet onderdrukken. Dit boerenprotest is een aanfluiting voor onze samenleving. Ze hebben geen respect voor anderen en denken alleen aan hun eigenbelang. Dit moet stoppen, nu! #GeenRespect #Woedend", keyword_clusters: {'woede': 2, 'boerenprotest': 1, 'aanfluiting': 4, 'samenleving': 7, 'respect': 7, 'eigenbelang': 2}},
{sentiment: 0.9878690838813782, lat: null, lng: null, count: 1, topics: ['geentolerantie', 'boosheid'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'chaos', 'overlast', 'wet', 'tijd', 'grenzen', 'GeenTolerantie', 'Boosheid'], full_text: "Het is onbegrijpelijk dat dit boerenprotest wordt getolereerd. Ze zorgen voor chaos en overlast, en denken dat ze boven de wet staan. Het is hoog tijd dat er duidelijke grenzen worden gesteld! #GeenTolerantie #Boosheid", keyword_clusters: {'boerenprotest': 1, 'chaos': 4, 'overlast': 1, 'wet': 0, 'tijd': 3, 'grenzen': 5, 'GeenTolerantie': 5, 'Boosheid': 4}},
{sentiment: 0.02368927001953125, lat: null, lng: null, count: 1, topics: ['furieus', 'schadelijk'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'imago', 'landbouwsector', 'reputatie', 'boeren', 'gevaar', 'klap', 'gezicht', 'manier', 'Furieus'], full_text: "Ik ben echt furieus over dit boerenprotest. Ze schaden het imago van de landbouwsector en brengen de reputatie van hardwerkende boeren in gevaar. Dit is een klap in het gezicht van iedereen die wel op een verantwoorde manier protesteert! #Furieus #Schadelijk", keyword_clusters: {'boerenprotest': 1, 'imago': 5, 'landbouwsector': 5, 'reputatie': 0, 'boeren': 5, 'gevaar': 7, 'klap': 5, 'gezicht': 4, 'manier': 5, 'Furieus': 7}},
{sentiment: 0.02756345272064209, lat: null, lng: null, count: 1, topics: ['woest', 'onaanvaardbaar'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'wegen', 'leven', 'mensen', 'tijd', 'verantwoordelijkheid', 'gevolgen', 'acties', 'Woest'], full_text: "Dit boerenprotest maakt me woest. Ze blokkeren de wegen en verstoren het dagelijks leven van mensen. Het is hoog tijd dat ze hun verantwoordelijkheid nemen en de gevolgen van hun acties inzien! #Woest #Onaanvaardbaar", keyword_clusters: {'boerenprotest': 1, 'wegen': 0, 'leven': 7, 'mensen': 0, 'tijd': 3, 'verantwoordelijkheid': 4, 'gevolgen': 1, 'acties': 4, 'Woest': 5}},
{sentiment: 0.9962640404701233, lat: null, lng: null, count: 1, topics: ['woedend'], group: 'piet', emojis: ['🤥', '🤥', '🤥'], keywords: ['Rutte', 'leugenaar', 'Woedend', '🤥', '🤥', '🤥'], full_text: "Rutte is een leugenaar! #Woedend 🤥🤥🤥", keyword_clusters: {'Rutte': 0, 'leugenaar': 0, 'Woedend': 7}},
{sentiment: 0.12943249940872192, lat: null, lng: null, count: 1, topics: ['boos'], group: 'piet', emojis: ['😡'], keywords: ['Rot', 'Rutte', 'Boos'], full_text: "Rot op, Rutte! #Boos 😡", keyword_clusters: {'Rot': 5, 'Rutte': 0, 'Boos': 5}},
{sentiment: 0.03745448589324951, lat: null, lng: null, count: 1, topics: ['razend'], group: 'piet', emojis: [], keywords: ['vent', 'Rutte'], full_text: "Ik kan die vent Rutte niet uitstaan! #Razend", keyword_clusters: {'vent': 0, 'Rutte': 0}},
{sentiment: 0.024365603923797607, lat: null, lng: null, count: 1, topics: ['kwaad'], group: 'piet', emojis: ['😡'], keywords: ['Rutte', 'Kwaad'], full_text: "Rutte, je maakt me ziek! #Kwaad 😡", keyword_clusters: {'Rutte': 0, 'Kwaad': 0}},
{sentiment: 0.017013192176818848, lat: null, lng: null, count: 1, topics: ['furieus'], group: 'piet', emojis: ['😡'], keywords: ['Rutte', 'Furieus'], full_text: "Ik walg van Rutte! #Furieus 😡", keyword_clusters: {'Rutte': 0, 'Furieus': 7}},
{sentiment: 0.9983202815055847, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['Rutte', 'Woede'], full_text: "Rutte moet weg, nu! #Woede", keyword_clusters: {'Rutte': 0, 'Woede': 0}},
{sentiment: 0.016600430011749268, lat: null, lng: null, count: 1, topics: ['woest'], group: 'piet', emojis: ['😡', '😡', '😡'], keywords: ['Rutte', 'schande'], full_text: "Rutte, je bent een schande! #Woest 😡😡😡", keyword_clusters: {'Rutte': 0, 'schande': 2}},
{sentiment: 0.42786139249801636, lat: null, lng: null, count: 1, topics: ['boosheid'], group: 'piet', emojis: [], keywords: ['leugenaar', 'Rutte', 'Boosheid'], full_text: "Ik kan die leugenaar Rutte niet meer verdragen! #Boosheid", keyword_clusters: {'leugenaar': 0, 'Rutte': 0, 'Boosheid': 4}},
{sentiment: 0.9975873231887817, lat: null, lng: null, count: 1, topics: ['razernij'], group: 'piet', emojis: [], keywords: ['Rutte', 'Razernij'], full_text: "Rutte, je bent een hypocriet! #Razernij", keyword_clusters: {'Rutte': 0, 'Razernij': 0}},
{sentiment: 0.9982572197914124, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['Rutte', 'Woede'], full_text: "Rutte, je hebt ons verraden! #Woede", keyword_clusters: {'Rutte': 0, 'Woede': 0}},
{sentiment: 0.9979029893875122, lat: null, lng: null, count: 1, topics: ['woedend'], group: 'piet', emojis: ['🤥', '🤥'], keywords: ['Rutte', 'leugenaar', 'Woedend', '🤥', '🤥'], full_text: "Rutte is een vieze leugenaar! #Woedend 🤥🤥", keyword_clusters: {'Rutte': 0, 'leugenaar': 0, 'Woedend': 7}},
{sentiment: 0.992489218711853, lat: null, lng: null, count: 1, topics: ['boos'], group: 'piet', emojis: [], keywords: ['Rot', 'Rutte', 'schade', 'Boos'], full_text: "Rot op, Rutte! Je hebt genoeg schade aangericht. #Boos", keyword_clusters: {'Rot': 5, 'Rutte': 0, 'schade': 4, 'Boos': 5}},
{sentiment: 0.023028016090393066, lat: null, lng: null, count: 1, topics: ['razend'], group: 'piet', emojis: ['🤮', '🤮', '🤮'], keywords: ['Mark', 'Rutte', 'beloften'], full_text: "Mark Rutte, je maakt me ziek met je valse beloften! #Razend 🤮🤮🤮", keyword_clusters: {'Mark': 4, 'Rutte': 0, 'beloften': 7}},
{sentiment: 0.02209782600402832, lat: null, lng: null, count: 1, topics: ['kwaad'], group: 'piet', emojis: [], keywords: ['vent', 'Rutte', 'Kwaad'], full_text: "Ik kan die vent Rutte echt niet uitstaan! #Kwaad", keyword_clusters: {'vent': 0, 'Rutte': 0, 'Kwaad': 0}},
{sentiment: 0.9969224333763123, lat: null, lng: null, count: 1, topics: ['furieus'], group: 'piet', emojis: [], keywords: ['Rutte', 'toonbeeld', 'onbetrouwbaarheid', 'Furieus'], full_text: "Rutte, jij bent het toonbeeld van onbetrouwbaarheid! #Furieus", keyword_clusters: {'Rutte': 0, 'toonbeeld': 0, 'onbetrouwbaarheid': 5, 'Furieus': 7}},
{sentiment: 0.08693307638168335, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: ['😡'], keywords: ['Rutte', 'punt', 'Woede', '😡'], full_text: "Rutte moet oprotten, punt uit! #Woede 😡", keyword_clusters: {'Rutte': 0, 'punt': 7, 'Woede': 0}},
{sentiment: 0.054703354835510254, lat: null, lng: null, count: 1, topics: ['woest'], group: 'piet', emojis: [], keywords: ['Rutte', 'schande', 'land'], full_text: "Rutte, je bent een absolute schande voor dit land! #Woest", keyword_clusters: {'Rutte': 0, 'schande': 2, 'land': 7}},
{sentiment: 0.15142953395843506, lat: null, lng: null, count: 1, topics: ['boosheid'], group: 'piet', emojis: ['🤥'], keywords: ['leugenaar', 'Rutte', 'Boosheid', '🤥'], full_text: "Ik kan die leugenaar Rutte niet meer verdragen! #Boosheid 🤥", keyword_clusters: {'leugenaar': 0, 'Rutte': 0, 'Boosheid': 4}},
{sentiment: 0.020621955394744873, lat: null, lng: null, count: 1, topics: ['razernij'], group: 'piet', emojis: ['😡', '😡', '😡'], keywords: ['Rutte', 'zakkenvuller', 'Razernij'], full_text: "Rutte, je bent een hypocriete zakkenvuller! #Razernij 😡😡😡", keyword_clusters: {'Rutte': 0, 'zakkenvuller': 0, 'Razernij': 0}},
{sentiment: 0.9982231259346008, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['Rutte', 'Woede'], full_text: "Rutte, je hebt ons verraden en teleurgesteld! #Woede", keyword_clusters: {'Rutte': 0, 'Woede': 0}},
{sentiment: 0.9981531500816345, lat: null, lng: null, count: 1, topics: ['woedend'], group: 'piet', emojis: ['💩'], keywords: ['Kaag', 'politicus', '💩'], full_text: "Kaag is een hypocriete politicus! #Woedend 💩", keyword_clusters: {'Kaag': 0, 'politicus': 7}},
{sentiment: 0.9967691898345947, lat: null, lng: null, count: 1, topics: ['boos'], group: 'piet', emojis: ['💩'], keywords: ['Sigrid', 'Kaag', 'politiek', '💩'], full_text: "Sigrid Kaag, je bent totaal ongeschikt voor de politiek! #Boos 💩", keyword_clusters: {'Sigrid': 4, 'Kaag': 0, 'politiek': 1}},
{sentiment: 0.948531985282898, lat: 52.2132601, lng: 4.5583307, count: 1, topics: ['razend'], group: 'piet', emojis: [], keywords: ['Kaag', 'beloften'], full_text: "Ik kan die Kaag niet uitstaan met haar valse beloften! #Razend", keyword_clusters: {'Kaag': 0, 'beloften': 7}},
{sentiment: 0.40271008014678955, lat: null, lng: null, count: 1, topics: ['kwaad'], group: 'piet', emojis: ['💩', '💩', '💩'], keywords: ['Kaag', 'houding', 'Kwaad', '💩', '💩', '💩'], full_text: "Kaag, jij maakt me echt woedend met je arrogante houding! #Kwaad 💩💩💩", keyword_clusters: {'Kaag': 0, 'houding': 7, 'Kwaad': 0}},
{sentiment: 0.21469366550445557, lat: null, lng: null, count: 1, topics: ['furieus'], group: 'piet', emojis: [], keywords: ['Sigrid', 'Kaag', 'schande', 'land', 'Furieus'], full_text: "Sigrid Kaag, jij bent een regelrechte schande voor dit land! #Furieus", keyword_clusters: {'Sigrid': 4, 'Kaag': 0, 'schande': 2, 'land': 7, 'Furieus': 7}},
{sentiment: 0.9965883493423462, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: ['🤮'], keywords: ['Kaag', 'schade', 'Woede'], full_text: "Kaag moet opstappen, ze heeft genoeg schade aangericht! #Woede 🤮", keyword_clusters: {'Kaag': 0, 'schade': 4, 'Woede': 0}},
{sentiment: 0.997506320476532, lat: null, lng: null, count: 1, topics: ['woest'], group: 'piet', emojis: [], keywords: ['Sigrid', 'Kaag', 'ramp', 'politiek'], full_text: "Sigrid Kaag, je bent een ramp voor de politiek! #Woest", keyword_clusters: {'Sigrid': 4, 'Kaag': 0, 'ramp': 0, 'politiek': 1}},
{sentiment: 0.36830317974090576, lat: 52.2132601, lng: 4.5583307, count: 1, topics: ['boosheid'], group: 'piet', emojis: [], keywords: ['leugenaar', 'Kaag', 'Boosheid'], full_text: "Ik kan die leugenaar Kaag niet meer verdragen! #Boosheid", keyword_clusters: {'leugenaar': 0, 'Kaag': 0, 'Boosheid': 4}},
{sentiment: 0.995555579662323, lat: null, lng: null, count: 1, topics: ['razernij'], group: 'piet', emojis: [], keywords: ['Kaag', 'mislukking', 'politicus', 'Razernij'], full_text: "Kaag, je bent een totale mislukking als politicus! #Razernij", keyword_clusters: {'Kaag': 0, 'mislukking': 4, 'politicus': 7, 'Razernij': 0}},
{sentiment: 0.9877325296401978, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: ['🤮'], keywords: ['Sigrid', 'Kaag', 'Woede'], full_text: "Sigrid Kaag, je hebt ons teleurgesteld en verraden! #Woede 🤮", keyword_clusters: {'Sigrid': 4, 'Kaag': 0, 'Woede': 0}},
{sentiment: 0.9979718327522278, lat: null, lng: null, count: 1, topics: ['woedend'], group: 'piet', emojis: [], keywords: ['Klaver', 'leugenaar'], full_text: "Klaver is een opportunistische leugenaar! #Woedend", keyword_clusters: {'Klaver': 4, 'leugenaar': 0}},
{sentiment: 0.9780969023704529, lat: null, lng: null, count: 1, topics: ['boos'], group: 'piet', emojis: [], keywords: ['Rot', 'Klaver', 'beloftes', 'Boos'], full_text: "Rot op, Klaver! Je hebt niets bereikt met je loze beloftes. #Boos", keyword_clusters: {'Rot': 5, 'Klaver': 4, 'beloftes': 5, 'Boos': 5}},
{sentiment: 0.8247266411781311, lat: null, lng: null, count: 1, topics: ['razend'], group: 'piet', emojis: [], keywords: ['Jesse', 'Klaver', 'retoriek'], full_text: "Jesse Klaver, je maakt me ziek met je holle retoriek! #Razend", keyword_clusters: {'Jesse': 4, 'Klaver': 4, 'retoriek': 0}},
{sentiment: 0.023459136486053467, lat: null, lng: null, count: 1, topics: ['kwaad'], group: 'piet', emojis: [], keywords: ['vent', 'Klaver', 'Kwaad'], full_text: "Ik kan die vent Klaver echt niet uitstaan! #Kwaad", keyword_clusters: {'vent': 0, 'Klaver': 4, 'Kwaad': 0}},
{sentiment: 0.9981433153152466, lat: null, lng: null, count: 1, topics: ['furieus'], group: 'piet', emojis: ['🙈'], keywords: ['Klaver', 'lachertje', 'gedrag', 'Furieus'], full_text: "Klaver, jij bent een lachertje met je hypocriete gedrag! #Furieus 🙈", keyword_clusters: {'Klaver': 4, 'lachertje': 5, 'gedrag': 5, 'Furieus': 7}},
{sentiment: 0.9967852830886841, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['Klaver', 'mislukking', 'Woede'], full_text: "Klaver moet verdwijnen, hij is een politieke mislukking! #Woede", keyword_clusters: {'Klaver': 4, 'mislukking': 4, 'Woede': 0}},
{sentiment: 0.43261033296585083, lat: null, lng: null, count: 1, topics: ['woest'], group: 'piet', emojis: ['🙈'], keywords: ['Jesse', 'Klaver', 'schande', 'politiek', '🙈'], full_text: "Jesse Klaver, je bent een schande voor de politiek! #Woest 🙈", keyword_clusters: {'Jesse': 4, 'Klaver': 4, 'schande': 2, 'politiek': 1}},
{sentiment: 0.3460896611213684, lat: null, lng: null, count: 1, topics: ['boosheid'], group: 'piet', emojis: [], keywords: ['leugenaar', 'Klaver', 'Boosheid'], full_text: "Ik kan die leugenaar Klaver niet meer verdragen! #Boosheid", keyword_clusters: {'leugenaar': 0, 'Klaver': 4, 'Boosheid': 4}},
{sentiment: 0.9941603541374207, lat: null, lng: null, count: 1, topics: ['razernij'], group: 'piet', emojis: ['🙈'], keywords: ['Klaver', 'zakkenvuller', 'Razernij', '🙈'], full_text: "Klaver, je bent een onbetrouwbare zakkenvuller! #Razernij 🙈", keyword_clusters: {'Klaver': 4, 'zakkenvuller': 0, 'Razernij': 0}},
{sentiment: 0.9970097541809082, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['Jesse', 'Klaver', 'steek', 'Woede'], full_text: "Jesse Klaver, je hebt ons in de steek gelaten en teleurgesteld! #Woede", keyword_clusters: {'Jesse': 4, 'Klaver': 4, 'steek': 7, 'Woede': 0}},
{sentiment: 0.9966368675231934, lat: null, lng: null, count: 1, topics: ['steun'], group: 'piet', emojis: ['🚜', '🚜', '🚜'], keywords: ['Caroline', 'van', 'der', 'Plas', 'boerenbelangen', 'Steun', '🚜', '🚜', '🚜'], full_text: "Caroline van der Plas staat voor echte boerenbelangen! #Steun 🚜🚜🚜", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'boerenbelangen': 4, 'Steun': 7}},
{sentiment: 0.9965516328811646, lat: null, lng: null, count: 1, topics: ['support'], group: 'piet', emojis: ['🇳🇱'], keywords: ['politicus', 'boeren', 'Caroline', 'van', 'der', 'Plas', 'top', 'Support'], full_text: "Eindelijk een politicus die opkomt voor de boeren! Caroline van der Plas is top! #Support 🇳🇱", keyword_clusters: {'politicus': 7, 'boeren': 5, 'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'top': 4, 'Support': 0}},
{sentiment: 0.9958012700080872, lat: null, lng: null, count: 1, topics: ['stemophaar'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas', 'wind', 'politiek', 'StemOpHaar'], full_text: "Caroline van der Plas is een frisse wind in de politiek! #StemOpHaar", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'wind': 7, 'politiek': 1, 'StemOpHaar': 4}},
{sentiment: 0.996641993522644, lat: null, lng: null, count: 1, topics: ['supporter'], group: 'piet', emojis: ['🇳🇱', '🇳🇱', '🇳🇱'], keywords: ['fan', 'Caroline', 'van', 'der', 'Plas', 'standpunten', 'Supporter'], full_text: "Ik ben fan van Caroline van der Plas en haar standpunten! #Supporter 🇳🇱🇳🇱🇳🇱", keyword_clusters: {'fan': 7, 'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'standpunten': 0, 'Supporter': 7}},
{sentiment: 0.9974060654640198, lat: 53.0006, lng: 6.68635, count: 1, topics: ['steun'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas', 'stem', 'boeren', 'Steun'], full_text: "Caroline van der Plas geeft een stem aan de boeren, daar kunnen anderen wat van leren! #Steun", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'stem': 1, 'boeren': 5, 'Steun': 7}},
{sentiment: 0.9981714487075806, lat: null, lng: null, count: 1, topics: ['support'], group: 'piet', emojis: [], keywords: ['daadkracht', 'betrokkenheid', 'Caroline', 'van', 'der', 'Plas', 'Support'], full_text: "Echte daadkracht en betrokkenheid bij Caroline van der Plas, daar houd ik van! #Support", keyword_clusters: {'daadkracht': 0, 'betrokkenheid': 5, 'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'Support': 0}},
{sentiment: 0.9962177872657776, lat: null, lng: null, count: 1, topics: ['stemophaar'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas', 'boerenbelangen', 'arena', 'StemOpHaar'], full_text: "Caroline van der Plas brengt de boerenbelangen naar de politieke arena, geweldig! #StemOpHaar", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'boerenbelangen': 4, 'arena': 0, 'StemOpHaar': 4}},
{sentiment: 0.9964283108711243, lat: null, lng: null, count: 1, topics: ['steun'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas', 'stem', 'platteland', 'Steun'], full_text: "Caroline van der Plas, de stem van het platteland! #Steun", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'stem': 1, 'platteland': 7, 'Steun': 7}},
{sentiment: 0.9966036081314087, lat: null, lng: null, count: 1, topics: ['supporter'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas', 'verfrissing', 'politiek', 'Supporter'], full_text: "Caroline van der Plas is de verfrissing die de politiek nodig heeft! #Supporter", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'verfrissing': 5, 'politiek': 1, 'Supporter': 7}},
{sentiment: 0.9974445104598999, lat: null, lng: null, count: 1, topics: ['steun'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas', 'boeren', 'verstand', 'Steun'], full_text: "Met Caroline van der Plas hebben we eindelijk iemand die opkomt voor de boeren én het gezonde verstand! #Steun", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'boeren': 5, 'verstand': 7, 'Steun': 7}},
{sentiment: 0.9962999224662781, lat: null, lng: null, count: 1, topics: ['woedend'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas', 'opportunist'], full_text: "Caroline van der Plas is een populistische opportunist! #Woedend", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'opportunist': 5}},
{sentiment: 0.4520723819732666, lat: null, lng: null, count: 1, topics: ['boos'], group: 'piet', emojis: [], keywords: ['schijnheiligheid', 'Caroline', 'van', 'der', 'Plas', 'boerenstemmen', 'Boos'], full_text: "Wat een schijnheiligheid van Caroline van der Plas! Ze speelt alleen maar in op de boerenstemmen. #Boos", keyword_clusters: {'schijnheiligheid': 5, 'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'boerenstemmen': 0, 'Boos': 5}},
{sentiment: 0.997443675994873, lat: null, lng: null, count: 1, topics: ['razernij'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas', 'standpunten', 'Razernij'], full_text: "Caroline van der Plas, je maakt me razend met je simplistische standpunten! #Razernij", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'standpunten': 0, 'Razernij': 0}},
{sentiment: 0.029613971710205078, lat: null, lng: null, count: 1, topics: ['kwaad'], group: 'piet', emojis: [], keywords: ['vrouw', 'BBB', 'Caroline', 'van', 'der', 'Plas', 'mislukking', 'Kwaad'], full_text: "Ik kan die vrouw van de BBB niet uitstaan! Caroline van der Plas is een regelrechte mislukking! #Kwaad", keyword_clusters: {'vrouw': 0, 'BBB': 0, 'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'mislukking': 4, 'Kwaad': 0}},
{sentiment: 0.9982435703277588, lat: null, lng: null, count: 1, topics: ['furieus'], group: 'piet', emojis: [], keywords: ['Van', 'der', 'Plas', 'wolf', 'schaapskleren', 'praatjes', 'Furieus'], full_text: "Van der Plas, jij bent een wolf in schaapskleren met je populistische praatjes! #Furieus", keyword_clusters: {'Van': 7, 'der': 5, 'Plas': 4, 'wolf': 0, 'schaapskleren': 0, 'praatjes': 7, 'Furieus': 7}},
{sentiment: 0.9455951452255249, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas', 'schande', 'politiek', 'Woede'], full_text: "Caroline van der Plas, je bent een schande voor de politiek! #Woede", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'schande': 2, 'politiek': 1, 'Woede': 0}},
{sentiment: 0.9895836114883423, lat: null, lng: null, count: 1, topics: ['woest'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas'], full_text: "Ik kan die opportunistische Caroline van der Plas niet meer verdragen! #Woest", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4}},
{sentiment: 0.9963297247886658, lat: null, lng: null, count: 1, topics: ['boosheid'], group: 'piet', emojis: [], keywords: ['Caroline', 'van', 'der', 'Plas', 'leugenaar', 'gewin', 'Boosheid'], full_text: "Caroline van der Plas, je bent een leugenaar die alleen uit is op persoonlijk gewin! #Boosheid", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'leugenaar': 0, 'gewin': 0, 'Boosheid': 4}},
{sentiment: 0.995361864566803, lat: null, lng: null, count: 1, topics: ['razernij'], group: 'piet', emojis: [], keywords: ['Van', 'der', 'Plas', 'mislukking', 'retoriek', 'Razernij'], full_text: "Van der Plas, je bent een politieke mislukking met je holle retoriek! #Razernij", keyword_clusters: {'Van': 7, 'der': 5, 'Plas': 4, 'mislukking': 4, 'retoriek': 0, 'Razernij': 0}},
{sentiment: 0.997221827507019, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: ['🚜'], keywords: ['Caroline', 'van', 'der', 'Plas', 'praatjes', 'Woede', '🚜'], full_text: "Caroline van der Plas, je hebt ons teleurgesteld met je populistische praatjes! #Woede 🚜", keyword_clusters: {'Caroline': 5, 'van': 4, 'der': 5, 'Plas': 4, 'praatjes': 7, 'Woede': 0}},
{sentiment: 0.9981200098991394, lat: null, lng: null, count: 1, topics: ['wildersfann'], group: 'piet', emojis: [], keywords: ['Wldrs', 'bst', 'krachtdadg', 'opkmnd', 'onz', 'ntrljkhid', 'WildersFann'], full_text: "Wldrs is de bst, krachtdadg en opkmnd vr onz ntrljkhid! #WildersFann", keyword_clusters: {'Wldrs': 5, 'bst': 0, 'krachtdadg': 0, 'opkmnd': 5, 'onz': 4, 'ntrljkhid': 0, 'WildersFann': 5}},
{sentiment: 0.9979578256607056, lat: null, lng: null, count: 1, topics: ['grtwildrs'], group: 'piet', emojis: [], keywords: ['btwnstrss', 'bn', 'schildrs', 'vn', 'Wildrs', 'snppn', 'GrtWildrs'], full_text: "Al di btwnstrss is bn schildrs vn Wildrs, zij snppn ht! #GrtWildrs", keyword_clusters: {'btwnstrss': 0, 'bn': 7, 'schildrs': 7, 'vn': 4, 'Wildrs': 5, 'snppn': 7, 'GrtWildrs': 7}},
{sentiment: 0.9979603290557861, lat: null, lng: null, count: 1, topics: ['pvvfrt'], group: 'piet', emojis: [], keywords: ['Wildrs', 'Ndrland', 'Gn', 'mrd', 'Islam', 'bschrmng', 'PVVfrt'], full_text: "Wildrs vr Ndrland! Gn mrd Islam n bschrmng! #PVVfrt", keyword_clusters: {'Wildrs': 5, 'Ndrland': 0, 'Gn': 7, 'mrd': 7, 'Islam': 0, 'bschrmng': 0, 'PVVfrt': 5}},
{sentiment: 0.9981130361557007, lat: null, lng: null, count: 1, topics: ['grtfann'], group: 'piet', emojis: [], keywords: ['d', 'rst', 'di', 'lk', 'kr', 'opknmt', 'Ndrland', 'GrtFann'], full_text: "Wildrs is d rst di lk kr opknmt vr Ndrland! #GrtFann", keyword_clusters: {'d': 4, 'rst': 7, 'di': 5, 'lk': 7, 'kr': 4, 'opknmt': 0, 'Ndrland': 0, 'GrtFann': 5}},
{sentiment: 0.9978023171424866, lat: null, lng: null, count: 1, topics: ['pvvstrk'], group: 'piet', emojis: [], keywords: ['Wldrs', 'vrandring', 'Mndr', 'immigrati', 'n', 'mr', 'vzorgng', 'ns', 'land', 'PVVstrk'], full_text: "Wldrs, ht is tjd vr n vrandring. Mndr immigrati n mr vzorgng vr ns land! #PVVstrk", keyword_clusters: {'Wldrs': 5, 'vrandring': 7, 'Mndr': 7, 'immigrati': 5, 'n': 5, 'mr': 4, 'vzorgng': 4, 'ns': 0, 'land': 7, 'PVVstrk': 0}},
{sentiment: 0.9980698227882385, lat: null, lng: null, count: 1, topics: ['wildrsfvr'], group: 'piet', emojis: [], keywords: ['PVV', 'vr', 'onz', 'vrdrcht', 'WildrsFvr'], full_text: "PVV is d nign di vr onz vrdrcht zorgt! #WildrsFvr", keyword_clusters: {'PVV': 5, 'vr': 4, 'onz': 4, 'vrdrcht': 5, 'WildrsFvr': 0}},
{sentiment: 0.9977114200592041, lat: null, lng: null, count: 1, topics: ['grtwildrs'], group: 'piet', emojis: [], keywords: ['Wldrs', 'd', 'brgwrkncht', 'hbbn', 'Grn', 'hndj', 'Ndrland', 'GrtWildrs'], full_text: "Wldrs is d brgwrkncht di wij ndig hbbn. Grn hndj n grn Ndrland! #GrtWildrs", keyword_clusters: {'Wldrs': 5, 'd': 4, 'brgwrkncht': 7, 'hbbn': 4, 'Grn': 7, 'hndj': 0, 'Ndrland': 0, 'GrtWildrs': 7}},
{sentiment: 0.9975040555000305, lat: null, lng: null, count: 1, topics: ['pvv4vr'], group: 'piet', emojis: [], keywords: ['plitici', 'prttprtrs', 'Wildrs', 'zgr', 'hlndrznd', 'PVV4vr'], full_text: "Al di plitici zn prttprtrs. Wildrs vr d zgr, vr n hlndrznd! #PVV4vr", keyword_clusters: {'plitici': 5, 'prttprtrs': 5, 'Wildrs': 5, 'zgr': 0, 'hlndrznd': 0, 'PVV4vr': 7}},
{sentiment: 0.9968336224555969, lat: null, lng: null, count: 1, topics: ['wildrsftwrk'], group: 'piet', emojis: [], keywords: ['Wldrs', 'd', 'knmpr', 'di', 'Opkmn', 'vr', 'onz', 'waardn', 'cultuur', 'Wildrsftwrk'], full_text: "Wldrs is d knmpr di wij nodig hbbn. Opkmn vr onz waardn n cultuur! #Wildrsftwrk", keyword_clusters: {'Wldrs': 5, 'd': 4, 'knmpr': 5, 'di': 5, 'Opkmn': 4, 'vr': 4, 'onz': 4, 'waardn': 7, 'cultuur': 4, 'Wildrsftwrk': 0}},
{sentiment: 0.9981651902198792, lat: null, lng: null, count: 1, topics: ['pvvstrng'], group: 'piet', emojis: [], keywords: ['Grt', 'Wildrs', 'd', 'rst', 'vrcht', 'di', 'vr', 'onz', 'vrdrcht', 'PVVstrng'], full_text: "Grt Wildrs is d rst vrcht, di vr onz vrdrcht strdt! #PVVstrng", keyword_clusters: {'Grt': 7, 'Wildrs': 5, 'd': 4, 'rst': 7, 'vrcht': 4, 'di': 5, 'vr': 4, 'onz': 4, 'vrdrcht': 5, 'PVVstrng': 7}},
{sentiment: 0.025450408458709717, lat: null, lng: null, count: 1, topics: ['boos'], group: 'piet', emojis: [], keywords: ['boeren', 'plan', 'wegen', 'regels', 'Boos'], full_text: "Ik ben nu al boos op die boeren die van plan zijn de wegen te blokkeren. Respecteer de regels en anderen! #Boos", keyword_clusters: {'boeren': 5, 'plan': 0, 'wegen': 0, 'regels': 4, 'Boos': 5}},
{sentiment: 0.017175912857055664, lat: null, lng: null, count: 1, topics: ['woedend'], group: 'piet', emojis: [], keywords: ['egoïsme', 'onverantwoordelijkheid'], full_text: "Ik kan niet geloven dat ze dit gaan doen. Pure egoïsme en onverantwoordelijkheid! #Woedend", keyword_clusters: {'egoïsme': 2, 'onverantwoordelijkheid': 7}},
{sentiment: 0.9967323541641235, lat: null, lng: null, count: 1, topics: ['razend'], group: 'piet', emojis: [], keywords: ['boeren', 'leven'], full_text: "Deze boeren maken het leven moeilijk voor iedereen. Denken ze alleen aan zichzelf? #Razend", keyword_clusters: {'boeren': 5, 'leven': 7}},
{sentiment: 0.9235665202140808, lat: null, lng: null, count: 1, topics: ['kwaad'], group: 'piet', emojis: [], keywords: ['kwaad', 'boeren', 'Kwaad'], full_text: "Het is nog niet eens begonnen en ik ben nu al kwaad op die boeren die denken dat ze alles kunnen maken. #Kwaad", keyword_clusters: {'kwaad': 5, 'boeren': 5, 'Kwaad': 0}},
{sentiment: 0.9505734443664551, lat: null, lng: null, count: 1, topics: ['furieus'], group: 'piet', emojis: [], keywords: ['chaos', 'problemen', 'Furieus'], full_text: "Ik ben furieus dat ze dit willen doen. Het veroorzaakt alleen maar chaos en problemen. #Furieus", keyword_clusters: {'chaos': 4, 'problemen': 4, 'Furieus': 7}},
{sentiment: 0.99403315782547, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['boel', 'boeren', 'Woede'], full_text: "Waarom moeten ze altijd de boel verstieren? Ik ben woedend op die boeren! #Woede", keyword_clusters: {'boel': 0, 'boeren': 5, 'Woede': 0}},
{sentiment: 0.2769649624824524, lat: null, lng: null, count: 1, topics: ['woest'], group: 'piet', emojis: [], keywords: ['onzin'], full_text: "Ik ben nu al woest dat ze denken weg te komen met deze onzin. Stop ermee! #Woest", keyword_clusters: {'onzin': 7}},
{sentiment: 0.01996302604675293, lat: 53.0006, lng: 6.68635, count: 1, topics: ['boosheid'], group: 'piet', emojis: [], keywords: ['boeren', 'last', 'Boosheid'], full_text: "Deze boeren maken me boos. Ze moeten gewoon normaal doen en anderen niet tot last zijn! #Boosheid", keyword_clusters: {'boeren': 5, 'last': 5, 'Boosheid': 4}},
{sentiment: 0.8097662329673767, lat: 53.0006, lng: 6.68635, count: 1, topics: ['razernij'], group: 'piet', emojis: [], keywords: ['verantwoordelijkheid', 'Razernij'], full_text: "Het is belachelijk dat ze dit overwegen. Ze moeten hun verantwoordelijkheid nemen en anderen niet benadelen! #Razernij", keyword_clusters: {'verantwoordelijkheid': 4, 'Razernij': 0}},
{sentiment: 0.018300414085388184, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['boeren', 'wet', 'plannen', 'Woede'], full_text: "Ik ben nu al woedend op die boeren die denken dat ze boven de wet staan. Stop met deze kinderachtige plannen! #Woede", keyword_clusters: {'boeren': 5, 'wet': 0, 'plannen': 7, 'Woede': 0}},
{sentiment: 0.9981314539909363, lat: null, lng: null, count: 1, topics: ['boos'], group: 'piet', emojis: [], keywords: ['demonstratie', 'wegen', 'Boos'], full_text: "Deze demonstratie is belachelijk! Stop met het blokkeren van de wegen! #Boos", keyword_clusters: {'demonstratie': 0, 'wegen': 0, 'Boos': 5}},
{sentiment: 0.7198805212974548, lat: 53.0006, lng: 6.68635, count: 1, topics: ['woedend'], group: 'piet', emojis: [], keywords: ['onzin', 'boerenprotesten', 'werk'], full_text: "Wat een onzin, die boerenprotesten! Ze moeten gewoon hun werk doen en niet anderen lastigvallen! #Woedend", keyword_clusters: {'onzin': 7, 'boerenprotesten': 4, 'werk': 4}},
{sentiment: 0.994889497756958, lat: null, lng: null, count: 1, topics: ['razend'], group: 'piet', emojis: [], keywords: ['protest', 'verspilling', 'tijd', 'geld'], full_text: "Dit protest is een verspilling van tijd en geld. Ga iets nuttigs doen! #Razend", keyword_clusters: {'protest': 1, 'verspilling': 5, 'tijd': 3, 'geld': 7}},
{sentiment: 0.9973623156547546, lat: null, lng: null, count: 1, topics: ['kwaad'], group: 'piet', emojis: [], keywords: ['boeren', 'overlast', 'schade', 'Kwaad'], full_text: "De boeren denken alleen aan zichzelf. Ze veroorzaken alleen maar overlast en schade. #Kwaad", keyword_clusters: {'boeren': 5, 'overlast': 1, 'schade': 4, 'Kwaad': 0}},
{sentiment: 0.018522918224334717, lat: null, lng: null, count: 1, topics: ['furieus'], group: 'piet', emojis: [], keywords: ['boeren', 'podium', 'Furieus'], full_text: "Ik ben klaar met die boeren die denken dat ze alles kunnen maken. Geef ze geen podium! #Furieus", keyword_clusters: {'boeren': 5, 'podium': 4, 'Furieus': 7}},
{sentiment: 0.9844290018081665, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['protest', 'aanfluiting', 'onderhandelingstafel', 'Woede'], full_text: "Dit protest is een aanfluiting. Ze moeten gewoon aan de onderhandelingstafel gaan zitten. #Woede", keyword_clusters: {'protest': 1, 'aanfluiting': 4, 'onderhandelingstafel': 0, 'Woede': 0}},
{sentiment: 0.02155900001525879, lat: null, lng: null, count: 1, topics: ['woest'], group: 'piet', emojis: [], keywords: ['verkeer', 'actievoerders'], full_text: "Ik ben het zat om vast te staan in het verkeer vanwege deze egoïstische actievoerders! #Woest", keyword_clusters: {'verkeer': 5, 'actievoerders': 7}},
{sentiment: 0.9686121940612793, lat: null, lng: null, count: 1, topics: ['boosheid'], group: 'piet', emojis: [], keywords: ['boeren', 'belang', 'gedrag', 'Boosheid'], full_text: "Deze boeren denken alleen aan hun eigen belang. Ze moeten ophouden met hun kinderachtige gedrag! #Boosheid", keyword_clusters: {'boeren': 5, 'belang': 7, 'gedrag': 5, 'Boosheid': 4}},
{sentiment: 0.9943085312843323, lat: null, lng: null, count: 1, topics: ['razernij'], group: 'piet', emojis: [], keywords: ['demonstratie', 'impact', 'leven', 'Razernij'], full_text: "Het is belachelijk dat deze demonstratie zo'n impact heeft op het dagelijks leven van anderen. Stop ermee! #Razernij", keyword_clusters: {'demonstratie': 0, 'impact': 5, 'leven': 7, 'Razernij': 0}},
{sentiment: 0.9950258135795593, lat: null, lng: null, count: 1, topics: ['woede'], group: 'piet', emojis: [], keywords: ['protest', 'overlast', 'samenleving', 'Boeren', 'werk', 'Woede'], full_text: "Dit protest is een pure overlast voor de samenleving. Boeren, ga gewoon aan het werk! #Woede", keyword_clusters: {'protest': 1, 'overlast': 1, 'samenleving': 7, 'Boeren': 7, 'werk': 4, 'Woede': 0}},
{sentiment: 0.9959331154823303, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'fvd'], group: 'piet', emojis: [], keywords: ['boeren', 'regelgeving', 'ruggengraat', 'Nederland', 'SteunDeBoeren', 'FVD'], full_text: "Geweldig om te zien dat de boeren opstaan tegen de verstikkende regelgeving! Jullie zijn de ruggengraat van Nederland! #SteunDeBoeren #FVD", keyword_clusters: {'boeren': 5, 'regelgeving': 0, 'ruggengraat': 0, 'Nederland': 5, 'SteunDeBoeren': 5, 'FVD': 4}},
{sentiment: 0.9959614872932434, lat: null, lng: null, count: 1, topics: ['boerenprotest', 'fvd'], group: 'piet', emojis: ['🚜'], keywords: ['boeren', 'arrogantie', 'Den', 'Haag', 'Tijd', 'verandering', 'erkenning', 'rol', 'Boerenprotest', 'FVD', '🚜'], full_text: "De boeren hebben genoeg van de arrogantie van Den Haag. Tijd voor verandering en erkenning van hun belangrijke rol! #Boerenprotest #FVD 🚜", keyword_clusters: {'boeren': 5, 'arrogantie': 0, 'Den': 0, 'Haag': 5, 'Tijd': 4, 'verandering': 1, 'erkenning': 5, 'rol': 5, 'Boerenprotest': 4, 'FVD': 4}},
{sentiment: 0.9979704022407532, lat: null, lng: null, count: 1, topics: ['fvd', 'boeren'], group: 'piet', emojis: ['🚜'], keywords: ['boerenprotest', 'tijd', 'koerswijziging', 'boeren', 'steun', 'bureaucratie', 'FVD', 'Boeren', '🚜'], full_text: "Dit boerenprotest laat zien dat het tijd is voor een radicale koerswijziging. Onze boeren verdienen betere steun en minder bureaucratie! #FVD #Boeren 🚜", keyword_clusters: {'boerenprotest': 1, 'tijd': 3, 'koerswijziging': 4, 'boeren': 5, 'steun': 7, 'bureaucratie': 0, 'FVD': 4, 'Boeren': 7}},
{sentiment: 0.16883361339569092, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'fvd'], group: 'piet', emojis: [], keywords: ['regering', 'boeren', 'steek', 'strijd', 'eerlijkheid', 'respect', 'SteunDeBoeren', 'FVD'], full_text: "Het is schandalig hoe de regering de boeren in de steek laat. Wij staan achter jullie strijd voor eerlijkheid en respect! #SteunDeBoeren #FVD", keyword_clusters: {'regering': 1, 'boeren': 5, 'steek': 7, 'strijd': 7, 'eerlijkheid': 7, 'respect': 7, 'SteunDeBoeren': 5, 'FVD': 4}},
{sentiment: 0.996292233467102, lat: null, lng: null, count: 1, topics: ['fvd', 'boerenprotest'], group: 'piet', emojis: [], keywords: ['boeren', 'werk', 'waarde', 'Tijd', 'landbouwbeleid', 'FVD', 'Boerenprotest'], full_text: "De boeren hebben gelijk: hun werk is van onschatbare waarde en mag niet worden genegeerd. Tijd voor een realistisch landbouwbeleid! #FVD #Boerenprotest", keyword_clusters: {'boeren': 5, 'werk': 4, 'waarde': 5, 'Tijd': 4, 'landbouwbeleid': 7, 'FVD': 4, 'Boerenprotest': 4}},
{sentiment: 0.9949951171875, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'fvd'], group: 'piet', emojis: ['🚜'], keywords: ['tijd', 'boeren', 'handen', 'elite', 'verandering', 'SteunDeBoeren', 'FVD', '🚜'], full_text: "Het is hoog tijd dat de boeren het heft in eigen handen nemen. Samen kunnen we de politieke elite wakker schudden en verandering eisen! #SteunDeBoeren #FVD 🚜", keyword_clusters: {'tijd': 3, 'boeren': 5, 'handen': 5, 'elite': 0, 'verandering': 1, 'SteunDeBoeren': 5, 'FVD': 4}},
{sentiment: 0.024951577186584473, lat: null, lng: null, count: 1, topics: ['fvd', 'boeren'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'signaal', 'regering', 'stop', 'boeren', 'regels', 'eisen', 'FVD', 'Boeren'], full_text: "Het boerenprotest is een krachtig signaal naar de regering: stop met het verstikken van onze boeren met onzinnige regels en eisen! #FVD #Boeren", keyword_clusters: {'boerenprotest': 1, 'signaal': 4, 'regering': 1, 'stop': 7, 'boeren': 5, 'regels': 4, 'eisen': 5, 'FVD': 4, 'Boeren': 7}},
{sentiment: 0.9975759387016296, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'fvd'], group: 'piet', emojis: ['🚜', '🇳🇱'], keywords: ['boeren', 'respect', 'steun', 'betutteling', 'beperkingen', 'sector', 'SteunDeBoeren', 'FVD', '🚜'], full_text: "De boeren verdienen respect en steun, geen betutteling en beperkingen. Wij zijn trots op onze agrarische sector en laten dat horen! #SteunDeBoeren #FVD 🚜🇳🇱", keyword_clusters: {'boeren': 5, 'respect': 7, 'steun': 7, 'betutteling': 5, 'beperkingen': 4, 'sector': 0, 'SteunDeBoeren': 5, 'FVD': 4}},
{sentiment: 0.9975098371505737, lat: null, lng: null, count: 1, topics: ['fvd', 'boerenprotest'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'onvrede', 'onmacht', 'Nederlanders', 'beleid', 'FVD', 'Boerenprotest'], full_text: "Dit boerenprotest toont de onvrede en onmacht die veel Nederlanders voelen. Laten we samen opstaan voor een eerlijk beleid! #FVD #Boerenprotest", keyword_clusters: {'boerenprotest': 1, 'onvrede': 5, 'onmacht': 5, 'Nederlanders': 7, 'beleid': 7, 'FVD': 4, 'Boerenprotest': 4}},
{sentiment: 0.9966511130332947, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'fvd'], group: 'piet', emojis: ['🇳🇱'], keywords: ['boeren', 'steun', 'strijd', 'regelgeving', 'vuist', 'Nederland', 'SteunDeBoeren', 'FVD'], full_text: "De boeren hebben onze steun nodig in hun strijd tegen de verstikkende regelgeving. Samen kunnen we een vuist maken voor een beter Nederland! #SteunDeBoeren #FVD 🇳🇱", keyword_clusters: {'boeren': 5, 'steun': 7, 'strijd': 7, 'regelgeving': 0, 'vuist': 5, 'Nederland': 5, 'SteunDeBoeren': 5, 'FVD': 4}},
{sentiment: 0.9975206255912781, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'pvv'], group: 'piet', emojis: ['🚜'], keywords: ['tijd', 'stem', 'boeren', 'demonstratie', 'beleid', 'erkenning', 'werk', 'SteunDeBoeren', 'PVV', '🚜'], full_text: "Het is tijd dat de stem van de boeren gehoord wordt! Steun deze demonstratie voor een eerlijk beleid en erkenning van hun harde werk! #SteunDeBoeren #PVV 🚜", keyword_clusters: {'tijd': 3, 'stem': 1, 'boeren': 5, 'demonstratie': 0, 'beleid': 7, 'erkenning': 5, 'werk': 4, 'SteunDeBoeren': 5, 'PVV': 5}},
{sentiment: 0.9953542947769165, lat: null, lng: null, count: 1, topics: ['boerenprotest', 'pvv'], group: 'piet', emojis: ['🇳🇱'], keywords: ['boeren', 'betutteling', 'regelzucht', 'Den', 'Haag', 'Tijd', 'signaal', 'belangen', 'Boerenprotest', 'PVV'], full_text: "De boeren hebben genoeg van de betutteling en regelzucht van Den Haag. Tijd voor een krachtig signaal dat hun belangen serieus genomen moeten worden! #Boerenprotest #PVV 🇳🇱", keyword_clusters: {'boeren': 5, 'betutteling': 5, 'regelzucht': 5, 'Den': 0, 'Haag': 5, 'Tijd': 4, 'signaal': 4, 'belangen': 1, 'Boerenprotest': 4, 'PVV': 5}},
{sentiment: 0.9973598122596741, lat: null, lng: null, count: 1, topics: ['pvv', 'boerenprotest'], group: 'piet', emojis: [], keywords: ['boeren', 'steun', 'waardering', 'rechten', 'toekomst', 'sector', 'PVV', 'Boerenprotest'], full_text: "De boeren verdienen onze steun en waardering. Laten we samen opkomen voor hun rechten en strijden voor een gezonde toekomst van onze agrarische sector! #PVV #Boerenprotest", keyword_clusters: {'boeren': 5, 'steun': 7, 'waardering': 4, 'rechten': 1, 'toekomst': 1, 'sector': 0, 'PVV': 5, 'Boerenprotest': 4}},
{sentiment: 0.9977006316184998, lat: 52.2434979, lng: 5.6343227, count: 1, topics: ['steundeboeren', 'pvv'], group: 'piet', emojis: [], keywords: ['demonstratie', 'boeren', 'stem', 'Nederland', 'boeren', 'SteunDeBoeren', 'PVV'], full_text: "Deze demonstratie laat zien dat de boeren terecht hun stem willen laten horen. Laten we samen opstaan voor een Nederland waar boeren trots op kunnen zijn! #SteunDeBoeren #PVV", keyword_clusters: {'demonstratie': 0, 'boeren': 5, 'stem': 1, 'Nederland': 5, 'SteunDeBoeren': 5, 'PVV': 5}},
{sentiment: 0.9972991347312927, lat: null, lng: null, count: 1, topics: ['pvv', 'boerenprotest'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'wake-upcall', 'elite', 'boeren', 'regels', 'eisen', 'PVV', 'Boerenprotest'], full_text: "Het boerenprotest is een wake-upcall voor de politieke elite. Stop met het verstikken van onze boeren met onnodige regels en eisen! #PVV #Boerenprotest", keyword_clusters: {'boerenprotest': 1, 'wake-upcall': 4, 'elite': 0, 'boeren': 5, 'regels': 4, 'eisen': 5, 'PVV': 5, 'Boerenprotest': 4}},
{sentiment: 0.9951561689376831, lat: 52.2434979, lng: 5.6343227, count: 1, topics: ['steundeboeren', 'pvv'], group: 'piet', emojis: ['🚜', '🇳🇱'], keywords: ['boeren', 'ruggengraat', 'Nederland', 'respect', 'inzet', 'bijdrage', 'voedselvoorziening', 'SteunDeBoeren', 'PVV', '🚜'], full_text: "De boeren zijn de ruggengraat van Nederland en verdienen respect voor hun inzet en bijdrage aan onze voedselvoorziening. Laten we samen achter hen staan! #SteunDeBoeren #PVV 🚜🇳🇱", keyword_clusters: {'boeren': 5, 'ruggengraat': 0, 'Nederland': 5, 'respect': 7, 'inzet': 4, 'bijdrage': 0, 'voedselvoorziening': 5, 'SteunDeBoeren': 5, 'PVV': 5}},
{sentiment: 0.9957075119018555, lat: null, lng: null, count: 1, topics: ['pvv', 'boerenprotest'], group: 'piet', emojis: [], keywords: ['tijd', 'boeren', 'stem', 'recht', 'landbouwsector', 'PVV', 'Boerenprotest'], full_text: "Het is tijd om de boeren een eerlijke stem te geven. Laat je horen en vecht voor hun recht op een duurzame en bloeiende landbouwsector! #PVV #Boerenprotest", keyword_clusters: {'tijd': 3, 'boeren': 5, 'stem': 1, 'recht': 0, 'landbouwsector': 5, 'PVV': 5, 'Boerenprotest': 4}},
{sentiment: 0.9957263469696045, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'pvv'], group: 'piet', emojis: ['🇳🇱'], keywords: ['boeren', 'toekomst', 'sector', 'tijd', 'landbouwbeleid', 'belangen', 'SteunDeBoeren', 'PVV'], full_text: "De boeren zijn terecht boos en bezorgd over de toekomst van hun sector. Het is tijd voor een realistisch landbouwbeleid dat hun belangen centraal stelt! #SteunDeBoeren #PVV 🇳🇱", keyword_clusters: {'boeren': 5, 'toekomst': 1, 'sector': 0, 'tijd': 3, 'landbouwbeleid': 7, 'belangen': 1, 'SteunDeBoeren': 5, 'PVV': 5}},
{sentiment: 0.9939246773719788, lat: null, lng: null, count: 1, topics: ['pvv', 'boerenprotest'], group: 'piet', emojis: [], keywords: ['boerenprotest', 'noodkreet', 'mensen', 'gevoel', 'steek', 'stem', 'PVV', 'Boerenprotest'], full_text: "Dit boerenprotest is een noodkreet van hardwerkende mensen die het gevoel hebben dat ze in de steek worden gelaten. Laten we hun stem luider laten klinken! #PVV #Boerenprotest", keyword_clusters: {'boerenprotest': 1, 'noodkreet': 5, 'mensen': 0, 'gevoel': 4, 'steek': 7, 'stem': 1, 'PVV': 5, 'Boerenprotest': 4}},
{sentiment: 0.996397078037262, lat: null, lng: null, count: 1, topics: ['steundeboeren', 'pvv'], group: 'piet', emojis: ['🚜', '🇳🇱'], keywords: ['boeren', 'steun', 'dankbaarheid', 'recht', 'toekomst', 'Nederland', 'SteunDeBoeren', 'PVV', '🚜'], full_text: "De boeren verdienen onze steun en dankbaarheid. Laten we samen opkomen voor hun recht op een gezonde en welvarende toekomst in Nederland! #SteunDeBoeren #PVV 🚜🇳🇱", keyword_clusters: {'boeren': 5, 'steun': 7, 'dankbaarheid': 7, 'recht': 0, 'toekomst': 1, 'Nederland': 5, 'SteunDeBoeren': 5, 'PVV': 5}},
    ],
  },
  mutations: {
    updateTweets (state, newTweetList) {
      state.tweets = newTweetList;
    },
    updateKeywords (state, newKeywordList) {
      state.keywords = newKeywordList;
    },
    addIncident (state, incident) {
      state.incidents.push(incident)
    },
    setIncidents (state, data) {
      state.incidents = data
      state.dataLoading = false
    },
    updateIncident (state, data) {
      let i = state.incidents.findIndex(x => x.id === data.id)
      state.incidents[i] = Object.assign(state.incidents[i], data.data)
    },
    addRelation (state, data) {
      let i = state.incidents.findIndex(x => x.id === data.incident_id)
      if(data.tweet) state.incidents[i].related_tweets.push(data.tweet)
      state.incidents[i].related_tweet_ids.push(data.tweet_id)
    },
    addAlert (state, alert) {
      state.alerts.push(alert)
    },
    deleteAlert (state, index) {
      state.alerts.splice(index, 1)
    },
    setApiInfo (state, data) {
      state.api_info = data
    },
    setDetectionSystemCount (state, data) {
      state.detectionSystemCount = data
    },
    markIncidentAsSeen (state, id) {
      let i = state.incidents.findIndex(x => x.id === id)
      state.incidents[i]["seen"] = true
    }
  },
  actions: {
    addIncident (context, incident) {
      if(!this.state.incidents.find(x => x.id === incident.id)) {
        incident["seen"] = false
        context.commit('addIncident', incident)
      }else{
        // Duplicate
      }
    },
    setIncidents (context, data) {
      context.commit('setIncidents', data)
    },
    updateIncident (context, data) {
      context.commit('updateIncident', data)
    },
    markIncidentAsSeen (context, id) {
      context.commit('markIncidentAsSeen', id)
    },
    addRelation (context, data) {
      context.commit('addRelation', data)
    },
    addAlert (context, alert) {
      if(!this.state.alerts.find(x => x.id === alert.id)) context.commit('addAlert', alert)
    },
    deleteAlert (context, id) {
      let index = this.state.alerts.findIndex(x => x.id === id)
      if(index > -1) context.commit('deleteAlert', index)
    },
    setApiInfo (context, data) {
      context.commit('setApiInfo', data)
    },
    setDetectionSystemCount (context, data) {
      context.commit('setDetectionSystemCount', data)
    }
  },
  getters: {
    getIncidents: state => {
      return state.incidents
    },
    getIncident: state => (id) => {
      return state.incidents.find(x => x.id === id)
    },
    getIncidentIDs: state => {
      let ids = []
      for(let i = 0; i < state.incidents.length; i++) {
        ids.push(state.incidents[i].id)
      }
      return ids
    },
    getLastIncidentID: state => {
      return state.incidents[state.incidents.length - 1]?.id
    },
    getAlerts: state => {
      return state.alerts
    },
    getApiInfo: state => {
      return state.api_info
    },
    getLoadingState: state => {
      return state.dataLoading
    },
    getSystemCount: state => {
      return state.detectionSystemCount
    }
  },
  modules: {
  }
})

