<template>
  <div id="help">
    <v-row no-gutters>
      <v-card
          max-width="400"
          class="mr-4"
      >
        <v-card-title>
          Topic Netwerk
        </v-card-title>
        <div style="width: 100%; height: 300px; overflow:hidden;">
          <img class="help-image" src="../assets/help/topic_network.png">
        </div>
        <v-card-text>
Dit netwerk toont alle onderwerpen van de geselecteerde tweets. Je kunt inzoomen met het scrollwheel van de muis, zodat de labels zichtbaar worden. De onderwerpen zijn gegroepeerd in verschillende clusters die allemaal een eigen kleur hebben. Wanneer onderwerpen vaak samengaan in dezelfde tweet, zullen ze een verbinding krijgen. Hoe vaker een onderwerp voorkomt, hoe groter het onderwerp wordt afgebeeld in het netwerk.
        </v-card-text>
      </v-card>
      <v-card
          max-width="400"
          class="mr-4"
      >
        <v-card-title>
          Kaart
        </v-card-title>
        <div style="width: 100%; height:250px; overflow:hidden;">
          <img style="margin-top: -280px;" class="help-image" src="../assets/help/heatmap.png">
        </div>
        <v-card-text>
          De kaart toont locaties die voorkomen in de geselecteerde Tweets. Deze locaties zijn gebaseerd op:
          <ul>
            <li>Plaatsen in tekst</li>
            <li>GPS coördinaten van de auteur tijdens het plaatsen</li>
            <li>Een door de auteur opgegeven locatie</li>
          </ul>
          Hoe vaker een locatie voorkomt binnen een incident, hoe intenser dit wordt weergegeven op de kaart. Op bovenstaande kaart worden er meerdere locaties weergegeven, maar Rotterdam komt het vaakst voor en wordt daarom ook intenser weergegeven.
        </v-card-text>
      </v-card>
      <v-card
          max-width="400"
          class="mr-4"
      >
        <v-card-title>
          Emojis
        </v-card-title>
        <img width="100%" src="../assets/help/emoji_list.png" style="margin-top:110px; margin-bottom:120px;">
        <v-card-text>
De emojisbar is een balk of rij van emoji-symbolen die worden weergegeven op basis van de frequentie waarmee ze voorkomen in de geselecteerde tweets. De emoji die het meest frequent voorkomt, wordt aan de linkerkant geplaatst, gevolgd door emoji's die minder vaak worden gebruikt. Met andere woorden, de volgorde van de emoji's op de balk geeft aan hoe vaak ze in de tweets voorkomen, waarbij de meest voorkomende aan de linkerkant wordt geplaatst.
        </v-card-text>
      </v-card>
      <v-card
          max-width="400"
          class="mr-4"
      >
        <v-card-title>
          Sentiment Topics
        </v-card-title>
        <img width="100%" src="../assets/help/topic_sentiment.png" style="margin-top:80px; margin-bottom:100px;">
        <v-card-text>
De weergeven van hashtags uit de geselecteerde tweets, waarbij de kleur van de hashtag aangeeft welk sentiment de bijbehorende tweet uitdrukt. In dit geval wordt aangegeven dat hoe roder de kleur is, des te duidelijker is de tweet aangemerkt als een uiting van onvrede. Met andere woorden, de kleuring van de hashtags geeft aan in hoeverre de tweets negatieve gevoelens of ontevredenheid uitdrukken, waarbij een roder kleurenspectrum wijst op een sterker gevoel van onvrede.
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Help',
  data: function() {
    return {

    }
  }
}
</script>

<style scoped>

  .help-image {
    object-fit: cover;
  }

</style>

