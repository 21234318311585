<template>
  <div id="main">
    <v-row>
      <v-col>
        <smid-searchbar></smid-searchbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <smid-topics :tweets="tweetsX"></smid-topics>
        <smid-emoji-bar :tweets="tweetsX"></smid-emoji-bar>
        <smid-network :tweets="tweetsX"></smid-network>
      </v-col>
      <v-col>
        <smid-map :tweets="tweetsX"></smid-map>
      </v-col>
    </v-row>
    <smid-confirm-dialog ref="confirm" />
  </div>
</template>

<script>
// @ is an alias to /src
import Map from '@/components/Map'
import SearchBar from '@/components/SearchBar'
import Network from '@/components/Network'
import Topics from '@/components/Topics'
import EmojiBar from '@/components/EmojiBar'
import ConfirmDlg from '@/components/ConfirmDlg'
import { bus } from '../main'
import { HTTP } from '@/services/axios.js'

export default {
  name: 'Home',
  components:{
    "smid-map": Map,
    "smid-network": Network,
    "smid-searchbar": SearchBar,
    "smid-topics": Topics,
    "smid-emoji-bar": EmojiBar,
    "smid-confirm-dialog": ConfirmDlg,
  },
  data: function() {
    return {
      selectedIncident: null,
      binSize: null,
      tweetsDialog: false,
      confirmDialog: false,
      currentIncidentID: null,
      imagesDialog: false,
      url: window.location.hostname == 'localhost' ? 'http://localhost:8000/' : 'https://' + window.location.hostname + '/api/',
      interval: null
    }
  },
  methods: {
    async emitInc(command, id) {
      let title = ""
      let text = ""
      let options = {}
      switch(command) {
        case "confirm":
          title = "Bevestigen"
          text = "Weet je zeker dat je wilt bevestigen dat dit incident (" + id + ") een incident is?",
          options = {'color': 'primary'}
          break
        case "delete":
          title = "Verwijderen"
          text = "Weet je zeker dat je dit incident (" + id + ") wilt verwijderen? Doe dit alleen als het incident geen incident blijkt te zijn.",
          options = {'color': 'error'}
          break
        case "complete":
          title = "Afronden"
          text = "Weet je zeker dat je dit incident (" + id + ") wilt markeren als afgerond?",
          options = {'color': 'success'}
          break
      }
      if(
        await this.$refs.confirm.open(
          title,
          text,
          options
        )
      ) {
        bus.$emit('emit', {"message": command, "id": id})
      }
    },
    selectIncident(id) {
      if(this.currentIncidentID === id) {
        this.currentIncidentID = null
        clearInterval(this.interval)
      } else {
        this.currentIncidentID = id
        clearInterval(this.interval)
      }
      this.binSize = null
      this.$store.dispatch('markIncidentAsSeen', id)
      setTimeout(() => {
        this.updateCurrentIncident()
          this.interval = setInterval(() => {
            this.updateCurrentIncident()
          }, 60000)
      }, 3000)
    },
    updateCurrentIncident() {
      let id = this.currentIncidentID
      if(id != null){
        HTTP
          .get('/incident/' + id)
          .then(response => (
            this.$store.dispatch('updateIncident', {'id': id, 'data': response.data})
          ))
      }
    }
  },
  watch: {
    // Notification counter
    unseenCount(count) {
      // console.log(count)
      document.title = count > 0 ? "SMID (" + count + ")" : "SMID"
    }
  },
  computed: {
    // incidents() {
    //   let incidents = this.$store.getters.getIncidents
    //   return incidents?.sort(function (a, b) {
    //     return b.id > a.id
    //   })
    // },
    // This is a seperate computed function, as adding the places in the function that references the store results in an infinite loop warning (adding the places triggers an update, which triggers adding the places...)
    // incidentsWithPlaces() {
    //   let incidents = this.incidents
    //   for(let i = 0; i < incidents.length; i++) {
    //     let incident = incidents[i]
    //     let tweets = incident.related_tweets
    //     let places = []
    //     places = [].concat.apply([], tweets.map(x => x.pre_places_in_text ? JSON.parse(x.pre_places_in_text.replace(/'/g, '"').replace(/\(/g, '"(').replace(/\)/g, ')"')).map(x => x.place) : []))
    //     let exclude = ["adam", "denemarken", "overeind", "voort", "noorden", "putten", "ruiten", "sprong", "stein", "toom", "vrij", "veld", "stationsplein", "anderen", "engeland", "frankrijk", "keur", "hinder", "look", "linie", "onrust", "onderuit", "ren", "uitweg", "vechten", "viel", "west", "well", "wijk", "winkel", "wandelbos", "waarde", "ziek", "alm", "appel", "helden", "hond", "drie", "eiland", "neer", "noord", "rutten", "rotte", "leest", "strand", "straten", "woord", "hoef", "laan", "eind", "boord", "acht", "dijk", "gun", "hoek", "echt", "burg", "middel", "park", "plein", "leek", "johanna", "klimmen", "rijen", "top", "val", "vecht", "zand", "zetten", "zuid", "station", "binnenstad", "heel", "mark", "amerika", "hoeven", "dam", "waard", "zittend", "driehoek", "bent", "leg", "hal", "haag", "straat", "stadhuis", "einde", "keer", "haring", "haven", "gaal", "aanzee", "honderd", "blokker", "brand", "baal", "belt", "gauw", "geest", "gooi", "heer", "heers", "haren", "dorp", "dingen", "centrum", "brug", "broek", "best", "holy", "hop", "koekoek", "kopstukken", "meent", "partij", "raam", "raar"]
    //     places = places.filter(x => exclude.indexOf(x) < 0)
    //     incidents[i]['places'] = places
    //     let count = {}
    //     for(let j = 0; j < places.length; j++) {
    //       count[places[j]] = (count[places[j]] || 0) + 1
    //     }
    //     if(Object.entries(count).length > 0) {
    //       incidents[i]["pre_estimated_location"] = Object.keys(count).reduce((a, b) => count[a] > count[b] ? a : b)
    //     } else {
    //       incidents[i]["pre_estimated_location"] = ""
    //     }
    //   }
    //   return incidents
    // },
    // unseenCount() {
    //   return this.incidents.filter(x => x.seen == false).length
    // },
    // currentIncident() {
    //   return this.incidentsWithPlaces.find(x => x.id === this.currentIncidentID)
    // },
    // currentRelatedTweets() {
    //   let tweets = this.currentIncident?.related_tweets
    //   return  tweets?.sort(function (a, b) {
    //     return a.created_at.localeCompare(b?.created_at);
    //   })
    // },
    // mapTweets() {
    //   let tweets = []
    //   if (this.currentIncident) {
    //     tweets = this.currentIncident.related_tweets
    //   } else{
    //     // If no incident is selected, merge all related Tweets to show them all on a heatmap
    //     for (let i = 0; i < this.incidents.length; i++) {
    //       tweets.push(...this.incidents[i].related_tweets)
    //     }
    //   }
    //   return tweets
    // },
    tweetsX() {
      return [
      // {sentiment: 0.1, lat:52.3670076, lng:4.9019191, count: 25, topics: ["demo", "amsterdam", "farmers"], group: "piet", connections: ["jaap", "jan"]},
      // {sentiment: 0.8, lat:52.3633037, lng:4.8745924, count: 1, topics: ["amsterdam", "tulpen"], group: "koos", connections: []},
      // {sentiment: 0.6, lat:52.4449827, lng:4.6032182, count: 1, topics: ["demo", "BBB"], group: "jaap", connections: []},
      // {sentiment: 0.7, lat:52.3763354, lng:4.631018, count: 1, topics: ["demo", "van der plas"], group: "jan", connections: ["bas"]},
      // {sentiment: 0.2, lat:51.8800309, lng:4.5574057, count: 2, topics: ["BBB", "farmers"], group: "bas", connections: ["bas"]},







      ]
    },
    loading() {
      return this.$store.getters.getLoadingState
    }
  }
}

</script>

<style>
  .timeline-bar {
    display: inline-block;
    min-height: 1px;
    width: 100%;
    background-color:#004682;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #000;
  }
  .timeline-container {
    display: flex; 
    flex-direction: column;
    line-height: 1em;
  }
  .timeline-spacer {
    flex-grow: 1;
  }
  .timeline-labels {
    font-size: 0.75em;
  }
  .timeline-x-labels {
    position:absolute;
    bottom:-2.75em;
    border-left: 1px dashed grey;
  }
  .timeline-y-col1 {
    min-width: 25px !important;
    max-width: 25px !important;
    margin-top: 50px;
  }
  .timeline-y-col2 {
    min-width: 35px !important;
    max-width: 35px !important;
    border-right: 1px solid #000;
    text-align: right;
  }
  .timeline-y-label {
    position: absolute;
    left: 25px;
    width: calc(100% - 27px);
    border-top: 1px dashed #004682;
    padding-right: 2px;
  }
  .timeline-y-zero {
    bottom: -1.3em;
  }
  .timeline-y-label > span {
    display: block;
    margin-top: 0px;
  }
  .timeline-row {
    position:relative; 
    height:200px; 
    margin: 0 0 calc(1.6em + 15px) 0 !important;
  }
  .incident-new {
    background: #EF9A9A;
  }
</style>

